import React from "react";
import { Text4, Text5 } from "../../mainComponent/Paragraph";
import opet from "../../assets/s1mu.webp";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";

const whiteIconStyle = {
  color: "white",
};

const Footer = () => {
  return (
    <div className="bg-darkgray p-8 grid grid-cols-12 gap-4">
      <div className=" col-span-4">
        <Text4 text=" Alamat" />
        <img src={opet} alt="" className="w-[200px] mb-2" />
        <Text5 text=" Jalan ...." />
      </div>
      <div className="border-l px-2 border-white col-span-4">
        <Text4 text=" Tentang Kami" />
        <Text5 text="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Placeat, deleniti" />
        <Text4 text=" Kontak" />
        <div className="mt-2">
          <img src={opet} alt="" className="w-[70px]" />
        </div>
        <div className="mt-2">
          <img src={opet} alt="" className="w-[70px]" />
        </div>
        <div className="mt-2">
          <img src={opet} alt="" className="w-[70px]" />
        </div>
      </div>
      <div className="border-l px-2 border-white col-span-4">
        <div className="mb-2">
          <Text4 text=" Media Sosial" />
        </div>
        <div className="flex mb-2">
          <div className="mr-4">
            <TwitterIcon style={whiteIconStyle} />
          </div>
          <Text5 text="Twitter" />
        </div>
        <div className="flex mb-2">
          <div className="mr-4">
            <InstagramIcon style={whiteIconStyle} />
          </div>
          <Text5 text="Instagram" />
        </div>
        <div className="flex mb-2">
          <div className="mr-4">
            <FacebookIcon style={whiteIconStyle} />
          </div>
          <Text5 text="Tiktok" />
        </div>
        <div className="flex mb-2">
          <div className="mr-4">
            <YouTubeIcon style={whiteIconStyle} />
          </div>
          <Text5 text="Youtube" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
