import {
  FETCH_PELAPORAN_IKK_OUTPUT_DETAIL_FAILURE,
  FETCH_PELAPORAN_IKK_OUTPUT_DETAIL_REQUEST,
  FETCH_PELAPORAN_IKK_OUTPUT_DETAIL_SUCCESS,
  FETCH_PELAPORAN_IKK_OUTPUT_FAILURE,
  FETCH_PELAPORAN_IKK_OUTPUT_REQUEST,
  FETCH_PELAPORAN_IKK_OUTPUT_SUCCESS,
} from "./ActionType";
import axios from "axios";
import API_BASE_URL from "../config";
import Swal from "sweetalert2";

const fetchPelaporanIKKOutputRequest = () => {
  return {
    type: FETCH_PELAPORAN_IKK_OUTPUT_REQUEST,
  };
};

const fetchPelaporanIKKOutputSuccess = (data) => {
  return {
    type: FETCH_PELAPORAN_IKK_OUTPUT_SUCCESS,
    payload: data,
  };
};

const fetchPelaporanIKKOutputFailure = (err) => {
  return {
    type: FETCH_PELAPORAN_IKK_OUTPUT_FAILURE,
    payload: err,
  };
};

const fetchPelaporanIKKOutputDetailRequest = () => {
  return {
    type: FETCH_PELAPORAN_IKK_OUTPUT_DETAIL_REQUEST,
  };
};

const fetchPelaporanIKKOutputDetailSuccess = (data) => {
  return {
    type: FETCH_PELAPORAN_IKK_OUTPUT_DETAIL_SUCCESS,
    payload: data,
  };
};

const fetchPelaporanIKKOutputDetailFailure = (err) => {
  return {
    type: FETCH_PELAPORAN_IKK_OUTPUT_DETAIL_FAILURE,
    payload: err,
  };
};

export const fetchPelaporanIKKOutputList = (year) => {
  return (dispatch) => {
    dispatch(fetchPelaporanIKKOutputRequest(year));

    // Retrieve the token from session storage
    const token = sessionStorage.getItem("token");
    const role = sessionStorage.getItem("role");

    const apiEndpoint =
      role === "2"
        ? `/api/dashboard/ikk_outputs/pemda/report-all?year=${year}`
        : "";

    if (!apiEndpoint) {
      // Handle invalid role
      dispatch(fetchPelaporanIKKOutputFailure("Invalid role"));

      Swal.fire({
        icon: "warning",
        title: "Error!",
        text: "Invalid role access",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
      return;
    }

    // Set the token in the request headers
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${API_BASE_URL}${apiEndpoint}`, axiosConfig)
      .then((res) => {
        let _list = res.data;
        dispatch(fetchPelaporanIKKOutputSuccess(_list));
      })
      .catch((err) => {
        dispatch(fetchPelaporanIKKOutputFailure(err.message));
      });
  };
};

export const fetchPelaporanIKKOutputDetail = (id) => {
  return (dispatch) => {
    dispatch(fetchPelaporanIKKOutputDetailRequest());

    // Retrieve the token from session storage
    const token = sessionStorage.getItem("token");
    const role = sessionStorage.getItem("role");

    const apiEndpoint =
      role === "2" ? `/api/dashboard/ikk-outputs/show/${id}` : "";

    if (!apiEndpoint) {
      // Handle invalid role
      dispatch(fetchPelaporanIKKOutputDetailFailure("Invalid role"));

      Swal.fire({
        icon: "warning",
        title: "Error!",
        text: "Invalid role access",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
      return;
    }

    // Set the token in the request headers
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${API_BASE_URL}${apiEndpoint}`, axiosConfig)
      .then((res) => {
        let _list = res.data;
        dispatch(fetchPelaporanIKKOutputDetailSuccess(_list));
      })
      .catch((err) => {
        dispatch(fetchPelaporanIKKOutputDetailFailure(err.message));
      });
  };
};

export const clearErrors = () => (dispatch) => {
  dispatch({ type: "CLEAR_ERRORS" });
};
