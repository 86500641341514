import React from "react";
import MainLayout from "../../layout/MainLayout";
import DetailArtikel from "../../components/berita/DetailArtikel";
import PencarianDetailArtikel from "../../components/berita/PencarianDetailArtikel";

const DetailArticleView = () => {
  return (
    <MainLayout>
      <div className="col-span-8">
        <DetailArtikel />
      </div>
      <div className="col-span-4">
        <PencarianDetailArtikel />
      </div>
    </MainLayout>
  );
};

export default DetailArticleView;
