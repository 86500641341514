import React, { useEffect, useState } from "react";
import edit from "../../../assets/edit.png";
import deleteIcon from "../../../assets/delete.png";
import Button from "@mui/material/Button";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { connect } from "react-redux";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PrintIcon from "@mui/icons-material/Print";
import excel from "../../../assets/excel.png";
import { TextField } from "@mui/material";
import detail from "../../../assets/search.png";
import { fetchPelaporanIKKOutputList } from "../../../redux/pelaporanIKKOutput/PelaporanIKKOutputAction";
// modal

const PelaporanIKKOutput = (props) => {
  const Swal = require("sweetalert2");
  const itemsPerPage = 5; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const [checkedItems, setCheckedItems] = useState({});
  const role = sessionStorage.getItem("role");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentYear, setCurrentYear] = useState();
  const [inputSearch, setInputSearch] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    props.fetchPelaporanIKKOutputList(currentYear);
  }, [currentYear]);

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  const data = props.PelaporanIKKOutput.data.data || [];
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handlePrevClick = () => {
    paginate(currentPage - 1);
  };

  const handleNextClick = () => {
    paginate(currentPage + 1);
  };

  const onDelete = async (id) => {
    const confirmationResult = await Swal.fire({
      title: "Apakah anda ingin menghapus data ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    });
    if (confirmationResult.isConfirmed) {
      try {
        Swal.fire({
          title: "Success!",
          text: "Delete data categori berhasil ",
          confirmButtonColor: "#3085d6",
          icon: "success",
          confirmButtonText: "OK",
        });
      } catch (error) {
        Swal.fire({
          title: "Error!",
          text: ` Data User Tidak Dapat Dihapus ${error}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const handleCheck = (id) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [id]: !prevCheckedItems[id],
    }));
  };

  const handleCheckAll = (isCheckedAll) => {
    const newCheckedItems = {};
    data.forEach((item) => {
      newCheckedItems[item.id] = isCheckedAll;
    });
    setCheckedItems(newCheckedItems);
  };

  const isChecked = (id) => {
    return checkedItems[id] || false;
  };

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const idUser = sessionStorage.getItem("id");

  const handleSearchChange = (event) => {
    const input = event.target.value.toLowerCase();
    setInputSearch(input);
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await props.fetchPelaporanIKKOutputList(inputSearch);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <div className="mb-4 grid grid-cols-12 gap-2">
        <div className="col-span-4 md:col-span-1 ">
          <Stack
            component="form"
            sx={{
              width: "10ch",
            }}
            spacing={1}
            noValidate
            autoComplete="off"
          >
            <TextField
              hiddenLabel
              id="filled-hidden-label-small"
              defaultValue={currentYear}
              onChange={handleSearchChange}
              InputLabelProps={{ shrink: false }}
              variant="filled"
              size="small"
              placeholder="Enter Year"
            />
          </Stack>
        </div>
        <div className="col-span-8 md:col-span-1 ">
          <Stack
            direction="row"
            spacing={2}
            sx={{
              height: "4.1ch",
            }}
          >
            {loading ? (
              <Button
                variant="outlined"
                startIcon={<SearchIcon />}
                onClick={handleSearch}
              >
                Loading...
              </Button>
            ) : (
              <Button
                variant="outlined"
                startIcon={<SearchIcon />}
                onClick={handleSearch}
              >
                Cari
              </Button>
            )}
          </Stack>
        </div>
        <div className="col-span-10 flex justify-end">
          <Stack direction="row" spacing={2}>
            <Link to="/admin/ikk/add">
              <Button variant="contained">Tambah</Button>
            </Link>
            <Button variant="contained" endIcon={<PrintIcon />}>
              Print
            </Button>
            <Button variant="contained" endIcon={<PictureAsPdfIcon />}>
              PDF
            </Button>
            <Button variant="contained">
              Excel{" "}
              <img src={excel} alt="Excel Icon" className="ml-2 w-[20px]" />
            </Button>
          </Stack>
        </div>
      </div>
      <div className="">
        <table className="w-[90vw] mt-8 border border-collapse ">
          <thead>
            <tr className="bg-gray ">
              <th className="p-4" rowSpan="2">
                <input
                  type="checkbox"
                  onChange={(e) => handleCheckAll(e.target.checked)}
                />
              </th>
              <th className="p-4" rowSpan="2">
                No IKKs
              </th>
              <th className="p-4" rowSpan="2">
                No IKK Outcome
              </th>
              <th className="p-4" rowSpan="2">
                IKK Output
              </th>
              <th className="p-4" colSpan="4">
                Pemda
              </th>
              <th className="p-4" colSpan="3">
                Apip
              </th>

              <th className="p-4" rowSpan="2">
                Status Rekomendasi
              </th>
              <th className="p-4" rowSpan="2">
                Keterangan Rekomendasi
              </th>
              <th className="p-4" rowSpan="2">
                Aksi
              </th>
            </tr>
            <tr className="bg-gray">
              <th className="p-4">Nilai </th>
              <th className="p-4">Diperbarui</th>
              <th className="p-4">Ket</th>
              <th className="p-4">Bukti File</th>
              <th className="p-4">Status</th>
              <th className="p-4">Ket</th>
              <th className="p-4">Diperbarui</th>
            </tr>
          </thead>
          {currentItems.map((item, index) => (
            <tbody className="border">
              <tr
                key={index}
                className={index % 2 === 0 ? "bg-light" : "bg-[#cae6ed]"}
              >
                <td className="p-4 text-center border-b">
                  <input
                    type="checkbox"
                    checked={isChecked(item.id)}
                    onChange={() => handleCheck(item.id)}
                  />
                </td>

                <td className="p-4 text-left border-b" colSpan="12">
                  {item.kategori_urusan_ikk !== null
                    ? item.kategori_urusan_ikk
                    : "-"}
                </td>

                <td className="p-4 text-center border-b">
                  <div className="flex items-center justify-center">
                    <img
                      src={deleteIcon}
                      alt=""
                      className="w-[30px]"
                      onClick={() => onDelete(item.id)}
                    />
                  </div>
                </td>
              </tr>
              <tr
                key={index}
                className={index % 2 === 0 ? "bg-light" : "bg-[#cae6ed]"}
              >
                <td className="p-4 text-left border-b"></td>
                <td className="p-4 text-left border-b" colSpan="14">
                  {item.urusan !== null ? item.urusan : "-"}
                </td>
              </tr>

              {item.ikkoutput.map((item, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "bg-light" : "bg-[#cae6ed]"}
                >
                  <td className="p-4 text-center"></td>
                  <td className="p-4 text-center">no ikk blm ada</td>
                  <td className="p-4 text-center">
                    {item.no_ikk_outcome !== null ? item.no_ikk_outcome : "-"}
                  </td>
                  <td className="p-4 text-center">
                    {item.ikk_output_in_outcome !== null
                      ? item.ikk_output_in_outcome
                      : "-"}
                  </td>
                  <td className="p-4 text-center">
                    {item.nilai_pemda !== null ? item.nilai_pemda : "-"}
                  </td>
                  <td className="p-4 text-center">
                    {item.update_by_pemda !== null ? item.update_by_pemda : "-"}
                  </td>
                  <td className="p-4 text-center">
                    {item.desc_pemda !== null ? item.desc_pemda : "-"}
                  </td>
                  <td className="p-4 text-center">
                    <a href={item.bukti_url} target="_blank" rel="noreferrer">
                      <Button
                        variant="contained"
                        endIcon={<FileDownloadIcon />}
                      >
                        File
                      </Button>
                    </a>
                  </td>
                  <td className="p-4 text-center">
                    {item.status_apip !== null ? item.status_apip : "-"}
                  </td>
                  <td className="p-4 text-center">
                    {item.desc_apip !== null ? item.desc_apip : "-"}
                  </td>
                  <td className="p-4 text-center">
                    {item.update_apip !== null ? item.update_apip : "-"}
                  </td>
                  <td className="p-4 text-center">
                    {item.status_rekomendasi !== null
                      ? item.status_rekomendasi
                      : "-"}
                  </td>
                  <td className="p-4 text-center">
                    {item.keterangan_rekomendasi !== null
                      ? item.keterangan_rekomendasi
                      : "-"}
                  </td>

                  <td className="p-4 text-center">
                    <div className="flex items-center justify-center">
                      <Link
                        to={`/admin/userpengajuan/pelaporanikkoutput/detail/${item.id}`}
                      >
                        <img src={detail} alt="" className="w-[30px]" />
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          ))}
        </table>
      </div>

      {/* Pagination */}
      {/* Pagination */}
      <div className="flex justify-center mt-4 pb-4">
        <button
          onClick={handlePrevClick}
          className={`mx-2 px-4 py-2 ${
            currentPage === 1 ? "bg-gray text-white rounded-full" : ""
          }`}
        >
          {"<"}
        </button>

        {[...Array(totalPages).keys()].map((number) => (
          <button
            key={number}
            onClick={() => paginate(number + 1)}
            className={`mx-2 px-4 py-2 ${
              currentPage === number + 1
                ? "bg-gray text-white rounded-full"
                : ""
            }`}
          >
            {number + 1}
          </button>
        ))}

        <button
          onClick={handleNextClick}
          className={`mx-2 px-4 py-2 ${
            currentPage === totalPages ? "bg-gray text-white rounded-full" : ""
          }`}
        >
          {">"}
        </button>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    PelaporanIKKOutput: state.PelaporanIKKOutput,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPelaporanIKKOutputList: (currentYear) =>
      dispatch(fetchPelaporanIKKOutputList(currentYear)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PelaporanIKKOutput);
