import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import defaultImage from "../../../assets/defaultimage.png";
import {
  fetchOldIKKMakro,
  updateIKKMakro,
} from "../../../redux/ikkMakro/IkkMakroAction";

const EditPelaporanMakro = ({
  fetchOldIKKMakro,
  oldIKKMakroData,
  updateIKKMakro,
  clearErrors,
  error,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [ikk, setIkk] = useState("");
  const [satuan, setSatuan] = useState("");
  const [selectedFile, setSelectedFile] = useState(defaultImage);

  useEffect(() => {
    fetchOldIKKMakro(id);
  }, [fetchOldIKKMakro]);

  useEffect(() => {
    if (oldIKKMakroData) {
      setIkk(oldIKKMakroData.ikk || "");
      setSatuan(oldIKKMakroData.satuan || "");
    }

    // console.log("mapp", oldIKKOutputData?.data);
  }, [oldIKKMakroData]);

  const isDataEmpty = () => {
    // Check if all input fields are empty
    return !ikk || !satuan;
  };

  const handleUpdate = () => {
    const updatedIKKMakroData = {
      ikk,
      satuan,
    };

    // Assuming you have the ID of the IKK item to update (hardcoded as 2 in this case)
    updateIKKMakro(id, updatedIKKMakroData);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];

      if (allowedTypes.includes(file.type)) {
        setSelectedFile(file);
      } else {
        Swal.fire({
          title: "Invalid File Type!",
          text: "Please select a valid file type (doc, pdf, xlsx, docx).",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const onCancel = async () => {
    const confirmationResult = await Swal.fire({
      title: "Apakah anda ingin membatalkan aktivitas?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    });
    if (confirmationResult.isConfirmed) {
      navigate("admin/ikk/pelaporan-ikk-makro");
    }
  };

  return (
    <div className="pl-8 overflow-hidden max-w-screen">
      <Box
        component="form"
        sx={{
          "& > :not(style)": { pb: 2, pr: 2, width: "30ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="filled-basic"
          label="No IKK"
          variant="filled"
          value={ikk}
          onChange={(e) => setIkk(e.target.value)}
        />

        <TextField
          id="filled-basic"
          label="Satuan"
          variant="filled"
          value={satuan}
          onChange={(e) => setSatuan(e.target.value)}
        />
        {/* <TextField
          id="filled-basic"
          label="Status Apip "
          variant="filled"
          value={satuan}
          onChange={(e) => setSatuan(e.target.value)}
        /> */}

        {/* <TextField
          id="filled-basic"
          label="Keterangan "
          variant="filled"
          value={satuan}
          onChange={(e) => setSatuan(e.target.value)}
        /> */}

        {/* <TextField
          id="filled-basic"
          label="Status Rekomendasi "
          variant="filled"
          value={satuan}
          onChange={(e) => setSatuan(e.target.value)}
        /> */}
        {/* <TextField
          id="filled-basic"
          label="Catatan Rekomendasi "
          variant="filled"
          value={satuan}
          onChange={(e) => setSatuan(e.target.value)}
        /> */}
        {/* <TextField
          id="date"
          label="Diperbarui APIP"
          type="date"
          defaultValue="2023-01-01"
          InputLabelProps={{
            shrink: true,
          }}
        /> */}
        {/* <TextField
          id="date"
          label="Diperbarui APIP"
          type="date"
          defaultValue="2023-01-01"
          InputLabelProps={{
            shrink: true,
          }}
        /> */}
        {/* <div>
          <input type="file" onChange={handleFileChange} className="ml-2" />
        </div> */}
      </Box>

      <div className="w-[200px] flex justify-between">
        <Button
          variant="contained"
          onClick={handleUpdate}
          disabled={isDataEmpty()}
        >
          Simpan
        </Button>

        <Button variant="contained" color="error" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const mappedProps = {
    oldIKKMakroData: state.IkkMakroList.oldIKKMakroData,
  };

  console.log("Mapped Props:", mappedProps);

  return mappedProps;
};

export default connect(mapStateToProps, {
  fetchOldIKKMakro,
  updateIKKMakro,
})(EditPelaporanMakro);
