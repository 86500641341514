export const FETCH_IKK_OUTPUT_REQUEST = "FETCH_IKK_OUTPUT_REQUEST";
export const FETCH_IKK_OUTPUT_SUCCESS = "FETCH_IKK_OUTPUT_SUCCESS";
export const FETCH_IKK_OUTPUT_FAILURE = "FETCH_IKK_OUTPUT_FAILURE";

export const DELETE_IKK_OUTPUT_SUCCESS = "DELETE_IKK_OUTPUT_SUCCESS";
export const DELETE_IKK_OUTPUT_FAILURE = "DELETE_IKK_OUTPUT_FAILURE";

export const FETCH_OLD_IKK_OUTPUT_SUCCESS = "FETCH_OLD_IKK_OUTPUT_SUCCESS";
export const FETCH_OLD_IKK_OUTPUT_FAILURE = "FETCH_OLD_IKK_OUTPUT_FAILURE";
export const UPDATE_IKK_OUTPUT_SUCCESS = "UPDATE_IKK_OUTPUT_SUCCESS";
export const UPDATE_IKK_OUTPUT_FAILURE = "UPDATE_IKK_OUTPUT_FAILURE";

export const CLONE_IKK_OUTPUT_SUCCESS = "CLONE_IKK_OUTPUT_SUCCESS";
export const CLONE_IKK_OUTPUT_FAILURE = "CLONE_IKK_OUTPUT_FAILURE";
