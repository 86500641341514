// Modal.js
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import defaultImage from "../../assets/defaultimage.png";

const ModalAddGallery = ({ isOpen, handleClose }) => {
  const [category, setCategory] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([null]); // Default null for each input
  const [inputFileElements, setInputFileElements] = useState(1);

  const handleSave = () => {
    // Validation logic
    let isValid = true;

    if (!category) {
      setCategoryError("Category is required");
      isValid = false;
    } else {
      setCategoryError("");
    }

    if (isValid) {
      // Perform save operation with selectedFiles array
      // ...
    }
  };

  const handleFileChange = (event, index) => {
    const files = event.target.files;
    const newSelectedFiles = [...selectedFiles];

    for (let i = 0; i < files.length; i++) {
      newSelectedFiles[index + i] = URL.createObjectURL(files[i]);
    }

    setSelectedFiles(newSelectedFiles);
  };

  const handleAddFileInput = () => {
    setInputFileElements((prev) => prev + 1);
    setSelectedFiles((prev) => [...prev, null]);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Tambah Gallery</DialogTitle>
      <DialogContent>
        <div className="grid grid-cols-3 gap-4 ">
          {Array.from({ length: inputFileElements }).map((_, index) => (
            <div key={index}>
              <input
                type="file"
                accept="image/*"
                onChange={(event) => handleFileChange(event, index)}
                className="hidden"
                id={`fileInput${index}`}
              />
              <label htmlFor={`fileInput${index}`} className="cursor-pointer">
                {selectedFiles[index] ? (
                  <img
                    src={selectedFiles[index]}
                    alt={`Selected File Preview ${index}`}
                    className="h-auto w-[250px] hover:border-blue-500 "
                  />
                ) : (
                  <div className=" hover:border-blue-500 flex items-center justify-center   ">
                    <img
                      src={defaultImage}
                      alt=""
                      className=" hover:border-blue-500 w-[100px]"
                    />
                  </div>
                )}
              </label>
            </div>
          ))}
          <div onClick={handleAddFileInput} className="cursor-pointer">
            <AddToPhotosIcon />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
        <Button onClick={handleClose} color="error">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalAddGallery;
