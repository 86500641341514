// Modal.js
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Box, TextField } from "@mui/material";

const ModalEditCategory = ({ isOpen, handleClose }) => {
  const [category, setCategory] = useState("");
  const [categoryError, setCategoryError] = useState("");

  const handleSave = () => {
    // Validation logic
    let isValid = true;

    if (!category) {
      setCategoryError("Username is required");
      isValid = false;
    } else {
      setCategoryError("");
    }

    if (isValid) {
      // Perform save operation
      // ...
    }
  };
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Edit Category</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 2, width: "30ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="filled-basic"
            label="Category"
            variant="filled"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            error={!!categoryError}
            helperText={categoryError}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
        <Button onClick={handleClose} color="error">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalEditCategory;
