import axios from "axios";
import API_BASE_URL from "../config";
import Swal from "sweetalert2";
import {
  FETCH_PARENT_FAILURE,
  FETCH_PARENT_REQUEST,
  FETCH_PARENT_SUCCESS,
} from "./ActionType";

const fetchParentRequest = () => {
  return {
    type: FETCH_PARENT_REQUEST,
  };
};

const fetchParentSuccess = (data) => {
  return {
    type: FETCH_PARENT_SUCCESS,
    payload: data,
  };
};

const fetchParentFailure = (err) => {
  return {
    type: FETCH_PARENT_FAILURE,
    payload: err,
  };
};

export const fetchParentList = () => {
  return (dispatch) => {
    dispatch(fetchParentRequest());

    // Retrieve the token from session storage
    const token = sessionStorage.getItem("token"); // Replace "your_token_key" with the actual key used to store the token

    // Set the token in the request headers
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${API_BASE_URL}/api/parent-pages`, axiosConfig)
      .then((res) => {
        let _list = res.data;
        dispatch(fetchParentSuccess(_list));
      })
      .catch((err) => {
        dispatch(fetchParentFailure(err.message));
      });
  };
};
