import axios from "axios";
import API_BASE_URL from "../config";
import Swal from "sweetalert2";
import {
  FETCH_GEOGRAFIS_FAILURE,
  FETCH_GEOGRAFIS_REQUEST,
  FETCH_GEOGRAFIS_SUCCESS,
} from "./ActionType";

const fetchGeografisRequest = () => {
  return {
    type: FETCH_GEOGRAFIS_REQUEST,
  };
};

const fetchGeografisSuccess = (data) => {
  return {
    type: FETCH_GEOGRAFIS_SUCCESS,
    payload: data,
  };
};

const fetchGeografisFailure = (err) => {
  return {
    type: FETCH_GEOGRAFIS_FAILURE,
    payload: err,
  };
};

export const fetchGeografisList = () => {
  return (dispatch) => {
    dispatch(fetchGeografisRequest());
    const token = sessionStorage.getItem("token");
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${API_BASE_URL}/api/custom-pages/data-geografis`, axiosConfig)
      .then((res) => {
        let _list = res.data;
        dispatch(fetchGeografisSuccess(_list));
      })
      .catch((err) => {
        dispatch(fetchGeografisFailure(err.message));
        console.log("first eror ikk output", err.message);
        Swal.fire({
          title: "Error!",
          text: `${err.message}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      });
  };
};
