import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import defaultImage from "../../../assets/defaultimage.png";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  saveIKKPengajuan,
  clearErrors,
  fetchIKKPengajuanList,
} from "../../../redux/ikkPengajuan/IkkPengajuanAction";
import IKKMakroDropdown from "../../masterIKK/IKKMakroDropdown";

const AddIkkPengajuanDaerah = ({
  saveIKKPengajuan,
  error,
  props,
  fetchIKKPengajuanList,
  clearErrors,
}) => {
  const navigate = useNavigate();
  const [capaian, setCapaian] = useState("");
  const [nilaiRumus1, setNilaiRumus1] = useState("");
  const [nilaiRumus2, setNilaiRumus2] = useState("");
  const [ikkMaster, setIkkMaster] = useState("");
  const [selectedFile, setSelectedFile] = useState(defaultImage);
  const [IKKSelect, setIKKSelect] = useState("");

  console.log("file2 ", selectedFile);

  useEffect(() => {
    // Fetch urusan data when the component mounts
    console.log("Fetching urusan list...");
    fetchIKKPengajuanList();
  }, [fetchIKKPengajuanList]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];

      if (allowedTypes.includes(file.type)) {
        setSelectedFile(file);
      } else {
        Swal.fire({
          title: "Invalid File Type!",
          text: "Please select a valid file type (doc, pdf, xlsx, docx).",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const onCancel = async (id) => {
    const confirmationResult = await Swal.fire({
      title: "Apakah anda ingin membatalkan aktivitas?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    });
    if (confirmationResult.isConfirmed) {
      try {
        navigate("/admin/management-user");
      } catch (error) {
        Swal.fire({
          title: "Error!",
          text: ` Anda tidak dapat membatalkan aktivitas ${error}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const handleSave = () => {
    const IkkPengajuanList = {
      capaian,
      nilaiRumus1,
      nilaiRumus2,
      IKKSelect,
      selectedFile, // Update this line
    };

    saveIKKPengajuan(IkkPengajuanList);
  };

  useEffect(() => {
    if (error) {
      Swal.fire({
        title: "Error!",
        text: `Failed to save Urusan. ${error}`,
        icon: "error",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
    clearErrors();
  }, [error, clearErrors]);

  return (
    <div className="pl-4 overflow-hidden max-w-screen  ">
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 2, width: "30ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="filled-basic"
          label="Capaian"
          variant="filled"
          value={capaian}
          onChange={(e) => setCapaian(e.target.value)}
        />

        <TextField
          id="filled-basic"
          label="Nilai Rumus 1 "
          variant="filled"
          value={nilaiRumus1}
          onChange={(e) => setNilaiRumus1(e.target.value)}
        />

        <TextField
          id="filled-basic"
          label="Nilai Rumus 2 "
          variant="filled"
          value={nilaiRumus2}
          onChange={(e) => setNilaiRumus2(e.target.value)}
        />

        <IKKMakroDropdown setIKKSelect={setIKKSelect} />

        <div>
          <input type="file" onChange={handleFileChange} className="ml-2" />
        </div>
      </Box>

      <div className="w-[200px]  flex justify-between pl-4">
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={
            !capaian ||
            !nilaiRumus1 ||
            !nilaiRumus2 ||
            !IKKSelect ||
            !selectedFile
          }
        >
          Simpan
        </Button>

        <Button variant="contained" color="error" onClick={() => onCancel()}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  IkkPengajuanList: state.IkkPengajuanList,
  error: state.error,
});

export default connect(mapStateToProps, {
  saveIKKPengajuan,
  fetchIKKPengajuanList,
  clearErrors,
})(AddIkkPengajuanDaerah);
