import React, { useEffect, useState } from "react";
import { Text10, Text6 } from "../../../mainComponent/Paragraph";
import edit from "../../../assets/edit.png";
import Button from "@mui/material/Button";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import deleteIcon from "../../../assets/delete.png";
import { connect } from "react-redux";
import {
  deleteIkkOutput,
  fetchIKKOutputList,
} from "../../../redux/ikkOutput/IkkOutputAction";
import { Stack, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Link, useNavigate } from "react-router-dom";
import PrintIcon from "@mui/icons-material/Print";
import excel from "../../../assets/excel.png";
import jsPDF from "jspdf";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import * as XLSX from "xlsx";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

const PelaporanIKKOutput = (props) => {
  const Swal = require("sweetalert2");
  const navigate = useNavigate();
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [inputSearch, setInputSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [dataChecked, setDataChecked] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    props.fetchIKKOutputList(currentYear);
  }, [currentYear]);

  console.log("checked ", checkedItems);
  console.log("checked 2 ", dataChecked);

  // console.log("pelaporan ikk output", props.IkkOutputList);
  const data = props.IkkOutputList.data.data || [];

  console.log("dataaa", data);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  if (props.IkkOutputList.error === "Request failed with status code 401") {
    Swal.fire({
      title: "Error!",
      text: `${props.IkkOutputList.error}`,
      icon: "warning",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/admin/login");
      }
    });
  }

  const itemsPerPage = 5; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handlePrevClick = () => {
    paginate(currentPage - 1);
  };

  const handleNextClick = () => {
    paginate(currentPage + 1);
  };

  // delete function
  const onDelete = async (id) => {
    const confirmationResult = await Swal.fire({
      title: `Apakah anda ingin menghapus data ${id}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    });

    if (confirmationResult.isConfirmed) {
      try {
        // Dispatch the delete action with the item id
        await props.deleteIkkOutput(id);

        // Check the delete status in props.IkkList.deleteIkkStatus
        if (props.IkkOutputList.deleteIkkOutputStatus === "success") {
          Swal.fire({
            title: "Success!",
            text: "Data IKK Output Berhasil Dihapus ",
            confirmButtonColor: "#3085d6",
            icon: "success",
            confirmButtonText: "OK",
          });
        } else {
          // Handle the case where the deletion was not successful
          Swal.fire({
            title: "Error!",
            text: "Gagal menghapus data.",
            icon: "error",
            confirmButtonColor: "#d33",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        console.log("error delete ikk output", error);
        Swal.fire({
          title: "Error!",
          text: ` Data User Tidak Dapat Dihapus ${error}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const handleCheck = (id) => {
    setCheckedItems((prevCheckedItems) => {
      const updatedCheckedItems = {
        ...prevCheckedItems,
        [id]: !prevCheckedItems[id],
      };

      // Get the checked item data
      const checkedItemData = data.find((item) => item.id === id);

      // Update dataChecked with all fields from the item
      setDataChecked((prevDataChecked) => {
        if (updatedCheckedItems[id]) {
          // If item is checked, add it to dataChecked
          return [
            ...prevDataChecked,
            {
              ...checkedItemData,
            },
          ];
        } else {
          // If item is unchecked, remove it from dataChecked
          return prevDataChecked.filter((data) => data.id !== id);
        }
      });

      return updatedCheckedItems;
    });
  };

  const handleCheckAll = (isCheckedAll) => {
    const newCheckedItems = {};
    data.forEach((item) => {
      newCheckedItems[item.id] = isCheckedAll;
    });
    setCheckedItems(newCheckedItems);

    setDataChecked(
      isCheckedAll
        ? data.map((item) => ({
            ...item, // Include all fields from the item
          }))
        : []
    );
  };

  const isChecked = (id) => {
    return checkedItems[id] || false;
  };

  const exportToExcel = () => {
    const dataToExport = [
      [
        "No IKK",
        "IKK Outcome",
        "IKK Output",
        "Nilai",
        "Diperbarui (Pemda)",
        "Ket (Pemda)",
        "Bukti File (pemda)",
        "Status (Apip)",
        "Ket (Apip)",
        "Diperharui (Apip)",
        "Capaian",
        "Status Rekomendasi",
        "Keterangan Rekomendasi",
      ],
      ...dataChecked.map((item, index) => [
        index + 1,
        `# ${item.no_ikk_outcome}`,
        item.ikk_output_in_outcome,
        item.nilai_pemda,
        item.update_by_pemda,
        item.desc_pemda,
        item.bukti_by_pemda,
        item.status_apip,
        item.desc_apip,
        item.update_apip,
        item.nilai_pemda,
        item.status_rekomendasi,
        item.keterangan_rekomendasi,
      ]),
    ];

    const ws = XLSX.utils.aoa_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "IKK");

    // Save the Excel file
    XLSX.writeFile(wb, "IKK_Output.xlsx");
  };

  const downloadPDF = () => {
    const pdf = new jsPDF();
    pdf.setFontSize(12); // Adjust the font size as needed

    pdf.text("IKK Data", 20, 20);

    dataChecked.forEach((item, index) => {
      const yPosition = 30 + index * 15; // Adjust the multiplier as needed
      const text = `${index + 1}. No IKK: ${item.no_ikk}\nIKK Output: ${
        item.ikk_output
      }\nIKK Outcome: ${item.ikk_outcome}\nRumus: ${item.rumus}`;
      pdf.text(text, 20, yPosition);
    });

    pdf.save("IKK_Output.pdf");
  };
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const downloadFile = async (fileUrl) => {
    try {
      const blob = await fetch(fileUrl).then((response) => response.blob());
      const filename = fileUrl.split("/").pop();

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;

      // Trigger a click event on the link to start the download
      link.click();

      // Remove the temporary link element
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading file:", error);
      // Handle the error, e.g., show an alert to the user
    }
  };
  const handleSearchChange = (event) => {
    const input = event.target.value.toLowerCase();
    setInputSearch(input);
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await props.fetchIKKOutputList(inputSearch);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <div className="grid grid-cols-12 gap-4 ">
        <div className="  col-span-6">
          <Text10 text="Keterangan :" />
          <div className="flex items-center">
            <div className="w-[15px] h-[15px] bg-red-500 mr-2"></div>
            <div className="flex items-center">
              <Text10 text="IKK yang mendapat rekomendasi" />
            </div>
          </div>
          <div className="flex items-center ">
            <div className="w-[15px] h-[15px] bg-green-500 mr-2"></div>
            <div className="flex items-center ">
              <Text10 text="IKK yang mendapat rekomendasi dan telah diperbaharui" />
            </div>
          </div>
        </div>
      </div>
      <div className="mb-4 grid grid-cols-12 gap-2  ">
        <div className="col-span-4 md:col-span-1 ">
          <Stack
            component="form"
            sx={{
              width: "10ch",
            }}
            spacing={1}
            noValidate
            autoComplete="off"
          >
            <TextField
              hiddenLabel
              id="filled-hidden-label-small"
              defaultValue={currentYear}
              onChange={handleSearchChange}
              InputLabelProps={{ shrink: false }}
              variant="filled"
              size="small"
              placeholder="Enter Year"
            />
          </Stack>
        </div>
        <div className="col-span-8 md:col-span-1 ">
          <Stack
            direction="row"
            spacing={2}
            sx={{
              height: "4.1ch",
            }}
          >
            {loading ? (
              <Button
                variant="outlined"
                startIcon={<SearchIcon />}
                onClick={handleSearch}
              >
                Loading...
              </Button>
            ) : (
              <Button
                variant="outlined"
                startIcon={<SearchIcon />}
                onClick={handleSearch}
              >
                Cari
              </Button>
            )}
          </Stack>
        </div>
        <div className="col-span-12 md:col-span-10 flex justify-end">
          <Stack direction="row" spacing={1}>
            <Link to="/admin/ikk/pelaporan-ikk-output/add">
              <Button variant="contained">Tambah</Button>
            </Link>
            <Button
              variant="contained"
              endIcon={<PrintIcon />}
              disabled={!dataChecked.length}
            >
              Print
            </Button>
            <Button
              variant="contained"
              onClick={downloadPDF}
              endIcon={<PictureAsPdfIcon />}
              disabled={!dataChecked.length}
            >
              PDF
            </Button>
            <Button
              variant="contained"
              onClick={exportToExcel}
              disabled={!dataChecked.length}
            >
              Excel{" "}
              <img src={excel} alt="Excel Icon" className="ml-2 w-[20px]" />
            </Button>
          </Stack>
        </div>
      </div>

      <div>
        <table className="w-[93.5vw] mt-8 ">
          <thead>
            <tr className="bg-gray">
              <th className="p-4" rowSpan="2">
                <input
                  type="checkbox"
                  onChange={(e) => handleCheckAll(e.target.checked)}
                />
              </th>
              <th className="p-4" rowSpan="2">
                No IKKs
              </th>
              <th className="p-4" rowSpan="2">
                No IKK Outcome
              </th>
              <th className="p-4" rowSpan="2">
                IKK Output
              </th>
              <th className="p-4" colSpan="4">
                Pemda
              </th>
              <th className="p-4" colSpan="3">
                Apip
              </th>
              <th className="p-4" rowSpan="2">
                Urusan
              </th>
              <th className="p-4" rowSpan="2">
                Status Rekomendasi
              </th>
              <th className="p-4" rowSpan="2">
                Keterangan Rekomendasi
              </th>
              <th className="p-4" rowSpan="2">
                Aksi
              </th>
            </tr>
            <tr className="bg-gray">
              <th className="p-4">Nilai </th>
              <th className="p-4">Diperbarui</th>
              <th className="p-4">Ket</th>
              <th className="p-4">Bukti File</th>
              <th className="p-4">Status</th>
              <th className="p-4">Ket</th>
              <th className="p-4">Diperbarui</th>
            </tr>
          </thead>

          {!data || data.length === 0 ? (
            <tbody className="">
              <tr>
                <td colSpan="15" className=" text-center p-4 bg-[#cae6ed]">
                  No Available Data
                </td>
              </tr>
            </tbody>
          ) : (
            <>
              {currentItems.map((item, index) => (
                <tbody>
                  <tr
                    key={index}
                    className={index % 2 === 0 ? "bg-light" : "bg-[#cae6ed]"}
                  >
                    <td className="p-4 text-center">
                      <input
                        type="checkbox"
                        checked={isChecked(item.id)}
                        onChange={() => handleCheck(item.id)}
                      />
                    </td>
                    <td className="p-4 text-center">no ikk blm ada</td>
                    <td className="p-4 text-center">
                      {item.no_ikk_outcome !== null ? item.no_ikk_outcome : "-"}
                    </td>
                    <td className="p-4 text-center">
                      {item.ikk_output_in_outcome !== null
                        ? item.ikk_output_in_outcome
                        : "-"}
                    </td>
                    <td className="p-4 text-center">
                      {item.nilai_pemda !== null ? item.nilai_pemda : "-"}
                    </td>
                    <td
                      className="p-4 text-center"
                      style={{ cursor: "pointer" }}
                      onClick={() => downloadFile(item.bukti_by_pemda)}
                    >
                      {item.update_by_pemda !== null
                        ? item.update_by_pemda
                        : "-"}
                    </td>

                    <td className="p-4 text-center">
                      {item.desc_pemda !== null ? item.desc_pemda : "-"}
                    </td>
                    <td className="p-4 text-center">
                      {/* {item.bukti_by_pemda !== null ? item.bukti_by_pemda : "-"} */}
                      <a href={item.bukti_url} target="_blank" rel="noreferrer">
                        <Button
                          variant="contained"
                          endIcon={<FileDownloadIcon />}
                        >
                          File
                        </Button>
                      </a>
                    </td>
                    <td className="p-4 text-center">
                      {item.status_apip !== null ? item.status_apip : "-"}
                    </td>
                    <td className="p-4 text-center">
                      {item.desc_apip !== null ? item.desc_apip : "-"}
                    </td>
                    <td className="p-4 text-center">
                      {item.update_apip !== null ? item.update_apip : "-"}
                    </td>
                    <td className="p-4 text-center">{item.urusan.urusan}</td>
                    <td className="p-4 text-center">
                      {item.status_rekomendasi !== null
                        ? item.status_rekomendasi
                        : "-"}
                    </td>
                    <td className="p-4 text-center">
                      {item.keterangan_rekomendasi !== null
                        ? item.keterangan_rekomendasi
                        : "-"}
                    </td>

                    <td className="p-4 text-center">
                      <div className="flex items-center justify-center">
                        <Link
                          to={`/admin/ikk/pelaporan-ikk-output/edit/${item.id}`}
                        >
                          <img src={edit} alt="" className="w-[125px]" />
                        </Link>

                        <img
                          src={deleteIcon}
                          alt=""
                          className="w-[30px]"
                          onClick={() => onDelete(item.id)}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              ))}
            </>
          )}
        </table>
      </div>

      {/* Pagination */}
      {/* Pagination */}

      {!data || data.length === 0 ? (
        ""
      ) : (
        <div className="flex justify-center mt-4 pb-4">
          <button
            onClick={handlePrevClick}
            className={`mx-2 px-4 py-2 ${
              currentPage === 1 ? "bg-gray text-white rounded-full" : ""
            }`}
          >
            {"<"}
          </button>

          {[...Array(totalPages).keys()].map((number) => (
            <button
              key={number}
              onClick={() => paginate(number + 1)}
              className={`mx-2 px-4 py-2 ${
                currentPage === number + 1
                  ? "bg-gray text-white rounded-full"
                  : ""
              }`}
            >
              {number + 1}
            </button>
          ))}

          <button
            onClick={handleNextClick}
            className={`mx-2 px-4 py-2 ${
              currentPage === totalPages
                ? "bg-gray text-white rounded-full"
                : ""
            }`}
          >
            {">"}
          </button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    IkkOutputList: state.IkkOutputList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchIKKOutputList: (currentYear) =>
      dispatch(fetchIKKOutputList(currentYear)),
    deleteIkkOutput: (id) => dispatch(deleteIkkOutput(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PelaporanIKKOutput);
