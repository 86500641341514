import React from "react";
import MainLayoutAdmin from "../../../layout/MainLayoutAdmin";
import { Text8 } from "../../../mainComponent/Paragraph";
import UpdateContact from "../../../componentsAdmin/contact/UpdateContact";

const ContactEditView = () => {
  return (
    <MainLayoutAdmin>
      <div>
        <div className="pb-4  border-b border-darkgray mb-8">
          <Text8 text="Edit Contact" />
        </div>
        <UpdateContact />
      </div>
    </MainLayoutAdmin>
  );
};

export default ContactEditView;
