import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Box } from "@mui/material";
import { fetchParentList } from "../../redux/parent/ParentAction";

const ParentDropdown = (props) => {
  const Swal = require("sweetalert2");
  const navigate = useNavigate();
  const [parent, setParent] = useState(1);

  const [data, setData] = useState([]);
  useEffect(() => {
    props.fetchParentList();
  }, []);

  useEffect(() => {
    setData(props.parentList.data.parent_page || []);
  }, [props.parentList]);

  console.log("data", data);
  return (
    <div>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { width: "30ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="filled-select-currency"
          select
          label="Select"
          value={parent}
          onChange={(e) => {
            const selectedValue = e.target.value;
            setParent(selectedValue);
            props.setParentSelect(selectedValue);
          }}
          helperText="Please select your parent"
          variant="filled"
        >
          {data.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.title_page}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    parentList: state.parentList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchParentList: () => dispatch(fetchParentList()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ParentDropdown);
