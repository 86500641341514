import { LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT } from "./ActionType";
import axios from "axios";
import Swal from "sweetalert2";
import API_BASE_URL from "../config";

export const login = (username, password) => async (dispatch) => {
  try {
    const response = await axios.post(
      "https://api-be-xyz.tapemansel.co.id/api/login",
      {
        email: username,
        password: password,
      }
    );

    if (response.status === 200) {
      const token = response.data.token;
      const role = response.data.user.role_id;
      const id = response.data.user.id;
      console.log("role", role);
      sessionStorage.setItem("token", token);
      sessionStorage.setItem("role", role);
      sessionStorage.setItem("id", id);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: response.data.user,
      });
    } else {
      dispatch({
        type: LOGIN_FAILURE,
        payload: response.data.error,
      });
    }
  } catch (error) {
    console.error("Login failed:", error);
    Swal.fire({
      icon: "error",
      title: error.response.status,
      text: error.response.data.message,
      footer: error.message,
    });
    dispatch({
      type: LOGIN_FAILURE,
      payload: "An error occurred during login",
    });
  }
};

export const logout = () => {
  return (dispatch) => {
    const token = sessionStorage.getItem("token");
    // Make an API request to log the user out
    fetch(`${API_BASE_URL}/api/logout`, {
      method: "POST", // or the appropriate method for your API
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        // Add any additional headers as needed
      },
    })
      .then((response) => {
        if (response.ok) {
          // If the API request is successful, dispatch the logout action
          sessionStorage.clear();
          dispatch({ type: LOGOUT });
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Logout Successfully",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
          }).then(() => {
            window.location.href = "/admin/login";
          });
        } else {
          // Handle errors if needed
          console.error("Logout failed:");
          Swal.fire({
            title: "Error!",
            text: `logout failed`,
            icon: "warning",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        // Handle network errors or other issues
        console.error("Logout failed:", error.message);
        Swal.fire({
          title: "Error!",
          text: `${error.message}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      });
  };
};
