import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import defaultImage from "../../assets/defaultimage.png";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import {
  fetchCustomPageList,
  saveCustomPage,
} from "../../redux/customPage/CustomPageAction";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import { Text6 } from "../../mainComponent/Paragraph";
import { MenuItem } from "@mui/material";
import TextEditor from "../../components/main/TextEditor";
import ParentDropdown from "../parent/ParentDropdown";

const CustomPageAdd = ({ saveCustomPage, customPageList, props }) => {
  const navigate = useNavigate();
  const [title_page, setTitlePage] = useState("");
  const [content, setContent] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([null]);
  const [parent_id, setParent] = useState([null]);
  const [inputFileElements, setInputFileElements] = useState(1);

  console.log("coba1", selectedFiles);

  useEffect(() => {
    // Fetch urusan data when the component mounts
    console.log("Fetching urusan list...");
    fetchCustomPageList();
  }, [fetchCustomPageList]);

  const handleFileChange = (event, index) => {
    const files = event.target.files;
    const newSelectedFiles = [...selectedFiles];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const previewUrl = URL.createObjectURL(file);
      newSelectedFiles[index + i] = { file, previewUrl };
    }

    setSelectedFiles(newSelectedFiles);
  };

  const handleEditorChange = (value) => {
    setContent(value);
  };

  const handleAddFileInput = () => {
    setInputFileElements((prev) => prev + 1);
    setSelectedFiles((prev) => [...prev, null]);
  };

  const onCancel = async (id) => {
    const confirmationResult = await Swal.fire({
      title: "Apakah anda ingin membatalkan aktivitas?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    });
    if (confirmationResult.isConfirmed) {
      try {
        navigate("/admin/management-user");
      } catch (error) {
        Swal.fire({
          title: "Error!",
          text: ` Anda tidak dapat membatalkan aktivitas ${error}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    }
  };
  const handleParentChange = (e) => {
    setParent(e.target.value);
  };

  const handleSave = async () => {
    try {
      const files = await Promise.all(
        selectedFiles.map(async ({ file }) => {
          // Assuming you want to send the file data in the 'banner' field
          return file;
        })
      );

      const customPageList = {
        title_page,
        content,
        parent_id,
        banner: files,
      };

      saveCustomPage(customPageList);
    } catch (error) {
      console.error("Error saving custom page:", error);
      // Handle error, show a message to the user, etc.
    }
  };
  return (
    <div className="pl-4 overflow-hidden max-w-screen  ">
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 2, width: "30ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="filled-basic"
          label="Title Page"
          variant="filled"
          value={title_page}
          onChange={(e) => setTitlePage(e.target.value)}
        />

        <ParentDropdown setParentSelect={setParent} />

        <div className="grid grid-cols-3 gap-4 ">
          {Array.from({ length: inputFileElements }).map((_, index) => (
            <div key={index}>
              <input
                type="file"
                accept="image/*"
                onChange={(event) => handleFileChange(event, index)}
                className="hidden"
                id={`fileInput${index}`}
              />
              <label htmlFor={`fileInput${index}`} className="cursor-pointer">
                {selectedFiles[index] ? (
                  <img
                    src={selectedFiles[index].previewUrl}
                    alt={`Selected File Preview ${index}`}
                    className="h-auto w-[250px] hover:border-blue-500"
                  />
                ) : (
                  <div className=" hover:border-blue-500 flex items-center justify-center   ">
                    <img
                      src={defaultImage}
                      alt=""
                      className=" hover:border-blue-500 w-[100px]"
                    />
                  </div>
                )}
              </label>
            </div>
          ))}
          <div onClick={handleAddFileInput} className="cursor-pointer">
            <AddToPhotosIcon />
          </div>
        </div>
      </Box>

      <TextEditor text={content} handleChange={handleEditorChange} />

      <div className="w-[200px]  flex justify-between mt-20">
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={!title_page || !content || !parent_id || !selectedFiles}
        >
          Simpan
        </Button>

        <Button variant="contained" color="error" onClick={() => onCancel()}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  customPageList: state.customPageList,
});

export default connect(mapStateToProps, {
  saveCustomPage,
  fetchCustomPageList,
})(CustomPageAdd);
