import {
  FETCH_PERANGKAT_DAERAH_FAILURE,
  FETCH_PERANGKAT_DAERAH_REQUEST,
  FETCH_PERANGKAT_DAERAH_SUCCESS,
} from "./ActionType";

const initialstate = {
  loading: true,
  data: [],
  oldData: null,
  error: "",
};

export const PerangkatDaerahReducer = (state = initialstate, action) => {
  switch (action.type) {
    case "CLEAR_ERRORS":
      return { ...state, error: null };
    case FETCH_PERANGKAT_DAERAH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PERANGKAT_DAERAH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    case FETCH_PERANGKAT_DAERAH_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };

    default:
      return state;
  }
};
