import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import defaultImage from "../../../assets/defaultimage.png";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  updateIKKPengajuan,
  fetchOldIKKPengajuan,
} from "../../../redux/ikkPengajuan/IkkPengajuanAction";
import IKKMakroDropdown from "../../masterIKK/IKKMakroDropdown";

const EditIkkPengajuanDaerah = ({
  oldIKKPengajuanData,
  fetchOldIKKPengajuan,
  updateIKKPengajuan,
  IkkPengajuanList,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [nilai_rumus1, setNilai_rumus1] = useState("");
  const [nilai_rumus2, setNilai_rumus2] = useState("");
  const [selectedFile, setSelectedFile] = useState(defaultImage);

  console.log("file", selectedFile);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];

      if (allowedTypes.includes(file.type)) {
        setSelectedFile(file);
      } else {
        Swal.fire({
          title: "Invalid File Type!",
          text: "Please select a valid file type (doc, pdf, xlsx, docx).",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  console.log("selected file", selectedFile);

  const onCancel = async (id) => {
    const confirmationResult = await Swal.fire({
      title: "Apakah anda ingin membatalkan aktivitas?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    });
    if (confirmationResult.isConfirmed) {
      try {
        navigate("/admin/management-user");
      } catch (error) {
        Swal.fire({
          title: "Error!",
          text: ` Anda tidak dapat membatalkan aktivitas ${error}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    }
  };

  useEffect(() => {
    fetchOldIKKPengajuan(id);
  }, [fetchOldIKKPengajuan]);

  useEffect(() => {
    if (IkkPengajuanList) {
      setNilai_rumus1(IkkPengajuanList.data.nilai_rumus1 || "");
      setNilai_rumus2(IkkPengajuanList.data.nilai_rumus2 || "");
      setSelectedFile(IkkPengajuanList.data.selectedFile || "");
    }
  }, [IkkPengajuanList]);

  const handleSave = () => {
    const updatedIKKData = {
      nilai_rumus1,
      nilai_rumus2,
      selectedFile,
    };

    // Assuming you have the ID of the IKK item to update (hardcoded as 2 in this case)
    updateIKKPengajuan(id, updatedIKKData);
  };
  return (
    <div className="pl-4 overflow-hidden max-w-screen  ">
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 2, width: "30ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="filled-basic"
          label="Nilai Rumus 1"
          variant="filled"
          value={nilai_rumus1}
          onChange={(e) => setNilai_rumus1(e.target.value)}
        />
        <TextField
          id="filled-basic"
          label="Nilai Rumus 2 "
          variant="filled"
          value={nilai_rumus2}
          onChange={(e) => setNilai_rumus2(e.target.value)}
        />

        <div>
          <input type="file" onChange={handleFileChange} className="ml-2" />
        </div>
      </Box>

      <div className="w-[200px]  flex justify-between pl-4">
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={!nilai_rumus1 || !nilai_rumus2 || !selectedFile}
        >
          Simpan
        </Button>

        <Button variant="contained" color="error" onClick={() => onCancel()}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const mappedProps = {
    IkkPengajuanList: state.IkkPengajuanList.oldIKKPengajuanData,
  };

  console.log("Mapped Props:", mappedProps);

  return mappedProps;
};

export default connect(mapStateToProps, {
  fetchOldIKKPengajuan,
  updateIKKPengajuan,
})(EditIkkPengajuanDaerah);
