import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { HomeReducer } from "./HomeReducer";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { Reducer } from "../lppdHome/Reducer";
import authReducer from "../login/AuthReducer";
import { ArticleReducer } from "../article/ArticleReducer";
import { CustomPageReducer } from "../customPage/CustomPageReducer";
import { IkkReducer } from "../ikk/IkkReducer";
import UrusanReducer from "../urusan/UrusanReducer";
import { IkkOutputReducer } from "../ikkOutput/IkkOutputReducer";
import { IkkMakroReducer } from "../ikkMakro/IkkMakroReducer";
import { IkkUserPengajuanReducer } from "../ikkUserPengajuan/IkkUserPengajuanReducer";
import { IkkPengajuanReducer } from "../ikkPengajuan/IkkPengajuanReducer";
import { IkkMakroPengajuanReducer } from "../ikkMakroUserPengajuan/IkkMakroPengajuanReducer";
import { CapaianReducer } from "../capaian/CapaianReducer";
import { PelaporanIKKOutputReducer } from "../pelaporanIKKOutput/PelaporanIKKOutputReducer";
import { IkkOutputPengajuanReducer } from "../IkkOutputPengajuan/IkkOutputPengajuanReducer";
import { PelaporanIKKReducer } from "../pelaporanIKK/PelaporanIKKReducer";
import { IkkMakroPengajuanDaerahReducer } from "../ikkMakroUserPengajuanDaerah/IkkMakroPengajuanDaerahReducer";
import registerReducer from "../register/RegisterReducer";
import { IkkUserPengajuanReportReducer } from "../ikkUserPengajuanReport/IkkUserPengajuanReducer";
import { PerangkatDaerahReducer } from "../webPerangkatDaerah/PerangkatDaerahReducer";
import { StrukturOrganisasiReducer } from "../webStrukturOrganisasi/StrukturOrganisasiReducer";
import { WilayahReducer } from "../webWilayah/WilayahReducer";
import { GeografisReducer } from "../webGeografis/GeografisReducer";
import { PendudukReducer } from "../webPenduduk/PendudukReducer";
import { WebArticleReducer } from "../webArticle/WebArticleReducer";
import { BeritaReducer } from "../webBerita/BeritaReducer";
import { LayananReducer } from "../webLayanan/LayananReducer";
import { InformasiReducer } from "../webInformasi/InformasiReducer";
import { GalleryReducer } from "../webGallery/GalleryReducer";
import { ParentReducer } from "../parent/ParentReducer";
import { UserReducer } from "../user/UserReducer";
import { ContactReducer } from "../ contact/ContactReducer";

const rootreducer = combineReducers({
  register: registerReducer,
  home: HomeReducer,
  userList: UserReducer,
  PerangkatDaerah: PerangkatDaerahReducer,
  StrukturOrganisasi: StrukturOrganisasiReducer,
  Wilayah: WilayahReducer,
  Geografis: GeografisReducer,
  Penduduk: PendudukReducer,
  WebArticle: WebArticleReducer,
  Berita: BeritaReducer,
  Layanan: LayananReducer,
  Gallery: GalleryReducer,
  Informasi: InformasiReducer,
  homeList: Reducer,
  auth: authReducer,
  articleList: ArticleReducer,
  Contact: ContactReducer,
  parentList: ParentReducer,
  customPageList: CustomPageReducer,
  IkkList: IkkReducer,
  addUrusan: UrusanReducer,
  IkkOutputList: IkkOutputReducer,
  IkkMakroList: IkkMakroReducer,
  IkkUserPengajuanList: IkkUserPengajuanReducer,
  IkkUserPengajuanReport: IkkUserPengajuanReportReducer,
  IkkPengajuanList: IkkPengajuanReducer,
  IkkMakroPengajuan: IkkMakroPengajuanReducer,
  Capaian: CapaianReducer,
  PelaporanIKKOutput: PelaporanIKKOutputReducer,
  IkkOutputPengajuanList: IkkOutputPengajuanReducer,
  PelaporanIKKList: PelaporanIKKReducer,
  IkkMakroPengajuanDaerahList: IkkMakroPengajuanDaerahReducer,
});

const store = configureStore({
  reducer: rootreducer,
  middleware: [logger, thunk],
});

export default store;
