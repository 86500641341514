export const FETCH_CUSTOMPAGE_REQUEST = "FETCH_CUSTOMPAGE_REQUEST";
export const FETCH_CUSTOMPAGE_SUCCESS = "FETCH_CUSTOMPAGE_SUCCESS";
export const FETCH_CUSTOMPAGE_FAILURE = "FETCH_CUSTOMPAGE_FAILURE";

export const FETCH_OLD_CUSTOMPAGE_SUCCESS = "FETCH_OLD_CUSTOMPAGE_SUCCESS";
export const FETCH_OLD_CUSTOMPAGE_FAILURE = "FETCH_OLD_CUSTOMPAGE_FAILURE";
export const UPDATE_CUSTOMPAGE_SUCCESS = "UPDATE_CUSTOMPAGE_SUCCESS";
export const UPDATE_CUSTOMPAGE_FAILURE = "UPDATE_CUSTOMPAGE_FAILURE";

export const REDIRECT_TO_LOGIN = "REDIRECT_TO_LOGIN";
export const DELETE_CUSTOMPAGE_SUCCESS = "DELETE_CUSTOMPAGE_SUCCESS";
export const DELETE_CUSTOMPAGE_FAILURE = "DELETE_CUSTOMPAGE_FAILURE";
