import React, { useEffect } from "react";
import { Text1, Text2, Text6, Text7 } from "../../mainComponent/Paragraph";
import HomeIcon from "@mui/icons-material/Home";
import { connect } from "react-redux";
import { fetchWilayahList } from "../../redux/webWilayah/WilayahAction";

const DataWilayah = (props) => {
  useEffect(() => {
    props.fetchWilayahList();
  }, []);
  const data = props.Wilayah.data.custom_page || [];
  // console.log("perangkat daerah", data);
  const bgStyle = {
    backgroundImage: `url(${data.bannerUrl})`,
    backgroundSize: "cover", // Adjust based on your requirements
    backgroundRepeat: "no-repeat",
  };
  return (
    <div>
      <div
        className="flex justify-center  h-[150px] items-center mb-4 text-white"
        style={bgStyle}
      >
        <Text1 text={`${data.title_page}`} />
      </div>

      <div className="flex">
        <div className="mr-2">
          <HomeIcon />
        </div>
        <div className="mr-2">
          <Text6 text="Home" />
        </div>
        <div className="mr-2">
          <Text6 text="/" />
        </div>
        <div className="mr-2">
          <Text7 text="Data Wilayah" />
        </div>
      </div>
      <div className="mt-4">
        {/* <Text2 text={`${data.slug}`} />
        <div className="mt-2">
          <img src={data.bannerUrl} alt="gambar" className="w-[1000px]" />
        </div> */}
        <div
          dangerouslySetInnerHTML={{
            __html: data.content,
          }}
        />
        {/* <div className="mt-2">
          <Text2 text={`${data.content}`} />
        </div> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Wilayah: state.Wilayah,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchWilayahList: () => dispatch(fetchWilayahList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataWilayah);
