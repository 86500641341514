import React, { useEffect, useState } from "react";
import edit from "../../../assets/edit.png";
import deleteIcon from "../../../assets/delete.png";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { connect } from "react-redux";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PrintIcon from "@mui/icons-material/Print";
import excel from "../../../assets/excel.png";
import { TextField } from "@mui/material";
import {
  deleteCapaian,
  fetchCapaianList,
} from "../../../redux/capaian/CapaianAction";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

// modal
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Text6 } from "../../../mainComponent/Paragraph";

const MasterCapaian = (props) => {
  const Swal = require("sweetalert2");
  const itemsPerPage = 5; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const [checkedItems, setCheckedItems] = useState({});
  const role = sessionStorage.getItem("role");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    props.fetchCapaianList();
  }, []);

  useEffect(() => {
    setData(props.Capaian.data || []);
  }, [props.Capaian]);

  const handleSearchChange = (event) => {
    const input = event.target.value.toLowerCase();
    setSearchInput(input);

    // If searchInput is null or empty, reset data to the original list
    if (input === null || input.trim() === "") {
      setData(props.Capaian.data || []);
    } else {
      // Otherwise, update the data state with filtered items
      const filteredData = data.filter(
        (item) =>
          item.ikk.toLowerCase().includes(input) ||
          item.satuan.toLowerCase().includes(input)
      );
      setData(filteredData);
    }

    setCurrentPage(1); // Reset current page to 1 when search changes
  };

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handlePrevClick = () => {
    paginate(currentPage - 1);
  };

  const handleNextClick = () => {
    paginate(currentPage + 1);
  };

  const handleCheck = (id) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [id]: !prevCheckedItems[id],
    }));
  };

  const handleCheckAll = (isCheckedAll) => {
    const newCheckedItems = {};
    data.forEach((item) => {
      newCheckedItems[item.id] = isCheckedAll;
    });
    setCheckedItems(newCheckedItems);
  };

  const isChecked = (id) => {
    return checkedItems[id] || false;
  };

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onDelete = async (id) => {
    const confirmationResult = await Swal.fire({
      title: `Apakah anda ingin menghapus data ${id}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    });

    if (confirmationResult.isConfirmed) {
      try {
        await props.deleteCapaian(id);
      } catch (error) {}
    }
  };

  return (
    <div>
      <div className="mb-4 grid grid-cols-12 gap-2">
        <div className="col-span-12 md:col-span-2 ">
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 300,
            }}
          >
            <IconButton sx={{ p: "10px" }} aria-label="menu">
              <MenuIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search..."
              inputProps={{ "aria-label": "search google maps" }}
              value={searchInput}
              onChange={handleSearchChange}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>
        <div className="col-span-10 md:col-span-10  flex justify-end">
          <Stack direction="row" spacing={2}>
            <Link to="/admin/userpengajuan/capaian/add">
              <Button variant="contained">Tambah</Button>
            </Link>
            <Link>
              <Button variant="contained" endIcon={<PrintIcon />}>
                Print
              </Button>
            </Link>
            <Link>
              <Button variant="contained" endIcon={<PictureAsPdfIcon />}>
                PDF
              </Button>
            </Link>
            <Link>
              <Button variant="contained">
                Excel
                <img src={excel} alt="Excel Icon" className="ml-2 w-[20px]" />
              </Button>
            </Link>
          </Stack>
        </div>
      </div>

      <div>
        <table className="w-[93.5vw] ">
          <thead>
            <tr className="bg-gray">
              <th className="p-4">
                <input
                  type="checkbox"
                  onChange={(e) => handleCheckAll(e.target.checked)}
                />
              </th>
              <th className="p-4">IKK </th>
              <th className="p-4">Satuan</th>
              <th className="p-4">Capaian</th>
              <th className="p-4">Action</th>
            </tr>
          </thead>
          {currentItems.map((item, index) => (
            <tbody>
              <tr
                key={index}
                className={index % 2 === 0 ? "bg-light" : "bg-[#cae6ed]"}
              >
                <td className="p-4 text-center">
                  <input
                    type="checkbox"
                    checked={isChecked(item.id)}
                    onChange={() => handleCheck(item.id)}
                  />
                </td>
                <td className="p-4 text-center">{item.ikk}</td>
                <td className="p-4 text-center">{item.satuan}</td>
                <td className="p-4 text-center">
                  <Button
                    variant="contained"
                    href="#contained-buttons"
                    onClick={openModal}
                  >
                    Info
                  </Button>
                </td>

                <td className="p-4 text-center">
                  <div className="flex items-center justify-center">
                    <Link to={`/admin/userpengajuan/capaian/edit/${item.id}`}>
                      <img src={edit} alt="" className="w-[30px]" />
                    </Link>

                    <img
                      src={deleteIcon}
                      alt=""
                      className="w-[30px]"
                      onClick={() => onDelete(item.id)}
                    />
                  </div>
                </td>

                <Dialog open={isModalOpen} onClose={closeModal}>
                  <DialogTitle>Info Capaian</DialogTitle>
                  <DialogContent>
                    {item.capaian.map((capaian, index) => (
                      <div className="border-b border-gray">
                        <div className="flex">
                          <div className="w-[40%]">
                            <Text6 text="Tahun" />
                          </div>
                          <Text6 text={capaian.tahun} />
                        </div>
                        <div className="flex">
                          <div className="w-[40%]">
                            <Text6 text="Capaian Kinerja" />
                          </div>
                          <Text6 text={capaian.capaian_kinerja} />
                        </div>
                        <div className="flex">
                          <div className="w-[40%]">
                            <Text6 text="Laju Kinerja" />
                          </div>
                          <Text6 text={capaian.laju_kinerja} />
                        </div>
                        <div className="flex">
                          <div className="w-[40%]">
                            <Text6 text="Laju " />
                          </div>
                          <Text6 text={capaian.laju} />
                        </div>
                        <div className="flex">
                          <div className="w-[40%]">
                            <Text6 text="Rumus" />
                          </div>
                          <Text6 text={capaian.rumus} />
                        </div>
                      </div>
                    ))}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={closeModal} color="error">
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </tr>
            </tbody>
          ))}
        </table>
      </div>

      {/* Pagination */}
      {/* Pagination */}
      {!data || data.length === 0 ? (
        ""
      ) : (
        <div className="flex justify-center mt-4 pb-4">
          <button
            onClick={handlePrevClick}
            className={`mx-2 px-4 py-2 ${
              currentPage === 1 ? "bg-gray text-white rounded-full" : ""
            }`}
          >
            {"<"}
          </button>

          {[...Array(totalPages).keys()].map((number) => (
            <button
              key={number}
              onClick={() => paginate(number + 1)}
              className={`mx-2 px-4 py-2 ${
                currentPage === number + 1
                  ? "bg-gray text-white rounded-full"
                  : ""
              }`}
            >
              {number + 1}
            </button>
          ))}

          <button
            onClick={handleNextClick}
            className={`mx-2 px-4 py-2 ${
              currentPage === totalPages
                ? "bg-gray text-white rounded-full"
                : ""
            }`}
          >
            {">"}
          </button>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    Capaian: state.Capaian,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCapaianList: () => dispatch(fetchCapaianList()),
    deleteCapaian: (id) => dispatch(deleteCapaian(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterCapaian);
