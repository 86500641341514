import React from "react";
import MainLayout from "../../layout/MainLayout";
import StrukturOrganisasi from "../../components/profile/StrukturOrganisasi";

const StrukturOrganisasiView = () => {
  return (
    <MainLayout>
      <div className="col-span-12  ">
        <StrukturOrganisasi />
      </div>
    </MainLayout>
  );
};

export default StrukturOrganisasiView;
