import React from "react";
import video1 from "../../assets/video/video.mp4";

const Video = () => {
  return (
    <div>
      <video autoPlay loop muted className="w-full h-auto">
        <source src={video1} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Video;
