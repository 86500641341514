import React from "react";
import MainLayoutAdmin from "../../layout/MainLayoutAdmin";
import Gallery from "../../componentsAdmin/home/Gallery";
import { Text8 } from "../../mainComponent/Paragraph";

const HomeViewAdm = () => {
  return (
    <MainLayoutAdmin>
      <div>
        <div className="pb-4  border-b border-darkgray mb-8">
          <Text8 text="Dasboard" />
        </div>
        <Gallery />
      </div>
    </MainLayoutAdmin>
  );
};

export default HomeViewAdm;
