export const Input1 = (props) => {
  return (
    <div className="flex justify-between items-center">
      <button
        onClick={props.onClick}
        className="border border-dark w-[auto] px-8 rounded-lg h-[45px] bg-b hover:bg-dark hover:text-white text-dark"
      >
        <p className="font-medium text-[15px] leading-[25px]">{props.text}</p>
      </button>
    </div>
  );
};
