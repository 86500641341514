import React from "react";
import { useDispatch, useSelector } from "react-redux";
import MainLayoutAdmin from "../../../layout/MainLayoutAdmin";
import { Text8 } from "../../../mainComponent/Paragraph";
import EditUrusan from "../../../componentsAdmin/masterIKK/EditUrusan";

const EditUrusanAdm = () => {
  const dispatch = useDispatch();
  const isUrusanAddedSuccessfully = useSelector(
    (state) => state.addUrusan.isUrusanAddedSuccessfully
  );

  return (
    <MainLayoutAdmin>
      <div>
        <div className="">
          <div className="ml-8 pb-4 border-b border-darkgray mb-8 ">
            <Text8 text="Edit Urusan " />
          </div>
          <EditUrusan />
        </div>
      </div>
    </MainLayoutAdmin>
  );
};

export default EditUrusanAdm;
