import React from "react";
import Gallery2 from "../../components/berita/Gellery2";
import MainLayout from "../../layout/MainLayout";

const GalleryView = () => {
  return (
    <MainLayout>
      <div className="col-span-12">
        <Gallery2 />
      </div>
    </MainLayout>
  );
};

export default GalleryView;
