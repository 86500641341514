// ColorPicker.js
import React, { useState } from "react";
import { Text10, Text2 } from "../../mainComponent/Paragraph";
import { MenuItem, TextField } from "@mui/material";
import styled from "styled-components";
import defaultImage from "../../assets/default2.png";

const FontPickerContainer = styled.div`
  margin: 20px;
  width: 300px; // Set the width as needed
  height: 100px; // Set the height as needed
`;
const Settings = () => {
  const [h1_font, setH1_font] = useState("Poppins");
  const [h2_font, setH2_font] = useState("Poppins");
  const [h3_font, setH3_font] = useState("Poppins");
  const [h4_font, setH4_font] = useState("Poppins");
  const [h5_font, setH5_font] = useState("Poppins");
  const [p_font, setP_font] = useState("Poppins");
  const [span_font, setSpan_font] = useState("Poppins");

  const [h1_color, setH1_color] = useState("#FFFFF");
  const [h2_color, setH2_color] = useState("#FFFFF");
  const [h3_color, setH3_color] = useState("#FFFFF");
  const [h4_color, setH4_color] = useState("#FFFFF");
  const [h5_color, setH5_color] = useState("#FFFFF");
  const [p_color, setP_color] = useState("#FFFFF");
  const [span_color, setSpan_color] = useState("#FFFFF");

  const [main_color, setMain_color] = useState("#FFFFF");
  const [background_color, setBackground_color] = useState("#FFFFF");
  const [hover_color, setHover_color] = useState("#FFFFF");
  const [button_color, setbutton_color] = useState("#FFFFF");

  const [selectedFiles, setSelectedFiles] = useState(defaultImage);
  const [inputFileElements, setInputFileElements] = useState(1);

  const fontOptions = [
    "Roboto",
    "Arial",
    "Poppins",
    "Verdana",
    "Montserrat",
    "Times New Roman",
  ];

  const handleFontChangeH1 = (event) => {
    setH1_font(event.target.value);
  };
  const handleFontChangeH2 = (event) => {
    setH2_font(event.target.value);
  };
  const handleFontChangeH3 = (event) => {
    setH3_font(event.target.value);
  };

  const handleFontChangeH4 = (event) => {
    setH4_font(event.target.value);
  };

  const handleFontChangeH5 = (event) => {
    setH5_font(event.target.value);
  };

  const handleFontChangep = (event) => {
    setP_font(event.target.value);
  };

  const handleFontChangeSpan = (event) => {
    setSpan_font(event.target.value);
  };

  const handleFileChange = (event, index) => {
    const files = event.target.files;
    const newSelectedFiles = [...selectedFiles];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const previewUrl = URL.createObjectURL(file);
      newSelectedFiles[index + i] = { file, previewUrl };
    }

    setSelectedFiles(newSelectedFiles);
  };

  return (
    <div className="grid grid-cols-12 gap-4  justify-center h-auto pl-8">
      <div className="col-span-12">
        <Text2 text="LOGO :" />
        {Array.from({ length: inputFileElements }).map((_, index) => (
          <div key={index}>
            <input
              type="file"
              accept="image/*"
              onChange={(event) => handleFileChange(event, index)}
              className="hidden"
              id={`fileInput${index}`}
            />
            <label htmlFor={`fileInput${index}`} className="cursor-pointer">
              {selectedFiles[index] ? (
                <img
                  src={selectedFiles[index].previewUrl}
                  alt={`Selected File Preview ${index}`}
                  className="h-auto w-[250px] hover:border-blue-500"
                />
              ) : (
                <div className=" hover:border-blue-500 flex items-center justify-center   ">
                  <img
                    src={defaultImage}
                    alt=""
                    className=" hover:border-blue-500 w-[100px]"
                  />
                </div>
              )}
            </label>
          </div>
        ))}
      </div>
      <div className="col-span-3 w-64 p-4 border border-gray-300 rounded-lg shadow-md">
        <Text2 text="Main Color" />
        <Text10 text="Navbar, Footer, Banner Glass" />
        <div className="mt-4">
          <p className="mb-2">Selected Color:</p>
          <input
            type="color"
            className="mb-4"
            value={main_color}
            onChange={(e) => setMain_color(e.target.value)}
          />
          <div className="mt-4">
            <p className="mb-2">Selected Color:</p>
            <div
              className="w-12 h-12 bg-gray-200 border border-gray-300 rounded"
              style={{ backgroundColor: main_color }}
            ></div>
            <p className="mt-2 text-gray-600">{main_color}</p>
          </div>
        </div>
      </div>

      <div className="col-span-3 w-64 p-4 border border-gray-300 rounded-lg shadow-md">
        <Text2 text="Background Color" />
        <Text10 text="Navbar, Footer, Banner Glass" />
        <div className="mt-4">
          <p className="mb-2">Selected Color:</p>
          <input
            type="color"
            className="mb-4"
            value={background_color}
            onChange={(e) => setBackground_color(e.target.value)}
          />
          <div className="mt-4">
            <p className="mb-2">Selected Color:</p>
            <div
              className="w-12 h-12 bg-gray-200 border border-gray-300 rounded"
              style={{ backgroundColor: background_color }}
            ></div>
            <p className="mt-2 text-gray-600">{background_color}</p>
          </div>
        </div>
      </div>

      <div className="col-span-3 w-64 p-4 border border-gray-300 rounded-lg shadow-md">
        <Text2 text="Hover Color" />
        <Text10 text="Navbar, Footer, Banner Glass" />
        <div className="mt-4">
          <p className="mb-2">Selected Color:</p>
          <input
            type="color"
            className="mb-4"
            value={hover_color}
            onChange={(e) => setHover_color(e.target.value)}
          />
          <div className="mt-4">
            <p className="mb-2">Selected Color:</p>
            <div
              className="w-12 h-12 bg-gray-200 border border-gray-300 rounded"
              style={{ backgroundColor: hover_color }}
            ></div>
            <p className="mt-2 text-gray-600">{hover_color}</p>
          </div>
        </div>
      </div>

      <div className="col-span-3 w-64 p-4 border border-gray-300 rounded-lg shadow-md">
        <Text2 text="Button Color" />
        <Text10 text="Navbar, Footer, Banner Glass" />
        <div className="mt-4">
          <p className="mb-2">Button Color:</p>
          <input
            type="color"
            className="mb-4"
            value={button_color}
            onChange={(e) => setbutton_color(e.target.value)}
          />
          <div className="mt-4">
            <p className="mb-2">Selected Color:</p>
            <div
              className="w-12 h-12 bg-gray-200 border border-gray-300 rounded"
              style={{ backgroundColor: button_color }}
            ></div>
            <p className="mt-2 text-gray-600">{button_color}</p>
          </div>
        </div>
      </div>

      <div className="col-span-4 border border-gray-300 rounded-lg shadow-md p-4">
        <p className="mb-4">Custom Font H1</p>
        <TextField
          id="outlined-select-currency"
          select
          label="Select Font"
          value={h1_font}
          onChange={handleFontChangeH1}
          helperText="Please select your font"
          style={{ width: "200px" }}
        >
          {fontOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>

        <input
          type="color"
          className="mb-4 ml-4"
          value={h1_color}
          onChange={(e) => setH1_color(e.target.value)}
        />

        <FontPickerContainer>
          <p style={{ fontFamily: h1_font, color: h1_color }}>
            This is an h1 heading with the selected font.
          </p>
        </FontPickerContainer>
      </div>

      <div className="col-span-4 border border-gray-300 rounded-lg shadow-md p-4">
        <p className="mb-4">Custom Font H2</p>
        <TextField
          id="outlined-select-currency"
          select
          label="Select Font"
          value={h2_font}
          onChange={handleFontChangeH2}
          helperText="Please select your font"
          style={{ width: "200px" }}
        >
          {fontOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>

        <input
          type="color"
          className="mb-4 ml-4"
          value={h2_color}
          onChange={(e) => setH2_color(e.target.value)}
        />

        <FontPickerContainer>
          <p style={{ fontFamily: h2_font, color: h2_color }}>
            This is an h1 heading with the selected font.
          </p>
        </FontPickerContainer>
      </div>

      <div className="col-span-4 border border-gray-300 rounded-lg shadow-md p-4">
        <p className="mb-4">Custom Font H3</p>
        <TextField
          id="outlined-select-currency"
          select
          label="Select Font"
          value={h3_font}
          onChange={handleFontChangeH3}
          helperText="Please select your font"
          style={{ width: "200px" }}
        >
          {fontOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>

        <input
          type="color"
          className="mb-4 ml-4"
          value={h3_color}
          onChange={(e) => setH3_color(e.target.value)}
        />

        <FontPickerContainer>
          <p style={{ fontFamily: h3_font, color: h3_color }}>
            This is an h1 heading with the selected font.
          </p>
        </FontPickerContainer>
      </div>

      <div className="col-span-4 border border-gray-300 rounded-lg shadow-md p-4">
        <p className="mb-4">Custom Font H4</p>
        <TextField
          id="outlined-select-currency"
          select
          label="Select Font"
          value={h4_font}
          onChange={handleFontChangeH4}
          helperText="Please select your font"
          style={{ width: "200px" }}
        >
          {fontOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>

        <input
          type="color"
          className="mb-4 ml-4"
          value={h4_color}
          onChange={(e) => setH4_color(e.target.value)}
        />

        <FontPickerContainer>
          <p style={{ fontFamily: h4_font, color: h4_color }}>
            This is an h1 heading with the selected font.
          </p>
        </FontPickerContainer>
      </div>

      <div className="col-span-4 border border-gray-300 rounded-lg shadow-md p-4">
        <p className="mb-4">Custom Font H5</p>
        <TextField
          id="outlined-select-currency"
          select
          label="Select Font"
          value={h5_font}
          onChange={handleFontChangeH5}
          helperText="Please select your font"
          style={{ width: "200px" }}
        >
          {fontOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>

        <input
          type="color"
          className="mb-4 ml-4"
          value={h5_color}
          onChange={(e) => setH5_color(e.target.value)}
        />

        <FontPickerContainer>
          <p style={{ fontFamily: h5_font, color: h5_color }}>
            This is an h1 heading with the selected font.
          </p>
        </FontPickerContainer>
      </div>

      <div className="col-span-4 border border-gray-300 rounded-lg shadow-md p-4">
        <p className="mb-4">Custom Font p</p>
        <TextField
          id="outlined-select-currency"
          select
          label="Select Font"
          value={p_font}
          onChange={handleFontChangep}
          helperText="Please select your font"
          style={{ width: "200px" }}
        >
          {fontOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>

        <input
          type="color"
          className="mb-4 ml-4"
          value={p_color}
          onChange={(e) => setP_color(e.target.value)}
        />

        <FontPickerContainer>
          <p style={{ fontFamily: p_font, color: p_color }}>
            This is an h1 heading with the selected font.
          </p>
        </FontPickerContainer>
      </div>

      <div className="col-span-4 border border-gray-300 rounded-lg shadow-md p-4">
        <p className="mb-4">Custom Font Span</p>
        <TextField
          id="outlined-select-currency"
          select
          label="Select Font"
          value={span_font}
          onChange={handleFontChangeSpan}
          helperText="Please select your font"
          style={{ width: "200px" }}
        >
          {fontOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>

        <input
          type="color"
          className="mb-4 ml-4"
          value={span_color}
          onChange={(e) => setSpan_color(e.target.value)}
        />

        <FontPickerContainer>
          <p style={{ fontFamily: span_font, color: span_color }}>
            This is an h1 heading with the selected font.
          </p>
        </FontPickerContainer>
      </div>
    </div>
  );
};

export default Settings;
