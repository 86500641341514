import {
  DELETE_IKK_FAILURE,
  DELETE_IKK_SUCCESS,
  FETCH_IKK_OUTPUT_PENGAJUAN_FAILURE,
  FETCH_IKK_OUTPUT_PENGAJUAN_REQUEST,
  FETCH_IKK_OUTPUT_PENGAJUAN_SUCCESS,
} from "./ActionType";
import axios from "axios";
import API_BASE_URL from "../config";
import Swal from "sweetalert2";

const fetchIKKOutputPengajuanRequest = () => {
  return {
    type: FETCH_IKK_OUTPUT_PENGAJUAN_REQUEST,
  };
};

const fetchIKKOutputPengajuanSuccess = (data) => {
  return {
    type: FETCH_IKK_OUTPUT_PENGAJUAN_SUCCESS,
    payload: data,
  };
};

const fetchIKKOutputPengajuanFailure = (err) => {
  return {
    type: FETCH_IKK_OUTPUT_PENGAJUAN_FAILURE,
    payload: err,
  };
};

export const fetchIKKOutputPengajuanList = (year) => {
  return (dispatch) => {
    dispatch(fetchIKKOutputPengajuanRequest(year));

    // Retrieve the token from session storage
    const token = sessionStorage.getItem("token");

    // Set the token in the request headers
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const role = sessionStorage.getItem("role");

    const apiEndpoint =
      role === "2" ? `/api/dashboard/ikk-outputs/show?${year}` : "";

    if (!apiEndpoint) {
      // Handle invalid role
      dispatch(fetchIKKOutputPengajuanList("Invalid role"));

      Swal.fire({
        icon: "warning",
        title: "Error!",
        text: "Invalid role access",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
      return;
    }

    // {{uri}}/api/dashboard/ikk-outputs/show
    axios
      .get(`${API_BASE_URL}${apiEndpoint}`, axiosConfig)
      .then((res) => {
        let _list = res.data;
        dispatch(fetchIKKOutputPengajuanSuccess(_list));
      })
      .catch((err) => {
        dispatch(fetchIKKOutputPengajuanFailure(err.message));
        if (err.message === "Request failed with status code 401") {
          Swal.fire({
            title: "Error!",
            text: `${err.message}`,
            icon: "warning",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then(() => {
            window.location.href = "/admin/login";
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: `${err.message}`,
            icon: "warning",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          });
        }
      });
  };
};

export const clearErrors = () => (dispatch) => {
  dispatch({ type: "CLEAR_ERRORS" });
};
