// AddUrusan.js
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { saveUrusan, clearErrors } from "../../redux/urusan/UrusanAction";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const AddUrusan = ({ saveUrusan, clearErrors, urusanData, error }) => {
  const navigate = useNavigate();
  const [kategoriUrusan, setKategoriUrusan] = useState("");
  const [urusan, setUrusan] = useState("");

  const handleSave = () => {
    const urusanData = {
      kategoriUrusan,
      urusan,
    };

    saveUrusan(urusanData);
  };

  useEffect(() => {
    if (error) {
      Swal.fire({
        title: "Error!",
        text: `Failed to save Urusan. ${error}`,
        icon: "error",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
    clearErrors();
  }, [error, clearErrors]);

  const onCancel = async () => {
    const confirmationResult = await Swal.fire({
      title: "Apakah anda ingin membatalkan aktivitas?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    });
    if (confirmationResult.isConfirmed) {
      navigate("/admin/management-user");
    }
  };

  return (
    <div className="pl-8 overflow-hidden max-w-screen">
      <Box
        component="form"
        sx={{
          "& > :not(style)": { pb: 2, pr: 2, width: "30ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="filled-basic"
          label="Kategori Urusan"
          variant="filled"
          value={kategoriUrusan}
          onChange={(e) => setKategoriUrusan(e.target.value)}
        />

        <TextField
          id="filled-basic"
          label="Urusan"
          variant="filled"
          value={urusan}
          onChange={(e) => setUrusan(e.target.value)}
        />
      </Box>

      <div className="w-[200px] flex justify-between">
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={!kategoriUrusan || !urusan}
        >
          Simpan
        </Button>

        <Button variant="contained" color="error" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  urusanData: state.urusanData,
  error: state.error,
});

export default connect(mapStateToProps, { saveUrusan, clearErrors })(AddUrusan);
