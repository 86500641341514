import axios from "axios";
import API_BASE_URL from "../config";
import Swal from "sweetalert2";
import {
  FETCH_WILAYAH_FAILURE,
  FETCH_WILAYAH_REQUEST,
  FETCH_WILAYAH_SUCCESS,
} from "./ActionType";

const fetchWilayahRequest = () => {
  return {
    type: FETCH_WILAYAH_REQUEST,
  };
};

const fetchWilayahSuccess = (data) => {
  return {
    type: FETCH_WILAYAH_SUCCESS,
    payload: data,
  };
};

const fetchWilayahFailure = (err) => {
  return {
    type: FETCH_WILAYAH_FAILURE,
    payload: err,
  };
};

export const fetchWilayahList = () => {
  return (dispatch) => {
    dispatch(fetchWilayahRequest());
    const token = sessionStorage.getItem("token");
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${API_BASE_URL}/api/custom-pages/data-wilayah`, axiosConfig)
      .then((res) => {
        let _list = res.data;
        dispatch(fetchWilayahSuccess(_list));
      })
      .catch((err) => {
        dispatch(fetchWilayahFailure(err.message));
        console.log("first eror ikk output", err.message);
        Swal.fire({
          title: "Error!",
          text: `${err.message}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      });
  };
};
