import React, { useEffect, useState } from "react";
import opet from "../../assets/s1mu.webp";
import { Text1, Text2, Text3 } from "../../mainComponent/Paragraph";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { connect } from "react-redux";
import { fetchHomeList } from "../../redux/lppdHome/Action";

const Artikel = (props) => {
  const [currentItemIndex, setCurrentItemIndex] = useState(0);

  useEffect(() => {
    props.fetchHome();
  }, []);

  const handleNextItem = () => {
    setCurrentItemIndex((prevIndex) =>
      prevIndex < props.homeList.data.homepages[0].artikel.length - 1
        ? prevIndex + 1
        : prevIndex
    );
  };

  const handlePrevItem = () => {
    setCurrentItemIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  };

  return (
    <div>
      <div className="flex justify-between">
        <div className="border-b-2 border-blue w-16">
          <Text3 text="ARTIKEL" />
        </div>
        <div className="w-[80px] flex justify-between">
          <div
            className="border border-gray flex justify-center pl-1"
            onClick={handlePrevItem}
          >
            <ArrowBackIosIcon />
          </div>
          <div
            className="border border-gray flex justify-center pl-1"
            onClick={handleNextItem}
          >
            <ArrowForwardIosIcon />
          </div>
        </div>
      </div>
      {props.homeList &&
        props.homeList.data.homepages &&
        props.homeList.data.homepages.map((item) => (
          <div key={item.id} className="grid grid-cols-12 gap-2 mt-4">
            {item.artikel ? (
              item.artikel.length > 0 ? (
                <div className="col-span-12">
                  {item.artikel
                    .slice(currentItemIndex, currentItemIndex + 1)
                    .map((beritaItem) => (
                      <div className="col-span-full mb-12 " key={beritaItem.id}>
                        <img
                          src={beritaItem.imageThumbnailUrl}
                          alt="image"
                          className="rounded-lg opacity-80"
                          onError={(e) => {
                            e.target.src = opet; // Set default image on error
                          }}
                        />
                        <div className="-mt-[4rem] pl-4 bg-white bg-opacity-70  relative ">
                          <Text1 text={beritaItem.title_page} />
                          <Text2 text={beritaItem.created_at} />
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                <div className="col-span-12">
                  <Text2 text="No available data" />
                </div>
              )
            ) : (
              <p>No news data</p>
            )}
          </div>
        ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    homeList: state.homeList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchHome: () => dispatch(fetchHomeList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Artikel);
