import React from "react";
import MainLayoutAdmin from "../../../layout/MainLayoutAdmin";
import { Text8 } from "../../../mainComponent/Paragraph";
import AddCapaian from "../../../componentsAdmin/userPengajuan/masterCapaian/AddCapaian";
import EditCapaian from "../../../componentsAdmin/userPengajuan/masterCapaian/EditCapaian";

const EditCapaianAdm = () => {
  return (
    <MainLayoutAdmin>
      <div>
        <div className="pb-4  border-b border-darkgray mb-8">
          <Text8 text="Edit Capaian " />
        </div>
        <EditCapaian />
      </div>
    </MainLayoutAdmin>
  );
};

export default EditCapaianAdm;
