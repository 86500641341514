import {
  DELETE_IKK_PENGAJUAN_FAILURE,
  DELETE_IKK_PENGAJUAN_SUCCESS,
  FETCH_IKK_PENGAJUAN_FAILURE,
  FETCH_IKK_PENGAJUAN_REQUEST,
  FETCH_IKK_PENGAJUAN_SUCCESS,
  FETCH_OLD_IKK_PENGAJUAN_FAILURE,
  FETCH_OLD_IKK_PENGAJUAN_SUCCESS,
  UPDATE_IKK_PENGAJUAN_FAILURE,
  UPDATE_IKK_PENGAJUAN_SUCCESS,
} from "./ActionType";
import axios from "axios";
import API_BASE_URL from "../config";
import Swal from "sweetalert2";

export const updateIKKPengajuanSuccess = (data) => ({
  type: UPDATE_IKK_PENGAJUAN_SUCCESS,
  payload: data,
});

export const updateIKKPengajuanFailure = (error) => ({
  type: UPDATE_IKK_PENGAJUAN_FAILURE,
  payload: error,
});
export const saveIKKPengajuanSuccess = (data) => ({
  type: "ADD_IKK_PENGAJUAN_SUCCESS",
  payload: data,
});

export const saveIKKPengajuanFailure = (error) => ({
  type: "ADD_IKK_PENGAJUAN_FAILURE",
  payload: error,
});

export const saveIKKPengajuan = (IKKPengajuanData) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");

    const formData = new FormData();
    formData.append("capaian", IKKPengajuanData.capaian);
    formData.append("nilai_rumus1", IKKPengajuanData.nilaiRumus1);
    formData.append("nilai_rumus2", IKKPengajuanData.nilaiRumus2);
    formData.append("bukti", IKKPengajuanData.selectedFile);
    formData.append("ikk_master_id", IKKPengajuanData.IKKSelect);

    const response = await axios.post(
      `${API_BASE_URL}/api/dashboard/ikk-details/create`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    dispatch(saveIKKPengajuanSuccess(response.data));

    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "Data has been saved successfully.",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then(() => {
      window.location.href = "/admin/ikk";
    });
  } catch (error) {
    dispatch(saveIKKPengajuanFailure(error.message));
    if (error.message === "Request failed with status code 401") {
      Swal.fire({
        title: "Error!",
        text: `${error.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: `${error.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  }
};

const fetchIKKPengajuanRequest = () => {
  return {
    type: FETCH_IKK_PENGAJUAN_REQUEST,
  };
};

const fetchIKKPengajuanSuccess = (data) => {
  return {
    type: FETCH_IKK_PENGAJUAN_SUCCESS,
    payload: data,
  };
};

const fetchIKKPengajuanFailure = (err) => {
  return {
    type: FETCH_IKK_PENGAJUAN_FAILURE,
    payload: err,
  };
};

export const fetchIKKPengajuanList = (year) => {
  return (dispatch) => {
    dispatch(fetchIKKPengajuanRequest(year));

    // Retrieve the token from session storage
    const token = sessionStorage.getItem("token");
    const role = sessionStorage.getItem("role");

    const apiEndpoint =
      role === "2" ? `/api/dashboard/ikk-details?year=${year}` : "";

    if (!apiEndpoint) {
      // Handle invalid role
      dispatch(fetchIKKPengajuanFailure("Invalid role"));

      Swal.fire({
        icon: "warning",
        title: "Error!",
        text: "Invalid role access",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
      return;
    }

    // Set the token in the request headers
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${API_BASE_URL}${apiEndpoint}`, axiosConfig)
      .then((res) => {
        let _list = res.data;
        dispatch(fetchIKKPengajuanSuccess(_list));
      })
      .catch((err) => {
        dispatch(fetchIKKPengajuanFailure(err.message));
        if (err.message === "Request failed with status code 401") {
          Swal.fire({
            title: "Error!",
            text: `${err.message}`,
            icon: "warning",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then(() => {
            window.location.href = "/admin/login";
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: `${err.message}`,
            icon: "warning",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          });
        }
      });
  };
};

export const clearErrors = () => (dispatch) => {
  dispatch({ type: "CLEAR_ERRORS" });
};

export const deleteIkkPengajuan = (id) => {
  return async (dispatch) => {
    try {
      const token = sessionStorage.getItem("token");

      const response = await axios.delete(
        `${API_BASE_URL}/api/dashboard/ikk-details/delete/${id}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("first delete ikk", id);

      if (response.status === 200) {
        // Dispatch the DELETE_IKK_SUCCESS action
        dispatch({ type: DELETE_IKK_PENGAJUAN_SUCCESS });

        // Fetch the updated list after deletion
        dispatch(fetchIKKPengajuanList());
        Swal.fire({
          title: "Success!",
          text: "Data IKK Berhasil Dihapus ",
          confirmButtonColor: "#3085d6",
          icon: "success",
          confirmButtonText: "OK",
        });

        // Optionally, dispatch other actions or handle success
      } else {
        dispatch({ type: DELETE_IKK_PENGAJUAN_FAILURE });
        console.error("Error deleting item. Status:", response.status);
        Swal.fire({
          title: "Error!",
          text: `${response.status}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      dispatch({ type: DELETE_IKK_PENGAJUAN_FAILURE });
      console.error("Error deleting item:", error.message);
      if (error.message === "Request failed with status code 401") {
        Swal.fire({
          title: "Error!",
          text: `${error.message}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        }).then(() => {
          window.location.href = "/admin/login";
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: `${error.message}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    }
  };
};

export const fetchOldIKKPengajuan = (id) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");
    const response = await axios.get(
      `${API_BASE_URL}/api/dashboard/ikk-details/show/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: FETCH_OLD_IKK_PENGAJUAN_SUCCESS,
      payload: response.data, // Assuming the API returns the old data
    });
  } catch (error) {
    dispatch({
      type: FETCH_OLD_IKK_PENGAJUAN_FAILURE,
      payload: error.message,
    });
  }
};

export const updateIKKPengajuan = (id, updatedIKKData) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");
    const formData = new FormData();
    formData.append("nilai_rumus1", updatedIKKData.nilai_rumus1);
    formData.append("nilai_rumus2", updatedIKKData.nilai_rumus2);
    formData.append("bukti", updatedIKKData.selectedFile);

    // console.log("first", updatedIKKData.selectedFile);
    const response = await axios.put(
      `${API_BASE_URL}/api/dashboard/ikk-details/update/${id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("Update IKK response:", response.data);
    // dispatch({ type: "ADD_URUSAN_SUCCESS", payload: response.data });
    dispatch(updateIKKPengajuanSuccess(response.data));

    Swal.fire({
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      icon: "success",
      title: "Success!",
      text: "Data has been saved successfully.",
    }).then(() => {
      window.location.href = "/admin/ikk";
    });
  } catch (err) {
    // dispatch({ type: "ADD_URUSAN_FAILURE", payload: error.message });
    dispatch(updateIKKPengajuanFailure(err.message));
    if (err.message === "Request failed with status code 401") {
      Swal.fire({
        title: "Error!",
        text: `${err.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: `${err.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  }
};
