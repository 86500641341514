import axios from "axios";
import API_BASE_URL from "../config";
import Swal from "sweetalert2";
import {
  FETCH_INFORMASI_FAILURE,
  FETCH_INFORMASI_REQUEST,
  FETCH_INFORMASI_SUCCESS,
} from "./ActionType";

const fetchInformasiRequest = () => {
  return {
    type: FETCH_INFORMASI_REQUEST,
  };
};

const fetchInformasiSuccess = (data) => {
  return {
    type: FETCH_INFORMASI_SUCCESS,
    payload: data,
  };
};

const fetchInformasiFailure = (err) => {
  return {
    type: FETCH_INFORMASI_FAILURE,
    payload: err,
  };
};

export const fetchInformasiList = () => {
  return (dispatch) => {
    dispatch(fetchInformasiRequest());

    // Retrieve the token from session storage
    const token = sessionStorage.getItem("token"); // Replace "your_token_key" with the actual key used to store the token

    // Set the token in the request headers
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${API_BASE_URL}/api/list/informasi`, axiosConfig)
      .then((res) => {
        let _list = res.data;
        dispatch(fetchInformasiSuccess(_list));
      })
      .catch((err) => {
        dispatch(fetchInformasiFailure(err.message));
      });
  };
};
