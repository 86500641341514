import React, { useState } from "react";
import video from "../../assets/video/video.mp4";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ModalVideo from "./ModalVideo";

const Video = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      <div className="mb-4">
        <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={openModal}>
            Edit
          </Button>
        </Stack>
      </div>
      <video width="640" height="360" controls>
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <ModalVideo isOpen={isModalOpen} handleClose={closeModal} />
    </div>
  );
};

export default Video;
