export const FETCH_IKK_MAKRO_PENGAJUAN_REQUEST =
  "FETCH_IKK_MAKRO_PENGAJUAN_REQUEST";
export const FETCH_IKK_MAKRO_PENGAJUAN_SUCCESS =
  "FETCH_IKK_MAKRO_PENGAJUAN_SUCCESS";
export const FETCH_IKK_MAKRO_PENGAJUAN_FAILURE =
  "FETCH_IKK_MAKRO_PENGAJUAN_FAILURE";
export const REDIRECT_TO_LOGIN = "REDIRECT_TO_LOGIN";

// Detail IKK Makro
export const FETCH_DETAIL_IKK_MAKRO_PENGAJUAN_REQUEST =
  "FETCH_DETAIL_IKK_MAKRO_PENGAJUAN_REQUEST";
export const FETCH_DETAIL_IKK_MAKRO_PENGAJUAN_SUCCESS =
  "FETCH_DETAIL_IKK_MAKRO_PENGAJUAN_SUCCESS";
export const FETCH_DETAIL_IKK_MAKRO_PENGAJUAN_FAILURE =
  "FETCH_DETAIL_IKK_MAKRO_PENGAJUAN_FAILURE";

export const FETCH_OLD_IKK_MAKRO_PENGAJUAN_SUCCESS =
  "FETCH_OLD_IKK_MAKRO_PENGAJUAN_SUCCESS";
export const FETCH_OLD_IKK_MAKRO_PENGAJUAN_FAILURE =
  "FETCH_OLD_IKK_MAKRO_PENGAJUAN_FAILURE";
export const UPDATE_IKK_MAKRO_PENGAJUAN_SUCCESS =
  "UPDATE_IKK_MAKRO_PENGAJUAN_SUCCESS";
export const UPDATE_IKK_MAKRO_PENGAJUAN_FAILURE =
  "UPDATE_IKK_MAKRO_PENGAJUAN_FAILURE";
