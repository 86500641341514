import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeView from "../view/website/HomeView";
import ParagraphView from "../mainComponent/ParagraphView";
import ArticleView from "../view/website/ArticleView";
import StrukturOrganisasiView from "../view/website/StrukturOrganisasiView";
import DetailArticleView from "../view/website/DetailArticleView";
import HomeViewAdm from "../view/admin/HomeViewAdm";
import AddGalleryAdm from "../view/admin/homeSection/AddGalleryAdm";
import GalleryAdm from "../view/admin/homeSection/GalleryAdm";
import ManagementUserAdm from "../view/admin/managementUser/ManagementUserAdm";
import InputView from "../mainComponent/InputView";
import AddManagementUserAdm from "../view/admin/managementUser/AddManagementUserAdm";
import EditManagementUserAdm from "../view/admin/managementUser/EditManagementUserAdm";
import CategoryArtAdm from "../view/admin/categoryArticle/CategoryArtAdm";
import VideoAdm from "../view/admin/homeSection/VideoAdm";
import CustomPageArt from "../view/admin/settingGeneral/CustomPageArt";
import IKKadm from "../view/admin/masterIKK/IKKadm";
import AddIKKAdm from "../view/admin/masterIKK/AddIkkAdm";
import PelaporanIKKAdm from "../view/admin/masterIKK/pelaporanIKK/PelaporanIKKAdm";
import PelaporanIKKMakroAdm from "../view/admin/masterIKK/pelaporanIKK/PelaporanIKKMakroAdm";
import PelaporanIKKOutputAdm from "../view/admin/masterIKK/pelaporanIKK/PelaporanIKKOutputAdm";
import SettingGeneralAdm from "../view/admin/settingGeneral/SettingGeneralAdm";
import ArtikelViewAdm from "../view/admin/article/ArtikelViewAdm";
import LoginAdm from "../auth/LoginAdm";
import UserPengajuanAdm from "../view/admin/userPengajuan/UserPengajuanAdm";
import UrusanAdm from "../view/admin/masterIKK/UrusanAdm";
import AddIkkMakroAdm from "../view/admin/masterIKK/AddIkkMakroAdm";
import UserPengajuanIKKAdm from "../view/admin/userPengajuan/UserPengajuanIKKAdm";
import DetailUserPengajuanIKKAdm from "../view/admin/userPengajuan/DetailUserPengajuanIKKAdm";
import IkkPengajuanDaerahAdm from "../view/admin/userPengajuan/IkkPengajuanDaerahAdm";
import IkkMakroPengajuanAdm from "../view/admin/ikkMakroPengajuan/IkkMakroPengajuanAdm";
import DetailIkkMakroPengajuanAdm from "../view/admin/ikkMakroPengajuan/DetailIkkMakroPengajuanAdm";
import CapaianAdm from "../view/admin/capaian/CapaianAdm";
import PelaporanIKKOutputPengajuanAdm from "../view/admin/pelaporanIKKOutput/PelaporanIKKOutputPengajuanAdm";
import IKKMasterPengajuanAdm from "../view/admin/IkkPengajuan/IkkMasterPengajuanAdm";
import PelaporanIKKUserPengajuanAdm from "../view/admin/pelaporanIKKUserPengajuan/PelaporanIKKUserPengajuanAdm";
import AddIkkOutputadm from "../view/admin/masterIKK/AddIkkOutputadm";
import PelaporanIKKMakroPengajuanAdm from "../view/admin/masterIKK/pelaporanIKK/PelaporanIKKMakroPengajuanAdm";
import AddIkkPengajuanDaerahAdm from "../view/admin/userPengajuan/AddIkkPengajuanDaerahAdm";
import AddCapaianAdm from "../view/admin/capaian/AddCapaianAdm";
import Register from "../auth/Register";
import EditIkkAdm from "../view/admin/masterIKK/EditIkkAdm";
import EditIkkMakroAdm from "../view/admin/masterIKK/EditIkkMakroAdm";
import EditUrusanAdm from "../view/admin/masterIKK/EditUrusanAdm";
import EditIkkPengajuanDaerahAdm from "../view/admin/userPengajuan/EditIkkPengajuanDaerahAdm";
import EditIkkOutputadm from "../view/admin/masterIKK/EditIkkOutputadm";
import EditUserPengajuanIKKAdm from "../view/admin/userPengajuan/EditUserPengajuanIKKAdm";
import DetailUserPengajuanIKKAPIPAdm from "../view/admin/userPengajuan/DetailUserPengajuanIKKAPIPAdm";
import EditUserPengajuanIKKApipAdm from "../view/admin/userPengajuan/EditUserPengajuanIKKApipAdm";
import EditCapaianAdm from "../view/admin/capaian/EditCapaianAdm";
import EditIkkMakroPengajuanAdm from "../view/admin/ikkMakroPengajuan/EditIkkMakroPengajuanAdm";
import AddArtikelViewAdm from "../view/admin/article/AddArtikelViewAdm";
import EditArtikelViewAdm from "../view/admin/article/EditArtikelViewAdm";
import ReportUserPengajuanIKKAdm from "../view/admin/userPengajuan/ReportUserPengajuanIKKAdm";
import PelaporanIKKOutputPengajuanCloneAdm from "../view/admin/masterIKK/pelaporanIKK/PelaporanIKKOutputPengajuanCloneAdm";
import MasterPelaporanIKKOutputPengajuanAdm from "../view/admin/masterIKK/pelaporanIKK/MasterPelaporanIKKOutputPengajuanAdm";
import PelaporanIKKOutputPengajuanEditAdm from "../view/admin/masterIKK/pelaporanIKK/PelaporanIKKOutputPengajuanEditAdm";
import ReportUserPengajuanIKKEditAdm from "../view/admin/userPengajuan/ReportUserPengajuanIKKEditAdm";
import CapaianEditAdm from "../view/admin/masterIKK/pelaporanIKK/CapaianEditAdm";
import PelaporanIKKOutputPengajuandDetailAdm from "../view/admin/pelaporanIKKOutput/PelaporanIKKOutputPengajuandDetailAdm";
import PerangkatDaerahView from "../view/website/profile/PerangkatDaerahView";
import DataWilayahView from "../view/website/profile/DataWilayahView";
import DataGeografisView from "../view/website/profile/DataGeografisView";
import DataPendudukView from "../view/website/profile/DataPendudukView";
import BeritaView from "../view/website/BeritaView";
import LayananView from "../view/website/LayananView";
import InformasiView from "../view/website/InformasiView";
import GalleryView from "../view/website/GalleryView";
import ParentAdm from "../view/admin/parent/ParentAdm";
import CustomPageAddAdm from "../view/admin/settingGeneral/CustomPageAddAdm";
import CustomPageEditAdm from "../view/admin/settingGeneral/CustomPageEditAdm";
import DetailLayananView from "../view/website/DetailLayananView";
import DetailBeritaView from "../view/website/DetailBeritaView";
import ContactView from "../view/admin/contact/ContactView";
import ContactEditView from "../view/admin/contact/ContactEditView";

const WebRoute = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomeView />} />
          <Route path="/home" element={<HomeView />} />
          <Route path="/article" element={<ArticleView />} />
          <Route
            path="/artikel/detail-artikel/:slug/:id"
            element={<DetailArticleView />}
          />
          <Route path="/berita" element={<BeritaView />} />
          <Route
            path="/berita/detail/:slug/:category"
            element={<DetailBeritaView />}
          />
          <Route path="/layanan" element={<LayananView />} />
          <Route
            path="/layanan/:slug/:category"
            element={<DetailLayananView />}
          />
          <Route path="/informasi" element={<InformasiView />} />
          <Route path="/gallery" element={<GalleryView />} />
          {/* profile */}
          <Route
            path="/struktur-organisasi"
            element={<StrukturOrganisasiView />}
          />
          <Route path="/perangkat-daerah" element={<PerangkatDaerahView />} />
          <Route path="/data-wilayah" element={<DataWilayahView />} />
          <Route path="/data-geografis" element={<DataGeografisView />} />
          <Route path="/data-penduduk" element={<DataPendudukView />} />
          {/* end profile */}
          <Route path="/admin" element={<HomeViewAdm />} />
          {/* Reusable component */}
          <Route path="/paragraph" element={<ParagraphView />} />
          <Route path="/input" element={<InputView />} />
          {/* Login */}
          <Route path="/admin/login" element={<LoginAdm />} />
          <Route path="/admin/register" element={<Register />} />
          {/* Home Section */}
          <Route path="/admin/gallery" element={<GalleryAdm />} />
          <Route path="/admin/add-gallery" element={<AddGalleryAdm />} />
          <Route path="/admin/video" element={<VideoAdm />} />
          {/* Management User */}
          <Route
            path="/admin/management-user"
            element={<ManagementUserAdm />}
          />
          <Route
            path="/admin/management-user/add"
            element={<AddManagementUserAdm />}
          />
          <Route
            path="/admin/management-user/edit"
            element={<EditManagementUserAdm />}
          />
          {/* Category Artikel  */}
          <Route path="/admin/article/category" element={<CategoryArtAdm />} />
          <Route path="/admin/article/parent" element={<ParentAdm />} />
          <Route path="/admin/articleAdm" element={<ArtikelViewAdm />} />
          <Route path="/admin/articleAdm/add" element={<AddArtikelViewAdm />} />
          <Route
            path="/admin/articleAdm/edit/:slug/:id/:id2"
            element={<EditArtikelViewAdm />}
          />
          <Route path="/admin/contact" element={<ContactView />} />
          <Route path="/admin/contact/edit" element={<ContactEditView />} />

          {/* Setting General  */}
          <Route
            path="/admin/settingGeneral/customPage"
            element={<CustomPageArt />}
          />
          <Route
            path="/admin/settingGeneral/customPage/add"
            element={<CustomPageAddAdm />}
          />
          <Route
            path="/admin/settingGeneral/customPage/add"
            element={<CustomPageAddAdm />}
          />
          <Route
            path="/admin/settingGeneral/customPage/edit/:id/:slug"
            element={<CustomPageEditAdm />}
          />
          <Route
            path="/admin/settingGeneral/settingGeneral"
            element={<SettingGeneralAdm />}
          />
          {/* Master IKK */}
          <Route path="/admin/ikk/urusan" element={<UrusanAdm />} />
          <Route
            path="/admin/ikk/urusan/edit/:id"
            element={<EditUrusanAdm />}
          />
          <Route path="/admin/ikk" element={<IKKadm />} />
          <Route path="/admin/ikk/add" element={<AddIKKAdm />} />
          <Route path="/admin/ikk/edit/:id" element={<EditIkkAdm />} />
          <Route
            path="/admin/ikk/pelaporan-ikk"
            element={<PelaporanIKKAdm />}
          />
          <Route
            path="/admin/ikk/pelaporan-ikk/user-pengajuan"
            element={<PelaporanIKKUserPengajuanAdm />}
          />
          <Route
            path="/admin/ikk/pelaporan-ikk-makro"
            element={<PelaporanIKKMakroAdm />}
          />
          <Route
            path="/admin/ikk/pelaporan-ikk-makro/pengajuan"
            element={<PelaporanIKKMakroPengajuanAdm />}
          />
          <Route
            path="/admin/ikk/pelaporan-ikk-makro/capaian/edit/:id"
            element={<CapaianEditAdm />}
          />
          <Route
            path="/admin/ikk/pelaporan-ikk-makro/add"
            element={<AddIkkMakroAdm />}
          />
          <Route
            path="/admin/ikk/pelaporan-ikk-makro/edit/:id"
            element={<EditIkkMakroAdm />}
          />
          <Route
            path="/admin/ikk/pelaporan-ikk-output"
            element={<PelaporanIKKOutputAdm />}
          />
          <Route
            path="/admin/ikk/pelaporan-ikk-output/pengajuan"
            element={<MasterPelaporanIKKOutputPengajuanAdm />}
          />
          <Route
            path="/admin/ikk/pelaporan-ikk-output/pengajuan/clone/:id"
            element={<PelaporanIKKOutputPengajuanCloneAdm />}
          />
          <Route
            path="/admin/ikk/pelaporan-ikk-output/pengajuan/edit/:id"
            element={<PelaporanIKKOutputPengajuanEditAdm />}
          />
          <Route
            path="/admin/ikk/pelaporan-ikk-output/add"
            element={<AddIkkOutputadm />}
          />
          <Route
            path="/admin/ikk/pelaporan-ikk-output/edit/:id"
            element={<EditIkkOutputadm />}
          />
          <Route
            path="/admin/ikk/pelaporan-ikk-output-pengajuan"
            element={<IKKMasterPengajuanAdm />}
          />
          {/* user pengajuan */}
          <Route path="/admin/userpengajuan" element={<UserPengajuanAdm />} />
          <Route
            path="/admin/userpengajuan/ikk"
            element={<UserPengajuanIKKAdm />}
          />
          <Route
            path="/admin/userpengajuan/ikk/:id"
            element={<ReportUserPengajuanIKKAdm />}
          />
          <Route
            path="/admin/userpengajuan/ikk/report/edit/:id"
            element={<ReportUserPengajuanIKKEditAdm />}
          />
          <Route
            path="/admin/userpengajuan/ikk/edit/:id"
            element={<EditUserPengajuanIKKAdm />}
          />
          <Route
            path="/admin/userpengajuan/ikk/apip/edit/:id"
            element={<EditUserPengajuanIKKApipAdm />}
          />
          <Route
            path="/admin/userpengajuan/ikk-detail/:id"
            element={<DetailUserPengajuanIKKAdm />}
          />
          <Route
            path="/admin/userpengajuan/ikk-detail/apip/:id"
            element={<DetailUserPengajuanIKKAPIPAdm />}
          />
          <Route
            path="/admin/userpengajuan/ikk-pengajuan-daerah"
            element={<IkkPengajuanDaerahAdm />}
          />
          <Route
            path="/admin/userpengajuan/ikk-pengajuan-daerah/add"
            element={<AddIkkPengajuanDaerahAdm />}
          />
          <Route
            path="/admin/userpengajuan/ikk-pengajuan-daerah/edit/:id"
            element={<EditIkkPengajuanDaerahAdm />}
          />
          <Route
            path="/admin/userpengajuan/ikk-makro-pengajuan-daerah"
            element={<IkkMakroPengajuanAdm />}
          />
          <Route
            path="/admin/userpengajuan/ikk-makro-pengajuan-daerah/detail/:id"
            element={<DetailIkkMakroPengajuanAdm />}
          />
          <Route
            path="/admin/userpengajuan/ikk-makro-pengajuan-daerah/detail/edit/:id"
            element={<EditIkkMakroPengajuanAdm />}
          />
          {/* capaian */}
          <Route path="/admin/userpengajuan/capaian" element={<CapaianAdm />} />
          <Route
            path="/admin/userpengajuan/capaian/add"
            element={<AddCapaianAdm />}
          />
          <Route
            path="/admin/userpengajuan/capaian/edit/:id"
            element={<EditCapaianAdm />}
          />
          {/* pelaporan ikk output  */}
          <Route
            path="/admin/userpengajuan/pelaporanikkoutput"
            element={<PelaporanIKKOutputPengajuanAdm />}
          />
          <Route
            path="/admin/userpengajuan/pelaporanikkoutput/detail/:id"
            element={<PelaporanIKKOutputPengajuandDetailAdm />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default WebRoute;
