// Your actions file
import axios from "axios";
import Swal from "sweetalert2";
import API_BASE_URL from "../config";

// Action types
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const saveRegisterSuccess = (dataRegister) => ({
  type: REGISTER_SUCCESS,
  payload: dataRegister,
});

export const saveRegisterFailure = (error) => ({
  type: REGISTER_FAILURE,
  payload: error,
});

export const saveRegister = (dataRegister) => async (dispatch) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/register`, {
      name: dataRegister.name,
      email: dataRegister.email,
      role_id: dataRegister.role_id,
      password: dataRegister.password,
      password_confirmation: dataRegister.passwordConfirmation,
    });

    dispatch(saveRegisterSuccess(response.data));

    Swal.fire({
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      icon: "success",
      title: "Success!",
      text: "Registration successfully.",
    });
  } catch (err) {
    dispatch(saveRegisterFailure(err.message));

    Swal.fire({
      title: "Error!",
      text: `${err.message}`,
      icon: "warning",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
    });
  }
};

export const clearErrors = () => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
