import axios from "axios";
import API_BASE_URL from "../config";
import Swal from "sweetalert2";
import {
  FETCH_IKK_USER_PENGAJUAN_REPORT_FAILURE,
  FETCH_IKK_USER_PENGAJUAN_REPORT_REQUEST,
  FETCH_IKK_USER_PENGAJUAN_REPORT_SUCCESS,
  FETCH_OLD_IKK_USER_PENGAJUAN_REPORT_FAILURE,
  FETCH_OLD_IKK_USER_PENGAJUAN_REPORT_SUCCESS,
  UPDATE_IKK_USER_PENGAJUAN_REPORT_FAILURE,
  UPDATE_IKK_USER_PENGAJUAN_REPORT_SUCCESS,
} from "./ActionType";

export const updateIKKUserPengajuanReportSuccess = (data) => ({
  type: UPDATE_IKK_USER_PENGAJUAN_REPORT_SUCCESS,
  payload: data,
});

export const updateIKKUserPengajuanReportFailure = (error) => ({
  type: UPDATE_IKK_USER_PENGAJUAN_REPORT_FAILURE,
  payload: error,
});

const fetchIKKUserPengajuanReportRequest = () => {
  return {
    type: FETCH_IKK_USER_PENGAJUAN_REPORT_REQUEST,
  };
};

const fetchIKKUserPengajuanReportSuccess = (data) => {
  return {
    type: FETCH_IKK_USER_PENGAJUAN_REPORT_SUCCESS,
    payload: data,
  };
};

const fetchIKKUserPengajuanReportFailure = (err) => {
  return {
    type: FETCH_IKK_USER_PENGAJUAN_REPORT_FAILURE,
    payload: err,
  };
};

export const fetchIKKUserPengajuanReportList = (id, year) => {
  return (dispatch) => {
    dispatch(fetchIKKUserPengajuanReportRequest());

    // Retrieve the token from session storage
    const token = sessionStorage.getItem("token");
    const role = sessionStorage.getItem("role");

    const apiEndpoint =
      role === "3"
        ? `/api/dashboard/ikk-outputs/show/user_konfirmasi/${id}?year=${year}`
        : role === "5"
        ? `/api/dashboard/ikk-outputs/show/apip/${id}?year=${year}`
        : "";

    if (!apiEndpoint) {
      // Handle invalid role
      dispatch(fetchIKKUserPengajuanReportFailure("Invalid role"));

      Swal.fire({
        icon: "warning",
        title: "Error!",
        text: "Invalid role access",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
      return;
    }

    // Set the token in the request headers
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${API_BASE_URL}${apiEndpoint}`, axiosConfig)
      .then((res) => {
        let _list = res.data;
        dispatch(fetchIKKUserPengajuanReportSuccess(_list));
      })
      .catch((err) => {
        dispatch(fetchIKKUserPengajuanReportFailure(err.message));
      });
  };
};

export const fetchOldIKKUserPengajuanReport = (id) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");
    const role = sessionStorage.getItem("role");

    const apiEndpoint =
      role === "5"
        ? `/api/dashboard/ikk-outputs/detail/apip/2/2`
        : role === "3"
        ? `/api/dashboard/ikk-outputs/detail/admin_konfirmasi/2/2`
        : role === "2"
        ? `/api/dashboard/ikk-outputs/show/${id}`
        : "";

    const response = await axios.get(`${API_BASE_URL}${apiEndpoint}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: FETCH_OLD_IKK_USER_PENGAJUAN_REPORT_SUCCESS,
      payload: response.data, // Assuming the API returns the old data
    });
  } catch (error) {
    dispatch({
      type: FETCH_OLD_IKK_USER_PENGAJUAN_REPORT_FAILURE,
      payload: error.message,
    });
    if (error.message === "Request failed with status code 401") {
      Swal.fire({
        title: "Error!",
        text: `${error.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: `${error.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  }
};
// {{uri}}/api/dashboard/ikk-outputs/detail/apip/2/2

// export const updateIKKUserPengajuanReport =
//   (id, IKKData) => async (dispatch) => {
//     try {
//       const token = sessionStorage.getItem("token");
//       const role = sessionStorage.getItem("role");

//       const apiEndpoint =
//         role === "3"
//           ? `/api/dashboard/ikk_outputs/update/user_konfirmasi/${id}`
//           : role === "5"
//           ? `/api/dashboard/ikk_outputs/update/apip/${id}`
//           : "";

//       if (!apiEndpoint) {
//         // Handle invalid role
//         dispatch(fetchIKKUserPengajuanReportFailure("Invalid role"));

//         Swal.fire({
//           icon: "warning",
//           title: "Error!",
//           text: "Invalid role access",
//           cancelButtonColor: "#d33",
//           confirmButtonText: "OK",
//         }).then(() => {
//           window.location.href = "/admin/login";
//         });
//         return;
//       }

//       console.log("first", IKKData.urusanSelect);
//       const response = await axios.put(
//         `${API_BASE_URL}${apiEndpoint}`,
//         {
//           status_rekomendasi: IKKData.status_rekomendasi,
//           keterangan_rekomendasi: IKKData.keterangan_rekomendasi,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       console.log("Update IKK response:", response.data);
//       // dispatch({ type: "ADD_URUSAN_SUCCESS", payload: response.data });
//       dispatch(updateIKKUserPengajuanReportSuccess(response.data));

//       Swal.fire({
//         confirmButtonColor: "#3085d6",
//         cancelButtonColor: "#d33",
//         icon: "success",
//         title: "Success!",
//         text: "Data has been saved successfully.",
//       }).then(() => {
//         window.location.href = "/admin/userpengajuan/ikk";
//       });
//     } catch (err) {
//       // dispatch({ type: "ADD_URUSAN_FAILURE", payload: error.message });
//       dispatch(updateIKKUserPengajuanReportFailure(err.message));
//       if (err.message === "Request failed with status code 401") {
//         Swal.fire({
//           title: "Error!",
//           text: `${err.message}`,
//           icon: "warning",
//           cancelButtonColor: "#d33",
//           confirmButtonText: "OK",
//         }).then(() => {
//           window.location.href = "/admin/login";
//         });
//       } else {
//         Swal.fire({
//           title: "Error!",
//           text: `${err.message}`,
//           icon: "warning",
//           cancelButtonColor: "#d33",
//           confirmButtonText: "OK",
//         });
//       }
//     }
//   };

export const updateIKKUserPengajuanReport =
  (id, IKKData) => async (dispatch) => {
    try {
      const token = sessionStorage.getItem("token");
      const role = sessionStorage.getItem("role");

      let apiEndpoint = "";
      let requestData = {};

      if (role === "3") {
        apiEndpoint = `/api/dashboard/ikk_outputs/update/user_konfirmasi/${id}`;
        requestData = {
          status_rekomendasi: IKKData.status_rekomendasi,
          keterangan_rekomendasi: IKKData.keterangan_rekomendasi,
        };
      } else if (role === "5") {
        apiEndpoint = `/api/dashboard/ikk_outputs/update/apip/${id}`;
        requestData = {
          status_apip: IKKData.status_apip,
          desc_apip: IKKData.desc_apip,
        };
      } else {
        // Handle invalid role
        dispatch(fetchIKKUserPengajuanReportFailure("Invalid role"));

        Swal.fire({
          icon: "warning",
          title: "Error!",
          text: "Invalid role access",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        }).then(() => {
          window.location.href = "/admin/login";
        });
        return;
      }

      const response = await axios.put(
        `${API_BASE_URL}${apiEndpoint}`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch(updateIKKUserPengajuanReportSuccess(response.data));

      Swal.fire({
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        icon: "success",
        title: "Success!",
        text: "Data has been saved successfully.",
      }).then(() => {
        window.location.href = "/admin/userpengajuan/ikk";
      });
    } catch (err) {
      dispatch(updateIKKUserPengajuanReportFailure(err.message));
      if (err.message === "Request failed with status code 401") {
        Swal.fire({
          title: "Error!",
          text: `${err.message}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        }).then(() => {
          window.location.href = "/admin/login";
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: `${err.message}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    }
  };
