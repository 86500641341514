import React, { useEffect, useState } from "react";
import edit from "../../../assets/edit.png";
import deleteIcon from "../../../assets/delete.png";
import Button from "@mui/material/Button";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Stack from "@mui/material/Stack";
import { Link, useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { connect } from "react-redux";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PrintIcon from "@mui/icons-material/Print";
import excel from "../../../assets/excel.png";
import { TextField } from "@mui/material";
import * as XLSX from "xlsx";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import jsPDF from "jspdf";
import { fetchIKKOutputPengajuanList } from "../../../redux/IkkOutputPengajuan/IkkOutputPengajuanAction";

const IkkMasterPengajuan = (props) => {
  const Swal = require("sweetalert2");
  const [currentYear, setCurrentYear] = useState();
  const [inputSearch, setInputSearch] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const itemsPerPage = 5; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const [checkedItems, setCheckedItems] = useState({});
  const [dataChecked, setDataChecked] = useState([]);

  useEffect(() => {
    props.fetchIKKOutputPengajuanList(currentYear);
  }, [currentYear]);

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  const data = props.IkkOutputPengajuanList.data || [];
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handlePrevClick = () => {
    paginate(currentPage - 1);
  };

  const handleNextClick = () => {
    paginate(currentPage + 1);
  };

  const onDelete = async (id) => {
    const confirmationResult = await Swal.fire({
      title: `Apakah anda ingin menghapus data ${id}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    });

    if (confirmationResult.isConfirmed) {
      try {
        await props.deleteIkk(id);
      } catch (error) {}
    }
  };

  const handleCheck = (id) => {
    setCheckedItems((prevCheckedItems) => {
      const updatedCheckedItems = {
        ...prevCheckedItems,
        [id]: !prevCheckedItems[id],
      };

      // Get the checked item data
      const checkedItemData = data.find((item) => item.id === id);

      // Update dataChecked with the required data
      setDataChecked((prevDataChecked) => {
        if (updatedCheckedItems[id]) {
          // If item is checked, add it to dataChecked
          return [
            ...prevDataChecked,
            {
              no_ikk: checkedItemData.no_ikk,
              ikk_output: checkedItemData.ikk_output,
              ikk_outcome: checkedItemData.ikk_outcome,
              rumus: checkedItemData.rumus,
            },
          ];
        } else {
          // If item is unchecked, remove it from dataChecked
          return prevDataChecked.filter((data) => data.id !== id);
        }
      });

      return updatedCheckedItems;
    });
  };

  const handleCheckAll = (isCheckedAll) => {
    const newCheckedItems = {};
    data.forEach((item) => {
      newCheckedItems[item.id] = isCheckedAll;
    });
    setCheckedItems(newCheckedItems);

    setDataChecked(
      isCheckedAll
        ? data.map((item) => ({
            no_ikk: item.no_ikk,
            ikk_output: item.ikk_output,
            ikk_outcome: item.ikk_outcome,
            rumus: item.rumus,
          }))
        : []
    );
  };

  const isChecked = (id) => {
    return checkedItems[id] || false;
  };

  // export excel
  // console.log("data checked", dataChecked);
  const exportToExcel = () => {
    const dataToExport = [
      ["No IKK", "IKK Output", "IKK Outcome", "Rumus"],
      ...dataChecked.map((item, index) => [
        index + 1,
        `# ${item.no_ikk}`,
        item.ikk_output,
        item.ikk_outcome,
        item.rumus,
      ]),
    ];

    const ws = XLSX.utils.aoa_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "IKK");

    // Save the Excel file
    XLSX.writeFile(wb, "exportedIKK.xlsx");
  };

  const downloadPDF = () => {
    const pdf = new jsPDF();
    pdf.setFontSize(12); // Adjust the font size as needed

    pdf.text("IKK Data", 20, 20);

    dataChecked.forEach((item, index) => {
      const yPosition = 30 + index * 15; // Adjust the multiplier as needed
      const text = `${index + 1}. No IKK: ${item.no_ikk}\nIKK Output: ${
        item.ikk_output
      }\nIKK Outcome: ${item.ikk_outcome}\nRumus: ${item.rumus}`;
      pdf.text(text, 20, yPosition);
    });

    pdf.save("IKK_Data.pdf");
  };

  const handleSearchChange = (event) => {
    const input = event.target.value.toLowerCase();
    setInputSearch(input);
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await props.fetchIKKOutputPengajuanList(inputSearch);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="mb-4 grid grid-cols-12 gap-2  ">
        <div className="col-span-4 md:col-span-1 ">
          <Stack
            component="form"
            sx={{
              width: "10ch",
            }}
            spacing={1}
            noValidate
            autoComplete="off"
          >
            <TextField
              hiddenLabel
              id="filled-hidden-label-small"
              defaultValue={currentYear}
              onChange={handleSearchChange}
              InputLabelProps={{ shrink: false }}
              variant="filled"
              size="small"
              placeholder="Enter Year"
            />
          </Stack>
        </div>
        <div className="col-span-8 md:col-span-1 ">
          <Stack
            direction="row"
            spacing={2}
            sx={{
              height: "4.1ch",
            }}
          >
            {loading ? (
              <Button
                variant="outlined"
                startIcon={<SearchIcon />}
                onClick={handleSearch}
              >
                Loading...
              </Button>
            ) : (
              <Button
                variant="outlined"
                startIcon={<SearchIcon />}
                onClick={handleSearch}
              >
                Cari
              </Button>
            )}
          </Stack>
        </div>
        <div className="col-span-12 md:col-span-10 flex justify-end">
          <Stack direction="row" spacing={1}>
            <Link to="/admin/ikk/add">
              <Button variant="contained">Tambah</Button>
            </Link>
            <Button
              variant="contained"
              endIcon={<PrintIcon />}
              disabled={!dataChecked.length}
            >
              Print
            </Button>
            <Button
              variant="contained"
              onClick={downloadPDF}
              endIcon={<PictureAsPdfIcon />}
              disabled={!dataChecked.length}
            >
              PDF
            </Button>
            <Button
              variant="contained"
              onClick={exportToExcel}
              disabled={!dataChecked.length}
            >
              Excel{" "}
              <img src={excel} alt="Excel Icon" className="ml-2 w-[20px]" />
            </Button>
          </Stack>
        </div>
      </div>
      <table className="w-[93.5vw] ">
        <thead>
          <tr className="bg-gray">
            <th className="p-4">
              <input
                type="checkbox"
                onChange={(e) => handleCheckAll(e.target.checked)}
              />
            </th>
            <th className="p-4">No IKK</th>
            <th className="p-4">Tahun</th>
            <th className="p-4">IKK Output</th>
            <th className="p-4">Keterangan Rekomendasi</th>
            <th className="p-4">Nilai Pemda</th>
            <th className="p-4">Desc Pemda</th>
            <th className="p-4">Status APIP</th>
            <th className="p-4">Desc APIP</th>
            <th className="p-4">Bukti File</th>
            <th className="p-4">Action</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => (
            <tr
              key={index}
              className={index % 2 === 0 ? "bg-light" : "bg-[#cae6ed]"}
            >
              <td className="p-4 text-center">
                <input
                  type="checkbox"
                  checked={isChecked(item.id)}
                  onChange={() => handleCheck(item.id)}
                />
              </td>
              <td className="p-4 text-center">{item.no_ikk_outcome}</td>
              <td className="p-4 text-center">{item.tahun}</td>
              <td className="p-4 text-center">{item.ikk_output_in_outcome}</td>
              <td className="p-4 text-center">{item.keterangan_rekomendasi}</td>
              <td className="p-4 text-center">{item.nilai_pemda}</td>
              <td className="p-4 text-center">{item.desc_pemda}</td>
              <td className="p-4 text-center">{item.status_apip}</td>
              <td className="p-4 text-center">{item.desc_apip}</td>
              <td className="p-4 text-center">
                <a href={item.bukti_url} target="_blank" rel="noreferrer">
                  <Button variant="contained" endIcon={<FileDownloadIcon />}>
                    File
                  </Button>
                </a>
              </td>
              <td className="p-4 text-center">
                <div className="flex items-center justify-center">
                  <img src={edit} alt="" className="w-[30px]" />

                  <img
                    src={deleteIcon}
                    alt=""
                    className="w-[30px]"
                    onClick={() => onDelete(item.id)}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="flex justify-center mt-4 pb-4">
        <button
          onClick={handlePrevClick}
          className={`mx-2 px-4 py-2 ${
            currentPage === 1 ? "bg-gray text-white rounded-full" : ""
          }`}
        >
          {"<"}
        </button>

        {[...Array(totalPages).keys()].map((number) => (
          <button
            key={number}
            onClick={() => paginate(number + 1)}
            className={`mx-2 px-4 py-2 ${
              currentPage === number + 1
                ? "bg-gray text-white rounded-full"
                : ""
            }`}
          >
            {number + 1}
          </button>
        ))}

        <button
          onClick={handleNextClick}
          className={`mx-2 px-4 py-2 ${
            currentPage === totalPages ? "bg-gray text-white rounded-full" : ""
          }`}
        >
          {">"}
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    IkkOutputPengajuanList: state.IkkOutputPengajuanList.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchIKKOutputPengajuanList: (currentYear) =>
      dispatch(fetchIKKOutputPengajuanList(currentYear)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IkkMasterPengajuan);
