import axios from "axios";
import API_BASE_URL from "../config";
import Swal from "sweetalert2";
import {
  FETCH_CONTACT_FAILURE,
  FETCH_CONTACT_REQUEST,
  FETCH_CONTACT_SUCCESS,
  FETCH_OLD_CONTACT_FAILURE,
  FETCH_OLD_CONTACT_SUCCESS,
  UPDATE_CONTACT_FAILURE,
  UPDATE_CONTACT_SUCCESS,
} from "./ActionType";

export const updateContactSuccess = (data) => ({
  type: UPDATE_CONTACT_SUCCESS,
  payload: data,
});

export const updateContactFailure = (error) => ({
  type: UPDATE_CONTACT_FAILURE,
  payload: error,
});

const fetchContactRequest = () => {
  return {
    type: FETCH_CONTACT_REQUEST,
  };
};

const fetchContactSuccess = (data) => {
  return {
    type: FETCH_CONTACT_SUCCESS,
    payload: data,
  };
};

const fetchContactFailure = (err) => {
  return {
    type: FETCH_CONTACT_FAILURE,
    payload: err,
  };
};

export const fetchContactList = () => {
  return (dispatch) => {
    dispatch(fetchContactRequest());

    // Retrieve the token from session storage
    const token = sessionStorage.getItem("token"); // Replace "your_token_key" with the actual key used to store the token

    // Set the token in the request headers
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${API_BASE_URL}/api/setting-generals/contact`, axiosConfig)
      .then((res) => {
        let _list = res.data;
        dispatch(fetchContactSuccess(_list));
      })
      .catch((err) => {
        dispatch(fetchContactFailure(err.message));
      });
  };
};

export const updateContact = (data) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");
    console.log("data3", data.namaWeb);
    const response = await axios.put(
      `${API_BASE_URL}/api/setting-generals/contact-update`,
      {
        namaWeb: data.namaWeb,
        sub_title: data.sub_title,
        alamat: data.alamat,
        maps: data.maps,
        nomorTelp: data.nomorTelp,
        facebook: data.facebook,
        instagram: data.instagram,
        tiktok: data.tiktok,
        whatsapp: data.whatsapp,
        telegram: data.telegram,
        youtube: data.youtube,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(updateContactSuccess(response.data));

    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "Data has been saved successfully.",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    });
    // .then(() => {
    //   window.location.href = "/admin/articleAdm";
    // });
  } catch (error) {
    dispatch(updateContactFailure(error.message));
    if (error.message === "Request failed with status code 401") {
      Swal.fire({
        title: "Error!",
        text: `${error.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: `${error.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  }
};
