import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import img from "../../src/assets/login.png";
import { Text1, Text7 } from "../mainComponent/Paragraph";
import { Button } from "@mui/material";
import { login } from "../redux/login/AuthAction";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const LoginAdm = () => {
  // show password
  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // integration
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const handleLogin = () => {
    dispatch(login(username, password));
  };
  if (isAuthenticated) {
    // Redirect to /admin if authenticated
    return navigate("/admin");
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default form submission
      handleLogin();
    }
  };
  return (
    <div className="h-[100vh] grid grid-cols-12  items-center overflow-x-hidden">
      <div className="col-span-12 md:col-span-8 flex justify-center text-center  ">
        <form onSubmit={handleLogin}>
          <div>
            <div className="mb-8 mt-10 md:mt-0">
              <Text1 text="Login" />
            </div>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <div>
                <FormControl
                  sx={{
                    mb: { xs: 3, md: 1 },
                    width: { xs: "30ch", md: "50ch" },
                  }}
                  variant="filled"
                >
                  <TextField
                    id="filled-textarea"
                    label=" Username  "
                    placeholder="Username"
                    multiline
                    variant="filled"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </FormControl>
              </div>
            </Box>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <div>
                <FormControl
                  sx={{
                    mb: { xs: 3, md: 1 },
                    width: { xs: "30ch", md: "50ch" },
                  }}
                  variant="filled"
                >
                  <InputLabel htmlFor="filled-adornment-password">
                    Password
                  </InputLabel>
                  <FilledInput
                    id="filled-adornment-password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyPress}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    // Add autocomplete attribute
                    autoComplete="new-password"
                  />
                </FormControl>
              </div>
            </Box>

            <Box sx={{ "& button": { width: "97%", my: 3 } }}>
              <Button variant="outlined" size="large" onClick={handleLogin}>
                Login
              </Button>
            </Box>
            <div className="flex justify-center items-center mb-4">
              <div className="w-[40%] bg-gradient-to-l from-[#0B6EFE] to-white h-[6px]"></div>
              <div className="md:mx-2 cursor-pointer">
                <Link to="/admin/register">
                  <Text7 text="  Sign Up" />
                </Link>
              </div>
              <div className=" w-[40%] bg-gradient-to-r from-[#0B6EFE] to-white h-[6px]"></div>
            </div>
          </div>
        </form>
      </div>
      <div className="col-span-12 md:col-span-4 bg-gray  flex items-center justify-center h-[100vh]">
        <img src={img} alt="" className="w-[450px]" />
      </div>
    </div>
  );
};

export default LoginAdm;
