import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import defaultImage from "../../assets/defaultimage.png";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchArticleList,
  saveArticle,
} from "../../redux/article/ArticleAction";
import { fetchUrusanList } from "../../redux/urusan/UrusanAction";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import { Text6 } from "../../mainComponent/Paragraph";
import { MenuItem } from "@mui/material";
import TextEditor from "../../components/main/TextEditor";
import ParentDropdown from "../parent/ParentDropdown";

const parent = [
  {
    value: "1",
    label: "Parent 1 ",
  },
  {
    value: "2",
    label: "Parent 2 ",
  },
  {
    value: "3",
    label: "Parent 3 ",
  },
  {
    value: "4",
    label: "Parent 4 ",
  },
];

const category = [
  {
    value: "1",
    label: "Artikel ",
  },
  {
    value: "2",
    label: "Agenda",
  },
  {
    value: "3",
    label: "Berita ",
  },
  {
    value: "4",
    label: "Pariwisata",
  },
  {
    value: "5",
    label: "Kuliner",
  },
];

const AddArticle = ({ saveArticle, articleList, props }) => {
  const navigate = useNavigate();
  const [title_page, setTitlePage] = useState("");
  const [sub_title, setSubTitle] = useState("");
  const [content, setContent] = useState("");
  const [category_id, setCategoryId] = useState("");
  const [tags, setTags] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([null]);
  const [parent_id, setParent] = useState([null]);
  const [inputFileElements, setInputFileElements] = useState(1);

  console.log("html", content);

  useEffect(() => {
    // Fetch urusan data when the component mounts
    console.log("Fetching urusan list...");
    fetchUrusanList();
  }, [fetchUrusanList]);

  const handleFileChange = (event, index) => {
    const files = event.target.files;
    const newSelectedFiles = [...selectedFiles];

    for (let i = 0; i < files.length; i++) {
      newSelectedFiles[index + i] = URL.createObjectURL(files[i]);
    }

    setSelectedFiles(newSelectedFiles);
  };

  const handleEditorChange = (value) => {
    setContent(value);
  };

  const handleAddFileInput = () => {
    setInputFileElements((prev) => prev + 1);
    setSelectedFiles((prev) => [...prev, null]);
  };

  const onCancel = async (id) => {
    const confirmationResult = await Swal.fire({
      title: "Apakah anda ingin membatalkan aktivitas?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    });
    if (confirmationResult.isConfirmed) {
      try {
        navigate("/admin/management-user");
      } catch (error) {
        Swal.fire({
          title: "Error!",
          text: ` Anda tidak dapat membatalkan aktivitas ${error}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    }
  };
  const handleTagInputChange = (e) => {
    // Check if Enter key is pressed
    if (e.key === "Enter") {
      e.preventDefault(); // Prevents the default behavior (e.g., form submission)

      const newTag = e.target.value.trim(); // Remove leading and trailing spaces
      if (newTag) {
        setTags((prevTags) => [...prevTags, newTag]);
        e.target.value = ""; // Clear the input field after adding the tag
      }
    }
  };
  const handleRemoveTag = (index) => {
    // Create a new array excluding the tag at the specified index
    const updatedTags = tags.filter((_, i) => i !== index);
    setTags(updatedTags);
  };
  const handleParentChange = (e) => {
    setParent(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategoryId(e.target.value);
  };
  const handleSave = async () => {
    // Convert blob URLs to files
    const files = await Promise.all(
      selectedFiles.map(async (blobUrl, index) => {
        const response = await fetch(blobUrl);
        const blob = await response.blob();
        return new File([blob], `image_${index}.jpg`, { type: "image/jpeg" });
      })
    );

    // Now 'files' is an array of File objects that can be sent in the request

    const articleList = {
      title_page,
      sub_title,
      content,
      category_id,
      tags,
      images: files, // Assuming the server expects 'images' to be an array of files
      parent_id,
    };

    saveArticle(articleList);
  };

  return (
    <div className="pl-4 overflow-hidden max-w-screen  ">
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 2, width: "30ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="filled-basic"
          label="Title Page"
          variant="filled"
          value={title_page}
          onChange={(e) => setTitlePage(e.target.value)}
        />

        <TextField
          id="filled-basic"
          label="Sub Title"
          variant="filled"
          value={sub_title}
          onChange={(e) => setSubTitle(e.target.value)}
        />

        <TextField
          id="outlined-select-currency"
          select
          label="Select"
          defaultValue="1"
          helperText="Please select your category"
          onChange={handleCategoryChange}
        >
          {category.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <ParentDropdown setParentSelect={setParent} />

        <div className="grid grid-cols-3 gap-4 ">
          {Array.from({ length: inputFileElements }).map((_, index) => (
            <div key={index}>
              <input
                type="file"
                accept="image/*"
                onChange={(event) => handleFileChange(event, index)}
                className="hidden"
                id={`fileInput${index}`}
              />
              <label htmlFor={`fileInput${index}`} className="cursor-pointer">
                {selectedFiles[index] ? (
                  <img
                    src={selectedFiles[index]}
                    alt={`Selected File Preview ${index}`}
                    className="h-auto w-[250px] hover:border-blue-500 "
                  />
                ) : (
                  <div className=" hover:border-blue-500 flex items-center justify-center   ">
                    <img
                      src={defaultImage}
                      alt=""
                      className=" hover:border-blue-500 w-[100px]"
                    />
                  </div>
                )}
              </label>
            </div>
          ))}
          <div onClick={handleAddFileInput} className="cursor-pointer">
            <AddToPhotosIcon />
          </div>
        </div>
      </Box>

      <TextEditor text={content} handleChange={handleEditorChange} />

      <div className="border border-gray mt-20 p-4 w-[50%] mb-12">
        <Text6 text="Tags" />
        <div className="grid grid-cols-4 gap-2 mb-4">
          {tags.map((item, index) => (
            <div
              key={index}
              className="text-center py-1 px-1 text-white bg-blue flex justify-between"
            >
              <p>{item}</p>
              <p onClick={() => handleRemoveTag(index)}>x</p>
            </div>
          ))}
        </div>
        <TextField
          id="filled-basic"
          label="Add your tags here"
          variant="filled"
          onKeyDown={handleTagInputChange} // Handle Enter key press for adding tags
        />
        <div className="pt-4">
          {tags.map((item, index) => (
            <div key={index}>
              <p>{item}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="w-[200px]  flex justify-between pl-4">
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={
            !title_page ||
            !sub_title ||
            !content ||
            !category_id ||
            !tags ||
            !selectedFiles
          }
        >
          Simpan
        </Button>

        <Button variant="contained" color="error" onClick={() => onCancel()}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  articleList: state.articleList,
});

export default connect(mapStateToProps, { saveArticle, fetchArticleList })(
  AddArticle
);
