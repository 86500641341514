import { REGISTER_FAILURE, REGISTER_SUCCESS } from "./ActionType";

// urusanReducer.js
const initialState = {
  registerData: null,
  error: null,
  isRegisterSuccessfully: false,
};

const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REGISTER_SUCCESS":
      return {
        ...state,
        registerData: action.payload,
        error: null,
        isRegisterSuccessfully: true,
      };
    case "REGISTER_FAILURE":
      return { ...state, error: action.payload };
    case "CLEAR_ERRORS":
      return { ...state, error: null };
    default:
      return state;
  }
};

export default registerReducer;
