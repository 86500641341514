// Modal.js
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import defaultImage from "../../assets/defaultimage.png";

const ModalVideo = ({ isOpen, handleClose }) => {
  const [videoFile, setVideoFile] = useState(null);
  const [videoError, setVideoError] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // You can perform additional checks on the file type, size, etc. if needed

    setVideoFile(file);
  };

  const handleSave = () => {
    // Validation logic
    let isValid = true;

    if (!videoFile) {
      setVideoError("Video file is required");
      isValid = false;
    } else {
      setVideoError("");
    }

    if (isValid) {
      // Perform save operation with the selected video file
      // ...
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Tambah Video</DialogTitle>
      <DialogContent>
        <Input
          type="file"
          accept="video/mp4"
          onChange={handleFileChange}
          error={!!videoError}
        />
        {videoError && <div style={{ color: "red" }}>{videoError}</div>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
        <Button onClick={handleClose} color="error">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalVideo;
