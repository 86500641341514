import React from "react";
import MainLayoutAdmin from "../../../layout/MainLayoutAdmin";
import { Text8 } from "../../../mainComponent/Paragraph";
import ReportIKK from "../../../componentsAdmin/userPengajuan/ikk/ReportIKK";

const ReportUserPengajuanIKKAdm = () => {
  return (
    <MainLayoutAdmin>
      <div>
        <div className="pb-4  border-b border-darkgray mb-8">
          <Text8 text=" Report IKK User Pengajuan " />
        </div>
        <ReportIKK />
      </div>
    </MainLayoutAdmin>
  );
};

export default ReportUserPengajuanIKKAdm;
