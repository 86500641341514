import {
  FETCH_HOME_FAILURE,
  FETCH_HOME_REQUEST,
  FETCH_HOME_SUCCESS,
} from "./ActionType";
import axios from "axios";

const fetchHomeRequest = () => {
  return {
    type: FETCH_HOME_REQUEST,
  };
};

const fetchHomeSuccess = (data) => {
  return {
    type: FETCH_HOME_SUCCESS,
    payload: data,
  };
};

const fetchHomeFailure = (err) => {
  return {
    type: FETCH_HOME_FAILURE,
    payload: err,
  };
};

export const fetchHomeList = () => {
  return (dispatch) => {
    dispatch(fetchHomeRequest);
    axios
      .get("https://api-be-xyz.tapemansel.co.id/api/home")
      .then((res) => {
        let _list = res.data;
        dispatch(fetchHomeSuccess(_list));
      })
      .catch((err) => {
        dispatch(fetchHomeFailure(err.message));
      });
  };
};
