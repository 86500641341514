import React from "react";
import MainLayoutAdmin from "../../../layout/MainLayoutAdmin";
import Gallery from "../../../componentsAdmin/home/Gallery";
import { Text8 } from "../../../mainComponent/Paragraph";
import Video from "../../../componentsAdmin/home/Video";

const VideoAdm = () => {
  return (
    <MainLayoutAdmin>
      <div>
        <div className="pb-4  border-b border-darkgray mb-8">
          <Text8 text="Video " />
        </div>
        <Video />
      </div>
    </MainLayoutAdmin>
  );
};

export default VideoAdm;
