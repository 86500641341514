export const FETCH_IKK_REQUEST = "FETCH_IKK_REQUEST";
export const FETCH_IKK_SUCCESS = "FETCH_IKK_SUCCESS";
export const FETCH_IKK_FAILURE = "FETCH_IKK_FAILURE";

export const REDIRECT_TO_LOGIN = "REDIRECT_TO_LOGIN";
export const DELETE_IKK_SUCCESS = "DELETE_IKK_SUCCESS";
export const DELETE_IKK_FAILURE = "DELETE_IKK_FAILURE";

export const FETCH_OLD_IKK_SUCCESS = "FETCH_OLD_IKK_SUCCESS";
export const FETCH_OLD_IKK_FAILURE = "FETCH_OLD_IKK_FAILURE";
export const UPDATE_IKK_SUCCESS = "UPDATE_IKK_SUCCESS";
export const UPDATE_IKK_FAILURE = "UPDATE_IKK_FAILURE";
