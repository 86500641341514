import React, { useEffect } from "react";
import opet from "../../assets/s1mu.webp";
import { Text2, Text4, Text6 } from "../../mainComponent/Paragraph";
import { connect, useSelector } from "react-redux";
import { fetchHomeList } from "../../redux/lppdHome/Action";

const Berita = (props) => {
  // const category = useSelector((state) => state.home.category);

  useEffect(() => {
    props.fetchHome(); // Use the correct prop name "fetchHome" here
    // console.log("homeeee", props.homeList);
  }, []);

  return props.homeList.loading ? (
    <h2>loading...</h2>
  ) : props.homeList.error ? (
    <h2>{props.homeList.error}</h2>
  ) : (
    <div>
      <div>
        <div className="w-[75%] mt-20 ">
          <div className="border-b border-blue">
            <div className="bg-blue w-[80px] px-2 py-1 rounded-t-lg">
              <Text4 text="BERITA" />
            </div>
          </div>
        </div>

        {props.homeList &&
          props.homeList.data.homepages &&
          props.homeList.data.homepages.map((item) => (
            <div key={item.id} className="">
              {item.berita ? (
                item.berita.length > 0 ? (
                  <div className="grid grid-cols-12 gap-2 ">
                    {item.berita.slice(0, 4).map((item, index) => (
                      <div
                        className="col-span-12 md:col-span-3  py-4   "
                        key={index}
                      >
                        <div>
                          <img
                            src={item.imageThumbnailUrl}
                            alt="gambar"
                            className="w-[90%] relative  rounded-md"
                            onError={(e) => {
                              e.target.src = opet; // Set default image on error
                            }}
                          />
                          <div className="w-[90%] relative ">
                            <Text2 text={item.title_page} />
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.content,
                              }}
                            />
                            {/* <Text6 text={item.content} /> */}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="col-span-12">
                    <Text2 text="No available data" />
                  </div>
                )
              ) : (
                <p>No news data</p>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    homeList: state.homeList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchHome: () => dispatch(fetchHomeList()), // Use the correct prop name "fetchHome" here
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Berita);
