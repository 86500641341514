import React from "react";
import MainLayoutAdmin from "../../../layout/MainLayoutAdmin";
import { Text8 } from "../../../mainComponent/Paragraph";
import EditIkkPengajuanDaerah from "../../../componentsAdmin/userPengajuan/ikkPengajuanDaerah/EditIkkPengajuanDaerah";

const EditIkkPengajuanDaerahAdm = () => {
  return (
    <MainLayoutAdmin>
      <div>
        <div className="pb-4  border-b border-darkgray mb-8">
          <Text8 text=" Edit IKK Pengajuan Daerah" />
        </div>
        <EditIkkPengajuanDaerah />
      </div>
    </MainLayoutAdmin>
  );
};

export default EditIkkPengajuanDaerahAdm;
