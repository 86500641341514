import React, { useEffect, useState } from "react";
import edit from "../../../assets/edit.png";
import deleteIcon from "../../../assets/delete.png";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Link, useParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { connect } from "react-redux";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PrintIcon from "@mui/icons-material/Print";
import excel from "../../../assets/excel.png";
import { TextField } from "@mui/material";
import detail from "../../../assets/search.png";
import { fetchDetailIKKMakroUserPengajuanList } from "../../../redux/ikkMakroUserPengajuan/IkkMakroPengajuanAction";

// modal
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Text6 } from "../../../mainComponent/Paragraph";

const DetailIKKMakro = (props) => {
  const { id } = useParams();
  const Swal = require("sweetalert2");
  const itemsPerPage = 5; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const [checkedItems, setCheckedItems] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentYear, setCurrentYear] = useState();
  const [inputSearch, setInputSearch] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  useEffect(() => {
    if (id) {
      props.fetchDetailIKKMakroUserPengajuanList(id, currentYear);
    }
  }, [id, currentYear]);
  const data = props.IkkMakroPengajuan.data.data || [];
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handlePrevClick = () => {
    paginate(currentPage - 1);
  };

  const handleNextClick = () => {
    paginate(currentPage + 1);
  };

  const onDelete = async (id) => {
    const confirmationResult = await Swal.fire({
      title: "Apakah anda ingin menghapus data ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    });
    if (confirmationResult.isConfirmed) {
      try {
        Swal.fire({
          title: "Success!",
          text: "Delete data categori berhasil ",
          confirmButtonColor: "#3085d6",
          icon: "success",
          confirmButtonText: "OK",
        });
      } catch (error) {
        Swal.fire({
          title: "Error!",
          text: ` Data User Tidak Dapat Dihapus ${error}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const handleCheck = (id) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [id]: !prevCheckedItems[id],
    }));
  };

  const handleCheckAll = (isCheckedAll) => {
    const newCheckedItems = {};
    data.forEach((item) => {
      newCheckedItems[item.id] = isCheckedAll;
    });
    setCheckedItems(newCheckedItems);
  };

  const isChecked = (id) => {
    return checkedItems[id] || false;
  };

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSearchChange = (event) => {
    const input = event.target.value.toLowerCase();
    setInputSearch(input);
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await props.fetchDetailIKKMakroUserPengajuanList(id, inputSearch);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="mb-4 grid grid-cols-12 gap-2">
        <div className="col-span-4 md:col-span-1 ">
          <Stack
            component="form"
            sx={{
              width: "10ch",
            }}
            spacing={1}
            noValidate
            autoComplete="off"
          >
            <TextField
              hiddenLabel
              id="filled-hidden-label-small"
              defaultValue={currentYear}
              onChange={handleSearchChange}
              InputLabelProps={{ shrink: false }}
              variant="filled"
              size="small"
              placeholder="Enter Year"
            />
          </Stack>
        </div>
        <div className="col-span-8 md:col-span-1 ">
          <Stack
            direction="row"
            spacing={2}
            sx={{
              height: "4.1ch",
            }}
          >
            {loading ? (
              <Button
                variant="outlined"
                startIcon={<SearchIcon />}
                onClick={handleSearch}
              >
                Loading...
              </Button>
            ) : (
              <Button
                variant="outlined"
                startIcon={<SearchIcon />}
                onClick={handleSearch}
              >
                Cari
              </Button>
            )}
          </Stack>
        </div>
        <div className="col-span-10 flex justify-end">
          <Stack direction="row" spacing={2}>
            <Link to="/admin/ikk/add">
              <Button variant="contained">Tambah</Button>
            </Link>
            <Button variant="contained" endIcon={<PrintIcon />}>
              Print
            </Button>
            <Button variant="contained" endIcon={<PictureAsPdfIcon />}>
              PDF
            </Button>
            <Button variant="contained">
              Excel{" "}
              <img src={excel} alt="Excel Icon" className="ml-2 w-[20px]" />
            </Button>
          </Stack>
        </div>
      </div>
      {currentItems.map((item, index) => (
        <div>
          <table className="w-[90vw] ">
            <thead>
              <tr className="bg-gray">
                <th className="p-4">
                  <input
                    type="checkbox"
                    onChange={(e) => handleCheckAll(e.target.checked)}
                  />
                </th>
                <th className="p-4">IKK</th>
                <th className="p-4">Satuan</th>
                <th className="p-4">Capaian</th>
                <th className="p-4">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                key={index}
                className={index % 2 === 0 ? "bg-light" : "bg-[#cae6ed]"}
              >
                <td className="p-4 text-center">
                  <input
                    type="checkbox"
                    checked={isChecked(item.id)}
                    onChange={() => handleCheck(item.id)}
                  />
                </td>
                <td className="p-4 text-center">{item.ikk}</td>
                <td className="p-4 text-center">{item.satuan}</td>
                <td className="p-4 text-center">
                  <Button
                    variant="contained"
                    href="#contained-buttons"
                    onClick={openModal}
                  >
                    Info
                  </Button>
                </td>

                <td className="p-4 text-center">
                  <div className="flex items-center justify-center">
                    <Link
                      to={`/admin/userpengajuan/ikk-makro-pengajuan-daerah/detail/edit/${item.id}`}
                    >
                      {" "}
                      <img src={edit} alt="" className="w-[30px]" />
                    </Link>

                    <img
                      src={deleteIcon}
                      alt=""
                      className="w-[30px]"
                      onClick={() => onDelete(item.id)}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <Dialog open={isModalOpen} onClose={closeModal}>
            <DialogTitle>Info Capaian</DialogTitle>
            <DialogContent>
              {item.capaian.map((capaian, index) => (
                <div className="border-b border-gray">
                  <div className="flex">
                    <div className="w-[40%]">
                      <Text6 text="Tahun" />
                    </div>
                    <Text6 text={capaian.tahun} />
                  </div>
                  <div className="flex">
                    <div className="w-[40%]">
                      <Text6 text="Capaian Kinerja" />
                    </div>
                    <Text6 text={capaian.capaian_kinerja} />
                  </div>
                  <div className="flex">
                    <div className="w-[40%]">
                      <Text6 text="Laju Kinerja" />
                    </div>
                    <Text6 text={capaian.laju_kinerja} />
                  </div>
                  <div className="flex">
                    <div className="w-[40%]">
                      <Text6 text="Laju " />
                    </div>
                    <Text6 text={capaian.laju} />
                  </div>
                  <div className="flex">
                    <div className="w-[40%]">
                      <Text6 text="Rumus" />
                    </div>
                    <Text6 text={capaian.rumus} />
                  </div>
                </div>
              ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={closeModal} color="error">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ))}

      {/* Pagination */}
      {/* Pagination */}
      <div className="flex justify-center mt-4 pb-4">
        <button
          onClick={handlePrevClick}
          className={`mx-2 px-4 py-2 ${
            currentPage === 1 ? "bg-gray text-white rounded-full" : ""
          }`}
        >
          {"<"}
        </button>

        {[...Array(totalPages).keys()].map((number) => (
          <button
            key={number}
            onClick={() => paginate(number + 1)}
            className={`mx-2 px-4 py-2 ${
              currentPage === number + 1
                ? "bg-gray text-white rounded-full"
                : ""
            }`}
          >
            {number + 1}
          </button>
        ))}

        <button
          onClick={handleNextClick}
          className={`mx-2 px-4 py-2 ${
            currentPage === totalPages ? "bg-gray text-white rounded-full" : ""
          }`}
        >
          {">"}
        </button>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    IkkMakroPengajuan: state.IkkMakroPengajuan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDetailIKKMakroUserPengajuanList: (id, currentYear) =>
      dispatch(fetchDetailIKKMakroUserPengajuanList(id, currentYear)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailIKKMakro);
