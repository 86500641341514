import axios from "axios";
import API_BASE_URL from "../config";
import Swal from "sweetalert2";
import {
  FETCH_GALLERY_FAILURE,
  FETCH_GALLERY_REQUEST,
  FETCH_GALLERY_SUCCESS,
} from "./ActionType";

const fetchGalleryRequest = () => {
  return {
    type: FETCH_GALLERY_REQUEST,
  };
};

const fetchGallerySuccess = (data) => {
  return {
    type: FETCH_GALLERY_SUCCESS,
    payload: data,
  };
};

const fetchGalleryFailure = (err) => {
  return {
    type: FETCH_GALLERY_FAILURE,
    payload: err,
  };
};

export const fetchGalleryList = () => {
  return (dispatch) => {
    dispatch(fetchGalleryRequest());

    // Retrieve the token from session storage
    const token = sessionStorage.getItem("token"); // Replace "your_token_key" with the actual key used to store the token

    // Set the token in the request headers
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${API_BASE_URL}/api/all/galleries`, axiosConfig)
      .then((res) => {
        let _list = res.data;
        dispatch(fetchGallerySuccess(_list));
      })
      .catch((err) => {
        dispatch(fetchGalleryFailure(err.message));
      });
  };
};
