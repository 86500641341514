export const FETCH_IKK_USER_PENGAJUAN_REQUEST =
  "FETCH_IKK_USER_PENGAJUAN_REQUEST";
export const FETCH_IKK_USER_PENGAJUAN_SUCCESS =
  "FETCH_IKK_USER_PENGAJUAN_SUCCESS";
export const FETCH_IKK_USER_PENGAJUAN_FAILURE =
  "FETCH_IKK_USER_PENGAJUAN_FAILURE";
export const REDIRECT_TO_LOGIN = "REDIRECT_TO_LOGIN";

export const FETCH_DETAIL_IKK_USER_PENGAJUAN_REQUEST =
  "FETCH_DETAIL_IKK_USER_PENGAJUAN_REQUEST";
export const FETCH_DETAIL_IKK_USER_PENGAJUAN_SUCCESS =
  "FETCH_DETAIL_IKK_USER_PENGAJUAN_SUCCESS";
export const FETCH_DETAIL_IKK_USER_PENGAJUAN_FAILURE =
  "FETCH_DETAIL_IKK_USER_PENGAJUAN_FAILURE";

export const DELETE_IKK_USER_PENGAJUAN_SUCCESS =
  "DELETE_IKK_USER_PENGAJUAN_SUCCESS";
export const DELETE_IKK_USER_PENGAJUAN_FAILURE =
  "DELETE_IKK_USER_PENGAJUAN_FAILURE";

export const FETCH_OLD_IKK_USER_PENGAJUAN_SUCCESS =
  "FETCH_OLD_IKK_USER_PENGAJUAN_SUCCESS";
export const FETCH_OLD_IKK_USER_PENGAJUAN_FAILURE =
  "FETCH_OLD_IKK_USER_PENGAJUAN_FAILURE";
export const UPDATE_IKK_USER_PENGAJUAN_SUCCESS =
  "UPDATE_IKK_USER_PENGAJUAN_SUCCESS";
export const UPDATE_IKK_USER_PENGAJUAN_FAILURE =
  "UPDATE_IKK_USER_PENGAJUAN_FAILURE";
