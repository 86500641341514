import React from "react";
import { useDispatch, useSelector } from "react-redux";
import MainLayoutAdmin from "../../../layout/MainLayoutAdmin";
import { Text8 } from "../../../mainComponent/Paragraph";
import AddIKK from "../../../componentsAdmin/masterIKK/AddIKK";
import AddUrusan from "../../../componentsAdmin/masterIKK/AddUrusan";

const AddIKKAdm = () => {
  const dispatch = useDispatch();
  const isUrusanAddedSuccessfully = useSelector(
    (state) => state.addUrusan.isUrusanAddedSuccessfully
  );

  return (
    <MainLayoutAdmin>
      <div>
        <div className="">
          <div className="ml-8 pb-4 border-b border-darkgray mb-8 ">
            <Text8 text="Tambah Urusan " />
          </div>
          <AddUrusan />
        </div>

        {isUrusanAddedSuccessfully ? (
          <div className=" mt-6">
            <div className="ml-8 pb-4 border-b border-darkgray mb-8 ">
              <Text8 text="Tambah IKK " />
            </div>
            <AddIKK />
          </div>
        ) : null}
      </div>
    </MainLayoutAdmin>
  );
};

export default AddIKKAdm;
