import React from "react";
import MainLayout from "../../layout/MainLayout";
import DetailBerita from "../../components/berita/DetailBerita";

const DetailBeritaView = () => {
  return (
    <MainLayout>
      <div className="col-span-12">
        <DetailBerita />
      </div>
    </MainLayout>
  );
};

export default DetailBeritaView;
