import {
  DELETE_URUSAN_FAILURE,
  DELETE_URUSAN_SUCCESS,
  FETCH_OLD_URUSAN_FAILURE,
  FETCH_OLD_URUSAN_SUCCESS,
  FETCH_URUSAN_FAILURE,
  FETCH_URUSAN_REQUEST,
  FETCH_URUSAN_SUCCESS,
  REDIRECT_TO_LOGIN,
  UPDATE_URUSAN_FAILURE,
  UPDATE_URUSAN_SUCCESS,
} from "./ActionType";

// urusanReducer.js
const initialState = {
  urusanData: null,
  oldUrusanData: null,
  error: null,
  isUrusanAddedSuccessfully: false,
};

const urusanReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_URUSAN_SUCCESS":
      return {
        ...state,
        urusanData: action.payload,
        error: null,
        isUrusanAddedSuccessfully: true,
      };
    case "ADD_URUSAN_FAILURE":
      return { ...state, error: action.payload };
    case "CLEAR_ERRORS":
      return { ...state, error: null };
    case FETCH_URUSAN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_URUSAN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    case FETCH_URUSAN_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };
    case REDIRECT_TO_LOGIN:
      return {
        ...state,
        redirectToLogin: true,
      };
    case DELETE_URUSAN_SUCCESS:
      return {
        ...state,
        deleteIkkStatus: "success",
      };
    case DELETE_URUSAN_FAILURE:
      return {
        ...state,
        deleteIkkStatus: "failure",
      };
    case FETCH_OLD_URUSAN_SUCCESS:
      return {
        ...state,
        oldUrusanData: action.payload,
      };

    case FETCH_OLD_URUSAN_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_URUSAN_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
      };
    case UPDATE_URUSAN_FAILURE:
      return {
        ...state,
        data: [],
        error: action.payload || "An unknown error occurred",
      };
    default:
      return state;
  }
};

export default urusanReducer;
