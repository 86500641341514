import React from "react";
import PerangkatDaerah from "../../../components/profile/PerangkatDaerah";
import MainLayout from "../../../layout/MainLayout";

const PerangkatDaerahView = () => {
  return (
    <MainLayout>
      <div className="col-span-12">
        <PerangkatDaerah />
      </div>
    </MainLayout>
  );
};

export default PerangkatDaerahView;
