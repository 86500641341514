import {
  CLONE_IKK_OUTPUT_FAILURE,
  CLONE_IKK_OUTPUT_SUCCESS,
  DELETE_IKK_OUTPUT_FAILURE,
  DELETE_IKK_OUTPUT_SUCCESS,
  FETCH_IKK_OUTPUT_FAILURE,
  FETCH_IKK_OUTPUT_REQUEST,
  FETCH_IKK_OUTPUT_SUCCESS,
  FETCH_OLD_IKK_OUTPUT_FAILURE,
  FETCH_OLD_IKK_OUTPUT_SUCCESS,
  UPDATE_IKK_OUTPUT_FAILURE,
  UPDATE_IKK_OUTPUT_SUCCESS,
} from "./ActionType";

const initialstate = {
  loading: true,
  data: [],
  oldIKKOutputData: null,
  cloneData: null,
  error: "",
  redirectToLogin: false,
  deleteIkkOutputStatus: null,
};

export const IkkOutputReducer = (state = initialstate, action) => {
  switch (action.type) {
    case "ADD_IKK_OUTPUT_SUCCESS":
      return {
        ...state,
        data: action.payload,
        error: null,
        isUrusanAddedSuccessfully: true,
      };
    case "ADD_IKK_OUTPUT_FAILURE":
      return { ...state, error: action.payload };
    case FETCH_IKK_OUTPUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_IKK_OUTPUT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    case FETCH_IKK_OUTPUT_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };
    case DELETE_IKK_OUTPUT_SUCCESS:
      return {
        ...state,
        deleteIkkOutputStatus: "success",
      };

    case DELETE_IKK_OUTPUT_FAILURE:
      return {
        ...state,
        deleteIkkOutputStatus: "failure",
      };
    case FETCH_OLD_IKK_OUTPUT_SUCCESS:
      return {
        ...state,
        oldIKKOutputData: action.payload,
      };

    case FETCH_OLD_IKK_OUTPUT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_IKK_OUTPUT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
      };
    case UPDATE_IKK_OUTPUT_FAILURE:
      return {
        ...state,
        data: [], // reset data to an empty array
        error: action.payload || "An unknown error occurred",
      };
    case CLONE_IKK_OUTPUT_SUCCESS:
      return {
        ...state,
        cloneData: action.payload,
      };

    case CLONE_IKK_OUTPUT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};
