import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import defaultImage from "../../../assets/defaultimage.png";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  saveCapaian,
  fetchCapaianList,
  clearErrors,
} from "../../../redux/capaian/CapaianAction";

const AddCapaian = ({
  saveCapaian,
  error,
  props,
  fetchCapaianList,
  clearErrors,
}) => {
  const navigate = useNavigate();
  const [tahun, setTahun] = useState("");
  const [capaian_kinerja, setCapaian_kinerja] = useState("");
  const [laju_kinerja, setlaju_kinerja] = useState("");
  const [laju, setLaju] = useState("");
  const [rumus, setRumus] = useState("");
  const [ikk_makro_id, setIkk_makro_id] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [selectedFile, setSelectedFile] = useState(defaultImage);

  useEffect(() => {
    fetchCapaianList();
  }, [fetchCapaianList]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];

      if (allowedTypes.includes(file.type)) {
        setSelectedFile(file);
      } else {
        Swal.fire({
          title: "Invalid File Type!",
          text: "Please select a valid file type (doc, pdf, xlsx, docx).",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const onCancel = async (id) => {
    const confirmationResult = await Swal.fire({
      title: "Apakah anda ingin membatalkan aktivitas?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    });
    if (confirmationResult.isConfirmed) {
      try {
        navigate("/admin/management-user");
      } catch (error) {
        Swal.fire({
          title: "Error!",
          text: ` Anda tidak dapat membatalkan aktivitas ${error}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const handleSave = () => {
    const IkkPengajuanList = {
      tahun,
      capaian_kinerja,
      laju_kinerja,
      laju,
      rumus,
      ikk_makro_id,
      keterangan,
      bukti: selectedFile, // Update this line
    };

    saveCapaian(IkkPengajuanList);
  };

  useEffect(() => {
    if (error) {
      Swal.fire({
        title: "Error!",
        text: `Failed to save Urusan. ${error}`,
        icon: "error",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
    clearErrors();
  }, [error, clearErrors]);

  return (
    <div className="pl-4 overflow-hidden max-w-screen  ">
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 2, width: "30ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="filled-basic"
          label="Tahun"
          variant="filled"
          value={tahun}
          onChange={(e) => setTahun(e.target.value)}
        />

        <TextField
          id="filled-basic"
          label="Capaian Kinerja"
          variant="filled"
          value={capaian_kinerja}
          onChange={(e) => setCapaian_kinerja(e.target.value)}
        />
        <TextField
          id="filled-basic"
          label="Laju Kinerja"
          variant="filled"
          value={laju_kinerja}
          onChange={(e) => setlaju_kinerja(e.target.value)}
        />
        <TextField
          id="filled-basic"
          label="Laju"
          variant="filled"
          value={laju}
          onChange={(e) => setLaju(e.target.value)}
        />

        <TextField
          id="filled-basic"
          label="Rumus"
          variant="filled"
          value={rumus}
          onChange={(e) => setRumus(e.target.value)}
        />

        <TextField
          id="filled-basic"
          label="IKK Makro id  "
          variant="filled"
          value={ikk_makro_id}
          onChange={(e) => setIkk_makro_id(e.target.value)}
        />

        <TextField
          id="filled-basic"
          label="Keterangan "
          variant="filled"
          value={keterangan}
          onChange={(e) => setKeterangan(e.target.value)}
        />
        <div>
          <input type="file" onChange={handleFileChange} className="ml-2" />
        </div>
      </Box>

      <div className="w-[200px]  flex justify-between pl-4">
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={
            !tahun ||
            !capaian_kinerja ||
            !laju_kinerja ||
            !laju ||
            !rumus ||
            !ikk_makro_id ||
            !keterangan ||
            !selectedFile
          }
        >
          Simpan
        </Button>

        <Button variant="contained" color="error" onClick={() => onCancel()}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  Capaian: state.Capaian,
  error: state.error,
});

export default connect(mapStateToProps, {
  saveCapaian,
  fetchCapaianList,
  clearErrors,
})(AddCapaian);
