import {
  DELETE_USER_FAILURE,
  DELETE_USER_SUCCESS,
  FETCH_OLD_USER_FAILURE,
  FETCH_OLD_USER_SUCCESS,
  FETCH_USER_FAILURE,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  REDIRECT_TO_LOGIN,
  UPDATE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
} from "./ActionType";

const initialstate = {
  loading: true,
  data: [],
  oldIKKData: null,
  error: "",
  redirectToLogin: false,
  deleteIkkStatus: null,
};

export const UserReducer = (state = initialstate, action) => {
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };
    case REDIRECT_TO_LOGIN:
      return {
        ...state,
        redirectToLogin: true,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteIkkStatus: "success",
      };
    case DELETE_USER_FAILURE:
      return {
        ...state,
        deleteIkkStatus: "failure",
      };
    case FETCH_OLD_USER_SUCCESS:
      return {
        ...state,
        oldIKKData: action.payload,
      };
    case FETCH_OLD_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        data: [],
        error: action.payload || "An unknown error occurred",
      };
    default:
      return state;
  }
};
