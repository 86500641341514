import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchOldIKKMakroPengajuan,
  updateIKKMakroPengajuan,
} from "../../../redux/ikkMakroUserPengajuan/IkkMakroPengajuanAction";

const EditIkkMakro = ({
  fetchOldIKKMakroPengajuan,
  updateIKKMakroPengajuan,
  oldData,
}) => {
  const { id } = useParams();
  const [status_rekomendasi, setStatusRekomendasi] = useState("");
  const [catatan_rekomendasi, setCatatanRekomendasi] = useState("");

  useEffect(() => {
    fetchOldIKKMakroPengajuan(id);
  }, [fetchOldIKKMakroPengajuan]);

  useEffect(() => {
    if (oldData) {
      setStatusRekomendasi(oldData.no_ikk);
      setCatatanRekomendasi(oldData.ikk_output);
    }
  }, [oldData]);

  const handleUpdate = () => {
    const updatedIKKData = {
      status_rekomendasi,
      catatan_rekomendasi,
    };

    // Assuming you have the ID of the IKK item to update (hardcoded as 2 in this case)
    updateIKKMakroPengajuan(id, updatedIKKData);
  };

  const onCancel = async (id) => {};

  return (
    <div className="pl-4 overflow-hidden max-w-screen  ">
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 2, width: "30ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="filled-basic"
          label="Status Rekomendasi"
          variant="filled"
          value={status_rekomendasi}
          onChange={(e) => setStatusRekomendasi(e.target.value)}
        />

        <TextField
          id="filled-basic"
          label="Catatan Rekomendasi"
          variant="filled"
          value={catatan_rekomendasi}
          onChange={(e) => setCatatanRekomendasi(e.target.value)}
        />
      </Box>

      <div className="w-[200px]  flex justify-between pl-4">
        <Button
          variant="contained"
          onClick={handleUpdate}
          disabled={!status_rekomendasi || !catatan_rekomendasi}
        >
          Simpan
        </Button>

        <Button variant="contained" color="error" onClick={() => onCancel()}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const mappedProps = {
    // Existing mapStateToProps...
    oldData: state.IkkMakroPengajuan.oldData,
  };

  console.log("Mapped Props:", mappedProps); // Add this line to log the result

  return mappedProps;
};

export default connect(mapStateToProps, {
  fetchOldIKKMakroPengajuan,
  updateIKKMakroPengajuan,
})(EditIkkMakro);
