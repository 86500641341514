import React, { useEffect, useState } from "react";
import edit from "../../../assets/edit.png";
import { Text10, Text6 } from "../../../mainComponent/Paragraph";
import Button from "@mui/material/Button";
import deleteIcon from "../../../assets/delete.png";
import { connect } from "react-redux";
import { Stack, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Link, useNavigate } from "react-router-dom";
import PrintIcon from "@mui/icons-material/Print";
import excel from "../../../assets/excel.png";
import jsPDF from "jspdf";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import * as XLSX from "xlsx";
// modal
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { fetchIKKMakroPengajuanDaerahList } from "../../../redux/ikkMakroUserPengajuanDaerah/IkkMakroPengajuanDaerahAction";

const PelaporanIKKMakroPengajuan = (props) => {
  const Swal = require("sweetalert2");
  const navigate = useNavigate();
  const [currentYear, setCurrentYear] = useState();
  const [checkedItems, setCheckedItems] = useState({});
  const [dataChecked, setDataChecked] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputSearch, setInputSearch] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    props.fetchIKKMakroPengajuanDaerahList(currentYear);
  }, [currentYear]);

  // console.log("pelaporan ikk output", props.IkkOutputList);
  const data = props.IkkMakroPengajuanDaerahList.data.data || [];

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  const itemsPerPage = 5; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handlePrevClick = () => {
    paginate(currentPage - 1);
  };

  const handleNextClick = () => {
    paginate(currentPage + 1);
  };

  // delete function
  const onDelete = async (id) => {
    const confirmationResult = await Swal.fire({
      title: `Apakah anda ingin menghapus data ${id}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    });

    if (confirmationResult.isConfirmed) {
      try {
        await props.deleteIkkMakro(id);
      } catch (error) {}
    }
  };

  const handleCheck = (id) => {
    setCheckedItems((prevCheckedItems) => {
      const updatedCheckedItems = {
        ...prevCheckedItems,
        [id]: !prevCheckedItems[id],
      };

      // Get the checked item data
      const checkedItemData = data.find((item) => item.id === id);

      // Update dataChecked with all fields from the item
      setDataChecked((prevDataChecked) => {
        if (updatedCheckedItems[id]) {
          // If item is checked, add it to dataChecked
          return [
            ...prevDataChecked,
            {
              ...checkedItemData,
            },
          ];
        } else {
          // If item is unchecked, remove it from dataChecked
          return prevDataChecked.filter((data) => data.id !== id);
        }
      });

      return updatedCheckedItems;
    });
  };

  const handleCheckAll = (isCheckedAll) => {
    const newCheckedItems = {};
    data.forEach((item) => {
      newCheckedItems[item.id] = isCheckedAll;
    });
    setCheckedItems(newCheckedItems);

    setDataChecked(
      isCheckedAll
        ? data.map((item) => ({
            ...item, // Include all fields from the item
          }))
        : []
    );
  };

  const isChecked = (id) => {
    return checkedItems[id] || false;
  };

  const exportToExcel = () => {
    const dataToExport = [
      [
        "No IKK",
        "IKK Outcome",
        "IKK Output",
        "Nilai",
        "Diperbarui (Pemda)",
        "Ket (Pemda)",
        "Bukti File (pemda)",
        "Status (Apip)",
        "Ket (Apip)",
        "Diperharui (Apip)",
        "Capaian",
        "Status Rekomendasi",
        "Keterangan Rekomendasi",
      ],
      ...dataChecked.map((item, index) => [
        index + 1,
        `# ${item.no_ikk_outcome}`,
        item.ikk_output_in_outcome,
        item.nilai_pemda,
        item.update_by_pemda,
        item.desc_pemda,
        item.bukti_by_pemda,
        item.status_apip,
        item.desc_apip,
        item.update_apip,
        item.nilai_pemda,
        item.status_rekomendasi,
        item.keterangan_rekomendasi,
      ]),
    ];

    const ws = XLSX.utils.aoa_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "IKK");

    // Save the Excel file
    XLSX.writeFile(wb, "IKK_Output.xlsx");
  };

  const downloadPDF = () => {
    const pdf = new jsPDF();
    pdf.setFontSize(12); // Adjust the font size as needed

    pdf.text("IKK Data", 20, 20);

    dataChecked.forEach((item, index) => {
      const yPosition = 30 + index * 15; // Adjust the multiplier as needed
      const text = `${index + 1}. No IKK: ${item.no_ikk}\nIKK Output: ${
        item.ikk_output
      }\nIKK Outcome: ${item.ikk_outcome}\nRumus: ${item.rumus}`;
      pdf.text(text, 20, yPosition);
    });

    pdf.save("IKK_Output.pdf");
  };

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSearchChange = (event) => {
    const input = event.target.value.toLowerCase();
    setInputSearch(input);
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await props.fetchIKKMakroPengajuanDaerahList(inputSearch);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="">
      <div className="grid grid-cols-12 gap-4 ">
        <div className="  col-span-6">
          <Text10 text="Keterangan :" />
          <div className="flex items-center">
            <div className="w-[15px] h-[15px] bg-red-500 mr-2"></div>
            <div className="flex items-center">
              <Text10 text="IKK yang mendapat rekomendasi" />
            </div>
          </div>
          <div className="flex items-center ">
            <div className="w-[15px] h-[15px] bg-green-500 mr-2"></div>
            <div className="flex items-center ">
              <Text10 text="IKK yang mendapat rekomendasi dan telah diperbaharui" />
            </div>
          </div>
        </div>
      </div>
      <div className="mb-4 grid grid-cols-12 gap-2  ">
        <div className="col-span-4 md:col-span-1 ">
          <Stack
            component="form"
            sx={{
              width: "10ch",
            }}
            spacing={1}
            noValidate
            autoComplete="off"
          >
            <TextField
              hiddenLabel
              id="filled-hidden-label-small"
              defaultValue={currentYear}
              onChange={handleSearchChange}
              InputLabelProps={{ shrink: false }}
              variant="filled"
              size="small"
              placeholder="Enter Year"
            />
          </Stack>
        </div>
        <div className="col-span-8 md:col-span-1 ">
          <Stack
            direction="row"
            spacing={2}
            sx={{
              height: "4.1ch",
            }}
          >
            {loading ? (
              <Button
                variant="outlined"
                startIcon={<SearchIcon />}
                onClick={handleSearch}
              >
                Loading...
              </Button>
            ) : (
              <Button
                variant="outlined"
                startIcon={<SearchIcon />}
                onClick={handleSearch}
              >
                Cari
              </Button>
            )}
          </Stack>
        </div>
        <div className="col-span-12 md:col-span-10 flex justify-end">
          <Stack direction="row" spacing={1}>
            <Link to="/admin/ikk/pelaporan-ikk-makro/add">
              <Button variant="contained">Tambah</Button>
            </Link>
            <Button
              variant="contained"
              endIcon={<PrintIcon />}
              disabled={!dataChecked.length}
            >
              Print
            </Button>
            <Button
              variant="contained"
              onClick={downloadPDF}
              endIcon={<PictureAsPdfIcon />}
              disabled={!dataChecked.length}
            >
              PDF
            </Button>
            <Button
              variant="contained"
              onClick={exportToExcel}
              disabled={!dataChecked.length}
            >
              Excel{" "}
              <img src={excel} alt="Excel Icon" className="ml-2 w-[20px]" />
            </Button>
          </Stack>
        </div>
      </div>

      <div>
        <table className="w-[93.5vw] mt-8 ">
          <thead>
            <tr className="bg-gray">
              <th className="p-4">
                <input
                  type="checkbox"
                  onChange={(e) => handleCheckAll(e.target.checked)}
                />
              </th>
              <th className="p-4 ">IKK</th>
              <th className="p-4">Satuan</th>
              <th className="p-4">Capaian</th>
              <th className="p-4">Aksi</th>
            </tr>
          </thead>

          {!data || data.length === 0 ? (
            <tbody className="">
              <tr>
                <td colSpan="15" className=" text-center p-4 bg-[#cae6ed]">
                  No Available Data
                </td>
              </tr>
            </tbody>
          ) : (
            <>
              {currentItems.map((item, index) => (
                <tbody>
                  <tr
                    key={index}
                    className={index % 2 === 0 ? "bg-light" : "bg-[#cae6ed]"}
                  >
                    <td className="p-4 text-center">
                      <input
                        type="checkbox"
                        checked={isChecked(item.id)}
                        onChange={() => handleCheck(item.id)}
                      />
                    </td>
                    <td className="p-4 text-center">
                      {item.ikk !== null ? item.ikk : "-"}
                    </td>
                    <td className="p-4 text-center">
                      {item.satuan !== null ? item.satuan : "-"}
                    </td>
                    <td className="p-4 text-center">
                      <Button
                        variant="contained"
                        href="#contained-buttons"
                        onClick={openModal}
                      >
                        Info
                      </Button>
                    </td>

                    <td className="p-4 text-center">
                      <div className="flex items-center justify-center">
                        <img
                          src={deleteIcon}
                          alt=""
                          className="w-[30px]"
                          onClick={() => onDelete(item.id)}
                        />
                      </div>
                    </td>
                  </tr>
                  <Dialog open={isModalOpen} onClose={closeModal}>
                    <DialogTitle>Info Capaian</DialogTitle>
                    <DialogContent>
                      {item.capaian.map((capaian, index) => (
                        <div className="border-b border-gray">
                          <div className="flex">
                            <div className="w-[40%]">
                              <Text6 text="Tahun" />
                            </div>
                            <Text6 text={capaian.tahun} />
                          </div>
                          <div className="flex">
                            <div className="w-[40%]">
                              <Text6 text="Capaian Kinerja" />
                            </div>
                            <Text6 text={capaian.capaian_kinerja} />
                          </div>
                          <div className="flex">
                            <div className="w-[40%]">
                              <Text6 text="Laju Kinerja" />
                            </div>
                            <Text6 text={capaian.laju_kinerja} />
                          </div>
                          <div className="flex">
                            <div className="w-[40%]">
                              <Text6 text="Laju " />
                            </div>
                            <Text6 text={capaian.laju} />
                          </div>
                          <div className="flex">
                            <div className="w-[40%]">
                              <Text6 text="Rumus" />
                            </div>
                            <Text6 text={capaian.rumus} />
                          </div>

                          <Link
                            to={`/admin/ikk/pelaporan-ikk-makro/capaian/edit/${capaian.capaian_id}`}
                          >
                            <img src={edit} alt="" className="w-[30px]" />
                          </Link>
                        </div>
                      ))}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={closeModal} color="error">
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                </tbody>
              ))}
            </>
          )}
        </table>
      </div>

      {/* Pagination */}
      {/* Pagination */}
      {!data || data.length === 0 ? (
        ""
      ) : (
        <div className="flex justify-center mt-4 pb-4">
          <button
            onClick={handlePrevClick}
            className={`mx-2 px-4 py-2 ${
              currentPage === 1 ? "bg-gray text-white rounded-full" : ""
            }`}
          >
            {"<"}
          </button>

          {[...Array(totalPages).keys()].map((number) => (
            <button
              key={number}
              onClick={() => paginate(number + 1)}
              className={`mx-2 px-4 py-2 ${
                currentPage === number + 1
                  ? "bg-gray text-white rounded-full"
                  : ""
              }`}
            >
              {number + 1}
            </button>
          ))}

          <button
            onClick={handleNextClick}
            className={`mx-2 px-4 py-2 ${
              currentPage === totalPages
                ? "bg-gray text-white rounded-full"
                : ""
            }`}
          >
            {">"}
          </button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    IkkMakroPengajuanDaerahList: state.IkkMakroPengajuanDaerahList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchIKKMakroPengajuanDaerahList: (currentYear) =>
      dispatch(fetchIKKMakroPengajuanDaerahList(currentYear)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PelaporanIKKMakroPengajuan);
