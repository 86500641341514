import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Box } from "@mui/material";
import { fetchIKKMakroList } from "../../redux/ikkMakro/IkkMakroAction";

const IKKMakroDropdown = (props) => {
  const Swal = require("sweetalert2");
  const navigate = useNavigate();
  const [ikk, setIKK] = useState(1);

  useEffect(() => {
    props.fetchIKKMakroList();
  }, []);

  // console.log("pelaporan ikk output", props.IkkOutputList);
  const data = props.IkkMakroList.data || [];

  return (
    <div>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { width: "30ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="filled-select-currency"
          select
          label="Select"
          value={ikk}
          onChange={(e) => {
            const selectedValue = e.target.value;
            setIKK(selectedValue);
            props.setIKKSelect(selectedValue);
          }}
          helperText="Pilih IKK Makro"
          variant="filled"
        >
          {data.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.ikk}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    IkkMakroList: state.IkkMakroList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchIKKMakroList: () => dispatch(fetchIKKMakroList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IKKMakroDropdown);
