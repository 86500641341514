import React, { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import man from "../../src/assets/man.png";
import power from "../../src/assets/power.png";
import { useDispatch } from "react-redux";
import { logout } from "../redux/login/AuthAction";
import SourceIcon from "@mui/icons-material/Source";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import SummarizeIcon from "@mui/icons-material/Summarize";
import HomeIcon from "@mui/icons-material/Home";
import { Text2, Text6 } from "../mainComponent/Paragraph";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Link } from "react-router-dom";

// superadmin
const menuItems_superadmin = [
  {
    id: "1",
    label: "Dashboard",
    icon: HomeIcon,
    submenu: [],
  },
  {
    id: "3",
    label: "Setting General",
    icon: SettingsIcon,
    submenu: [
      {
        id: "3.1",
        label: "Home Section",
        icon: SettingsSuggestIcon,
        url: "/admin",
        role: [],
      },
      {
        id: "3.2",
        label: "Custom Page",
        icon: SettingsSuggestIcon,
        url: "/admin/settingGeneral/customPage",
        role: [],
      },
      {
        id: "3.3",
        label: "Contact Section",
        icon: SettingsSuggestIcon,
        url: "/admin",
        role: [],
      },
      {
        id: "3.4",
        label: "Master Category",
        icon: SettingsSuggestIcon,
        url: "/admin",
        role: [],
      },
      {
        id: "3.4",
        label: "Setting General",
        icon: SettingsSuggestIcon,
        url: "/admin/settingGeneral/settingGeneral",
        role: [],
      },
    ],
  },
  {
    id: "4",
    label: "Article",
    icon: NewspaperIcon,
    submenu: [
      // {
      //   id: "4.1",
      //   label: "Category",
      //   icon: NewspaperIcon,
      //   url: "/admin/article/category",
      //   role: [],
      // },
      {
        id: "4.2",
        label: "Parent",
        icon: NewspaperIcon,
        url: "/admin/article/parent",
        role: [],
      },
      {
        id: "4.3",
        label: "Article",
        icon: NewspaperIcon,
        url: "/admin/articleAdm",
        role: [],
      },
    ],
  },
  {
    id: "5",
    label: "Management User",
    icon: ManageAccountsIcon,
    submenu: [
      {
        id: "5.1",
        label: "Management User",
        icon: ManageAccountsIcon,
        url: "/admin/management-user",
        role: [],
      },
    ],
  },
  {
    id: "6",
    label: "Home Section",
    icon: ViewCarouselIcon,
    submenu: [
      {
        id: "6.1",
        label: "Banner ",
        icon: ViewCarouselIcon,
        url: "/admin/gallery",
        role: [],
      },
      {
        id: "6.2",
        label: "Banner Video ",
        icon: ViewCarouselIcon,
        url: "/admin/video",
        role: [],
      },
    ],
  },
  {
    id: "2",
    label: "IKK",
    icon: SummarizeIcon,
    submenu: [
      {
        id: "2.1",
        label: "Master IKK",
        icon: SummarizeIcon,
        url: "/admin/ikk",
      },
      {
        id: "2.2",
        label: "Pelaporan IKK Output",
        icon: SummarizeIcon,
        url: "/admin/ikk/pelaporan-ikk-output",
      },
      {
        id: "2.3",
        label: "Pelaporan IKK Makro",
        icon: SummarizeIcon,
        url: "/admin/ikk/pelaporan-ikk-makro",
      },
      {
        id: "2.4",
        label: "Urusan",
        icon: SummarizeIcon,
        url: "/admin/ikk/urusan",
      },
    ],
  },
];

// user pengajuan
const menuItems_userPengajuan = [
  {
    id: "1",
    label: "Dashboard",
    icon: HomeIcon,
    submenu: [],
  },
  {
    id: "2",
    label: "IKK",
    icon: SummarizeIcon,
    submenu: [
      // {
      //   id: "2.1",
      //   label: "IKK User Pengajuan",
      //   icon: SummarizeIcon,
      //   url: "/admin/ikk/pelaporan-ikk-output-pengajuan",
      //   role: [],
      // },
      {
        id: "2.2",
        label: "Pelaporan IKK Pengajuan ",
        icon: SummarizeIcon,
        url: "/admin/ikk/pelaporan-ikk/user-pengajuan",
        role: [],
      },

      {
        id: "2.3",
        label: "Pelaporan IKK Makro Pengajuan ",
        icon: SummarizeIcon,
        url: "/admin/ikk/pelaporan-ikk-makro/pengajuan",
        role: [],
      },
      {
        id: "2.4",
        label: "Clone IKK Output ",
        icon: SummarizeIcon,
        url: "/admin/ikk/pelaporan-ikk-output/pengajuan",
        role: [],
      },
    ],
  },
  {
    id: "7",
    label: "USER PENGAJUAN",
    icon: SourceIcon,
    submenu: [
      {
        id: "7.1",
        label: "IKK Pengajuan Daerah",
        icon: SourceIcon,
        url: "/admin/userpengajuan/ikk-pengajuan-daerah",
      },
      {
        id: "7.2",
        label: "Capaian",
        icon: SourceIcon,
        url: "/admin/userpengajuan/capaian",
        role: [],
      },
      {
        id: "7.3",
        label: "Pelaporan IKK Output",
        icon: SourceIcon,
        url: "/admin/userpengajuan/pelaporanikkoutput",
        role: [],
      },
    ],
  },
];

// admin konfirmasi
const menuItems_admkonfirmasi = [
  {
    id: "1",
    label: "Dashboard",
    icon: HomeIcon,
    submenu: [],
  },

  {
    id: "7",
    label: "USER PENGAJUAN",
    icon: SourceIcon,
    submenu: [
      {
        id: "7.1",
        label: "IKK",
        icon: SourceIcon,
        url: "/admin/userpengajuan/ikk",
        role: [],
      },

      {
        id: "7.3",
        label: "IKK Makro",
        icon: SourceIcon,
        url: "/admin/userpengajuan/ikk-makro-pengajuan-daerah",
        role: [],
      },
      {
        id: "7.4",
        label: "IKK Output",
        icon: SourceIcon,
        url: "/admin/ikk/pelaporan-ikk-makro",
        role: [],
      },

      // {
      //   id: "7.5",
      //   label: "Capaian",
      //   icon: SourceIcon,
      //   url: "/admin/userpengajuan/capaian",
      //   role: [],
      // },

      // {
      //   id: "7.6",
      //   label: "Pelaporan IKK Output",
      //   icon: SourceIcon,
      //   url: "/admin/userpengajuan/pelaporanikkoutput",
      //   role: [],
      // },

      {
        id: "7.7",
        label: "Report IKK New",
        icon: SourceIcon,
        url: "/admin/userpengajuan/pelaporanikkoutputadm/ikk-output",
        role: [],
      },
    ],
  },
];

// admin APIP
const menuItems_apip = [
  {
    id: "1",
    label: "Dashboard",
    icon: HomeIcon,
    submenu: [],
  },

  {
    id: "7",
    label: "USER PENGAJUAN",
    icon: SourceIcon,
    submenu: [
      {
        id: "7.1",
        label: "IKK",
        icon: SourceIcon,
        url: "/admin/userpengajuan/ikk",
        role: [],
      },
      {
        id: "7.2",
        label: "IKK Makro",
        icon: SourceIcon,
        url: "/admin/userpengajuan/ikk-makro-pengajuan-daerah",
        role: [],
      },
    ],
  },
];

const Navbar = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [isProfileOpen, setProfileOpen] = useState(false);
  const role = sessionStorage.getItem("role");
  const [active, setActive] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  useEffect(() => {
    // Set menu items based on the user's role
    let selectedMenuItems = [];
    if (role === "1") {
      selectedMenuItems = menuItems_superadmin;
    } else if (role === "2") {
      selectedMenuItems = menuItems_userPengajuan;
    } else if (role === "3") {
      selectedMenuItems = menuItems_admkonfirmasi;
    } else if (role === "5") {
      selectedMenuItems = menuItems_apip;
    }

    setMenuItems(selectedMenuItems);
  }, [role]);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  const handleItemClick2 = (itemName) => {
    setActiveItem(itemName);
    localStorage.setItem("activeItem", itemName);
  };
  const profileMenu = () => {
    setProfileOpen(!isProfileOpen);
  };
  const dispatch = useDispatch();

  const handleLogout = () => {
    // Dispatch the logout action
    dispatch(logout());
  };
  const getCurrentDate = () => {
    const currentDate = new Date();
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return currentDate.toLocaleDateString("en-US", options);
  };

  const handleItemClick = (itemName) => {
    setActive((prevActive) => (prevActive === itemName ? null : itemName));
  };

  return (
    <div>
      <div
        className={`transition-all duration-700 ease-in-out overflow-hidden bg-white grid grid-cols-12 shadow-md h-[auto] `}
      >
        <div className="col-span-12 flex justify-between items-center px-4 h-[60px]">
          <p className="text-black text-xl font-bold ">Tapemansel LPPD</p>
          <div className="block md:hidden" onClick={toggleMenu}>
            <MenuIcon />
          </div>

          <div className="hidden md:flex items-center">
            <p>Current Date: {getCurrentDate()}</p>
            <img
              src={man}
              alt=""
              className="w-[40px] ml-4 "
              onClick={profileMenu}
            />
          </div>
        </div>

        {/* Render the menu if it's open */}
      </div>

      <div>
        {isMenuOpen && (
          <div className="py-5  px-4 bg-gray w-[100%] shadow-md">
            {menuItems.map((item, index) => (
              <div key={index} className=" w-[90%] pb-2">
                <div className="flex justify-between ">
                  <div className="flex ">
                    <div className="pr-3">
                      <item.icon />
                    </div>
                    <Text2 text={item.label} />
                  </div>
                  <div className="" onClick={() => handleItemClick(item.id)}>
                    {active == item.id ? (
                      <ExpandMoreIcon />
                    ) : (
                      <ExpandLessIcon />
                    )}
                  </div>
                </div>

                {item.submenu && (
                  <div
                    className={`${
                      active === item.id
                        ? "opacity-100"
                        : "opacity-0 relative hidden"
                    }  mb-2`}
                  >
                    {item.submenu && (
                      <div
                        className={`${
                          active === item.id
                            ? "opacity-100"
                            : "opacity-0 relative hidden"
                        }  mb-2`}
                      >
                        {item.submenu.map((submenuItem) => (
                          <Link to={submenuItem.url}>
                            <div
                              key={submenuItem.id}
                              onClick={() => handleItemClick2(submenuItem.id)}
                              className={`${
                                activeItem === submenuItem.id
                                  ? "bg-white bg-opacity-20"
                                  : "bg-opacity-100"
                              } flex items-center h-[40px] px-2 hover:bg-white hover:bg-opacity-50 rounded-md mb-2`}
                            >
                              <submenuItem.icon />
                              <div className={`pl-5 w-[600px] `}>
                                <a
                                  className={`${
                                    isSidebarVisible ? "text-darkgray" : "none"
                                  }`}
                                >
                                  {submenuItem.label}
                                </a>
                              </div>
                            </div>
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      {isProfileOpen ? (
        <div className="profile-menu col-span-12 flex justify-end  transition-opacity duration-700 opacity-100">
          <div className="p-4 w-[200px] bg-white shadow-lg   rounded-md ">
            <div className="flex ">
              <img src={power} alt="" className="w-[35px]" />
              <div className="flex items-center pl-2" onClick={handleLogout}>
                <p>logout</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="profile-menu col-span-12 flex justify-end transition-opacity duration-700 opacity-0"></div>
      )}
    </div>
  );
};

export default Navbar;
