import React from "react";
import MainLayout from "../../../layout/MainLayout";
import DataGeografis from "../../../components/profile/DataGeografis";

const DataGeografisView = () => {
  return (
    <MainLayout>
      <div className="col-span-12">
        <DataGeografis />
      </div>
    </MainLayout>
  );
};

export default DataGeografisView;
