import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import ListIcon from "@mui/icons-material/List";
import { Text2, Text4 } from "../../mainComponent/Paragraph";

const whiteIconStyle = {
  color: "white",
};

const PencarianDetailArtikel = () => {
  const [content, setContent] = useState([
    "Artikel",
    "Agenda",
    "Berita",
    "Pariwisata",
    "Kuliner",
  ]);

  const [searchInput, setSearchInput] = useState("");

  const handleSearchChange = (event) => {
    const input = event.target.value.toLowerCase();
    setSearchInput(input);

    // Update the data state with filtered items
    const filteredData = content.filter((item) =>
      item.toLowerCase().includes(input)
    );
    setContent(filteredData);
  };

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: "alpha(theme.palette.common.white, 0.25)",
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
  }));
  return (
    <div>
      <div>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" style={{ backgroundColor: "#8EB3D5" }}>
            <Toolbar>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  value={searchInput}
                  onChange={handleSearchChange}
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>
            </Toolbar>
          </AppBar>
        </Box>
      </div>
      <div className="mt-4">
        <div className="bg-gray flex ">
          <div className="ml-4">
            <ListIcon style={whiteIconStyle} />
          </div>
          <div className="ml-4">
            <Text4 text="Kategori" />
          </div>
        </div>
        <div>
          {content.map((data, index) => (
            <div className="ml-4 py-3 border-b border-gray" key={index}>
              <Text2 text={data} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PencarianDetailArtikel;
