import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchHomeList } from "../../redux/lppdHome/Action";
import { Link } from "react-router-dom";

const Navlink = (props) => {
  useEffect(() => {
    props.fetchHome();
  }, []);

  const links2 = props.homeList.data.homepages;
  const [heading, setHeading] = useState("");
  const [subHeading, setSubHeading] = useState("");
  const [open, setOpen] = useState(false);

  // console.log("first open", open);

  const handleLinkClick = (item) => {
    // Add code to update sessionStorage here
    const newOpenValue = !sessionStorage.getItem("navbarOpen");
    sessionStorage.setItem("navbarOpen", JSON.stringify(newOpenValue));

    // Other logic for setting heading and subHeading
    setHeading(item.name);
    setSubHeading("");
  };

  return (
    <>
      {props.homeList &&
        props.homeList.data.homepages &&
        props.homeList.data.homepages.map((item, index) => (
          <div key={index} className="md:flex ">
            {item.navbar.map((link, index) => (
              <div key={index}>
                <div className="px-3 text-left md:cursor-pointer group ">
                  {link.customPages.length > 0 ? (
                    <div
                      className="py-7 flex justify-between items-center md:pr-0 pr-5 group "
                      onClick={() => {
                        heading !== link.name
                          ? setHeading(link.name)
                          : setHeading("");
                        setSubHeading("");
                      }}
                    >
                      <div
                        className="hover:border-b-4 hover:border-white  "
                        onClick={() => setOpen(!open)}
                      >
                        {link.title}
                      </div>
                    </div>
                  ) : (
                    <Link to={`/${link.link_parent}`}>
                      <h1
                        className="py-7 flex justify-between items-center md:pr-0 pr-5 group "
                        onClick={() => {
                          heading !== link.name
                            ? setHeading(link.name)
                            : setHeading("");
                          setSubHeading("");
                        }}
                      >
                        {link.title}
                      </h1>
                    </Link>
                  )}
                  {link.customPages && link.customPages.length > 0 && (
                    <div
                      className={`md:absolute md:hidden group-hover:md:block hover:md:block ${
                        open ? "hidden" : ""
                      }`}
                    >
                      <div className="bg-gray md:bg-light  p-2 ">
                        {link.customPages.map((item) => (
                          <div
                            key={item.title}
                            className="py-2  border-b border-gray hover:font-bold"
                          >
                            <Link
                              to={`/${item.link}`}
                              onClick={() => handleLinkClick(item)}
                            >
                              <h1>{item.title}</h1>
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        ))}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    homeList: state.homeList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchHome: () => dispatch(fetchHomeList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navlink);
