import React, { useEffect, useState } from "react";
import edit from "../../../assets/edit.png";
import deleteIcon from "../../../assets/delete.png";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Link, useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PrintIcon from "@mui/icons-material/Print";
import excel from "../../../assets/excel.png";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { TextField } from "@mui/material";
import * as XLSX from "xlsx";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import jsPDF from "jspdf";
import {
  deleteUrusan,
  fetchUrusanList,
} from "../../../redux/urusan/UrusanAction";

const Urusan = (props) => {
  const Swal = require("sweetalert2");
  const navigate = useNavigate();

  const itemsPerPage = 5; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const [checkedItems, setCheckedItems] = useState({});
  const [dataChecked, setDataChecked] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    props.fetchUrusanList();
  }, []);

  useEffect(() => {
    setData(props.addUrusan.data || []);
  }, [props.addUrusan]);
  const handleSearchChange = (event) => {
    const input = event.target.value.toLowerCase();
    setSearchInput(input);

    // If searchInput is null or empty, reset data to the original list
    if (input === null || input.trim() === "") {
      setData(props.addUrusan.data || []);
    } else {
      // Otherwise, update the data state with filtered items
      const filteredData = data.filter(
        (item) =>
          item.kategori_urusan_ikk.toLowerCase().includes(input) ||
          item.urusan.toLowerCase().includes(input)
      );
      setData(filteredData);
    }

    setCurrentPage(1); // Reset current page to 1 when search changes
  };

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handlePrevClick = () => {
    paginate(currentPage - 1);
  };

  const handleNextClick = () => {
    paginate(currentPage + 1);
  };

  const onDelete = async (id) => {
    const confirmationResult = await Swal.fire({
      title: `Apakah anda ingin menghapus data ${id}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    });

    if (confirmationResult.isConfirmed) {
      try {
        await props.deleteUrusan(id);
      } catch (error) {}
    }
  };

  const handleCheck = (id) => {
    setCheckedItems((prevCheckedItems) => {
      const updatedCheckedItems = {
        ...prevCheckedItems,
        [id]: !prevCheckedItems[id],
      };

      // Get the checked item data
      const checkedItemData = data.find((item) => item.id === id);

      // Update dataChecked with all fields from the item
      setDataChecked((prevDataChecked) => {
        if (updatedCheckedItems[id]) {
          // If item is checked, add it to dataChecked
          return [
            ...prevDataChecked,
            {
              ...checkedItemData,
            },
          ];
        } else {
          // If item is unchecked, remove it from dataChecked
          return prevDataChecked.filter((data) => data.id !== id);
        }
      });

      return updatedCheckedItems;
    });
  };

  const handleCheckAll = (isCheckedAll) => {
    const newCheckedItems = {};
    data.forEach((item) => {
      newCheckedItems[item.id] = isCheckedAll;
    });
    setCheckedItems(newCheckedItems);

    setDataChecked(
      isCheckedAll
        ? data.map((item) => ({
            ...item, // Include all fields from the item
          }))
        : []
    );
  };

  const isChecked = (id) => {
    return checkedItems[id] || false;
  };

  // export excel
  // console.log("data checked", dataChecked);
  const exportToExcel = () => {
    const dataToExport = [
      ["No ", "Kategori Urusan", "Urusan"],
      ...dataChecked.map((item, index) => [
        index + 1,
        `# ${item.id}`,
        item.kategori_urusan_ikk,
        item.urusan,
      ]),
    ];

    const ws = XLSX.utils.aoa_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Urusan");

    // Save the Excel file
    XLSX.writeFile(wb, "Urusan.xlsx");
  };

  const downloadPDF = () => {
    const pdf = new jsPDF();
    pdf.setFontSize(12); // Adjust the font size as needed

    pdf.text("Urusan Data", 20, 20);

    dataChecked.forEach((item, index) => {
      const yPosition = 30 + index * 15; // Adjust the multiplier as needed
      const text = `${index + 1}. Kategori Urusan: ${
        item.kategori_urusan_ikk
      }\nUrusan: ${item.urusan}`;
      pdf.text(text, 20, yPosition);
    });

    pdf.save("IKK_Data.pdf");
  };

  return (
    <div>
      <div className="mb-4 grid grid-cols-12 gap-2  ">
        <div className="col-span-12 md:col-span-6 ">
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 300,
            }}
          >
            <IconButton sx={{ p: "10px" }} aria-label="menu">
              <MenuIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search..."
              inputProps={{ "aria-label": "search google maps" }}
              value={searchInput}
              onChange={handleSearchChange}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>

        <div className="col-span-12 md:col-span-6 flex justify-end">
          <Stack direction="row" spacing={1}>
            <Link to="/admin/ikk/add">
              <Button variant="contained">Tambah</Button>
            </Link>
            <Button
              variant="contained"
              endIcon={<PrintIcon />}
              disabled={!dataChecked.length}
            >
              Print
            </Button>
            <Button
              variant="contained"
              onClick={downloadPDF}
              endIcon={<PictureAsPdfIcon />}
              disabled={!dataChecked.length}
            >
              PDF
            </Button>
            <Button
              variant="contained"
              onClick={exportToExcel}
              disabled={!dataChecked.length}
            >
              Excel{" "}
              <img src={excel} alt="Excel Icon" className="ml-2 w-[20px]" />
            </Button>
          </Stack>
        </div>
      </div>
      <table className="w-[93.5vw]">
        <thead>
          <tr className="bg-gray">
            <th className="p-4">
              <input
                type="checkbox"
                onChange={(e) => handleCheckAll(e.target.checked)}
              />
            </th>
            <th className="p-4">No </th>
            <th className="p-4">Kategori Urusan</th>
            <th className="p-4">Urusan</th>
            <th className="p-4">Action</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => (
            <tr
              key={index}
              className={index % 2 === 0 ? "bg-light" : "bg-[#cae6ed]"}
            >
              <td className="p-4 text-center">
                <input
                  type="checkbox"
                  checked={isChecked(item.id)}
                  onChange={() => handleCheck(item.id)}
                />
              </td>
              <td className="p-4 text-center">{index + 1}</td>
              <td className="p-4 text-center">{item.kategori_urusan_ikk}</td>
              <td className="p-4 text-center">{item.urusan}</td>
              <td className="p-4 text-center">
                <div className="flex items-center justify-center">
                  <Link to={`/admin/ikk/urusan/edit/${item.id}`}>
                    <img src={edit} alt="" className="w-[30px]" />
                  </Link>

                  <img
                    src={deleteIcon}
                    alt=""
                    className="w-[30px]"
                    onClick={() => onDelete(item.id)}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="flex justify-center mt-4 pb-4">
        <button
          onClick={handlePrevClick}
          className={`mx-2 px-4 py-2 ${
            currentPage === 1 ? "bg-gray text-white rounded-full" : ""
          }`}
        >
          {"<"}
        </button>

        {[...Array(totalPages).keys()].map((number) => (
          <button
            key={number}
            onClick={() => paginate(number + 1)}
            className={`mx-2 px-4 py-2 ${
              currentPage === number + 1
                ? "bg-gray text-white rounded-full"
                : ""
            }`}
          >
            {number + 1}
          </button>
        ))}

        <button
          onClick={handleNextClick}
          className={`mx-2 px-4 py-2 ${
            currentPage === totalPages ? "bg-gray text-white rounded-full" : ""
          }`}
        >
          {">"}
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    addUrusan: state.addUrusan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUrusanList: () => dispatch(fetchUrusanList()),
    deleteUrusan: (id) => dispatch(deleteUrusan(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Urusan);
