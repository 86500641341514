import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ModalAddGallery from "./ModalAddGallery";
import Swal from "sweetalert2";

const Gallery = () => {
  const initialPhotos = [
    {
      url: "https://cdnwpedutorenews.gramedia.net/wp-content/uploads/2022/07/13193302/Contoh-budaya-budaya-Indonesia1-810x455.jpg",
      checked: false,
    },
    {
      url: "https://cdnwpedutorenews.gramedia.net/wp-content/uploads/2022/07/13193302/Contoh-budaya-budaya-Indonesia1-810x455.jpg",
      checked: false,
    },
    {
      url: "https://cdnwpedutorenews.gramedia.net/wp-content/uploads/2022/07/13193302/Contoh-budaya-budaya-Indonesia1-810x455.jpg",
      checked: false,
    },
    // Add more photo objects as needed
  ];
  const Swal = require("sweetalert2");
  const [setting, setSetting] = useState(false);
  const [photos, setPhotos] = useState(initialPhotos);
  const [photoSelected, setPhotoSelected] = useState([...initialPhotos]); // Use a copy of initialPhotos

  // modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCheckboxChange = (index) => {
    const updatedPhotos = [...photoSelected];
    updatedPhotos[index].checked = !updatedPhotos[index].checked;
    setPhotoSelected(updatedPhotos);
  };

  const handleSetting = () => {
    setSetting(true);
  };

  const handleCancelSetting = () => {
    setSetting(false);
  };

  const handleCheckAll = () => {
    const updatedPhotos = photos.map((photo) => ({
      ...photo,
      checked: true,
    }));
    setPhotoSelected(updatedPhotos);
  };

  const handleUncheckAll = () => {
    const updatedPhotos = photos.map((photo) => ({ ...photo, checked: false }));
    setPhotoSelected(updatedPhotos);
  };

  const onDelete = async (id) => {
    const confirmationResult = await Swal.fire({
      title: "Apakah anda ingin menghapus data ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    });
    if (confirmationResult.isConfirmed) {
      try {
        // const response = await axios.delete(
        //   `https://laravel-api-10.cerise.id/api/setting-generals/couriers/delete/${id}`,
        //   {
        //     headers: {
        //       Authorization: `Bearer ${localStorage.getItem("token")}`,
        //     },
        //   }
        // );
        Swal.fire({
          title: "Success!",
          text: "Delete data categori berhasil ",
          confirmButtonColor: "#3085d6",
          icon: "success",
          confirmButtonText: "OK",
        });
      } catch (error) {
        Swal.fire({
          title: "Error!",
          text: ` Data User Tidak Dapat Dihapus ${error}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    }
  };

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12">
        <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={openModal}>
            Tambah
          </Button>
          {setting === true ? (
            <div className="flex">
              <div>
                <Button
                  variant="contained"
                  onClick={() => onDelete()}
                  disabled={photoSelected.length === 0}
                >
                  Hapus
                </Button>
              </div>
              <div className="pl-4">
                <Button variant="contained" onClick={handleCancelSetting}>
                  Batal
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <Button variant="contained" onClick={handleSetting}>
                Atur
              </Button>
            </div>
          )}
        </Stack>
      </div>
      {photos.map((photo, index) => (
        <div key={index} className="mb-4 col-span-12 md:col-span-3">
          <div>
            {setting === true ? (
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-indigo-600"
              />
            ) : (
              ""
            )}
            <img
              src={photo.url}
              alt={`Photo ${index + 1}`}
              className="w-[300px] h-auto rounded-lg shadow-md"
            />
          </div>
        </div>
      ))}

      <ModalAddGallery isOpen={isModalOpen} handleClose={closeModal} />
    </div>
  );
};

export default Gallery;
