import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { fetchUrusanList } from "../../../redux/urusan/UrusanAction";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Box } from "@mui/material";

const DropdownUrusan = (props) => {
  const Swal = require("sweetalert2");
  const navigate = useNavigate();
  const [urusan, setUrusan] = useState(1);

  useEffect(() => {
    props.fetchUrusanList();
  }, []);

  const data = props.addUrusan.data || [];
  console.log("data dropdown urusan", urusan);

  if (props.addUrusan.error === "Request failed with status code 401") {
    Swal.fire({
      title: "Error!",
      text: `${props.addUrusan.error}`,
      icon: "warning",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/admin/login");
      }
    });
  }

  return (
    <div>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { width: "30ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="filled-select-currency"
          select
          label="Select"
          value={urusan}
          onChange={(e) => {
            setUrusan(e.target.value);
            props.setUrusanSelect(e.target.value); // Call the prop function
          }}
          helperText="Pilih Urusan"
          variant="filled"
        >
          {data.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.kategori_urusan_ikk}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    addUrusan: state.addUrusan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUrusanList: () => dispatch(fetchUrusanList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DropdownUrusan);
