export const Text1 = (props) => {
  return (
    <div
      className="font-semibold text-[24px] leading-[25px] "
      style={{
        color: localStorage.getItem("color-navbar"),
      }}
    >
      <p>{props.text}</p>
    </div>
  );
};

export const Text2 = (props) => {
  return (
    <div
      className="font-semibold text-[16px] leading-[25px] text-darkgray "
      style={{
        color: localStorage.getItem("color-navbar"),
      }}
    >
      <p>{props.text}</p>
    </div>
  );
};

export const Text3 = (props) => {
  return (
    <div
      className="font-normal text-[16px] leading-[25px] "
      style={{
        color: localStorage.getItem("color-navbar"),
      }}
    >
      <p>{props.text}</p>
    </div>
  );
};

export const Text4 = (props) => {
  return (
    <div
      className="font-normal text-[16px] leading-[25px] text-white"
      style={{
        color: localStorage.getItem("color-navbar"),
      }}
    >
      <p>{props.text}</p>
    </div>
  );
};

export const Text5 = (props) => {
  return (
    <div
      className="font-normal text-[12px] leading-[25px] text-white"
      style={{
        color: localStorage.getItem("color-navbar"),
      }}
    >
      <p>{props.text}</p>
    </div>
  );
};

export const Text6 = (props) => {
  return (
    <div
      className="font-bold text-[12px] leading-[25px] text-darkgray"
      style={{
        color: localStorage.getItem("color-navbar"),
      }}
    >
      <p>{props.text}</p>
    </div>
  );
};
export const Text7 = (props) => {
  return (
    <div
      className="font-bold text-[12px] leading-[25px] text-gray"
      style={{
        color: localStorage.getItem("color-navbar"),
      }}
    >
      <p>{props.text}</p>
    </div>
  );
};

export const Text8 = (props) => {
  return (
    <div
      className="font-semibold text-[24px] leading-[25px] text-darkgray "
      style={{
        color: localStorage.getItem("color-navbar"),
      }}
    >
      <p>{props.text}</p>
    </div>
  );
};

export const Text9 = (props) => {
  return (
    <div
      className="font-semibold text-[16px] leading-[25px] text-light "
      style={{
        color: localStorage.getItem("color-navbar"),
      }}
    >
      <p>{props.text}</p>
    </div>
  );
};

export const Text10 = (props) => {
  return (
    <div
      className="font-normal text-[10px] leading-[25px] "
      style={{
        color: localStorage.getItem("color-navbar"),
      }}
    >
      <p>{props.text}</p>
    </div>
  );
};
