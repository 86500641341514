import React, { useRef, useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Navbar from "../components/main/Navbar";
import SliderImg from "../components/main/SliderImg";
import Footer from "../components/main/Footer";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import video1 from "../../src/assets/video/video.mp4";
import Video from "../components/main/Video";

const MainLayout = (props) => {
  const contentRef = useRef(null);
  const [isToggled, setIsToggled] = useState(true);
  const [navbarFixed, setNavbarFixed] = useState(false);
  const [scrollDirection, setScrollDirection] = useState("down");
  const [scroll, setScroll] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  useEffect(() => {
    const handleScroll = () => {
      let scrollThreshold;

      if (window.innerWidth < 768) {
        // Set a different threshold for small screens (e.g., mobile)
        scrollThreshold = contentRef.current.clientHeight * 0.25;
      } else if (window.innerWidth < 1024) {
        // Set a different threshold for medium screens
        scrollThreshold = contentRef.current.clientHeight * 0.5; // Adjust this value as needed
      } else {
        // Default threshold for larger screens
        scrollThreshold = contentRef.current.clientHeight * 0.92;
      }

      const currentScrollY = window.scrollY;

      if (currentScrollY > scrollThreshold) {
        setNavbarFixed(true);
      } else {
        setNavbarFixed(false);
      }

      // Determine scroll direction
      if (currentScrollY > scrollY) {
        setScrollDirection("down");
      } else {
        setScrollDirection("up");
      }

      if (currentScrollY >= 8) {
        setScroll(true);
      } else {
        setScroll(false);
      }

      scrollY = currentScrollY;
    };

    let scrollY = 0;

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [alignment, setAlignment] = React.useState("photo");
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <React.Fragment>
      <div>
        <div className={`fixed top-0 left-0 w-full  `}>
          {isToggled ? <SliderImg /> : <Video />}
        </div>
        <div
          ref={contentRef}
          className={`relative  min-h-screen  flex flex-col -mt-[600px] md:-mt-0`}
        >
          <div className="flex-grow"></div>
          <div
            className={navbarFixed ? "fixed top-0 left-0 w-full z-50" : "z-50"}
          >
            <Navbar />
          </div>
        </div>
        <div
          className={`fixed w-[100%] flex justify-center bottom-[5.5rem]  ${
            scroll === true ? `-z-10` : ""
          } `}
        >
          <div className=" relative md:w-[40%] flex justify-center  ">
            <button
              className={`p-1 bg-darkgray  w-[50px] rounded-full focus:outline-none ${
                isToggled ? "bg-gray-500 w-[50px]" : ""
              }`}
              onClick={handleToggle}
            >
              <div
                className={`w-5 h-5 bg-white rounded-full transition-transform ${
                  isToggled ? "transform translate-x-full" : ""
                }`}
              ></div>
            </button>
          </div>
        </div>

        <div
          className={`relative bg-white z-5 grid grid-cols-12 gap-4 p-12 h-auto`}
        >
          {props.children}
        </div>
        <div className="relative z-5">
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
};

export default MainLayout;
