import React, { useEffect, useState } from "react";
import MainLayoutAdmin from "../../../../layout/MainLayoutAdmin";
import { Text8 } from "../../../../mainComponent/Paragraph";
import PelaporanIKKOutputPengajuanClone from "../../../../componentsAdmin/masterIKK/pelaporanIKK/PelaporanIKKOutputPengajuanClone";

const PelaporanIKKOutputPengajuanCloneAdm = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);
  return (
    <MainLayoutAdmin>
      <div>
        <div className="pb-4  border-b border-darkgray mb-8 ">
          <Text8 text={`Clone IKK Output Admin Pengajuan ${currentYear}`} />
        </div>
        <PelaporanIKKOutputPengajuanClone />
      </div>
    </MainLayoutAdmin>
  );
};

export default PelaporanIKKOutputPengajuanCloneAdm;
