import React from "react";
import MainLayout from "../../../layout/MainLayout";
import DataWilayah from "../../../components/profile/DataWilayah";

const DataWilayahView = () => {
  return (
    <MainLayout>
      <div className="col-span-12">
        <DataWilayah />
      </div>
    </MainLayout>
  );
};

export default DataWilayahView;
