import axios from "axios";
import API_BASE_URL from "../config";
import Swal from "sweetalert2";
import {
  FETCH_PENDUDUK_FAILURE,
  FETCH_PENDUDUK_REQUEST,
  FETCH_PENDUDUK_SUCCESS,
} from "./ActionType";

const fetchPendudukRequest = () => {
  return {
    type: FETCH_PENDUDUK_REQUEST,
  };
};

const fetchPendudukSuccess = (data) => {
  return {
    type: FETCH_PENDUDUK_SUCCESS,
    payload: data,
  };
};

const fetchPendudukFailure = (err) => {
  return {
    type: FETCH_PENDUDUK_FAILURE,
    payload: err,
  };
};

export const fetchPendudukList = () => {
  return (dispatch) => {
    dispatch(fetchPendudukRequest());
    const token = sessionStorage.getItem("token");
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(`${API_BASE_URL}/api/custom-pages/data-penduduk`, axiosConfig)
      .then((res) => {
        let _list = res.data;
        dispatch(fetchPendudukSuccess(_list));
      })
      .catch((err) => {
        dispatch(fetchPendudukFailure(err.message));
        console.log("first eror ikk output", err.message);
        Swal.fire({
          title: "Error!",
          text: `${err.message}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      });
  };
};
