import React, { useEffect, useState } from "react";
import opet from "../../assets/default2.png";
import { Text1, Text6, Text7 } from "../../mainComponent/Paragraph";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { fetchOldArticle } from "../../redux/article/ArticleAction";

const DetailArtikel = (props) => {
  const { slug, id } = useParams();
  useEffect(() => {
    props.fetchOldArticle(slug, id);
  }, []);
  const data = props.articleList || "";
  if (!data || !data.data || !data.data.article) {
    return <div>Loading...</div>; // You can show a loading indicator or handle it in another way
  }

  console.log("params", data);

  return (
    <div>
      <div className="flex">
        <div className="mr-2">
          <HomeIcon />
        </div>
        <div className="mr-2">
          <Link to="/home">
            <Text6 text="Home" />
          </Link>
        </div>
        <div className="mr-2">
          <Text6 text="/" />
        </div>
        <div className="mr-2">
          <Link to="/article">
            <Text6 text="Artikel" />
          </Link>
        </div>
      </div>
      <div className="mt-4 col-span-12 ">
        <div>
          <Text1 text={`${data.data.article.title_page}`} />
        </div>
        <div className="flex mx-1">
          <div className="flex">
            <PersonIcon style={{ width: "16px" }} />
            <Text6 text={`${data.data.article.author}`} />
          </div>
          <div className="flex mx-1">
            <CalendarMonthIcon style={{ width: "16px" }} />
            <Text6 text={`${data.data.article.created_at}`} />
          </div>
        </div>
      </div>
      <div>
        <img
          src={`${data.data.article.imageThumbnailUrl}`}
          alt="gambar"
          className="rounded-md"
          onError={(e) => {
            e.target.src = opet; // Set default image on error
          }}
        />
        {data.data.article.galleries.map((item, index) => (
          <div className="grid grid-cols-12 gap-4 mt-4" key={index}>
            <div className="col-span-3">
              <img src={item.url} alt="gambar" className="rounded-md" />
            </div>
          </div>
        ))}
      </div>
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: data.data.article.content,
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    articleList: state.articleList.oldData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOldArticle: (slug, id) => dispatch(fetchOldArticle(slug, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailArtikel);
