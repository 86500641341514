import axios from "axios";
import API_BASE_URL from "../config";
import {
  CLONE_IKK_OUTPUT_FAILURE,
  CLONE_IKK_OUTPUT_SUCCESS,
  DELETE_IKK_OUTPUT_FAILURE,
  DELETE_IKK_OUTPUT_SUCCESS,
  FETCH_IKK_OUTPUT_FAILURE,
  FETCH_IKK_OUTPUT_REQUEST,
  FETCH_IKK_OUTPUT_SUCCESS,
  FETCH_OLD_IKK_OUTPUT_FAILURE,
  FETCH_OLD_IKK_OUTPUT_SUCCESS,
  UPDATE_IKK_OUTPUT_FAILURE,
  UPDATE_IKK_OUTPUT_SUCCESS,
} from "./ActionType";
import Swal from "sweetalert2";

export const saveIkkOutputSuccess = (data) => ({
  type: "ADD_IKK_OUTPUT_SUCCESS",
  payload: data,
});

export const saveIkkOutputFailure = (error) => ({
  type: "ADD_IKK_OUTPUT_FAILURE",
  payload: error,
});

const fetchIKKOutputRequest = () => {
  return {
    type: FETCH_IKK_OUTPUT_REQUEST,
  };
};

const fetchIKKOutputSuccess = (data) => {
  return {
    type: FETCH_IKK_OUTPUT_SUCCESS,
    payload: data,
  };
};

export const fetchOldIKKOutputSuccess = (data) => ({
  type: FETCH_OLD_IKK_OUTPUT_SUCCESS,
  payload: data,
});

export const fetchOldIKKOutputFailure = (error) => ({
  type: FETCH_OLD_IKK_OUTPUT_FAILURE,
  payload: error,
});

export const updateIKKOutputSuccess = (data) => ({
  type: UPDATE_IKK_OUTPUT_SUCCESS,
  payload: data,
});

export const updateIKKOutputFailure = (error) => ({
  type: UPDATE_IKK_OUTPUT_FAILURE,
  payload: error,
});

export const cloneIKKOutputSuccess = (data) => ({
  type: CLONE_IKK_OUTPUT_SUCCESS,
  payload: data,
});

export const cloneIKKOutputFailure = (error) => ({
  type: CLONE_IKK_OUTPUT_FAILURE,
  payload: error,
});
export const clearErrors = () => (dispatch) => {
  dispatch({ type: "CLEAR_ERRORS" });
};

const fetchIKKOutputFailure = (err) => {
  return {
    type: FETCH_IKK_OUTPUT_FAILURE,
    payload: err,
  };
};

export const fetchIKKOutputList = (year) => {
  return (dispatch) => {
    dispatch(fetchIKKOutputRequest(year));

    // Retrieve the token from session storage
    const token = sessionStorage.getItem("token");

    const role = sessionStorage.getItem("role");

    const apiEndpoint =
      role === "1"
        ? `/api/dashboard/ikk_outputs?${year}`
        : role === "2"
        ? `/api/dashboard/ikk-outputs/show?${year}`
        : "";

    if (!apiEndpoint) {
      // Handle invalid role
      dispatch(fetchIKKOutputFailure("Invalid role"));

      Swal.fire({
        icon: "warning",
        title: "Error!",
        text: "Invalid role access",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
      return;
    }

    // Set the token in the request headers
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${API_BASE_URL}${apiEndpoint}`, axiosConfig)
      .then((res) => {
        let _list = res.data;
        dispatch(fetchIKKOutputSuccess(_list));
      })
      .catch((err) => {
        dispatch(fetchIKKOutputFailure(err.message));
        console.log("first eror ikk output", err.message);
      });
  };
};

export const deleteIkkOutput = (id) => {
  return async (dispatch) => {
    try {
      const token = sessionStorage.getItem("token");

      const response = await axios.delete(
        `${API_BASE_URL}/api/dashboard/ikk_outputs/delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("first delete ikk", id);

      if (response.status === 200) {
        // Dispatch the DELETE_IKK_SUCCESS action
        dispatch({ type: DELETE_IKK_OUTPUT_SUCCESS });

        // Fetch the updated list after deletion
        dispatch(fetchIKKOutputList());

        // Optionally, dispatch other actions or handle success
      } else {
        // If the response status is not 200, dispatch the DELETE_IKK_FAILURE action
        dispatch({ type: DELETE_IKK_OUTPUT_FAILURE });

        // Optionally, dispatch other actions or handle failure
        console.error("Error deleting item. Status:", response.status);
      }
    } catch (error) {
      // Dispatch the DELETE_IKK_FAILURE action
      dispatch({ type: DELETE_IKK_OUTPUT_FAILURE });

      // Handle error, dispatch an error action, or show a notification
      console.error("Error deleting item:", error.message);
    }
  };
};

export const saveIKKOutput = (data) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");
    const response = await axios.post(
      `${API_BASE_URL}/api/dashboard/ikk_outputs/store`,
      {
        urusan_id: data.urusanSelect,
        no_ikk_outcome: data.noIkkOutcome,
        ikk_output_in_outcome: data.ikkOutputInOutcome,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // dispatch({ type: "ADD_URUSAN_SUCCESS", payload: response.data });
    dispatch(saveIkkOutputSuccess(response.data));
    Swal.fire({
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      icon: "success",
      title: "Success!",
      text: "Data has been saved successfully.",
    }).then(() => {
      window.location.href = "/admin/ikk/pelaporan-ikk-output";
    });
  } catch (error) {
    // dispatch({ type: "ADD_URUSAN_FAILURE", payload: error.message });
    dispatch(saveIkkOutputFailure(error.message));
    if (error.message === "Request failed with status code 401") {
      Swal.fire({
        title: "Error!",
        text: `${error.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: `eee  ${error.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });

      // console.log("err", error.message);
    }
  }
};

export const fetchOldIKKOutput = (id) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");

    const role = sessionStorage.getItem("role");

    const apiEndpoint =
      role === "1"
        ? `/api/dashboard/ikk_outputs/${id}`
        : role === "2"
        ? `/api/dashboard/ikk-outputs/show/${id}`
        : "";

    if (!apiEndpoint) {
      // Handle invalid role
      dispatch(fetchIKKOutputFailure("Invalid role"));

      Swal.fire({
        icon: "warning",
        title: "Error!",
        text: "Invalid role access",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
      return;
    }
    const response = await axios.get(`${API_BASE_URL}${apiEndpoint}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: FETCH_OLD_IKK_OUTPUT_SUCCESS,
      payload: response.data, // Assuming the API returns the old data
    });
  } catch (error) {
    dispatch({
      type: FETCH_OLD_IKK_OUTPUT_SUCCESS,
      payload: error.message,
    });
    if (error.message === "Request failed with status code 401") {
      Swal.fire({
        title: "Error!",
        text: `${error.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: `eee  ${error.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });

      // console.log("err", error.message);
    }
  }
};

export const updateIKKOutput = (id, IKKData) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");

    console.log("first", IKKData.urusanSelect);
    const response = await axios.put(
      `${API_BASE_URL}/api/dashboard/ikk_outputs/update/pemda/${id}`,
      {
        nilai_pemda: IKKData.nilai_pemda,
        desc_pemda: IKKData.desc_pemda,
        bukti_by_pemda: IKKData.selectedFile,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("Update IKK response:", response.data);
    // dispatch({ type: "ADD_URUSAN_SUCCESS", payload: response.data });
    dispatch(updateIKKOutputSuccess(response.data));

    Swal.fire({
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      icon: "success",
      title: "Success!",
      text: "Data has been saved successfully.",
    }).then(() => {
      window.location.href = "/admin/ikk/pelaporan-ikk-output/pengajuan";
    });
  } catch (err) {
    // dispatch({ type: "ADD_URUSAN_FAILURE", payload: error.message });
    dispatch(updateIKKOutputFailure(err.message));
    if (err.message === "Request failed with status code 401") {
      Swal.fire({
        title: "Error!",
        text: `${err.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: `${err.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  }
};

export const cloneIKKOutput = (id, IKKData) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");

    const response = await axios.post(
      `${API_BASE_URL}/api/dashboard/ikk-outputs/clone-master/${id}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log("Clone IKK response:", response.data.cloned.id);

    dispatch(cloneIKKOutputSuccess(response.data));

    Swal.fire({
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      icon: "success",
      title: "Success!",
      text: "Data has been saved successfully.",
    }).then(() => {
      window.location.href = `/admin/ikk/pelaporan-ikk-output/pengajuan/clone/${response.data.cloned.id}`;
    });
  } catch (err) {
    dispatch(cloneIKKOutputFailure(err.message));

    if (err.message === "Request failed with status code 401") {
      Swal.fire({
        title: "Error!",
        text: `${err.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: `${err.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  }
};
