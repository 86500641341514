import React, { useState } from "react";

const Add = ({ images, addImage }) => {
  const [newImageUrl, setNewImageUrl] = useState(
    "https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
  );
  const [selectedFile, setSelectedFile] = useState(null);

  const handleAddImage = () => {
    if (newImageUrl.trim() !== "") {
      addImage(newImageUrl);
      setNewImageUrl("");
    } else if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        addImage(event.target.result);
      };
      reader.readAsDataURL(selectedFile);
      setSelectedFile(null);
    }
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  return (
    <div className="flex flex-wrap justify-center bg-white">
      {images.map((imageUrl, index) => (
        <div key={index} className="m-4">
          <img
            src={imageUrl}
            alt={`Image ${index}`}
            className="rounded-md shadow-md"
          />
        </div>
      ))}
      <div className="m-4">
        <input
          type="text"
          placeholder=""
          value={newImageUrl}
          onChange={(e) => setNewImageUrl(e.target.value)}
          className="p-2 border rounded-md"
        />
        <input type="file" onChange={handleFileChange} className="ml-2" />
      </div>
    </div>
  );
};

function AddGallery() {
  const [images, setImages] = useState([
    "https://placekitten.com/300/200",
    "https://placekitten.com/300/201",
    "https://placekitten.com/300/202",
  ]);

  const addImage = (imageUrl) => {
    setImages([...images, imageUrl]);
  };

  return (
    <div className="border border-red-500 p-5">
      <header className="">
        <h1 className="text-4xl font-bold mb-8 text-center">LPPD Gallery</h1>
        <Add images={images} addImage={addImage} />
      </header>
    </div>
  );
}

export default AddGallery;
