import React, { useState } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";

const TextEditor = (props) => {
  const { text, handleChange } = props;
  const customStyle = {
    height: "300px", // Default height is 300px, change it as needed
  };

  return (
    <ReactQuill
      value={text}
      onChange={handleChange}
      style={customStyle}
      modules={{
        toolbar: [
          [{ header: [1, 2, 3, 4, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["link", "image", "video"],
          ["clean"],
        ],
      }}
      formats={[
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "link",
        "image",
        "video",
      ]}
    />
  );
};

export default TextEditor;
