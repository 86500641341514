import React, { useRef, useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import img1 from "../../assets/s1mu.webp";
import img2 from "../../assets/s2mu.webp";
import img3 from "../../assets/img.jpeg";

const images = [img1, img2, img3]; // Define an array of images

const SliderImg = () => {
  const carouselRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to change the current slide index
  const handleSelect = (index) => {
    setCurrentIndex(index);
  };

  // Auto-play logic
  useEffect(() => {
    const interval = setInterval(() => {
      const nextIndex = (currentIndex + 1) % images.length;
      handleSelect(nextIndex);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [currentIndex]);

  return (
    <div>
      <Carousel
        autoPlay={false} // We manage auto-play manually
        interval={3000}
        infiniteLoop={true}
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        selectedItem={currentIndex} // Set the currently selected item
        onChange={handleSelect} // Handle change in selection
        ref={carouselRef}
      >
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt="" className="w-full h-auto md:h-[830px]" />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default SliderImg;
