export const FETCH_IKK_MAKRO_REQUEST = "FETCH_IKK_MAKRO_REQUEST";
export const FETCH_IKK_MAKRO_SUCCESS = "FETCH_IKK_MAKRO_SUCCESS";
export const FETCH_IKK_MAKRO_FAILURE = "FETCH_IKK_MAKRO_FAILURE";

export const ADD_IKKMAKRO_SUCCESS = "ADD_IKKMAKRO_SUCCESS";
export const ADD_IKKMAKRO_FAILURE = "ADD_IKKMAKRO_FAILURE";

export const DELETE_IKK_MAKRO_SUCCESS = "DELETE_IKK_MAKRO_SUCCESS";
export const DELETE_IKK_MAKRO_FAILURE = "DELETE_IKK_MAKRO_FAILURE";

export const FETCH_OLD_IKK_MAKRO_SUCCESS = "FETCH_OLD_IKK_MAKRO_SUCCESS";
export const FETCH_OLD_IKK_MAKRO_FAILURE = "FETCH_OLD_IKK_MAKRO_FAILURE";
export const UPDATE_IKK_MAKRO_SUCCESS = "UPDATE_IKK_MAKRO_SUCCESS";
export const UPDATE_IKK_MAKRO_FAILURE = "UPDATE_IKK_MAKRO_FAILURE";
