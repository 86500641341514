import {
  DELETE_ARTICLE_FAILURE,
  DELETE_ARTICLE_SUCCESS,
  FETCH_ARTICLE_FAILURE,
  FETCH_ARTICLE_REQUEST,
  FETCH_ARTICLE_SUCCESS,
  FETCH_OLD_ARTICLE_FAILURE,
  FETCH_OLD_ARTICLE_SUCCESS,
  UPDATE_ARTICLE_FAILURE,
  UPDATE_ARTICLE_SUCCESS,
} from "./ActionType";
import axios from "axios";
import API_BASE_URL from "../config";
import Swal from "sweetalert2";

export const updateArticleSuccess = (data) => ({
  type: UPDATE_ARTICLE_SUCCESS,
  payload: data,
});

export const updateArticleFailure = (error) => ({
  type: UPDATE_ARTICLE_FAILURE,
  payload: error,
});

export const saveArticleSuccess = (data) => ({
  type: "ADD_ARTICLE_SUCCESS",
  payload: data,
});
export const saveArticleFailure = (error) => ({
  type: "ADD_ARTICLE_FAILURE",
  payload: error,
});

const fetchArticleRequest = () => {
  return {
    type: FETCH_ARTICLE_REQUEST,
  };
};

const fetchArticleSuccess = (data) => {
  return {
    type: FETCH_ARTICLE_SUCCESS,
    payload: data,
  };
};

const fetchArticleFailure = (err) => {
  return {
    type: FETCH_ARTICLE_FAILURE,
    payload: err,
  };
};

export const fetchArticleList = () => {
  return (dispatch) => {
    dispatch(fetchArticleRequest());

    // Retrieve the token from session storage
    const token = sessionStorage.getItem("token"); // Replace "your_token_key" with the actual key used to store the token

    // Set the token in the request headers
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(
        "https://api-be-xyz.tapemansel.co.id/api/dashboard/article",
        axiosConfig
      )
      .then((res) => {
        let _list = res.data;
        dispatch(fetchArticleSuccess(_list));
      })
      .catch((err) => {
        dispatch(fetchArticleFailure(err.message));
      });
  };
};

export const saveArticle = (IKKData) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");
    const response = await axios.post(
      `${API_BASE_URL}/api/dashboard/article/store`,
      {
        title_page: IKKData.title_page,
        sub_title: IKKData.sub_title,
        content: IKKData.content,
        category_id: IKKData.category_id,
        tags: IKKData.tags,
        images: IKKData.selectedFiles,
        parent_id: IKKData.parent_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(saveArticleSuccess(response.data));

    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "Data has been saved successfully.",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then(() => {
      window.location.href = "/admin/articleAdm";
    });
  } catch (error) {
    dispatch(saveArticleFailure(error.message));
    if (error.message === "Request failed with status code 401") {
      Swal.fire({
        title: "Error!",
        text: `${error.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: `${error.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  }
};

export const deleteArticle = (id) => {
  return async (dispatch) => {
    try {
      const token = sessionStorage.getItem("token");

      const response = await axios.delete(
        `${API_BASE_URL}/api/dashboard/article/delete/${id}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // Dispatch the DELETE_IKK_SUCCESS action
        dispatch({ type: DELETE_ARTICLE_SUCCESS });

        // Fetch the updated list after deletion
        dispatch(fetchArticleList());
        Swal.fire({
          title: "Success!",
          text: "Data Artikel Berhasil Dihapus ",
          confirmButtonColor: "#3085d6",
          icon: "success",
          confirmButtonText: "OK",
        });

        // Optionally, dispatch other actions or handle success
      } else {
        dispatch({ type: DELETE_ARTICLE_FAILURE });
        console.error("Error deleting item. Status:", response.status);
        Swal.fire({
          title: "Error!",
          text: `${response.status}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      dispatch({ type: DELETE_ARTICLE_FAILURE });
      console.error("Error deleting item:", error.message);
      if (error.message === "Request failed with status code 401") {
        Swal.fire({
          title: "Error!",
          text: `${error.message}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        }).then(() => {
          window.location.href = "/admin/login";
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: `${error.message}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    }
  };
};

export const fetchOldArticle = (slug, id) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");
    const response = await axios.get(
      `${API_BASE_URL}/api/dashboard/article/${slug}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: FETCH_OLD_ARTICLE_SUCCESS,
      payload: response.data, // Assuming the API returns the old data
    });
  } catch (error) {
    dispatch({
      type: FETCH_OLD_ARTICLE_FAILURE,
      payload: error.message,
    });
  }
};

export const updateArticle = (id, IKKData) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");

    console.log("first", IKKData.urusanSelect);
    const response = await axios.put(
      `${API_BASE_URL}/api/dashboard/article/update/${id}`,
      {
        title_page: IKKData.title_page,
        sub_title: IKKData.sub_title,
        content: IKKData.content,
        category_id: IKKData.category_id,
        tags: IKKData.tags,
        images: IKKData.selectedFiles,
        parent_id: IKKData.parent_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("Update Artikel response:", response.data);
    // dispatch({ type: "ADD_URUSAN_SUCCESS", payload: response.data });
    dispatch(updateArticleSuccess(response.data));

    Swal.fire({
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      icon: "success",
      title: "Success!",
      text: "Data has been saved successfully.",
    }).then(() => {
      window.location.href = "/admin/articleAdm";
    });
  } catch (err) {
    // dispatch({ type: "ADD_URUSAN_FAILURE", payload: error.message });
    dispatch(updateArticleFailure(err.message));
    if (err.message === "Request failed with status code 401") {
      Swal.fire({
        title: "Error!",
        text: `${err.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: `${err.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  }
};
