import React from "react";
import MainLayoutAdmin from "../../../layout/MainLayoutAdmin";
import { Text8 } from "../../../mainComponent/Paragraph";
import CustomPageEdit from "../../../componentsAdmin/settingGeneral/CustomPageEdit";

const CustomPageEditAdm = () => {
  return (
    <MainLayoutAdmin>
      <div>
        <div className="pb-4  border-b border-darkgray mb-8">
          <Text8 text="Edit Custom Page " />
        </div>
        <CustomPageEdit />
      </div>
    </MainLayoutAdmin>
  );
};

export default CustomPageEditAdm;
