import {
  DELETE_IKK_FAILURE,
  DELETE_IKK_SUCCESS,
  FETCH_IKK_FAILURE,
  FETCH_IKK_REQUEST,
  FETCH_IKK_SUCCESS,
  FETCH_OLD_IKK_FAILURE,
  FETCH_OLD_IKK_SUCCESS,
  UPDATE_IKK_FAILURE,
  UPDATE_IKK_SUCCESS,
} from "./ActionType";
import axios from "axios";
import API_BASE_URL from "../config";
import Swal from "sweetalert2";

export const updateIKKSuccess = (data) => ({
  type: UPDATE_IKK_SUCCESS,
  payload: data,
});

export const updateIKKFailure = (error) => ({
  type: UPDATE_IKK_FAILURE,
  payload: error,
});
export const saveIKKSuccess = (data) => ({
  type: "ADD_IKK_SUCCESS",
  payload: data,
});

export const saveIKKFailure = (error) => ({
  type: "ADD_IKK_FAILURE",
  payload: error,
});

const fetchIKKRequest = () => {
  return {
    type: FETCH_IKK_REQUEST,
  };
};

const fetchIKKSuccess = (data) => {
  return {
    type: FETCH_IKK_SUCCESS,
    payload: data,
  };
};

// In your action creator
export const fetchOldIKKSuccess = (data) => ({
  type: FETCH_OLD_IKK_SUCCESS,
  payload: data,
});

export const fetchOldIKKFailure = (error) => ({
  type: FETCH_OLD_IKK_FAILURE,
  payload: error,
});

const fetchIKKFailure = (err) => {
  return {
    type: FETCH_IKK_FAILURE,
    payload: err,
  };
};

export const fetchIKKList = (year) => {
  return (dispatch) => {
    dispatch(fetchIKKRequest(year));

    // Retrieve the token from session storage
    const token = sessionStorage.getItem("token");

    // Set the token in the request headers
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const role = sessionStorage.getItem("role");

    const apiEndpoint =
      role === "1"
        ? `/api/dashboard/ikk?year=${year}`
        : role === "2"
        ? `/api/dashboard/ikk?year=${year}`
        : "";

    if (!apiEndpoint) {
      // Handle invalid role
      dispatch(fetchIKKList("Invalid role"));

      Swal.fire({
        icon: "warning",
        title: "Error!",
        text: "Invalid role access",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
      return;
    }

    // {{uri}}/api/dashboard/ikk-outputs/show
    axios
      .get(`${API_BASE_URL}${apiEndpoint}`, axiosConfig)
      .then((res) => {
        let _list = res.data;
        dispatch(fetchIKKSuccess(_list));
      })
      .catch((err) => {
        dispatch(fetchIKKFailure(err.message));
        if (err.message === "Request failed with status code 401") {
          Swal.fire({
            title: "Error!",
            text: `${err.message}`,
            icon: "warning",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then(() => {
            window.location.href = "/admin/login";
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: `${err.message}`,
            icon: "warning",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          });
        }
      });
  };
};

export const saveIKK = (IKKData) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");
    const response = await axios.post(
      `${API_BASE_URL}/api/dashboard/ikk/create`,
      {
        no_ikk: IKKData.noIKK,
        ikk_output: IKKData.IKKOutput,
        ikk_outcome: IKKData.IKKOutcome,
        rumus: IKKData.rumus,
        capaian: IKKData.capaian,
        keterangan: IKKData.description,
        var_rumus1: IKKData.rumus1,
        var_rumus2: IKKData.rumus2,
        nilai_rumus1: IKKData.nilaiRumus1,
        nilai_rumus2: IKKData.nilaiRumus2,
        updatePemda: IKKData.updatePemda,
        bukti: IKKData.selectedFile,
        tahun: IKKData.tahun,
        status_apip: IKKData.statusAPIP,
        keterangan_apip: IKKData.keteranganAPIP,
        catatan_rekomendasi: IKKData.catatanRekomendasi,
        definisi_operasional: IKKData.definisiOperasional,
        urusan_id: IKKData.urusanSelect,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(saveIKKSuccess(response.data));

    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "Data has been saved successfully.",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then(() => {
      window.location.href = "/admin/ikk";
    });
  } catch (error) {
    dispatch(saveIKKFailure(error.message));
    if (error.message === "Request failed with status code 401") {
      Swal.fire({
        title: "Error!",
        text: `${error.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: `${error.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  }
};

export const clearErrors = () => (dispatch) => {
  dispatch({ type: "CLEAR_ERRORS" });
};

export const deleteIkk = (id) => {
  return async (dispatch) => {
    try {
      const token = sessionStorage.getItem("token");

      const response = await axios.delete(
        `${API_BASE_URL}/api/dashboard/ikk/delete/${id}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // Dispatch the DELETE_IKK_SUCCESS action
        dispatch({ type: DELETE_IKK_SUCCESS });

        // Fetch the updated list after deletion
        dispatch(fetchIKKList());
        Swal.fire({
          title: "Success!",
          text: "Data IKK Berhasil Dihapus ",
          confirmButtonColor: "#3085d6",
          icon: "success",
          confirmButtonText: "OK",
        });

        // Optionally, dispatch other actions or handle success
      } else {
        dispatch({ type: DELETE_IKK_FAILURE });
        console.error("Error deleting item. Status:", response.status);
        Swal.fire({
          title: "Error!",
          text: `${response.status}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      dispatch({ type: DELETE_IKK_FAILURE });
      console.error("Error deleting item:", error.message);
      if (error.message === "Request failed with status code 401") {
        Swal.fire({
          title: "Error!",
          text: `${error.message}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        }).then(() => {
          window.location.href = "/admin/login";
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: `${error.message}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    }
  };
};

// Action to fetch old data
export const fetchOldIKK = (id) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");
    const response = await axios.get(
      `${API_BASE_URL}/api/dashboard/ikk/show/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: FETCH_OLD_IKK_SUCCESS,
      payload: response.data, // Assuming the API returns the old data
    });
  } catch (error) {
    dispatch({
      type: FETCH_OLD_IKK_FAILURE,
      payload: error.message,
    });
  }
};

export const updateIKK = (id, IKKData) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");

    console.log("first", IKKData.urusanSelect);
    const response = await axios.put(
      `${API_BASE_URL}/api/dashboard/ikk/update/${id}`,
      {
        no_ikk: IKKData.noIKK,
        ikk_output: IKKData.IKKOutput,
        ikk_outcome: IKKData.IKKOutcome,
        rumus: IKKData.rumus,
        capaian: IKKData.capaian,
        keterangan: IKKData.keterangan,
        var_rumus1: IKKData.var_rumus1,
        var_rumus2: IKKData.var_rumus2,
        nilai_rumus1: IKKData.rumus1,
        nilai_rumus2: IKKData.rumus2,
        updatePemda: IKKData.updatePemda,
        tahun: IKKData.tahun,
        urusan_id: IKKData.urusanSelect,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("Update IKK response:", response.data);
    // dispatch({ type: "ADD_URUSAN_SUCCESS", payload: response.data });
    dispatch(updateIKKSuccess(response.data));

    Swal.fire({
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      icon: "success",
      title: "Success!",
      text: "Data has been saved successfully.",
    }).then(() => {
      window.location.href = "/admin/ikk";
    });
  } catch (err) {
    // dispatch({ type: "ADD_URUSAN_FAILURE", payload: error.message });
    dispatch(updateIKKFailure(err.message));
    if (err.message === "Request failed with status code 401") {
      Swal.fire({
        title: "Error!",
        text: `${err.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: `${err.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  }
};
