import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchOldIKKOutput,
  updateIKKOutput,
} from "../../../redux/ikkOutput/IkkOutputAction";
import { fetchUrusanList } from "../../../redux/urusan/UrusanAction";
import DropdownUrusan from "../urusan/dropdownUrusan";
const EditPelaporanOutput = ({
  updateIKKOutput,
  fetchOldIKKOutput,
  oldIKKOutputData,
  props,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [urusanSelect, setUrusanSelect] = useState("");
  const [no_ikk_outcome, setNo_ikk_outcome] = useState("");
  const [ikk_output_in_outcome, setIkk_output_in_outcome] = useState("");

  useEffect(() => {
    fetchOldIKKOutput(id);
  }, [fetchOldIKKOutput]);

  useEffect(() => {
    if (oldIKKOutputData && oldIKKOutputData.data) {
      setUrusanSelect(oldIKKOutputData.data.urusan_id || "");
      setNo_ikk_outcome(oldIKKOutputData.data.no_ikk_outcome || "");
      setIkk_output_in_outcome(
        oldIKKOutputData.data.ikk_output_in_outcome || ""
      );
    }

    // console.log("mapp", oldIKKOutputData?.data);
  }, [oldIKKOutputData]);

  const handleUpdate = () => {
    const updatedIKKData = {
      urusanSelect,
      no_ikk_outcome,
      ikk_output_in_outcome,
    };

    // Assuming you have the ID of the IKK item to update (hardcoded as 2 in this case)
    updateIKKOutput(id, updatedIKKData);
  };

  const onCancel = async (id) => {};

  const isDataEmpty = () => {
    // Check if all input fields are empty
    return !urusanSelect || !no_ikk_outcome || !ikk_output_in_outcome;
  };
  return (
    <div className="pl-8 overflow-hidden max-w-screen">
      <Box
        component="form"
        sx={{
          "& > :not(style)": { pb: 2, pr: 2, width: "30ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <DropdownUrusan setUrusanSelect={setUrusanSelect} />
        <TextField
          id="filled-basic"
          label="No IKK Outcome"
          variant="filled"
          value={no_ikk_outcome}
          onChange={(e) => setNo_ikk_outcome(e.target.value)}
        />
        <TextField
          id="filled-basic"
          label="IKK Output in Outcome"
          variant="filled"
          value={ikk_output_in_outcome}
          onChange={(e) => setIkk_output_in_outcome(e.target.value)}
        />
      </Box>

      <div className="w-[200px] flex justify-between">
        <Button
          variant="contained"
          onClick={handleUpdate}
          disabled={isDataEmpty()}
        >
          Simpan
        </Button>

        <Button variant="contained" color="error" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const mappedProps = {
    oldIKKOutputData: state.IkkOutputList.oldIKKOutputData,
  };

  console.log("Mapped Props:", mappedProps);

  return mappedProps;
};

export default connect(mapStateToProps, {
  fetchUrusanList,
  fetchOldIKKOutput,
  updateIKKOutput,
})(EditPelaporanOutput);
