import React from "react";
import Artikel from "../../components/home/Artikel";
import Agenda from "../../components/home/Agenda";
import Berita from "../../components/home/Berita";
import MainLayout from "../../layout/MainLayout";
import GprWidget from "../../components/home/GprWidget";

const HomeView = () => {
  return (
    <MainLayout>
      <div className=" md:col-span-5 col-span-12  pt-2">
        <Artikel />
      </div>
      <div className=" md:col-span-4 col-span-12">
        <Agenda />
      </div>
      <div className=" md:col-span-3 col-span-12 ">
        <GprWidget />
      </div>
      <div className="col-span-12 md:-mt-[100px] ">
        <Berita />
      </div>
    </MainLayout>
  );
};

export default HomeView;
