import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  updateIKKUserPengajuanAPIP,
  fetchOldIKKUserPengajuan,
} from "../../../redux/ikkUserPengajuan/IkkUserPengajuanAction";

const EditUserPengajuanIKKApip = ({
  updateIKKUserPengajuanAPIP,
  fetchOldIKKUserPengajuan,
  IkkUserPengajuanList,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [status_apip, setStatus_apip] = useState("");
  const [keterangan_apip, setKeterangan_apip] = useState("");

  useEffect(() => {
    fetchOldIKKUserPengajuan(id);
  }, [fetchOldIKKUserPengajuan]);

  useEffect(() => {
    if (IkkUserPengajuanList) {
      setStatus_apip(IkkUserPengajuanList.catatan_rekomendasi || "");
      setKeterangan_apip(IkkUserPengajuanList.catatan_rekomendasi || "");
    }

    // console.log("mapp", oldIKKOutputData?.data);
  }, [IkkUserPengajuanList]);

  const isDataEmpty = () => {
    // Check if all input fields are empty
    return !setKeterangan_apip || !setStatus_apip;
  };

  const handleUpdate = () => {
    const updatedIKKMakroData = {
      status_apip,
      keterangan_apip,
    };

    // Assuming you have the ID of the IKK item to update (hardcoded as 2 in this case)
    updateIKKUserPengajuanAPIP(id, updatedIKKMakroData);
  };

  const onCancel = async () => {
    const confirmationResult = await Swal.fire({
      title: "Apakah anda ingin membatalkan aktivitas?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    });
    if (confirmationResult.isConfirmed) {
      navigate("admin/ikk/pelaporan-ikk-makro");
    }
  };

  return (
    <div className="pl-8 overflow-hidden max-w-screen">
      <Box
        component="form"
        sx={{
          "& > :not(style)": { pb: 2, pr: 2, width: "30ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="filled-basic"
          label="Keterangan APIP "
          variant="filled"
          value={keterangan_apip}
          onChange={(e) => setKeterangan_apip(e.target.value)}
        />
        <TextField
          id="filled-basic"
          label="Status APIP"
          variant="filled"
          value={status_apip}
          onChange={(e) => setStatus_apip(e.target.value)}
        />
      </Box>

      <div className="w-[200px] flex justify-between">
        <Button
          variant="contained"
          onClick={handleUpdate}
          disabled={isDataEmpty()}
        >
          Simpan
        </Button>

        <Button variant="contained" color="error" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const mappedProps = {
    IkkUserPengajuanList: state.IkkUserPengajuanList.oldIKKUserPengajuanData,
  };

  console.log("Mapped Props:", mappedProps);

  return mappedProps;
};

export default connect(mapStateToProps, {
  fetchOldIKKUserPengajuan,
  updateIKKUserPengajuanAPIP,
})(EditUserPengajuanIKKApip);
