import {
  FETCH_DETAIL_IKK_USER_PENGAJUAN_FAILURE,
  FETCH_DETAIL_IKK_USER_PENGAJUAN_REQUEST,
  FETCH_DETAIL_IKK_USER_PENGAJUAN_SUCCESS,
  FETCH_IKK_USER_PENGAJUAN_FAILURE,
  FETCH_IKK_USER_PENGAJUAN_REQUEST,
  FETCH_IKK_USER_PENGAJUAN_SUCCESS,
  FETCH_OLD_IKK_USER_PENGAJUAN_FAILURE,
  FETCH_OLD_IKK_USER_PENGAJUAN_SUCCESS,
  UPDATE_IKK_USER_PENGAJUAN_FAILURE,
  UPDATE_IKK_USER_PENGAJUAN_SUCCESS,
} from "./ActionType";
import axios from "axios";
import API_BASE_URL from "../config";
import Swal from "sweetalert2";

export const updateIKKUserPengajuanSuccess = (data) => ({
  type: UPDATE_IKK_USER_PENGAJUAN_SUCCESS,
  payload: data,
});

export const updateIKKUserPengajuanFailure = (error) => ({
  type: UPDATE_IKK_USER_PENGAJUAN_FAILURE,
  payload: error,
});

const fetchIKKUserPengajuanRequest = () => {
  return {
    type: FETCH_IKK_USER_PENGAJUAN_REQUEST,
  };
};

const fetchIKKUserPengajuanSuccess = (data) => {
  return {
    type: FETCH_IKK_USER_PENGAJUAN_SUCCESS,
    payload: data,
  };
};

const fetchIKKUserPengajuanFailure = (err) => {
  return {
    type: FETCH_IKK_USER_PENGAJUAN_FAILURE,
    payload: err,
  };
};

const fetchDetailIKKUserPengajuanRequest = () => {
  return {
    type: FETCH_DETAIL_IKK_USER_PENGAJUAN_REQUEST,
  };
};

const fetchDetailIKKUserPengajuanSuccess = (data) => {
  return {
    type: FETCH_DETAIL_IKK_USER_PENGAJUAN_SUCCESS,
    payload: data,
  };
};

const fetchDerailiIKKUserPengajuanFailure = (err) => {
  return {
    type: FETCH_DETAIL_IKK_USER_PENGAJUAN_FAILURE,
    payload: err,
  };
};

export const fetchIKKUserPengajuanList = (year) => {
  return (dispatch) => {
    dispatch(fetchIKKUserPengajuanRequest(year));

    // Retrieve the token from session storage
    const token = sessionStorage.getItem("token");
    const role = sessionStorage.getItem("role");

    const apiEndpoint =
      role === "3"
        ? `/api/dashboard/ikk/user-daerah/list?${year}`
        : role === "5"
        ? `/api/dashboard/ikk/user-daerah/apip/list?${year}`
        : "";

    if (!apiEndpoint) {
      // Handle invalid role
      dispatch(fetchIKKUserPengajuanFailure("Invalid role"));

      Swal.fire({
        icon: "warning",
        title: "Error!",
        text: "Invalid role access",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
      return;
    }

    // Set the token in the request headers
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${API_BASE_URL}${apiEndpoint}`, axiosConfig)
      .then((res) => {
        let _list = res.data;
        dispatch(fetchIKKUserPengajuanSuccess(_list));
      })
      .catch((err) => {
        dispatch(fetchIKKUserPengajuanFailure(err.message));
        if (err.message === "Request failed with status code 401") {
          Swal.fire({
            title: "Error!",
            text: `${err.message}`,
            icon: "warning",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then(() => {
            window.location.href = "/admin/login";
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: `${err.message}`,
            icon: "warning",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          });
        }
      });
  };
};

export const fetchDetailIKKUserPengajuanList = (id, year) => {
  return (dispatch) => {
    dispatch(fetchDetailIKKUserPengajuanRequest());

    // Retrieve the token from session storage
    const token = sessionStorage.getItem("token");
    const role = sessionStorage.getItem("role");
    // console.log("rolenya ", role);
    // console.log("Received parameters:", id, year);

    const apiEndpoint =
      role === "3"
        ? `/api/dashboard/ikk/list/${id}?year=${year}`
        : role === "5"
        ? `/api/dashboard/ikk/list/apip/${id}?year=${year}`
        : "";

    if (!apiEndpoint) {
      dispatch(fetchDerailiIKKUserPengajuanFailure("Invalid role"));

      Swal.fire({
        icon: "warning",
        title: "Error!",
        text: "Invalid role access",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
      return;
    }

    // Set the token in the request headers
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${API_BASE_URL}${apiEndpoint}`, axiosConfig)
      .then((res) => {
        let _list = res.data;
        dispatch(fetchDetailIKKUserPengajuanSuccess(_list));
      })
      .catch((err) => {
        dispatch(fetchDerailiIKKUserPengajuanFailure(err.message));
        if (err.message === "Request failed with status code 401") {
          Swal.fire({
            title: "Error!",
            text: `${err.message}`,
            icon: "warning",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then(() => {
            window.location.href = "/admin/login";
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: `${err.message}`,
            icon: "warning",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          });
        }
      });
  };
};

// Action to fetch old data
export const fetchOldIKKUserPengajuan = (id) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");
    const role = sessionStorage.getItem("role");

    const apiEndpoint =
      role === "3"
        ? `/api/dashboard/ikk-details/admKonforimasi/${id}`
        : role === "5"
        ? `/api/dashboard/ikk-details/apip/${id}`
        : "";

    if (!apiEndpoint) {
      // Handle invalid role
      dispatch(fetchIKKUserPengajuanFailure("Invalid role"));

      Swal.fire({
        icon: "warning",
        title: "Error!",
        text: "Invalid role access",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
      return;
    }

    const response = await axios.get(`${API_BASE_URL}${apiEndpoint}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: FETCH_OLD_IKK_USER_PENGAJUAN_SUCCESS,
      payload: response.data, // Assuming the API returns the old data
    });
  } catch (error) {
    dispatch({
      type: FETCH_OLD_IKK_USER_PENGAJUAN_FAILURE,
      payload: error.message,
    });
  }
};

export const updateIKKUserPengajuan = (id, IKKData) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");

    console.log("first", IKKData.urusanSelect);
    const response = await axios.put(
      `${API_BASE_URL}/api/dashboard/ikk/konfirmasi/${id}`,
      {
        catatan_rekomendasi: IKKData.catatan_rekomendasi,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("Update IKK response:", response.data);
    // dispatch({ type: "ADD_URUSAN_SUCCESS", payload: response.data });
    dispatch(updateIKKUserPengajuanSuccess(response.data));

    Swal.fire({
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      icon: "success",
      title: "Success!",
      text: "Data has been saved successfully.",
    }).then(() => {
      window.location.href = "/admin/userpengajuan/ikk";
    });
  } catch (err) {
    // dispatch({ type: "ADD_URUSAN_FAILURE", payload: error.message });
    dispatch(updateIKKUserPengajuanFailure(err.message));
    if (err.message === "Request failed with status code 401") {
      Swal.fire({
        title: "Error!",
        text: `${err.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: `${err.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  }
};

export const updateIKKUserPengajuanAPIP = (id, IKKData) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");

    console.log("first", IKKData.urusanSelect);
    const response = await axios.put(
      `${API_BASE_URL}/api/dashboard/ikk/konfirmasi/apip/${id}`,
      {
        status_apip: IKKData.status_apip,
        keterangan_apip: IKKData.status_apip,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("Update IKK response:", response.data);
    // dispatch({ type: "ADD_URUSAN_SUCCESS", payload: response.data });
    dispatch(updateIKKUserPengajuanSuccess(response.data));

    Swal.fire({
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      icon: "success",
      title: "Success!",
      text: "Data has been saved successfully.",
    }).then(() => {
      window.location.href = "/admin/userpengajuan/ikk";
    });
  } catch (err) {
    // dispatch({ type: "ADD_URUSAN_FAILURE", payload: error.message });
    dispatch(updateIKKUserPengajuanFailure(err.message));
    if (err.message === "Request failed with status code 401") {
      Swal.fire({
        title: "Error!",
        text: `${err.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: `${err.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  }
};
