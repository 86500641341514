export const FETCH_CAPAIAN_IKK_REQUEST = "FETCH_CAPAIAN_IKK_REQUEST";
export const FETCH_CAPAIAN_IKK_SUCCESS = "FETCH_CAPAIAN_IKK_SUCCESS";
export const FETCH_CAPAIAN_IKK_FAILURE = "FETCH_CAPAIAN_IKK_FAILURE";
export const REDIRECT_TO_LOGIN = "REDIRECT_TO_LOGIN";

export const DELETE_CAPAIAN_SUCCESS = "DELETE_CAPAIAN_SUCCESS";
export const DELETE_CAPAIAN_FAILURE = "DELETE_CAPAIAN_FAILURE";

export const FETCH_OLD_CAPAIAN_SUCCESS = "FETCH_OLD_CAPAIAN_SUCCESS";
export const FETCH_OLD_CAPAIAN_FAILURE = "FETCH_OLD_CAPAIAN_FAILURE";
export const UPDATE_CAPAIAN_SUCCESS = "UPDATE_CAPAIAN_SUCCESS";
export const UPDATE_CAPAIAN_FAILURE = "UPDATE_CAPAIAN_FAILURE";
