import React, { useEffect, useState } from "react";
import edit from "../../../assets/edit.png";
import deleteIcon from "../../../assets/delete.png";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { connect } from "react-redux";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PrintIcon from "@mui/icons-material/Print";
import excel from "../../../assets/excel.png";
import { TextField } from "@mui/material";
import detail from "../../../assets/search.png";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import { fetchIKKMakroPengajuanList } from "../../../redux/ikkMakroUserPengajuan/IkkMakroPengajuanAction";

const IkkMakro = (props) => {
  const Swal = require("sweetalert2");
  const itemsPerPage = 5; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const [checkedItems, setCheckedItems] = useState({});
  const role = sessionStorage.getItem("role");
  const [searchInput, setSearchInput] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    props.fetchIKKMakroPengajuanList();
  }, []);

  useEffect(() => {
    setData(props.IkkMakroPengajuan.data || []);
  }, [props.IkkMakroPengajuan]);

  const handleSearchChange = (event) => {
    const input = event.target.value.toLowerCase();
    setSearchInput(input);

    // If searchInput is null or empty, reset data to the original list
    if (input === null || input.trim() === "") {
      setData(props.IkkMakroPengajuan.data || []);
    } else {
      // Otherwise, update the data state with filtered items
      const filteredData = data.filter(
        (item) =>
          item.name.toLowerCase().includes(input) ||
          item.email.toLowerCase().includes(input)
      );
      setData(filteredData);
    }

    setCurrentPage(1); // Reset current page to 1 when search changes
  };
  // console.log("IKK pengajuan", props.IkkMakroPengajuan.data);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handlePrevClick = () => {
    paginate(currentPage - 1);
  };

  const handleNextClick = () => {
    paginate(currentPage + 1);
  };

  const onDelete = async (id) => {
    const confirmationResult = await Swal.fire({
      title: "Apakah anda ingin menghapus data ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    });
    if (confirmationResult.isConfirmed) {
      try {
        Swal.fire({
          title: "Success!",
          text: "Delete data categori berhasil ",
          confirmButtonColor: "#3085d6",
          icon: "success",
          confirmButtonText: "OK",
        });
      } catch (error) {
        Swal.fire({
          title: "Error!",
          text: ` Data User Tidak Dapat Dihapus ${error}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const handleCheck = (id) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [id]: !prevCheckedItems[id],
    }));
  };

  const handleCheckAll = (isCheckedAll) => {
    const newCheckedItems = {};
    data.forEach((item) => {
      newCheckedItems[item.id] = isCheckedAll;
    });
    setCheckedItems(newCheckedItems);
  };

  const isChecked = (id) => {
    return checkedItems[id] || false;
  };

  return (
    <div>
      <div className="mb-4 grid grid-cols-12 gap-2">
        <div className="col-span-12 md:col-span-6 ">
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 300,
            }}
          >
            <IconButton sx={{ p: "10px" }} aria-label="menu">
              <MenuIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search..."
              inputProps={{ "aria-label": "search google maps" }}
              value={searchInput}
              onChange={handleSearchChange}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>
        <div className="col-span-10  md:col-span-6 flex justify-end">
          <Stack direction="row" spacing={2}>
            <Link to="/admin/ikk/add">
              <Button variant="contained">Tambah</Button>
            </Link>
            <Link to="">
              <Button variant="contained" endIcon={<PrintIcon />}>
                Print
              </Button>
            </Link>
            <Link to="">
              <Button variant="contained" endIcon={<PictureAsPdfIcon />}>
                PDF
              </Button>
            </Link>
            <Link to="">
              <Button variant="contained">
                Excel
                <img src={excel} alt="Excel Icon" className="ml-2 w-[20px]" />
              </Button>
            </Link>
          </Stack>
        </div>
      </div>
      <table className="w-[93.5vw] ">
        <thead>
          <tr className="bg-gray">
            <th className="p-4">
              <input
                type="checkbox"
                onChange={(e) => handleCheckAll(e.target.checked)}
              />
            </th>
            <th className="p-4">NO </th>
            <th className="p-4">Nama</th>
            <th className="p-4">Email</th>
            <th className="p-4">Action</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => (
            <tr
              key={index}
              className={index % 2 === 0 ? "bg-light" : "bg-[#cae6ed]"}
            >
              <td className="p-4 text-center">
                <input
                  type="checkbox"
                  checked={isChecked(item.id)}
                  onChange={() => handleCheck(item.id)}
                />
              </td>
              <td className="p-4 text-center">{index + 1}</td>
              <td className="p-4 text-center">{item.name}</td>
              <td className="p-4 text-center">{item.email}</td>

              <td className="p-4 text-center">
                <div className="flex items-center justify-center">
                  <Link
                    to={`/admin/userpengajuan/ikk-makro-pengajuan-daerah/detail/${item.id}`}
                  >
                    <img src={detail} alt="" className="w-[30px]" />
                  </Link>

                  <img
                    src={deleteIcon}
                    alt=""
                    className="w-[30px]"
                    onClick={() => onDelete(item.id)}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      {/* Pagination */}
      <div className="flex justify-center mt-4 pb-4">
        <button
          onClick={handlePrevClick}
          className={`mx-2 px-4 py-2 ${
            currentPage === 1 ? "bg-gray text-white rounded-full" : ""
          }`}
        >
          {"<"}
        </button>

        {[...Array(totalPages).keys()].map((number) => (
          <button
            key={number}
            onClick={() => paginate(number + 1)}
            className={`mx-2 px-4 py-2 ${
              currentPage === number + 1
                ? "bg-gray text-white rounded-full"
                : ""
            }`}
          >
            {number + 1}
          </button>
        ))}

        <button
          onClick={handleNextClick}
          className={`mx-2 px-4 py-2 ${
            currentPage === totalPages ? "bg-gray text-white rounded-full" : ""
          }`}
        >
          {">"}
        </button>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    IkkMakroPengajuan: state.IkkMakroPengajuan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchIKKMakroPengajuanList: () => dispatch(fetchIKKMakroPengajuanList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IkkMakro);
