import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import {
  fetchOldIKKMakroPengajuan,
  updateIKKMakroPengajuanAPIP,
} from "../../../redux/ikkMakroUserPengajuan/IkkMakroPengajuanAction";

const EditIkkMakroAPIP = ({
  fetchOldIKKMakroPengajuan,
  updateIKKMakroPengajuanAPIP,
  oldData,
}) => {
  const { id } = useParams();
  const [ikk, setIkk] = useState("");
  const [satuan, setSatuan] = useState("");
  const [diperbarui_pemda, setDiperbaruiPemda] = useState("");
  const [diperbarui_apip, setDiperbaruiAPIP] = useState("");
  const [status_apip, setStatusAPIP] = useState("");
  const [selectedFile, setSelectedFile] = useState();

  useEffect(() => {
    fetchOldIKKMakroPengajuan(id);
  }, [fetchOldIKKMakroPengajuan]);

  useEffect(() => {
    if (oldData) {
      setIkk(oldData.no_ikk);
      setSatuan(oldData.ikk_output);
    }
  }, [oldData]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];

      if (allowedTypes.includes(file.type)) {
        setSelectedFile(file);
      } else {
        Swal.fire({
          title: "Invalid File Type!",
          text: "Please select a valid file type (doc, pdf, xlsx, docx).",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const handleUpdate = () => {
    const updatedIKKData = {
      ikk,
      satuan,
      diperbarui_pemda,
      diperbarui_apip,
      status_apip,
      selectedFile,
    };

    // Assuming you have the ID of the IKK item to update (hardcoded as 2 in this case)
    updateIKKMakroPengajuanAPIP(id, updatedIKKData);
  };

  const onCancel = async (id) => {};

  return (
    <div className="pl-4 overflow-hidden max-w-screen  ">
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 2, width: "30ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="filled-basic"
          label="IKK"
          variant="filled"
          value={ikk}
          onChange={(e) => setIkk(e.target.value)}
        />
        <TextField
          id="filled-basic"
          label="Satuan"
          variant="filled"
          value={satuan}
          onChange={(e) => setSatuan(e.target.value)}
        />

        <TextField
          id="diperbarui-apip"
          label="Diperbarui Pemda"
          variant="filled"
          type="date" // Set the input type to date
          value={diperbarui_pemda}
          onChange={(e) => setDiperbaruiPemda(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="diperbarui-apip"
          label="Diperbarui APIP"
          variant="filled"
          type="date" // Set the input type to date
          value={diperbarui_apip}
          onChange={(e) => setDiperbaruiAPIP(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="filled-basic"
          label="Diperbarui APIP"
          variant="filled"
          value={status_apip}
          onChange={(e) => setStatusAPIP(e.target.value)}
        />
        <div>
          <input type="file" onChange={handleFileChange} className="ml-2" />
        </div>
      </Box>

      <div className="w-[200px]  flex justify-between pl-4">
        <Button
          variant="contained"
          onClick={handleUpdate}
          disabled={
            !ikk ||
            !satuan ||
            !selectedFile ||
            !diperbarui_pemda ||
            !diperbarui_apip ||
            !status_apip
          }
        >
          Simpan
        </Button>

        <Button variant="contained" color="error" onClick={() => onCancel()}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const mappedProps = {
    // Existing mapStateToProps...
    oldData: state.IkkMakroPengajuan.oldData,
  };

  console.log("Mapped Props:", mappedProps); // Add this line to log the result

  return mappedProps;
};

export default connect(mapStateToProps, {
  fetchOldIKKMakroPengajuan,
  updateIKKMakroPengajuanAPIP,
})(EditIkkMakroAPIP);
