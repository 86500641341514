import React from "react";
import MainLayoutAdmin from "../../../layout/MainLayoutAdmin";
import { Text8 } from "../../../mainComponent/Paragraph";
import EditManagement from "../../../componentsAdmin/masterManagement/EditManagement";

const EditManagementUserAdm = () => {
  return (
    <MainLayoutAdmin>
      <div>
        <div className="pb-4 ml-8 border-b border-darkgray mb-8">
          <Text8 text="Edit Management User" />
        </div>
        <EditManagement />
      </div>
    </MainLayoutAdmin>
  );
};

export default EditManagementUserAdm;
