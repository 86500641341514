// import { ADD_URUSAN_FAILURE, ADD_URUSAN_SUCCESS } from "./ActionType";
import axios from "axios";
import API_BASE_URL from "../config";
import Swal from "sweetalert2";
import {
  DELETE_URUSAN_FAILURE,
  DELETE_URUSAN_SUCCESS,
  FETCH_OLD_URUSAN_FAILURE,
  FETCH_OLD_URUSAN_SUCCESS,
  FETCH_URUSAN_FAILURE,
  FETCH_URUSAN_REQUEST,
  FETCH_URUSAN_SUCCESS,
} from "./ActionType";

export const updateUrusanSuccess = (data) => ({
  type: DELETE_URUSAN_SUCCESS,
  payload: data,
});

export const updateUrusanFailure = (error) => ({
  type: DELETE_URUSAN_FAILURE,
  payload: error,
});

export const saveUrusanSuccess = (data) => ({
  type: "ADD_URUSAN_SUCCESS",
  payload: data,
});

export const saveUrusanFailure = (error) => ({
  type: "ADD_URUSAN_FAILURE",
  payload: error,
});

const fetchUrusanRequest = () => {
  return {
    type: FETCH_URUSAN_REQUEST,
  };
};

const fetchUrusanSuccess = (data) => {
  return {
    type: FETCH_URUSAN_SUCCESS,
    payload: data,
  };
};

const fetchUrusanFailure = (err) => {
  return {
    type: FETCH_URUSAN_FAILURE,
    payload: err,
  };
};

export const fetchUrusanList = () => {
  return (dispatch) => {
    dispatch(fetchUrusanRequest());

    // Retrieve the token from session storage
    const token = sessionStorage.getItem("token");

    // Set the token in the request headers
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${API_BASE_URL}/api/dashboard/urusan`, axiosConfig)
      .then((res) => {
        let _list = res.data;
        dispatch(fetchUrusanSuccess(_list));
      })
      .catch((err) => {
        dispatch(fetchUrusanFailure(err.message));
        if (err.message === "Request failed with status code 401") {
          Swal.fire({
            title: "Error!",
            text: `${err.message}`,
            icon: "warning",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then(() => {
            window.location.href = "/admin/login";
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: `${err.message}`,
            icon: "warning",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          });
        }
      });
  };
};

export const saveUrusan = (urusanData) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");
    const response = await axios.post(
      `${API_BASE_URL}/api/dashboard/urusan/store`,
      {
        kategori_urusan_ikk: urusanData.kategoriUrusan,
        urusan: urusanData.urusan,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // dispatch({ type: "ADD_URUSAN_SUCCESS", payload: response.data });
    dispatch(saveUrusanSuccess(response.data));

    Swal.fire({
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      icon: "success",
      title: "Success!",
      text: "Data has been saved successfully.",
    });
  } catch (err) {
    // dispatch({ type: "ADD_URUSAN_FAILURE", payload: error.message });
    dispatch(saveUrusanFailure(err.message));
    if (err.message === "Request failed with status code 401") {
      Swal.fire({
        title: "Error!",
        text: `${err.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: `${err.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  }
};

export const clearErrors = () => (dispatch) => {
  dispatch({ type: "CLEAR_ERRORS" });
};

export const deleteUrusan = (id) => {
  return async (dispatch) => {
    try {
      const token = sessionStorage.getItem("token");

      const response = await axios.delete(
        `${API_BASE_URL}/api/dashboard/urusan/delete/${id}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("first delete ikk", id);

      if (response.status === 200) {
        // Dispatch the DELETE_IKK_SUCCESS action
        dispatch({ type: DELETE_URUSAN_SUCCESS });

        // Fetch the updated list after deletion
        dispatch(fetchUrusanList());
        Swal.fire({
          title: "Success!",
          text: "Data IKK Berhasil Dihapus ",
          confirmButtonColor: "#3085d6",
          icon: "success",
          confirmButtonText: "OK",
        });

        // Optionally, dispatch other actions or handle success
      } else {
        dispatch({ type: DELETE_URUSAN_FAILURE });
        console.error("Error deleting item. Status:", response.status);
        Swal.fire({
          title: "Error!",
          text: `${response.status}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      dispatch({ type: DELETE_URUSAN_FAILURE });
      console.error("Error deleting item:", error.message);
      if (error.message === "Request failed with status code 401") {
        Swal.fire({
          title: "Error!",
          text: `${error.message}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        }).then(() => {
          window.location.href = "/admin/login";
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: `${error.message}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    }
  };
};

export const fetchOldUrusan = (id) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");
    const response = await axios.get(
      `${API_BASE_URL}/api/dashboard/urusan/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: FETCH_OLD_URUSAN_SUCCESS,
      payload: response.data, // Assuming the API returns the old data
    });
  } catch (error) {
    dispatch({
      type: FETCH_OLD_URUSAN_FAILURE,
      payload: error.message,
    });
    if (error.message === "Request failed with status code 401") {
      Swal.fire({
        title: "Error!",
        text: `${error.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: `${error.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  }
};

export const updateUrusan = (id, IKKData) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");

    const response = await axios.put(
      `${API_BASE_URL}/api/dashboard/urusan/update/${id}`,
      {
        kategori_urusan_ikk: IKKData.kategoriUrusan,
        urusan: IKKData.urusan,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("Update IKK response:", response.data);
    dispatch(updateUrusanSuccess(response.data));

    Swal.fire({
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      icon: "success",
      title: "Success!",
      text: "Data has been saved successfully.",
    }).then(() => {
      window.location.href = "/admin/ikk/urusan";
    });
  } catch (err) {
    dispatch(updateUrusanFailure(err.message));
    if (err.message === "Request failed with status code 401") {
      Swal.fire({
        title: "Error!",
        text: `${err.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: `${err.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  }
};
