import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  updateIKKUserPengajuanReport,
  fetchOldIKKUserPengajuanReport,
} from "../../../redux/ikkUserPengajuanReport/IkkUserPengajuanAction";

const EditReportIKK = ({
  updateIKKUserPengajuanReport,
  fetchOldIKKUserPengajuanReport,
  IkkUserPengajuanReport,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  console.log("dattta", id);
  const [status_rekomendasi, setStatusRekomendasi] = useState("");
  const [keterangan_rekomendasi, setKeteranganRekomendasi] = useState("");

  useEffect(() => {
    fetchOldIKKUserPengajuanReport(id);
  }, [fetchOldIKKUserPengajuanReport]);

  useEffect(() => {
    if (IkkUserPengajuanReport) {
      setStatusRekomendasi(IkkUserPengajuanReport.data.status_rekomendasi);
      setKeteranganRekomendasi(
        IkkUserPengajuanReport.data.keterangan_rekomendasi
      );
    }

    console.log("map2", IkkUserPengajuanReport.data);
  }, [IkkUserPengajuanReport]);

  const handleUpdate = () => {
    const updateData = {
      status_rekomendasi,
      keterangan_rekomendasi,
    };

    // Assuming you have the ID of the IKK item to update (hardcoded as 2 in this case)
    updateIKKUserPengajuanReport(id, updateData);
  };

  const onCancel = async () => {
    const confirmationResult = await Swal.fire({
      title: "Apakah anda ingin membatalkan aktivitas?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    });
    if (confirmationResult.isConfirmed) {
      navigate("admin/ikk/pelaporan-ikk-makro");
    }
  };

  return (
    <div className="pl-8 overflow-hidden max-w-screen">
      <Box
        component="form"
        sx={{
          "& > :not(style)": { pb: 2, pr: 2, width: "30ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="filled-basic"
          label="Status Rekomendasi"
          variant="filled"
          value={status_rekomendasi}
          onChange={(e) => setStatusRekomendasi(e.target.value)}
        />
        <TextField
          id="filled-basic"
          label="Keterangan Rekomendasi"
          variant="filled"
          value={keterangan_rekomendasi}
          onChange={(e) => setKeteranganRekomendasi(e.target.value)}
        />
      </Box>

      <div className="w-[200px] flex justify-between">
        <Button
          variant="contained"
          onClick={handleUpdate}
          disabled={!status_rekomendasi || !keterangan_rekomendasi}
        >
          Simpan
        </Button>

        <Button variant="contained" color="error" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const mappedProps = {
    // Existing mapStateToProps...
    IkkUserPengajuanReport: state.IkkUserPengajuanReport.oldData,
  };

  console.log("Mapped Props:", mappedProps); // Add this line to log the result

  return mappedProps;
};

export default connect(mapStateToProps, {
  fetchOldIKKUserPengajuanReport,
  updateIKKUserPengajuanReport,
})(EditReportIKK);
