import {
  DELETE_IKK_MAKRO_FAILURE,
  DELETE_IKK_MAKRO_SUCCESS,
  FETCH_IKK_MAKRO_FAILURE,
  FETCH_IKK_MAKRO_REQUEST,
  FETCH_IKK_MAKRO_SUCCESS,
  FETCH_OLD_IKK_MAKRO_FAILURE,
  FETCH_OLD_IKK_MAKRO_SUCCESS,
  UPDATE_IKK_MAKRO_FAILURE,
  UPDATE_IKK_MAKRO_SUCCESS,
} from "./ActionType";

const initialstate = {
  loading: true,
  data: [],
  oldIKKMakroData: null,
  error: "",
  redirectToLogin: false,
};

export const IkkMakroReducer = (state = initialstate, action) => {
  switch (action.type) {
    case "ADD_IKKMAKRO_SUCCESS":
      return {
        ...state,
        data: action.payload,
        error: null,
        isUrusanAddedSuccessfully: true,
      };
    case "ADD_IKKMAKRO_FAILURE":
      return { ...state, error: action.payload };
    case "CLEAR_ERRORS":
      return { ...state, error: null };
    case FETCH_IKK_MAKRO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_IKK_MAKRO_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    case FETCH_IKK_MAKRO_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };
    case DELETE_IKK_MAKRO_SUCCESS:
      return {
        ...state,
        deleteIkkStatus: "success",
      };
    case DELETE_IKK_MAKRO_FAILURE:
      return {
        ...state,
        deleteIkkStatus: "failure",
      };
    case FETCH_OLD_IKK_MAKRO_SUCCESS:
      return {
        ...state,
        oldIKKMakroData: action.payload,
      };
    case FETCH_OLD_IKK_MAKRO_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_IKK_MAKRO_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
      };
    case UPDATE_IKK_MAKRO_FAILURE:
      return {
        ...state,
        data: [],
        error: action.payload || "An unknown error occurred",
      };
    default:
      return state;
  }
};
