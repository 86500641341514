import axios from "axios";
import API_BASE_URL from "../config";
import Swal from "sweetalert2";
import {
  FETCH_PERANGKAT_DAERAH_FAILURE,
  FETCH_PERANGKAT_DAERAH_REQUEST,
  FETCH_PERANGKAT_DAERAH_SUCCESS,
} from "./ActionType";

const fetchPerangkatDaerahRequest = () => {
  return {
    type: FETCH_PERANGKAT_DAERAH_REQUEST,
  };
};

const fetchPerangkatDaerahSuccess = (data) => {
  return {
    type: FETCH_PERANGKAT_DAERAH_SUCCESS,
    payload: data,
  };
};

const fetchPerangkatDaerahFailure = (err) => {
  return {
    type: FETCH_PERANGKAT_DAERAH_FAILURE,
    payload: err,
  };
};

export const fetchPerangkatDaerahList = () => {
  return (dispatch) => {
    dispatch(fetchPerangkatDaerahRequest());
    const token = sessionStorage.getItem("token");
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${API_BASE_URL}/api/custom-pages/perangkat-daerah`, axiosConfig)
      .then((res) => {
        let _list = res.data;
        dispatch(fetchPerangkatDaerahSuccess(_list));
      })
      .catch((err) => {
        dispatch(fetchPerangkatDaerahFailure(err.message));
        console.log("first eror ikk output", err.message);
        Swal.fire({
          title: "Error!",
          text: `${err.message}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      });
  };
};
