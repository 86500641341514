import React, { useEffect, useState } from "react";
import image from "../../assets/opet2.jpeg";
import edit from "../../assets/edit.png";
import deleteIcon from "../../assets/delete.png";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import { fetchUserList, deleteUser } from "../../redux/user/UserAction";
import { connect } from "react-redux";
import opet from "../../assets/user.png";

const ViewManagement = (props) => {
  const Swal = require("sweetalert2");
  const itemsPerPage = 5; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const [input, setInput] = useState("");
  const [inputSearch, setInputSearch] = useState("");

  const [data, setData] = useState([]);

  useEffect(() => {
    props.fetchUser(inputSearch);
  }, [inputSearch]);

  useEffect(() => {
    setData(props.userList.data.data || []);
  }, [props.userList]);

  console.log("data", data);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const [loading, setLoading] = useState(false);

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handlePrevClick = () => {
    paginate(currentPage - 1);
  };

  const handleNextClick = () => {
    paginate(currentPage + 1);
  };

  // delete function
  const onDelete = async (id) => {
    const confirmationResult = await Swal.fire({
      title: `Apakah anda ingin menghapus data ${id}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    });

    if (confirmationResult.isConfirmed) {
      try {
        await props.deleteUser(id);
      } catch (error) {}
    }
  };

  const handleSearchChange = (event) => {
    const input = event.target.value.toLowerCase();
    setInputSearch(input);
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await props.fetchUserList(inputSearch);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="mb-4 md:flex  justify-between">
        <div>
          <Stack direction="row" spacing={2}>
            <Link to="add">
              <Button variant="contained">Tambah</Button>
            </Link>
          </Stack>
        </div>
        <div className="mt-4 md:mt-0">
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 300,
            }}
          >
            <IconButton sx={{ p: "10px" }} aria-label="menu">
              <MenuIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search..."
              inputProps={{ "aria-label": "search google maps" }}
              defaultValue={input}
              onChange={handleSearchChange}
            />
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={handleSearch}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>
      </div>
      <table className="w-[93.5vw] ">
        <thead>
          <tr className="bg-gray text-left">
            <th className="p-4">No</th>
            <th className="p-4">Username</th>
            <th className="p-4">Email</th>
            <th className="p-4">Photo Profile</th>
            <th className="p-4">Roles</th>
            <th className="p-4">Setting</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => (
            <tr
              key={index}
              className={index % 2 === 0 ? "bg-light" : "bg-[#cae6ed]"}
            >
              <td className="p-4">{index + 1}</td>
              <td className="p-4">{item.name}</td>
              <td className="p-4">{item.email}</td>
              <td className="p-4">
                <img
                  src={item.image}
                  alt="image"
                  className="w-[90px] rounded-lg"
                  onError={(e) => {
                    e.target.src = opet; // Set default image on error
                  }}
                />
              </td>
              <td>
                {item.roles.map((item, index) => (
                  <p>{item.name}</p>
                ))}
              </td>
              <td className="p-2">
                <div className="flex items-center">
                  <Link to="/admin/management-user/edit">
                    <img src={edit} alt="" className="w-[30px]" />
                  </Link>

                  <img
                    src={deleteIcon}
                    alt=""
                    className="w-[30px]"
                    onClick={() => onDelete(item.id)}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      {/* Pagination */}
      <div className="flex justify-center mt-4 pb-4">
        <button
          onClick={handlePrevClick}
          className={`mx-2 px-4 py-2 ${
            currentPage === 1 ? "bg-gray text-white rounded-full" : ""
          }`}
        >
          {"<"}
        </button>

        {[...Array(totalPages).keys()].map((number) => (
          <button
            key={number}
            onClick={() => paginate(number + 1)}
            className={`mx-2 px-4 py-2 ${
              currentPage === number + 1
                ? "bg-gray text-white rounded-full"
                : ""
            }`}
          >
            {number + 1}
          </button>
        ))}

        <button
          onClick={handleNextClick}
          className={`mx-2 px-4 py-2 ${
            currentPage === totalPages ? "bg-gray text-white rounded-full" : ""
          }`}
        >
          {">"}
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userList: state.userList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: (inputSearch) => dispatch(fetchUserList(inputSearch)),
    deleteUser: (id) => dispatch(deleteUser(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewManagement);
