import axios from "axios";
import API_BASE_URL from "../config";
import Swal from "sweetalert2";
import {
  FETCH_BERITA_FAILURE,
  FETCH_BERITA_REQUEST,
  FETCH_BERITA_SUCCESS,
} from "./ActionType";

const fetchBeritaRequest = () => {
  return {
    type: FETCH_BERITA_REQUEST,
  };
};

const fetchBeritaSuccess = (data) => {
  return {
    type: FETCH_BERITA_SUCCESS,
    payload: data,
  };
};

const fetchBeritaFailure = (err) => {
  return {
    type: FETCH_BERITA_FAILURE,
    payload: err,
  };
};

export const fetchBeritaList = () => {
  return (dispatch) => {
    dispatch(fetchBeritaRequest());

    // Retrieve the token from session storage
    const token = sessionStorage.getItem("token"); // Replace "your_token_key" with the actual key used to store the token

    // Set the token in the request headers
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${API_BASE_URL}/api/list/berita`, axiosConfig)
      .then((res) => {
        let _list = res.data;
        dispatch(fetchBeritaSuccess(_list));
      })
      .catch((err) => {
        dispatch(fetchBeritaFailure(err.message));
      });
  };
};
