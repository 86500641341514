import React, { useEffect, useState } from "react";
import Navbar from "../componentsAdmin/Navbar";
import HomeIcon from "@mui/icons-material/Home";
import logo from "../../src/assets/pancasila.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Link } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import SummarizeIcon from "@mui/icons-material/Summarize";
import SourceIcon from "@mui/icons-material/Source";

// superadmin
const menuItems_superadmin = [
  {
    id: "1",
    label: "Dashboard",
    icon: HomeIcon,
    submenu: [],
  },
  {
    id: "3",
    label: "Setting General",
    icon: SettingsIcon,
    submenu: [
      {
        id: "3.1",
        label: "Home Section",
        icon: SettingsSuggestIcon,
        url: "/admin",
        role: [],
      },
      {
        id: "3.2",
        label: "Custom Page",
        icon: SettingsSuggestIcon,
        url: "/admin/settingGeneral/customPage",
        role: [],
      },
      {
        id: "3.3",
        label: "Contact Section",
        icon: SettingsSuggestIcon,
        url: "/admin/contact",
        role: [],
      },
      {
        id: "3.4",
        label: "Setting General",
        icon: SettingsSuggestIcon,
        url: "/admin/settingGeneral/settingGeneral",
        role: [],
      },
    ],
  },
  {
    id: "4",
    label: "Article",
    icon: NewspaperIcon,
    submenu: [
      // {
      //   id: "4.1",
      //   label: "Category",
      //   icon: NewspaperIcon,
      //   url: "/admin/article/category",
      //   role: [],
      // },
      {
        id: "4.2",
        label: "Parent",
        icon: NewspaperIcon,
        url: "/admin/article/parent",
        role: [],
      },
      {
        id: "4.3",
        label: "Article",
        icon: NewspaperIcon,
        url: "/admin/articleAdm",
        role: [],
      },
    ],
  },
  {
    id: "5",
    label: "Management User",
    icon: ManageAccountsIcon,
    submenu: [
      {
        id: "5.1",
        label: "Management User",
        icon: ManageAccountsIcon,
        url: "/admin/management-user",
        role: [],
      },
    ],
  },
  {
    id: "6",
    label: "Home Section",
    icon: ViewCarouselIcon,
    submenu: [
      {
        id: "6.1",
        label: "Banner ",
        icon: ViewCarouselIcon,
        url: "/admin/gallery",
        role: [],
      },
      {
        id: "6.2",
        label: "Banner Video ",
        icon: ViewCarouselIcon,
        url: "/admin/video",
        role: [],
      },
    ],
  },
  {
    id: "2",
    label: "IKK",
    icon: SummarizeIcon,
    submenu: [
      {
        id: "2.1",
        label: "Master IKK",
        icon: SummarizeIcon,
        url: "/admin/ikk",
      },
      {
        id: "2.2",
        label: "Pelaporan IKK Output",
        icon: SummarizeIcon,
        url: "/admin/ikk/pelaporan-ikk-output",
      },
      {
        id: "2.3",
        label: "Pelaporan IKK Makro",
        icon: SummarizeIcon,
        url: "/admin/ikk/pelaporan-ikk-makro",
      },
      {
        id: "2.4",
        label: "Urusan",
        icon: SummarizeIcon,
        url: "/admin/ikk/urusan",
      },
    ],
  },
];

// user pengajuan
const menuItems_userPengajuan = [
  {
    id: "1",
    label: "Dashboard",
    icon: HomeIcon,
    submenu: [],
  },
  {
    id: "2",
    label: "IKK",
    icon: SummarizeIcon,
    submenu: [
      // {
      //   id: "2.1",
      //   label: "IKK User Pengajuan",
      //   icon: SummarizeIcon,
      //   url: "/admin/ikk/pelaporan-ikk-output-pengajuan",
      //   role: [],
      // },
      {
        id: "2.2",
        label: "Pelaporan IKK Pengajuan ",
        icon: SummarizeIcon,
        url: "/admin/ikk/pelaporan-ikk/user-pengajuan",
        role: [],
      },

      {
        id: "2.3",
        label: "Pelaporan IKK Makro Pengajuan ",
        icon: SummarizeIcon,
        url: "/admin/ikk/pelaporan-ikk-makro/pengajuan",
        role: [],
      },
      {
        id: "2.4",
        label: "Clone IKK Output ",
        icon: SummarizeIcon,
        url: "/admin/ikk/pelaporan-ikk-output/pengajuan",
        role: [],
      },
    ],
  },
  {
    id: "7",
    label: "USER PENGAJUAN",
    icon: SourceIcon,
    submenu: [
      {
        id: "7.1",
        label: "IKK Pengajuan Daerah",
        icon: SourceIcon,
        url: "/admin/userpengajuan/ikk-pengajuan-daerah",
      },
      {
        id: "7.2",
        label: "Capaian",
        icon: SourceIcon,
        url: "/admin/userpengajuan/capaian",
        role: [],
      },
      {
        id: "7.3",
        label: "Pelaporan IKK Output",
        icon: SourceIcon,
        url: "/admin/userpengajuan/pelaporanikkoutput",
        role: [],
      },
    ],
  },
];

// admin konfirmasi
const menuItems_admkonfirmasi = [
  {
    id: "1",
    label: "Dashboard",
    icon: HomeIcon,
    submenu: [],
  },

  {
    id: "7",
    label: "USER PENGAJUAN",
    icon: SourceIcon,
    submenu: [
      {
        id: "7.1",
        label: "IKK",
        icon: SourceIcon,
        url: "/admin/userpengajuan/ikk",
        role: [],
      },

      {
        id: "7.3",
        label: "IKK Makro",
        icon: SourceIcon,
        url: "/admin/userpengajuan/ikk-makro-pengajuan-daerah",
        role: [],
      },
      {
        id: "7.4",
        label: "IKK Output",
        icon: SourceIcon,
        url: "/admin/ikk/pelaporan-ikk-makro",
        role: [],
      },

      // {
      //   id: "7.5",
      //   label: "Capaian",
      //   icon: SourceIcon,
      //   url: "/admin/userpengajuan/capaian",
      //   role: [],
      // },

      // {
      //   id: "7.6",
      //   label: "Pelaporan IKK Output",
      //   icon: SourceIcon,
      //   url: "/admin/userpengajuan/pelaporanikkoutput",
      //   role: [],
      // },

      {
        id: "7.7",
        label: "Report IKK New",
        icon: SourceIcon,
        url: "/admin/userpengajuan/pelaporanikkoutputadm/ikk-output",
        role: [],
      },
    ],
  },
];

// admin APIP
const menuItems_apip = [
  {
    id: "1",
    label: "Dashboard",
    icon: HomeIcon,
    submenu: [],
  },

  {
    id: "7",
    label: "USER PENGAJUAN",
    icon: SourceIcon,
    submenu: [
      {
        id: "7.1",
        label: "IKK",
        icon: SourceIcon,
        url: "/admin/userpengajuan/ikk",
        role: [],
      },
      {
        id: "7.2",
        label: "IKK Makro",
        icon: SourceIcon,
        url: "/admin/userpengajuan/ikk-makro-pengajuan-daerah",
        role: [],
      },
    ],
  },
];

const MainLayoutAdmin = (props) => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [active, setActive] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const role = sessionStorage.getItem("role");
  useEffect(() => {
    // Set menu items based on the user's role
    let selectedMenuItems = [];
    if (role === "1") {
      selectedMenuItems = menuItems_superadmin;
    } else if (role === "2") {
      selectedMenuItems = menuItems_userPengajuan;
    } else if (role === "3") {
      selectedMenuItems = menuItems_admkonfirmasi;
    } else if (role === "5") {
      selectedMenuItems = menuItems_apip;
    }

    setMenuItems(selectedMenuItems);
  }, [role]);

  const handleMouseEnter = () => {
    setIsSidebarVisible(true);
  };

  const handleMouseLeave = () => {
    setIsSidebarVisible(false);
  };

  const handleItemClick = (itemName) => {
    setActive((prevActive) => (prevActive === itemName ? null : itemName));
  };

  const handleItemClick2 = (itemName) => {
    setActiveItem(itemName);
    localStorage.setItem("activeItem", itemName);
  };

  useEffect(() => {
    const storedItem = localStorage.getItem("activeItem");
    setActiveItem(storedItem);
  }, []);

  return (
    <React.Fragment>
      <div className="grid grid-cols-12">
        <div className="  col-span-12  top-0 left-0 h-full w-full  cursor-pointer ">
          <div className={`fixed top-0  left-0 w-full z-40`}>
            <Navbar />
          </div>
          <div
            className={`ml-4 pr-4 mt-[100px] ransition-all duration-700 ease-in-out overflow-x-auto  ${
              !isSidebarVisible ? "md:ml-[100px] " : "md:ml-[350px] "
            }`}
          >
            {props.children}
          </div>
        </div>
        <div
          className={` overflow-y-auto max-h-full md:block z-30 hidden   col-span-2 fixed top-0 left-0  cursor-pointer transition-all duration-700 ease-in-out  ${
            isSidebarVisible ? "text-black   " : "   flex justify-center"
          }`}
          style={{
            width: isSidebarVisible ? "300px" : "60px",
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className=" h-[130vh]  bg-gray   shadow-lg md:block hidden  ">
            <div className="h-[61px]  flex items-center  pl-4 border-b border-darkgray">
              <div className="flex items-center">
                <img src={logo} alt="" className="w-[25px] h-[25px]" />
                <div
                  className={`font-semibold text-[20px] pl-4 ${
                    isSidebarVisible
                      ? "   leading-[25px] text-darkgray  "
                      : "pl-4  none "
                  }`}
                >
                  LPPD
                </div>
              </div>
            </div>

            {menuItems.map((menuItem, index) => (
              <div className="pt-4  px-2 " key={menuItem.id}>
                <div>
                  <div
                    className={`flex items-center h-[60px] px-2 hover:bg-white hover:bg-opacity-50  rounded-md mb-2 ${
                      active === menuItem.id ? "active" : ""
                    }`}
                  >
                    <menuItem.icon />
                    <div className="pl-4 w-[600px]">
                      <a
                        className={`transition-all duration-700 ease-in-out ${
                          isSidebarVisible
                            ? "text-darkgray font-bold"
                            : "text-transparent  "
                        }`}
                      >
                        {menuItem.label}
                      </a>
                    </div>
                    <div
                      className=""
                      onClick={() => handleItemClick(menuItem.id)}
                    >
                      {active == menuItem.id ? (
                        <ExpandMoreIcon />
                      ) : (
                        <ExpandLessIcon />
                      )}
                    </div>
                  </div>

                  {menuItem.submenu && (
                    <div
                      className={`${
                        active === menuItem.id
                          ? "opacity-100"
                          : "opacity-0 relative hidden"
                      }  mb-2`}
                    >
                      {menuItem.submenu && (
                        <div
                          className={`${
                            active === menuItem.id
                              ? "opacity-100"
                              : "opacity-0 relative hidden"
                          }  mb-2`}
                        >
                          {menuItem.submenu.map((submenuItem) => (
                            <Link to={submenuItem.url}>
                              <div
                                key={submenuItem.id}
                                onClick={() => handleItemClick2(submenuItem.id)}
                                className={`${
                                  activeItem === submenuItem.id
                                    ? "bg-white bg-opacity-20"
                                    : "bg-opacity-100"
                                } flex items-center  h-[40px] px-2 hover:bg-white hover:bg-opacity-50 rounded-md mb-2`}
                              >
                                <div className="pl-2">
                                  <submenuItem.icon />
                                </div>
                                <div className={`pl-5 w-[600px] `}>
                                  <a
                                    className={`${
                                      isSidebarVisible
                                        ? "text-darkgray"
                                        : "none"
                                    }`}
                                  >
                                    {submenuItem.label}
                                  </a>
                                </div>
                              </div>
                            </Link>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-span-12 pt-[5rem]  h-[100vh] pr-8 pb-8 z-10 flex">
          <div
            className="w-[60px] "
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          ></div>
          {/* <div className="  ml-4 pr-4"> {props.children}</div> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default MainLayoutAdmin;
