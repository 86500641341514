import React from "react";
import MainLayoutAdmin from "../../../layout/MainLayoutAdmin";
import { Text8 } from "../../../mainComponent/Paragraph";
import EditIkkMakro from "../../../componentsAdmin/userPengajuan/pelaporanIkkMakro/EditIkkMakro";
import EditIkkMakroAPIP from "../../../componentsAdmin/userPengajuan/pelaporanIkkMakro/EditIkkMakroAPIP";

const EditIkkMakroPengajuanAdm = () => {
  const role = sessionStorage.getItem("role");
  return (
    <MainLayoutAdmin>
      <div>
        <div className="pb-4  border-b border-darkgray mb-8">
          <Text8 text="Edit Pelaporan IKK Makro" />
        </div>
        {role === 3 ? <EditIkkMakro /> : <EditIkkMakroAPIP />}
      </div>
    </MainLayoutAdmin>
  );
};

export default EditIkkMakroPengajuanAdm;
