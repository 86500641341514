import axios from "axios";
import API_BASE_URL from "../config";
import {
  DELETE_IKK_MAKRO_FAILURE,
  DELETE_IKK_MAKRO_SUCCESS,
  FETCH_IKK_MAKRO_FAILURE,
  FETCH_IKK_MAKRO_REQUEST,
  FETCH_IKK_MAKRO_SUCCESS,
  FETCH_OLD_IKK_MAKRO_FAILURE,
  FETCH_OLD_IKK_MAKRO_SUCCESS,
  UPDATE_IKK_MAKRO_FAILURE,
  UPDATE_IKK_MAKRO_SUCCESS,
} from "./ActionType";
import Swal from "sweetalert2";

export const updateIKKMakroSuccess = (data) => ({
  type: UPDATE_IKK_MAKRO_SUCCESS,
  payload: data,
});

export const updateIKKMakroFailure = (error) => ({
  type: UPDATE_IKK_MAKRO_FAILURE,
  payload: error,
});

export const saveIkkMakroSuccess = (data) => ({
  type: "ADD_IKKMAKRO_SUCCESS",
  payload: data,
});

export const saveIkkMakroFailure = (error) => ({
  type: "ADD_IKKMAKRO_FAILURE",
  payload: error,
});

const fetchIKKMakroRequest = () => {
  return {
    type: FETCH_IKK_MAKRO_REQUEST,
  };
};

const fetchIKKMakroSuccess = (data) => {
  return {
    type: FETCH_IKK_MAKRO_SUCCESS,
    payload: data,
  };
};

const fetchIKKMakroFailure = (err) => {
  return {
    type: FETCH_IKK_MAKRO_FAILURE,
    payload: err,
  };
};

export const fetchIKKMakroList = (year) => {
  return (dispatch) => {
    dispatch(fetchIKKMakroRequest(year));

    // Retrieve the token from session storage
    const token = sessionStorage.getItem("token");
    // Set the token in the request headers
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${API_BASE_URL}/api/dashboard/ikk-makro?${year}`, axiosConfig)
      .then((res) => {
        let _list = res.data;
        dispatch(fetchIKKMakroSuccess(_list));
      })
      .catch((err) => {
        dispatch(fetchIKKMakroFailure(err.message));
        console.log("first eror ikk output", err.message);
        if (err.message === "Request failed with status code 401") {
          Swal.fire({
            title: "Error!",
            text: `${err.message}`,
            icon: "warning",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then(() => {
            window.location.href = "/admin/login";
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: `${err.message}`,
            icon: "warning",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          });
        }
      });
  };
};

export const saveIKKMakro = (data) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");
    const response = await axios.post(
      `${API_BASE_URL}/api/dashboard/ikk-makro/create`,
      {
        ikk: data.ikk,
        satuan: data.satuan,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // dispatch({ type: "ADD_URUSAN_SUCCESS", payload: response.data });
    dispatch(saveIkkMakroSuccess(response.data));
    Swal.fire({
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      icon: "success",
      title: "Success!",
      text: "Data has been saved successfully.",
    }).then(() => {
      window.location.href = "/admin/ikk/pelaporan-ikk-makro";
    });
  } catch (error) {
    // dispatch({ type: "ADD_URUSAN_FAILURE", payload: error.message });
    dispatch(saveIkkMakroFailure(error.message));
    if (error.message === "Request failed with status code 401") {
      Swal.fire({
        title: "Error!",
        text: `${error.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: `eee  ${error.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });

      console.log("err", error.message);
    }
  }
};

export const clearErrors = () => (dispatch) => {
  dispatch({ type: "CLEAR_ERRORS" });
};

export const deleteIkkMakro = (id) => {
  return async (dispatch) => {
    try {
      const token = sessionStorage.getItem("token");

      const response = await axios.delete(
        `${API_BASE_URL}/api/dashboard/ikk-makro/delete/${id}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("first delete ikk", id);

      if (response.status === 200) {
        // Dispatch the DELETE_IKK_SUCCESS action
        dispatch({ type: DELETE_IKK_MAKRO_SUCCESS });

        // Fetch the updated list after deletion
        dispatch(fetchIKKMakroList());
        Swal.fire({
          title: "Success!",
          text: "Data IKK Berhasil Dihapus ",
          confirmButtonColor: "#3085d6",
          icon: "success",
          confirmButtonText: "OK",
        });

        // Optionally, dispatch other actions or handle success
      } else {
        dispatch({ type: DELETE_IKK_MAKRO_FAILURE });
        console.error("Error deleting item. Status:", response.status);
        Swal.fire({
          title: "Error!",
          text: `${response.status}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      dispatch({ type: DELETE_IKK_MAKRO_FAILURE });
      console.error("Error deleting item:", error.message);
      if (error.message === "Request failed with status code 401") {
        Swal.fire({
          title: "Error!",
          text: `${error.message}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        }).then(() => {
          window.location.href = "/admin/login";
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: `${error.message}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    }
  };
};

export const fetchOldIKKMakro = (id) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");
    const response = await axios.get(
      `${API_BASE_URL}/api/dashboard/ikk-makro/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: FETCH_OLD_IKK_MAKRO_SUCCESS,
      payload: response.data, // Assuming the API returns the old data
    });
  } catch (error) {
    dispatch({
      type: FETCH_OLD_IKK_MAKRO_FAILURE,
      payload: error.message,
    });
    if (error.message === "Request failed with status code 401") {
      Swal.fire({
        title: "Error!",
        text: `${error.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: `${error.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  }
};

export const updateIKKMakro = (id, IKKData) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");

    console.log("first", IKKData.urusanSelect);
    const response = await axios.put(
      `${API_BASE_URL}/api/dashboard/ikk-makro/update/${id}`,
      {
        ikk: IKKData.ikk,
        satuan: IKKData.satuan,
        // bukti_fisik: IKKData.bukti_fisik,
        // diperbarui_pemda: IKKData.diperbarui_pemda,
        // diperbarui_apip: IKKData.diperbarui_apip,
        // status_apip: IKKData.status_apip,
        // keterangan: IKKData.keterangan,
        // status_rekomendasi: IKKData.status_rekomendasi,
        // catatan_rekomendasi: IKKData.catatan_rekomendasi,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("Update IKK response:", response.data);
    dispatch(updateIKKMakroSuccess(response.data));

    Swal.fire({
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      icon: "success",
      title: "Success!",
      text: "Data has been saved successfully.",
    }).then(() => {
      window.location.href = "admin/ikk/pelaporan-ikk-makro";
    });
  } catch (err) {
    dispatch(updateIKKMakroFailure(err.message));
    if (err.message === "Request failed with status code 401") {
      Swal.fire({
        title: "Error!",
        text: `${err.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: `${err.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  }
};
