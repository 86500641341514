import React from "react";
import MainLayout from "../../layout/MainLayout";
import Informasi from "../../components/berita/Informasi";

const InformasiView = () => {
  return (
    <MainLayout>
      <div className="col-span-12">
        <Informasi />
      </div>
    </MainLayout>
  );
};

export default InformasiView;
