import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import defaultImage from "../../assets/defaultimage.png";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { clearErrors, saveIKK } from "../../redux/ikk/IkkAction";
import { fetchUrusanList } from "../../redux/urusan/UrusanAction";
import DropdownUrusan from "./urusan/dropdownUrusan";

const AddIKK = ({ saveIKK, IKKData, error, props, addUrusan }) => {
  const navigate = useNavigate();
  const [noIKK, setNoIKK] = useState("");
  const [noikkError, setNoIkkError] = useState("");

  const [IKKOutput, setIKKOutput] = useState("");
  const [IKKOutputError, setIKKOutputError] = useState("");

  const [IKKOutcome, setIKKOutcome] = useState("");
  const [IKKOutcomeError, setIKKOutcomeError] = useState("");

  const [rumus, setRumus] = useState("");
  const [rumusError, setRumusError] = useState("");

  const [rumus1, setRumus1] = useState("");
  const [rumusError1, setRumusError1] = useState("");

  const [rumus2, setRumus2] = useState("");
  const [rumusError2, setRumusError2] = useState("");

  const [definisiOperasional, setDefinisiOperasional] = useState("");
  const [definisiOperasionalError, setDefinisiOperasionalError] = useState("");

  const [urusanSelect, setUrusanSelect] = useState("");
  const [urusanError, setUrusanError] = useState("");

  const [selectedFile, setSelectedFile] = useState(defaultImage);

  useEffect(() => {
    // Fetch urusan data when the component mounts
    console.log("Fetching urusan list...");
    fetchUrusanList();
  }, [fetchUrusanList]);

  console.log("data urusannya apa", addUrusan);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file ? URL.createObjectURL(file) : defaultImage);
  };

  const onCancel = async (id) => {
    const confirmationResult = await Swal.fire({
      title: "Apakah anda ingin membatalkan aktivitas?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    });
    if (confirmationResult.isConfirmed) {
      try {
        navigate("/admin/management-user");
      } catch (error) {
        Swal.fire({
          title: "Error!",
          text: ` Anda tidak dapat membatalkan aktivitas ${error}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const handleSave = () => {
    const IKKData = {
      noIKK,
      IKKOutput,
      IKKOutcome,
      rumus,
      rumus1,
      rumus2,
      definisiOperasional,
      urusanSelect,
      selectedFile,
    };

    saveIKK(IKKData);
  };

  useEffect(() => {
    if (error) {
      Swal.fire({
        title: "Error!",
        text: `Failed to save Urusan. ${error}`,
        icon: "error",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
    clearErrors();
  }, [error, clearErrors]);

  return (
    <div className="pl-4 overflow-hidden max-w-screen  ">
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 2, width: "30ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="filled-basic"
          label="No IKK"
          variant="filled"
          value={noIKK}
          onChange={(e) => setNoIKK(e.target.value)}
          error={!!noikkError}
          helperText={noikkError}
        />

        <TextField
          id="filled-basic"
          label="IKK Output"
          variant="filled"
          value={IKKOutput}
          onChange={(e) => setIKKOutput(e.target.value)}
          error={!!IKKOutputError}
          helperText={IKKOutputError}
        />

        <TextField
          id="filled-basic"
          label="IKK Outcome"
          variant="filled"
          value={IKKOutcome}
          onChange={(e) => setIKKOutcome(e.target.value)}
          error={!!IKKOutcomeError}
          helperText={IKKOutcomeError}
        />

        <TextField
          id="filled-basic"
          label="Rumus"
          variant="filled"
          value={rumus}
          onChange={(e) => setRumus(e.target.value)}
          error={!!rumusError}
          helperText={rumusError}
        />

        <TextField
          id="filled-basic"
          label="Var Rumus 1 "
          variant="filled"
          value={rumus1}
          onChange={(e) => setRumus1(e.target.value)}
          error={!!rumusError1}
          helperText={rumusError1}
        />

        <TextField
          id="filled-basic"
          label="Var Rumus 2 "
          variant="filled"
          value={rumus2}
          onChange={(e) => setRumus2(e.target.value)}
          error={!!rumusError2}
          helperText={rumusError2}
        />

        <TextField
          id="filled-basic"
          label="Defisini Operasional"
          variant="filled"
          value={definisiOperasional}
          onChange={(e) => setDefinisiOperasional(e.target.value)}
          error={!!definisiOperasionalError}
          helperText={definisiOperasionalError}
        />
        <DropdownUrusan setUrusanSelect={setUrusanSelect} />
      </Box>

      <div className="w-[200px]  flex justify-between pl-4">
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={
            !noIKK ||
            !IKKOutput ||
            !IKKOutcome ||
            !rumus ||
            !rumus1 ||
            !rumus2 ||
            !definisiOperasional ||
            !urusanSelect ||
            !selectedFile
          }
        >
          Simpan
        </Button>

        <Button variant="contained" color="error" onClick={() => onCancel()}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ikkData: state.ikkData,
  error: state.error,
  addUrusan: state.addUrusan,
});

export default connect(mapStateToProps, { saveIKK, fetchUrusanList })(AddIKK);
