import React, { useEffect, useState } from "react";
import edit from "../../../assets/edit.png";
import deleteIcon from "../../../assets/delete.png";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { connect } from "react-redux";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PrintIcon from "@mui/icons-material/Print";
import excel from "../../../assets/excel.png";
import { TextField } from "@mui/material";
import { fetchIKKUserPengajuanList } from "../../../redux/ikkUserPengajuan/IkkUserPengajuanAction";
import detail from "../../../assets/search.png";
import { Text7 } from "../../../mainComponent/Paragraph";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

const UserPengajuan = (props) => {
  const Swal = require("sweetalert2");
  const itemsPerPage = 5; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const [checkedItems, setCheckedItems] = useState({});
  const role = sessionStorage.getItem("role");
  const [currentYear, setCurrentYear] = useState();
  const [inputSearch, setInputSearch] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  useEffect(() => {
    props.fetchIKKUserPengajuanList(currentYear);
  }, [currentYear]);
  const data = props.IkkUserPengajuanList.data || [];

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handlePrevClick = () => {
    paginate(currentPage - 1);
  };

  const handleNextClick = () => {
    paginate(currentPage + 1);
  };

  const onDelete = async (id) => {
    const confirmationResult = await Swal.fire({
      title: "Apakah anda ingin menghapus data ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    });
    if (confirmationResult.isConfirmed) {
      try {
        Swal.fire({
          title: "Success!",
          text: "Delete data categori berhasil ",
          confirmButtonColor: "#3085d6",
          icon: "success",
          confirmButtonText: "OK",
        });
      } catch (error) {
        Swal.fire({
          title: "Error!",
          text: ` Data User Tidak Dapat Dihapus ${error}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const handleCheck = (id) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [id]: !prevCheckedItems[id],
    }));
  };

  const handleCheckAll = (isCheckedAll) => {
    const newCheckedItems = {};
    data.forEach((item) => {
      newCheckedItems[item.id] = isCheckedAll;
    });
    setCheckedItems(newCheckedItems);
  };

  const isChecked = (id) => {
    return checkedItems[id] || false;
  };

  const handleSearchChange = (event) => {
    const input = event.target.value.toLowerCase();
    setInputSearch(input);
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await props.fetchIKKUserPengajuanList(inputSearch);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="mb-4 grid grid-cols-12 gap-2">
        <div className="col-span-4 md:col-span-1 ">
          <Stack
            component="form"
            sx={{
              width: "10ch",
            }}
            spacing={1}
            noValidate
            autoComplete="off"
          >
            <TextField
              hiddenLabel
              id="filled-hidden-label-small"
              defaultValue={currentYear}
              onChange={handleSearchChange}
              InputLabelProps={{ shrink: false }}
              variant="filled"
              size="small"
              placeholder="Enter Year"
            />
          </Stack>
        </div>
        <div className="col-span-8 md:col-span-1 ">
          <Stack
            direction="row"
            spacing={2}
            sx={{
              height: "4.1ch",
            }}
          >
            {loading ? (
              <Button
                variant="outlined"
                startIcon={<SearchIcon />}
                onClick={handleSearch}
              >
                Loading...
              </Button>
            ) : (
              <Button
                variant="outlined"
                startIcon={<SearchIcon />}
                onClick={handleSearch}
              >
                Cari
              </Button>
            )}
          </Stack>
        </div>
        <div className="col-span-10 flex justify-end">
          <Stack direction="row" spacing={2}>
            <Link to="/admin/ikk/add">
              <Button variant="contained">Tambah</Button>
            </Link>
            <Button variant="contained" endIcon={<PrintIcon />}>
              Print
            </Button>
            <Button variant="contained" endIcon={<PictureAsPdfIcon />}>
              PDF
            </Button>
            <Button variant="contained">
              Excel
              <img src={excel} s alt="Excel Icon" className="ml-2 w-[20px]" />
            </Button>
          </Stack>
        </div>
      </div>
      <table className="w-[90vw] ">
        <thead>
          <tr className="bg-gray">
            <th className="p-4">
              <input
                type="checkbox"
                onChange={(e) => handleCheckAll(e.target.checked)}
              />
            </th>
            <th className="p-4">Nama</th>
            <th className="p-4">Email</th>
            <th className="p-4">Informasi</th>
            <th className="p-4">Action</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => (
            <tr
              key={index}
              className={index % 2 === 0 ? "bg-light" : "bg-[#cae6ed]"}
            >
              <td className="p-4 text-center">
                <input
                  type="checkbox"
                  checked={isChecked(item.id)}
                  onChange={() => handleCheck(item.id)}
                />
              </td>
              <td className="p-4 text-center">{item.name}</td>
              <td className="p-4 text-center">{item.email}</td>
              <td className="p-4 text-center">
                <Link to={`/admin/userpengajuan/ikk/${item.id}`}>
                  <Text7 text="IKK" />
                </Link>
                <button>
                  <Text7 text="IKK Makro" />
                </button>
                <button>
                  <Text7 text="IKK Output" />
                </button>
              </td>

              <td className="p-4 text-center">
                <div className="flex items-center justify-center">
                  {role == 5 ? (
                    <Link
                      to={`/admin/userpengajuan/ikk-detail/apip/${item.id}`}
                    >
                      <img src={detail} alt="" className="w-[30px]" />
                    </Link>
                  ) : (
                    <Link to={`/admin/userpengajuan/ikk-detail/${item.id}`}>
                      <img src={detail} alt="" className="w-[30px]" />
                    </Link>
                  )}

                  <img
                    src={deleteIcon}
                    alt=""
                    className="w-[30px]"
                    onClick={() => onDelete(item.id)}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      {/* Pagination */}
      <div className="flex justify-center mt-4 pb-4">
        <button
          onClick={handlePrevClick}
          className={`mx-2 px-4 py-2 ${
            currentPage === 1 ? "bg-gray text-white rounded-full" : ""
          }`}
        >
          {"<"}
        </button>

        {[...Array(totalPages).keys()].map((number) => (
          <button
            key={number}
            onClick={() => paginate(number + 1)}
            className={`mx-2 px-4 py-2 ${
              currentPage === number + 1
                ? "bg-gray text-white rounded-full"
                : ""
            }`}
          >
            {number + 1}
          </button>
        ))}

        <button
          onClick={handleNextClick}
          className={`mx-2 px-4 py-2 ${
            currentPage === totalPages ? "bg-gray text-white rounded-full" : ""
          }`}
        >
          {">"}
        </button>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    IkkUserPengajuanList: state.IkkUserPengajuanList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchIKKUserPengajuanList: (currentYear) =>
      dispatch(fetchIKKUserPengajuanList(currentYear)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPengajuan);
