import React, { useEffect, useState } from "react";
import edit from "../../assets/edit.png";
import deleteIcon from "../../assets/delete.png";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { connect } from "react-redux";
import { fetchParentList } from "../../redux/parent/ParentAction";

const Parent = (props) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    props.fetchParentList();
  }, []);

  useEffect(() => {
    setData(props.parentList.data.parent_page || []);
  }, [props.parentList]);

  //   console.log("data", props.parentList.data.parent_page);

  const Swal = require("sweetalert2");
  const itemsPerPage = 5; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(data.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const [searchInput, setSearchInput] = useState("");

  console.log("first", searchInput);
  const handleSearchChange = (event) => {
    const input = event.target.value.toLowerCase();
    setSearchInput(input);

    // If searchInput is null or empty, reset data to the original list
    if (input === null || input.trim() === "") {
      setData(props.parentList.data.parent_page || []);
    } else {
      // Otherwise, update the data state with filtered items
      const filteredData = data.filter((item) =>
        item.title_page.toLowerCase().includes(input)
      );
      setData(filteredData);
    }

    setCurrentPage(1); // Reset current page to 1 when search changes
  };

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  const handlePrevClick = () => {
    paginate(currentPage - 1);
  };
  const handleNextClick = () => {
    paginate(currentPage + 1);
  };
  const onDelete = async (id) => {
    const confirmationResult = await Swal.fire({
      title: `Apakah anda ingin menghapus data ${id}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    });

    if (confirmationResult.isConfirmed) {
      try {
        await props.deleteArticle(id);
      } catch (error) {}
    }
  };

  return (
    <div>
      <div className="mb-4 md:flex justify-between">
        {/* <div>
          <Stack direction="row" spacing={2}>
            <Link to="/admin/articleAdm/add">
              <Button variant="contained">Tambah</Button>
            </Link>
          </Stack>
        </div> */}
        <div className="mt-4 md:mt-0">
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 300,
            }}
          >
            <IconButton sx={{ p: "10px" }} aria-label="menu">
              <MenuIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search..."
              inputProps={{ "aria-label": "search google maps" }}
              value={searchInput}
              onChange={handleSearchChange}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>
      </div>
      <table className="w-[93.5vw] ">
        <thead>
          <tr className="bg-gray">
            <th className="p-4">No</th>
            <th className="p-4">Title Page</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => (
            <tr
              key={index}
              className={index % 2 === 0 ? "bg-light" : "bg-[#cae6ed]"}
            >
              <td className="p-4 text-center">
                {index + 1 + indexOfFirstItem}
              </td>
              <td className="p-4 text-center">{item.title_page}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      {/* Pagination */}
      <div className="flex justify-center mt-4 pb-4">
        <button
          onClick={handlePrevClick}
          className={`mx-2 px-4 py-2 ${
            currentPage === 1 ? "bg-gray text-white rounded-full" : ""
          }`}
        >
          {"<"}
        </button>

        {[...Array(totalPages).keys()].map((number) => (
          <button
            key={number}
            onClick={() => paginate(number + 1)}
            className={`mx-2 px-4 py-2 ${
              currentPage === number + 1
                ? "bg-gray text-white rounded-full"
                : ""
            }`}
          >
            {number + 1}
          </button>
        ))}

        <button
          onClick={handleNextClick}
          className={`mx-2 px-4 py-2 ${
            currentPage === totalPages ? "bg-gray text-white rounded-full" : ""
          }`}
        >
          {">"}
        </button>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    parentList: state.parentList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchParentList: () => dispatch(fetchParentList()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Parent);
