import {
  DELETE_CAPAIAN_FAILURE,
  DELETE_CAPAIAN_SUCCESS,
  FETCH_CAPAIAN_IKK_FAILURE,
  FETCH_CAPAIAN_IKK_REQUEST,
  FETCH_CAPAIAN_IKK_SUCCESS,
  FETCH_OLD_CAPAIAN_FAILURE,
  FETCH_OLD_CAPAIAN_SUCCESS,
  UPDATE_CAPAIAN_FAILURE,
  UPDATE_CAPAIAN_SUCCESS,
} from "./ActionType";
import axios from "axios";
import API_BASE_URL from "../config";
import Swal from "sweetalert2";

export const updateCapaianSuccess = (data) => ({
  type: UPDATE_CAPAIAN_SUCCESS,
  payload: data,
});

export const updateCapaianFailure = (error) => ({
  type: UPDATE_CAPAIAN_FAILURE,
  payload: error,
});

export const saveCapaianSuccess = (data) => ({
  type: "ADD_CAPAIAN_SUCCESS",
  payload: data,
});

export const saveCapaianFailure = (error) => ({
  type: "ADD_CAPAIAN_FAILURE",
  payload: error,
});
const fetchCapaianRequest = () => {
  return {
    type: FETCH_CAPAIAN_IKK_REQUEST,
  };
};

const fetchCapaianSuccess = (data) => {
  return {
    type: FETCH_CAPAIAN_IKK_SUCCESS,
    payload: data,
  };
};

const fetchCapaianFailure = (err) => {
  return {
    type: FETCH_CAPAIAN_IKK_FAILURE,
    payload: err,
  };
};

export const fetchCapaianList = () => {
  return (dispatch) => {
    dispatch(fetchCapaianRequest());

    // Retrieve the token from session storage
    const token = sessionStorage.getItem("token");
    const role = sessionStorage.getItem("role");

    const apiEndpoint = role === "2" ? `/api/dashboard/ikk-makro-capaian` : "";

    if (!apiEndpoint) {
      // Handle invalid role
      dispatch(fetchCapaianFailure("Invalid role"));

      Swal.fire({
        icon: "warning",
        title: "Error!",
        text: "Invalid role access",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
      return;
    }

    // Set the token in the request headers
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${API_BASE_URL}${apiEndpoint}`, axiosConfig)
      .then((res) => {
        let _list = res.data;
        dispatch(fetchCapaianSuccess(_list));
      })
      .catch((err) => {
        dispatch(fetchCapaianFailure(err.message));
        if (err.message === "Request failed with status code 401") {
          Swal.fire({
            title: "Error!",
            text: `${err.message}`,
            icon: "warning",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then(() => {
            window.location.href = "/admin/login";
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: `${err.message}`,
            icon: "warning",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          });
        }
      });
  };
};

export const clearErrors = () => (dispatch) => {
  dispatch({ type: "CLEAR_ERRORS" });
};

export const deleteCapaian = (id) => {
  return async (dispatch) => {
    try {
      const token = sessionStorage.getItem("token");

      const response = await axios.delete(
        `${API_BASE_URL}/api/dashboard/ikk-makro/capaian/delete/${id}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("first delete ikk", id);

      if (response.status === 200) {
        // Dispatch the DELETE_IKK_SUCCESS action
        dispatch({ type: DELETE_CAPAIAN_SUCCESS });

        // Fetch the updated list after deletion
        dispatch(fetchCapaianList());
        Swal.fire({
          title: "Success!",
          text: "Data IKK Berhasil Dihapus ",
          confirmButtonColor: "#3085d6",
          icon: "success",
          confirmButtonText: "OK",
        });

        // Optionally, dispatch other actions or handle success
      } else {
        dispatch({ type: DELETE_CAPAIAN_FAILURE });
        console.error("Error deleting item. Status:", response.status);
        Swal.fire({
          title: "Error!",
          text: `${response.status}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      dispatch({ type: DELETE_CAPAIAN_FAILURE });
      console.error("Error deleting item:", error.message);
      if (error.message === "Request failed with status code 401") {
        Swal.fire({
          title: "Error!",
          text: `${error.message}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        }).then(() => {
          window.location.href = "/admin/login";
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: `${error.message}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    }
  };
};

export const saveCapaian = (capaianData) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");
    const response = await axios.post(
      `${API_BASE_URL}/api/dashboard/ikk-makro/capaian/create`,
      {
        tahun: capaianData.tahun,
        capaian_kinerja: capaianData.capaian_kinerja,
        laju_kinerja: capaianData.laju_kinerja,
        laju: capaianData.laju,
        rumus: capaianData.rumus,
        ikk_makro_id: capaianData.ikk_makro_id,
        keterangan: capaianData.keterangan,
        bukti_fisik: capaianData.bukti_fisik,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // dispatch({ type: "ADD_URUSAN_SUCCESS", payload: response.data });
    dispatch(saveCapaianSuccess(response.data));

    Swal.fire({
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      icon: "success",
      title: "Success!",
      text: "Data has been saved successfully.",
    }).then(() => {
      window.location.href = "/admin/userpengajuan/capaian";
    });
  } catch (err) {
    // dispatch({ type: "ADD_URUSAN_FAILURE", payload: error.message });
    dispatch(saveCapaianFailure(err.message));
    if (err.message === "Request failed with status code 401") {
      Swal.fire({
        title: "Error!",
        text: `${err.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: `${err.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  }
};

export const fetchOldCapaian = (id) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");
    const response = await axios.get(
      `${API_BASE_URL}/api/dashboard/ikk-makro/capaian/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: FETCH_OLD_CAPAIAN_SUCCESS,
      payload: response.data, // Assuming the API returns the old data
    });
  } catch (error) {
    dispatch({
      type: FETCH_OLD_CAPAIAN_FAILURE,
      payload: error.message,
    });
  }
};

export const updateCapaian = (id, capaianData) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");

    console.log("first", capaianData.urusanSelect);
    const response = await axios.put(
      `${API_BASE_URL}/api/dashboard/ikk-makro/capaian/update/${id}`,
      {
        tahun: capaianData.tahun,
        capaian_kinerja: capaianData.capaian_kinerja,
        laju_kinerja: capaianData.laju_kinerja,
        laju: capaianData.laju,
        rumus: capaianData.rumus,
        ikk_makro_id: capaianData.ikk_makro_id,
        keterangan: capaianData.keterangan,
        bukti_fisik: capaianData.bukti_fisik,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("Update IKK response:", response.data);
    // dispatch({ type: "ADD_URUSAN_SUCCESS", payload: response.data });
    dispatch(updateCapaianSuccess(response.data));

    Swal.fire({
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      icon: "success",
      title: "Success!",
      text: "Data has been saved successfully.",
    }).then(() => {
      window.location.href = "/admin/ikk/pelaporan-ikk-makro/pengajuan";
    });
  } catch (err) {
    // dispatch({ type: "ADD_URUSAN_FAILURE", payload: error.message });
    dispatch(updateCapaianFailure(err.message));
    if (err.message === "Request failed with status code 401") {
      Swal.fire({
        title: "Error!",
        text: `${err.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: `${err.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  }
};
