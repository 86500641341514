import {
  FETCH_IKK_MAKRO_PENGAJUAN_DAERAH_FAILURE,
  FETCH_IKK_MAKRO_PENGAJUAN_DAERAH_REQUEST,
  FETCH_IKK_MAKRO_PENGAJUAN_DAERAH_SUCCESS,
  REDIRECT_TO_LOGIN,
} from "./ActionType";

const initialstate = {
  loading: true,
  data: [],
  error: "",
  redirectToLogin: false,
  deleteIkkStatus: null,
};

export const IkkMakroPengajuanDaerahReducer = (
  state = initialstate,
  action
) => {
  switch (action.type) {
    case FETCH_IKK_MAKRO_PENGAJUAN_DAERAH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_IKK_MAKRO_PENGAJUAN_DAERAH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    case FETCH_IKK_MAKRO_PENGAJUAN_DAERAH_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };
    case REDIRECT_TO_LOGIN:
      return {
        ...state,
        redirectToLogin: true,
      };
    default:
      return state;
  }
};
