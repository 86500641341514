// Navbar.js
import React, { useEffect, useState } from "react";
import logo from "../../assets/garuda.png";
import { Button, Link, Stack } from "@mui/material";
import Navlink from "./Navlink";
import burger from "../../assets/menu.png";
import burgerlight from "../../assets/menu2.png";

const Navbar = (props) => {
  const [open, setOpen] = useState(true);
  const [hoveredItem, setHoveredItem] = useState(null);
  console.log("first", open);

  const handleNavbarItemHover = (itemId) => {
    setHoveredItem(itemId);
  };

  useEffect(() => {
    sessionStorage.setItem("navbarOpen", JSON.stringify(open));
  }, [open]);

  return (
    <nav class=" bg-gray ">
      <div className="md:flex items-center font-medium justify-center h-[80px]">
        <div className="z-50 p-5 md:w-auto w-full flex justify-between ">
          <img
            src={logo}
            alt="Logo"
            className="md-cursor-pointer h-[45px] md:h-[70px]"
          />
          <div className="md:hidden" onClick={() => setOpen(!open)}>
            <img src={burger} alt="" className="w-[45px]" />
          </div>
        </div>
        <div className="md:flex hidden uppercase items-center gap-8 font-[Poppins] ">
          <Navlink />
        </div>
        <div
          className={`md:block md:hidden bg-gray h-[100vh] -mt-8 pl-8 md:pl-0 ${
            open ? "hidden" : ""
          }`}
        >
          <Navlink />
        </div>

        {/* <ul
          className={`
        md:hidden bg-gray fixed w-full top-0 overflow-y-auto bottom-0 py-24 pl-4
        duration-500 ${open ? "left-0" : "left-[-100%]"}
        `}
        >
          <Navlink />
          <div className="py-5">
            <Button />
          </div>
        </ul> */}
      </div>
    </nav>
  );
};

export default Navbar;
