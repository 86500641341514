export const FETCH_ARTICLE_REQUEST = "FETCH_ARTICLE_REQUEST";
export const FETCH_ARTICLE_SUCCESS = "FETCH_ARTICLE_SUCCESS";
export const FETCH_ARTICLE_FAILURE = "FETCH_ARTICLE_FAILURE";

export const FETCH_OLD_ARTICLE_SUCCESS = "FETCH_OLD_ARTICLE_SUCCESS";
export const FETCH_OLD_ARTICLE_FAILURE = "FETCH_OLD_ARTICLE_FAILURE";
export const UPDATE_ARTICLE_SUCCESS = "UPDATE_ARTICLE_SUCCESS";
export const UPDATE_ARTICLE_FAILURE = "UPDATE_ARTICLE_FAILURE";

export const REDIRECT_TO_LOGIN = "REDIRECT_TO_LOGIN";
export const DELETE_ARTICLE_SUCCESS = "DELETE_ARTICLE_SUCCESS";
export const DELETE_ARTICLE_FAILURE = "DELETE_ARTICLE_FAILURE";
