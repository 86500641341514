import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { saveRegister, clearErrors } from "../redux/register/RegisterAction";
import IconButton from "@mui/material/IconButton";
import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import img from "../../src/assets/login.png";
import { Text1, Text7 } from "../mainComponent/Paragraph";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem } from "@mui/material";

const role = [
  {
    value: "1",
    label: "Superadmin",
  },
  {
    value: "2",
    label: "Admin Pengajuan",
  },
  {
    value: "3",
    label: "Admin Konfirmasi",
  },
  {
    value: "5",
    label: "Admin APIP",
  },
];
const Register = ({ saveRegister, error, registerData, clearErrors }) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);
  const navigate = useNavigate();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };

  // integration
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role_id, setRoleId] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  console.log("role", role_id);
  const handleSave = () => {
    const urusanData = {
      name,
      email,
      role_id,
      password,
      passwordConfirmation,
    };

    saveRegister(urusanData);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default form submission
      handleSave();
    }
  };

  const handleKeyPress1 = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default form submission
      handleSave();
    }
  };
  return (
    <div className="h-[100vh] grid grid-cols-12  items-center overflow-x-hidden">
      <div className="col-span-12 md:col-span-8 flex justify-center text-center  ">
        <div>
          <div className="mb-8 mt-10 md:mt-0">
            <Text1 text="Register" />
          </div>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <div>
              <FormControl
                sx={{
                  mb: { xs: 3, md: 1 },
                  width: { xs: "30ch", md: "50ch" },
                }}
                variant="filled"
              >
                <TextField
                  id="filled-textarea"
                  label=" Nama  "
                  placeholder="Nama"
                  multiline
                  variant="filled"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
            </div>
          </Box>

          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <div>
              <FormControl
                sx={{
                  mb: { xs: 3, md: 1 },
                  width: { xs: "30ch", md: "50ch" },
                }}
                variant="filled"
              >
                <TextField
                  id="filled-textarea"
                  label="Email"
                  placeholder="Email"
                  multiline
                  variant="filled"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
            </div>
          </Box>

          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": {
                width: "50ch",
                mb: 1,
                textAlign: "left",
              },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="filled-select-currency"
              select
              label="Select"
              defaultValue="Pilih"
              helperText=""
              variant="filled"
              value={role_id}
              onChange={(e) => setRoleId(e.target.value)}
            >
              {role.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <div>
              <FormControl
                sx={{
                  mb: { xs: 3, md: 1 },
                  width: { xs: "30ch", md: "50ch" },
                }}
                variant="filled"
              >
                <InputLabel htmlFor="filled-adornment-password">
                  Password
                </InputLabel>
                <FilledInput
                  id="filled-adornment-password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={handleKeyPress}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  // Add autocomplete attribute
                  autoComplete="new-password"
                />
              </FormControl>
            </div>
          </Box>

          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <div>
              <FormControl
                sx={{
                  mb: { xs: 3, md: 1 },
                  width: { xs: "30ch", md: "50ch" },
                }}
                variant="filled"
              >
                <InputLabel htmlFor="filled-adornment-password">
                  Password Confirmation
                </InputLabel>
                <FilledInput
                  id="filled-adornment-password"
                  type={showPassword1 ? "text" : "password"}
                  value={passwordConfirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                  onKeyDown={handleKeyPress1}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword1}
                        onMouseDown={handleMouseDownPassword1}
                        edge="end"
                      >
                        {showPassword1 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  // Add autocomplete attribute
                  autoComplete="new-password"
                />
              </FormControl>
            </div>
          </Box>

          <Box sx={{ "& button": { width: "97%", my: 3 } }}>
            <Button variant="outlined" size="large" onClick={handleSave}>
              Register
            </Button>
          </Box>
          <div className="flex justify-center items-center mb-4">
            <div className="w-[40%] bg-gradient-to-l from-[#0B6EFE] to-white h-[6px]"></div>
            <Link to="/admin/login">
              <div className="md:mx-2 cursor-pointer">
                <Text7 text="Login" />
              </div>
            </Link>
            <div className=" w-[40%] bg-gradient-to-r from-[#0B6EFE] to-white h-[6px]"></div>
          </div>
        </div>
      </div>
      <div className="col-span-12 md:col-span-4 bg-gray  flex items-center justify-center h-[100vh]">
        <img src={img} alt="" className="w-[450px]" />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  registerData: state.registerData,
  error: state.error,
});

export default connect(mapStateToProps, { saveRegister, clearErrors })(
  Register
);
