export const FETCH_IKK_USER_PENGAJUAN_REPORT_REQUEST =
  "FETCH_IKK_USER_PENGAJUAN_REPORT_REQUEST";
export const FETCH_IKK_USER_PENGAJUAN_REPORT_SUCCESS =
  "FETCH_IKK_USER_PENGAJUAN_REPORT_SUCCESS";
export const FETCH_IKK_USER_PENGAJUAN_REPORT_FAILURE =
  "FETCH_IKK_USER_PENGAJUAN_REPORT_FAILURE";
export const REDIRECT_TO_LOGIN = "REDIRECT_TO_LOGIN";

export const FETCH_OLD_IKK_USER_PENGAJUAN_REPORT_SUCCESS =
  "FETCH_OLD_IKK_USER_PENGAJUAN_REPORT_SUCCESS";
export const FETCH_OLD_IKK_USER_PENGAJUAN_REPORT_FAILURE =
  "FETCH_OLD_IKK_USER_PENGAJUAN_REPORT_FAILURE";
export const UPDATE_IKK_USER_PENGAJUAN_REPORT_SUCCESS =
  "UPDATE_IKK_USER_PENGAJUAN_REPORT_SUCCESS";
export const UPDATE_IKK_USER_PENGAJUAN_REPORT_FAILURE =
  "UPDATE_IKK_USER_PENGAJUAN_REPORT_FAILURE";
