export const ADD_URUSAN_SUCCESS = "ADD_URUSAN_SUCCESS";
export const ADD_URUSAN_FAILURE = "ADD_URUSAN_FAILURE";

export const FETCH_URUSAN_REQUEST = "FETCH_URUSAN_REQUEST";
export const FETCH_URUSAN_SUCCESS = "FETCH_URUSAN_SUCCESS";
export const FETCH_URUSAN_FAILURE = "FETCH_URUSAN_FAILURE";
export const REDIRECT_TO_LOGIN = "REDIRECT_TO_LOGIN";

export const DELETE_URUSAN_SUCCESS = "DELETE_URUSAN_SUCCESS";
export const DELETE_URUSAN_FAILURE = "DELETE_URUSAN_FAILURE";

export const FETCH_OLD_URUSAN_SUCCESS = "FETCH_OLD_URUSAN_SUCCESS";
export const FETCH_OLD_URUSAN_FAILURE = "FETCH_OLD_URUSAN_FAILURE";
export const UPDATE_URUSAN_SUCCESS = "UPDATE_URUSAN_SUCCESS";
export const UPDATE_URUSAN_FAILURE = "UPDATE_URUSAN_FAILURE";
