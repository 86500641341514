import React from "react";
import MainLayout from "../../../layout/MainLayout";
import DataPenduduk from "../../../components/profile/DataPenduduk";

const DataPendudukView = () => {
  return (
    <MainLayout>
      <div className="col-span-12">
        <DataPenduduk />
      </div>
    </MainLayout>
  );
};

export default DataPendudukView;
