import React, { useEffect } from "react";
import defaultImg from "../../assets/default2.png";
import { Text2, Text3, Text6 } from "../../mainComponent/Paragraph";
import { fetchBeritaList } from "../../redux/webBerita/BeritaAction";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const Berita = (props) => {
  useEffect(() => {
    props.fetchBeritaList();
  }, []);
  const data = props.Berita.data.data || "";
  if (!Array.isArray(data)) {
    // Handle the case where data is not an array
    return <div>No articles to display.</div>;
  }

  // console.log("perangkat daerah", data);

  return (
    <div className="grid grid-cols-12 gap-2">
      {data.map((item, index) => (
        <div className="col-span-12 md:col-span-4 " key={index}>
          {/* <p>category : {item.category.name}</p> */}
          <img
            src={item.imageThumbnailUrl}
            alt="artikel"
            className="rounded-md w-[500px] h-[300px]"
            onError={(e) => {
              e.target.src = defaultImg;
            }}
          />

          <div
            dangerouslySetInnerHTML={{
              __html: item.content.slice(0, 100),
            }}
          />
          <Link to={`/berita/detail/${item.slug}/${item.category.name}`}>
            <Text2 text="Lihat Detail" />
          </Link>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Berita: state.Berita,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBeritaList: () => dispatch(fetchBeritaList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Berita);
