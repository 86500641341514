import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchContactList,
  updateContact,
} from "../../redux/ contact/ContactAction";
import Swal from "sweetalert2";

const UpdateContact = ({ updateContact, fetchContactList, Contact }) => {
  const navigate = useNavigate();
  const [namaWeb, setNamaWeb] = useState("");
  const [alamat, setAlamat] = useState("");
  const [maps, setMaps] = useState("");
  const [nomorTelp, setNomorTelp] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [tiktok, setTiktok] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [telegram, setTelegram] = useState("");
  const [youtube, setYoutube] = useState("");

  console.log("data2", alamat);
  useEffect(() => {
    // Fetch urusan data when the component mounts
    // console.log("Fetching urusan list...");
    fetchContactList();
  }, [fetchContactList]);

  useEffect(() => {
    if (Contact && Contact.data.data ? Contact.data.data : "undefined") {
      // props.Contact && props.Contact.data && props.Contact.data.data;
      setNamaWeb(
        Contact && Contact.data.data ? Contact.data.data.namaWeb : "undefined"
      );
      setAlamat(
        Contact && Contact.data && Contact.data.data && Contact.data.data.alamat
          ? Contact.data.data.alamat
          : "undifined"
      );
      setMaps(
        Contact && Contact.data.data ? Contact.data.data.maps : "undefined"
      );
      setNomorTelp(
        Contact && Contact.data.data ? Contact.data.data.nomorTelp : "undefined"
      );
      setFacebook(
        Contact && Contact.data.data ? Contact.data.data.facebook : "undefined"
      );
      setInstagram(
        Contact && Contact.data.data ? Contact.data.data.instagram : "undefined"
      );
      setTiktok(
        Contact && Contact.data.data ? Contact.data.data.tiktok : "undefined"
      );
      setWhatsapp(
        Contact && Contact.data.data ? Contact.data.data.whatsapp : "undefined"
      );
      setTelegram(
        Contact && Contact.data.data ? Contact.data.data.telegram : "undefined"
      );
      setYoutube(
        Contact && Contact.data.data ? Contact.data.data.youtube : "undefined"
      );
    }

    {
      Contact && Contact.data && Contact.data.data && Contact.data.data.alamat
        ? console.log("datass", Contact.data.data.alamat)
        : console.log("data");
    }
    // console.log("data old", Contact.data.data.alamat);
  }, [Contact]);

  const handleSave = async () => {
    const data = {
      namaWeb,
      alamat,
      maps,
      nomorTelp,
      facebook,
      instagram,
      tiktok,
      whatsapp,
      telegram,
      youtube,
    };

    updateContact(data);
  };

  const onCancel = async (id) => {
    const confirmationResult = await Swal.fire({
      title: "Apakah anda ingin membatalkan aktivitas?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    });
    if (confirmationResult.isConfirmed) {
      try {
        navigate("/admin/management-user");
      } catch (error) {
        Swal.fire({
          title: "Error!",
          text: ` Anda tidak dapat membatalkan aktivitas ${error}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    }
  };

  return (
    <div className="pl-4 overflow-hidden max-w-screen  ">
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 2, width: "30ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="filled-basic"
          label="Nama Web"
          variant="filled"
          value={namaWeb}
          onChange={(e) => setNamaWeb(e.target.value)}
        />

        <TextField
          id="filled-basic"
          label="Alamat"
          variant="filled"
          value={alamat}
          onChange={(e) => setAlamat(e.target.value)}
        />

        <TextField
          id="filled-basic"
          label="Maps"
          variant="filled"
          value={maps}
          onChange={(e) => setMaps(e.target.value)}
        />
        <TextField
          id="filled-basic"
          label="No Telepon"
          variant="filled"
          value={nomorTelp}
          onChange={(e) => setNomorTelp(e.target.value)}
        />
        <TextField
          id="filled-basic"
          label="Facebook"
          variant="filled"
          value={facebook}
          onChange={(e) => setFacebook(e.target.value)}
        />
        <TextField
          id="filled-basic"
          label="Facebook"
          variant="filled"
          value={facebook}
          onChange={(e) => setFacebook(e.target.value)}
        />
        <TextField
          id="filled-basic"
          label="Instagram"
          variant="filled"
          value={instagram}
          onChange={(e) => setInstagram(e.target.value)}
        />
        <TextField
          id="filled-basic"
          label="Tiktok"
          variant="filled"
          value={tiktok}
          onChange={(e) => setTiktok(e.target.value)}
        />
        <TextField
          id="filled-basic"
          label="Whatsapp"
          variant="filled"
          value={whatsapp}
          onChange={(e) => setWhatsapp(e.target.value)}
        />
        <TextField
          id="filled-basic"
          label="Telegram"
          variant="filled"
          value={telegram}
          onChange={(e) => setTelegram(e.target.value)}
        />
        <TextField
          id="filled-basic"
          label="Youtube"
          variant="filled"
          value={youtube}
          onChange={(e) => setYoutube(e.target.value)}
        />
      </Box>

      <div className="w-[200px]  flex justify-between pl-4">
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={
            !namaWeb ||
            !alamat ||
            !maps ||
            !nomorTelp ||
            !facebook ||
            !instagram ||
            !tiktok ||
            !whatsapp ||
            !telegram ||
            !youtube
          }
        >
          Simpan
        </Button>

        <Button variant="contained" color="error" onClick={() => onCancel()}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const mappedProps = {
    Contact: state.Contact,
  };

  return mappedProps;
};

export default connect(mapStateToProps, {
  fetchContactList,
  updateContact,
})(UpdateContact);
