import React from "react";
import MainLayout from "../../layout/MainLayout";
import DetailLayanan from "../../components/berita/DetailLayanan";

const DetailLayananView = () => {
  return (
    <MainLayout>
      <div className="col-span-12">
        <DetailLayanan />
      </div>
    </MainLayout>
  );
};

export default DetailLayananView;
