import axios from "axios";
import {
  FETCH_CUSTOMPAGE_FAILURE,
  FETCH_CUSTOMPAGE_REQUEST,
  FETCH_CUSTOMPAGE_SUCCESS,
  FETCH_OLD_CUSTOMPAGE_FAILURE,
  FETCH_OLD_CUSTOMPAGE_SUCCESS,
  UPDATE_CUSTOMPAGE_FAILURE,
  UPDATE_CUSTOMPAGE_SUCCESS,
} from "./CustomPageActionType";
import API_BASE_URL from "../config";
import Swal from "sweetalert2";
const fetchCustomPageRequest = () => {
  return {
    type: FETCH_CUSTOMPAGE_REQUEST,
  };
};

const fetchCustomPageSuccess = (data) => {
  return {
    type: FETCH_CUSTOMPAGE_SUCCESS,
    payload: data,
  };
};

const fetchCustomPageFailure = (err) => {
  return {
    type: FETCH_CUSTOMPAGE_FAILURE,
    payload: err,
  };
};

export const saveCustomPageSuccess = (data) => ({
  type: "ADD_CUSTOMPAGE_SUCCESS",
  payload: data,
});
export const saveCustomPageFailure = (error) => ({
  type: "ADD_CUSTOMPAGE_FAILURE",
  payload: error,
});
export const updateCustomPageSuccess = (data) => ({
  type: UPDATE_CUSTOMPAGE_SUCCESS,
  payload: data,
});

export const updateCustomPageFailure = (error) => ({
  type: UPDATE_CUSTOMPAGE_FAILURE,
  payload: error,
});

export const fetchCustomPageList = () => {
  return (dispatch) => {
    dispatch(fetchCustomPageRequest());

    // Retrieve the token from session storage
    const token = sessionStorage.getItem("token"); // Replace "your_token_key" with the actual key used to store the token

    // Set the token in the request headers
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get("https://api-be-xyz.tapemansel.co.id/api/custom-pages", axiosConfig)
      .then((res) => {
        let _list = res.data;
        dispatch(fetchCustomPageSuccess(_list));
      })
      .catch((err) => {
        dispatch(fetchCustomPageFailure(err.message));
      });
  };
};

export const saveCustomPage = (customPageData) => async (dispatch) => {
  // console.log("coba", customPageData.banner);

  try {
    const token = sessionStorage.getItem("token");

    const formData = new FormData();
    formData.append("title_page", customPageData.title_page);
    formData.append("parent_id", customPageData.parent_id);
    formData.append("content", customPageData.content);
    formData.append("banner", customPageData.banner[0]); // Assuming banner is an array with a single file

    const response = await axios.post(
      `${API_BASE_URL}/api/custom-pages/store`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data", // Important for file uploads
        },
      }
    );

    dispatch(saveCustomPageSuccess(response.data));

    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "Data has been saved successfully.",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then(() => {
      window.location.href = "/admin/settingGeneral/customPage";
    });
  } catch (error) {
    dispatch(saveCustomPageFailure(error.message));

    if (error.message === "Request failed with status code 401") {
      Swal.fire({
        title: "Error!",
        text: `${error.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: `${error.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  }
};
export const fetchOldCustomPage = (slug) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");
    const response = await axios.get(
      `${API_BASE_URL}/api/custom-pages/${slug}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: FETCH_OLD_CUSTOMPAGE_SUCCESS,
      payload: response.data, // Assuming the API returns the old data
    });
  } catch (error) {
    dispatch({
      type: FETCH_OLD_CUSTOMPAGE_FAILURE,
      payload: error.message,
    });
  }
};

export const updateCustomPage = (id, customPageData) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");
    const formData = new FormData();
    formData.append("content", customPageData.content);
    formData.append("banner", customPageData.banner[0]); // Assuming banner is an array with a single file

    console.log("first", customPageData.content);
    const response = await axios.post(
      `${API_BASE_URL}/api/custom-pages/update/${id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data", // Important for file uploads
        },
        params: { _method: "PUT" }, // Add _method parameter with the value PUT
      }
    );

    console.log("Update IKK response:", response.data);
    // dispatch({ type: "ADD_URUSAN_SUCCESS", payload: response.data });
    dispatch(updateCustomPageSuccess(response.data));

    Swal.fire({
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      icon: "success",
      title: "Success!",
      text: "Data has been saved successfully.",
    }).then(() => {
      window.location.href = "/admin/settingGeneral/customPage";
    });
  } catch (err) {
    // dispatch({ type: "ADD_URUSAN_FAILURE", payload: error.message });
    dispatch(updateCustomPageFailure(err.message));
    if (err.message === "Request failed with status code 401") {
      Swal.fire({
        title: "Error!",
        text: `${err.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: `${err.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  }
};

// export const updateCustomPage = (id, customPageData) => async (dispatch) => {
//   try {
//     const token = sessionStorage.getItem("token");

//     console.log("first", customPageData.content);
//     const response = await axios.put(
//       `${API_BASE_URL}/api/custom-pages/update/${id}`,
//       {
//         content: customPageData.content,
//         banner: customPageData.banner,
//       },
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );
//     console.log("Update IKK response:", response.data);
//     // dispatch({ type: "ADD_URUSAN_SUCCESS", payload: response.data });
//     dispatch(updateCustomPageSuccess(response.data));

//     Swal.fire({
//       confirmButtonColor: "#3085d6",
//       cancelButtonColor: "#d33",
//       icon: "success",
//       title: "Success!",
//       text: "Data has been saved successfully.",
//     });
//   } catch (err) {
//     // dispatch({ type: "ADD_URUSAN_FAILURE", payload: error.message });
//     dispatch(updateCustomPageFailure(err.message));
//     if (err.message === "Request failed with status code 401") {
//       Swal.fire({
//         title: "Error!",
//         text: `${err.message}`,
//         icon: "warning",
//         cancelButtonColor: "#d33",
//         confirmButtonText: "OK",
//       }).then(() => {
//         window.location.href = "/admin/login";
//       });
//     } else {
//       Swal.fire({
//         title: "Error!",
//         text: `${err.message}`,
//         icon: "warning",
//         cancelButtonColor: "#d33",
//         confirmButtonText: "OK",
//       });
//     }
//   }
// };
