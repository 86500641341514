import React, { useEffect } from "react";
import { Text1, Text2, Text6, Text7 } from "../../mainComponent/Paragraph";
import HomeIcon from "@mui/icons-material/Home";
import { connect } from "react-redux";
import { fetchPerangkatDaerahList } from "../../redux/webPerangkatDaerah/PerangkatDaerahAction";
import { Link } from "react-router-dom";

const PerangkatDaerah = (props) => {
  useEffect(() => {
    props.fetchPerangkatDaerahList();
  }, []);
  const data = props.PerangkatDaerah.data.custom_page || [];

  const bgStyle = {
    backgroundImage: `url(${data.bannerUrl})`,
    backgroundSize: "cover", // Adjust based on your requirements
    backgroundRepeat: "no-repeat",
  };

  return (
    <div>
      <div
        className="flex justify-center  h-[150px] items-center mb-4 text-white"
        style={bgStyle}
      >
        <Text1 text={`${data.title_page}`} />
      </div>

      <div className="flex">
        <div className="mr-2">
          <Link to="/home">
            <HomeIcon />
          </Link>
        </div>
        <div className="mr-2">
          <Link to="/home">
            <Text6 text="Home" />
          </Link>
        </div>
        <div className="mr-2">
          <Text6 text="/" />
        </div>
        <div className="mr-2 ">
          <Text7 text="Perangkat Daerah" />
        </div>
      </div>
      <div className="mt-4">
        {/* <Text2 text={`${data.slug}`} /> */}
        {/* <div className="mt-2">
          <img src={data.bannerUrl} alt="gambar" className="w-[1000px]" />
        </div> */}
        <div
          dangerouslySetInnerHTML={{
            __html: data.content,
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    PerangkatDaerah: state.PerangkatDaerah,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPerangkatDaerahList: () => dispatch(fetchPerangkatDaerahList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PerangkatDaerah);
