import {
  FETCH_IKK_USER_PENGAJUAN_REPORT_FAILURE,
  FETCH_IKK_USER_PENGAJUAN_REPORT_REQUEST,
  FETCH_IKK_USER_PENGAJUAN_REPORT_SUCCESS,
  FETCH_OLD_IKK_USER_PENGAJUAN_REPORT_FAILURE,
  FETCH_OLD_IKK_USER_PENGAJUAN_REPORT_SUCCESS,
  REDIRECT_TO_LOGIN,
  UPDATE_IKK_USER_PENGAJUAN_REPORT_FAILURE,
  UPDATE_IKK_USER_PENGAJUAN_REPORT_SUCCESS,
} from "./ActionType";

const initialstate = {
  loading: true,
  data: [],
  oldData: "",
  error: "",
  redirectToLogin: false,
  deleteIkkStatus: null,
};

export const IkkUserPengajuanReportReducer = (state = initialstate, action) => {
  switch (action.type) {
    case FETCH_IKK_USER_PENGAJUAN_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_IKK_USER_PENGAJUAN_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    case FETCH_IKK_USER_PENGAJUAN_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };

    case REDIRECT_TO_LOGIN:
      return {
        ...state,
        redirectToLogin: true,
      };
    case FETCH_OLD_IKK_USER_PENGAJUAN_REPORT_SUCCESS:
      return {
        ...state,
        oldData: action.payload,
      };
    case FETCH_OLD_IKK_USER_PENGAJUAN_REPORT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_IKK_USER_PENGAJUAN_REPORT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
      };
    case UPDATE_IKK_USER_PENGAJUAN_REPORT_FAILURE:
      return {
        ...state,
        data: [],
        error: action.payload || "An unknown error occurred",
      };

    default:
      return state;
  }
};
