import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import defaultImage from "../../assets/defaultimage.png";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  clearErrors,
  saveIKK,
  updateIKK,
  fetchOldIKK,
  fetchIKKList,
} from "../../redux/ikk/IkkAction";
import { fetchUrusanList } from "../../redux/urusan/UrusanAction";
import DropdownUrusan from "./urusan/dropdownUrusan";

const EditIKK = ({ updateIKK, fetchOldIKK, oldIKKData, props }) => {
  const { id } = useParams();
  const [noIKK, setNoIKK] = useState("");
  const [IKKOutput, setIKKOutput] = useState("");
  const [IKKOutcome, setIKKOutcome] = useState("");
  const [capaian, setCapaian] = useState("");
  const [rumus, setRumus] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [var_rumus1, setVar_rumus1] = useState("");
  const [rumus1, setRumus1] = useState("");
  const [var_rumus2, setVar_rumus2] = useState("");
  const [rumus2, setRumus2] = useState("");
  const [updatePemda, setUpdatePemda] = useState("");
  const [tahun, setTahun] = useState("");
  const [definisiOperasional, setDefinisiOperasional] = useState("");
  const [urusanSelect, setUrusanSelect] = useState("");
  const [selectedFile, setSelectedFile] = useState(defaultImage);

  useEffect(() => {
    fetchOldIKK(id);
  }, [fetchOldIKK]);

  useEffect(() => {
    if (oldIKKData) {
      setNoIKK(oldIKKData.no_ikk);
      setIKKOutput(oldIKKData.ikk_output);
      setIKKOutcome(oldIKKData.ikk_outcome);
      setRumus(oldIKKData.rumus);
      setRumus1(oldIKKData.var_rumus1);
      setRumus2(oldIKKData.var_rumus2);
      setDefinisiOperasional(oldIKKData.definisi_operasional);
      setUrusanSelect(oldIKKData.urusan_id);
      setSelectedFile(oldIKKData.selectedFile);
    }
  }, [oldIKKData]);

  const handleUpdate = () => {
    const updatedIKKData = {
      noIKK,
      IKKOutput,
      IKKOutcome,
      rumus,
      capaian,
      keterangan,
      var_rumus1,
      var_rumus2,
      rumus1,
      rumus2,
      updatePemda,
      tahun,
      urusanSelect,
    };

    // Assuming you have the ID of the IKK item to update (hardcoded as 2 in this case)
    updateIKK(id, updatedIKKData);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file ? URL.createObjectURL(file) : defaultImage);
  };

  const onCancel = async (id) => {};

  return (
    <div className="pl-4 overflow-hidden max-w-screen  ">
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 2, width: "30ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="filled-basic"
          label="No IKK"
          variant="filled"
          value={noIKK}
          onChange={(e) => setNoIKK(e.target.value)}
        />

        <TextField
          id="filled-basic"
          label="IKK Output"
          variant="filled"
          value={IKKOutput}
          onChange={(e) => setIKKOutput(e.target.value)}
        />

        <TextField
          id="filled-basic"
          label="IKK Outcome"
          variant="filled"
          value={IKKOutcome}
          onChange={(e) => setIKKOutcome(e.target.value)}
        />

        <TextField
          id="filled-basic"
          label="Rumus"
          variant="filled"
          value={rumus}
          onChange={(e) => setRumus(e.target.value)}
        />

        <TextField
          id="filled-basic"
          label="Capaian"
          variant="filled"
          value={capaian}
          onChange={(e) => setCapaian(e.target.value)}
        />

        <TextField
          id="filled-basic"
          label="Keterangan"
          variant="filled"
          value={rumus}
          onChange={(e) => setRumus(e.target.value)}
        />

        <TextField
          id="filled-basic"
          label="Var Rumus 1 "
          variant="filled"
          value={var_rumus1}
          onChange={(e) => setVar_rumus1(e.target.value)}
        />
        <TextField
          id="filled-basic"
          label="Nilai Rumus 1 "
          variant="filled"
          value={rumus1}
          onChange={(e) => setRumus1(e.target.value)}
        />

        <TextField
          id="filled-basic"
          label="Var Rumus 2 "
          variant="filled"
          value={var_rumus2}
          onChange={(e) => setVar_rumus2(e.target.value)}
        />
        <TextField
          id="filled-basic"
          label="Nilai Rumus 2 "
          variant="filled"
          value={rumus2}
          onChange={(e) => setRumus2(e.target.value)}
        />

        <TextField
          id="filled-basic"
          label="Update Pemda"
          variant="filled"
          value={updatePemda}
          onChange={(e) => setUpdatePemda(e.target.value)}
        />
        <TextField
          id="filled-basic"
          label="Tahun "
          variant="filled"
          value={tahun}
          onChange={(e) => setTahun(e.target.value)}
        />
        <DropdownUrusan setUrusanSelect={setUrusanSelect} />
      </Box>

      <div className="w-[200px]  flex justify-between pl-4">
        <Button
          variant="contained"
          onClick={handleUpdate}
          disabled={
            !noIKK ||
            !IKKOutput ||
            !IKKOutcome ||
            !rumus ||
            !rumus1 ||
            !rumus2 ||
            !definisiOperasional ||
            !urusanSelect
          }
        >
          Simpan
        </Button>

        <Button variant="contained" color="error" onClick={() => onCancel()}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const mappedProps = {
    // Existing mapStateToProps...
    oldIKKData: state.IkkList.oldIKKData,
  };

  console.log("Mapped Props:", mappedProps); // Add this line to log the result

  return mappedProps;
};

export default connect(mapStateToProps, {
  saveIKK,
  fetchUrusanList,
  fetchOldIKK,
  updateIKK,
  fetchIKKList,
})(EditIKK);
