import React, { useEffect, useState } from "react";
import { Text10 } from "../../../mainComponent/Paragraph";
import Button from "@mui/material/Button";
import DescriptionIcon from "@mui/icons-material/Description";
import { Stack } from "@mui/material";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { fetchIKKOutputList } from "../../../redux/ikkOutput/IkkOutputAction";

const PelaporanIKK = (props) => {
  const Swal = require("sweetalert2");

  useEffect(() => {
    props.fetchIKKOutputList();
  }, []);

  console.log("pelaporan ikk output", props.IkkOutputList.data.data);
  const data = props.IkkOutputList.data.data || [];

  const itemsPerPage = 5; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [checkedItems, setCheckedItems] = useState({});

  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handlePrevClick = () => {
    paginate(currentPage - 1);
  };

  const handleNextClick = () => {
    paginate(currentPage + 1);
  };

  // delete function
  const onDelete = async (id) => {
    const confirmationResult = await Swal.fire({
      title: "Apakah anda ingin menghapus data ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    });
    if (confirmationResult.isConfirmed) {
      try {
        // const response = await axios.delete(
        //   `https://laravel-api-10.cerise.id/api/setting-generals/couriers/delete/${id}`,
        //   {
        //     headers: {
        //       Authorization: `Bearer ${localStorage.getItem("token")}`,
        //     },
        //   }
        // );
        Swal.fire({
          title: "Success!",
          text: "Delete data user berhasil ",
          confirmButtonColor: "#3085d6",
          icon: "success",
          confirmButtonText: "OK",
        });
      } catch (error) {
        Swal.fire({
          title: "Error!",
          text: ` Data User Tidak Dapat Dihapus ${error}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const handleCheck = (id) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [id]: !prevCheckedItems[id],
    }));
  };

  const handleCheckAll = (isCheckedAll) => {
    const newCheckedItems = {};
    data.forEach((item) => {
      newCheckedItems[item.id] = isCheckedAll;
    });
    setCheckedItems(newCheckedItems);
  };

  const isChecked = (id) => {
    return checkedItems[id] || false;
  };

  return (
    <div>
      <div className="grid grid-cols-12 gap-4 ">
        <div className="  col-span-6">
          <Text10 text="Keterangan :" />
          <div className="flex items-center">
            <div className="w-[15px] h-[15px] bg-red-500 mr-2"></div>
            <div className="flex items-center">
              <Text10 text="IKK yang mendapat rekomendasi" />
            </div>
          </div>
          <div className="flex items-center ">
            <div className="w-[15px] h-[15px] bg-green-500 mr-2"></div>
            <div className="flex items-center ">
              <Text10 text="IKK yang mendapat rekomendasi dan telah diperbaharui" />
            </div>
          </div>
        </div>
        <div className="col-span-6 text-right">
          <Button variant="contained" endIcon={<DescriptionIcon />}>
            Unduh Laporan
          </Button>
        </div>
      </div>

      <table className="w-[90vw] mt-8 ">
        <thead>
          <tr className="bg-gray">
            <th className="p-4">
              <input
                type="checkbox"
                onChange={(e) => handleCheckAll(e.target.checked)}
              />
            </th>
            <th className="p-4">Id Urusan</th>
            <th className="p-4">No IKK Outcome</th>
            <th className="p-4">IKK Output in Outcome</th>
            <th className="p-4">Nilai Pemda</th>
            <th className="p-4">Update by Pemda</th>
            <th className="p-4">Desc Pemda</th>
            <th className="p-4">Bukti File</th>
            <th className="p-4">Status APIP</th>
            <th className="p-4">Status Rekomendasi</th>
            <th className="p-4">Catatan Rekomendasi</th>
            <th className="p-4">Aksi </th>
          </tr>
        </thead>
        <tbody>{/* {currentItems.map((item, index) => ( */}</tbody>
      </table>

      {/* Pagination */}
      {/* Pagination */}
      <div className="flex justify-center mt-4 pb-4">
        <button
          onClick={handlePrevClick}
          className={`mx-2 px-4 py-2 ${
            currentPage === 1 ? "bg-gray text-white rounded-full" : ""
          }`}
        >
          {"<"}
        </button>

        {[...Array(totalPages).keys()].map((number) => (
          <button
            key={number}
            onClick={() => paginate(number + 1)}
            className={`mx-2 px-4 py-2 ${
              currentPage === number + 1
                ? "bg-gray text-white rounded-full"
                : ""
            }`}
          >
            {number + 1}
          </button>
        ))}

        <button
          onClick={handleNextClick}
          className={`mx-2 px-4 py-2 ${
            currentPage === totalPages ? "bg-gray text-white rounded-full" : ""
          }`}
        >
          {">"}
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    IkkOutputList: state.IkkOutputList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchIKKOutputList: () => dispatch(fetchIKKOutputList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PelaporanIKK);
