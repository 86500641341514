import React from "react";
import MainLayoutAdmin from "../../../layout/MainLayoutAdmin";
import { Text8 } from "../../../mainComponent/Paragraph";
import Ikk from "../../../componentsAdmin/userPengajuan/ikk/ikk";
import EditUserPengajuanIKK from "../../../componentsAdmin/userPengajuan/ikk/EditUserPengajuanIKK";

const EditUserPengajuanIKKAdm = () => {
  return (
    <MainLayoutAdmin>
      <div>
        <div className="pb-4  border-b border-darkgray mb-8">
          <Text8 text=" Edit IKK " />
        </div>
        <EditUserPengajuanIKK />
      </div>
    </MainLayoutAdmin>
  );
};

export default EditUserPengajuanIKKAdm;
