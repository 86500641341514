import React from "react";
import MainLayoutAdmin from "../../../layout/MainLayoutAdmin";
import { Text8 } from "../../../mainComponent/Paragraph";
import Settings from "../../../componentsAdmin/settingGeneral/Settings";

const SettingGeneralAdm = () => {
  return (
    <MainLayoutAdmin>
      <div>
        <div className="pb-4 ml-8 border-b border-darkgray mb-8">
          <Text8 text="Setting General" />
        </div>
        <Settings />
      </div>
    </MainLayoutAdmin>
  );
};

export default SettingGeneralAdm;
