import React, { useEffect, useState } from "react";
import edit from "../../../assets/edit.png";
import deleteIcon from "../../../assets/delete.png";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { connect } from "react-redux";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PrintIcon from "@mui/icons-material/Print";
import excel from "../../../assets/excel.png";
import { TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import detail from "../../../assets/search.png";
import { fetchPelaporanIKKOutputDetail } from "../../../redux/pelaporanIKKOutput/PelaporanIKKOutputAction";
// modal

const PelaporanIKKOutputDetail = (props) => {
  const Swal = require("sweetalert2");
  const { id } = useParams();
  const itemsPerPage = 5; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const [checkedItems, setCheckedItems] = useState({});
  const role = sessionStorage.getItem("role");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    props.fetchPelaporanIKKOutputDetail(id);
  }, []);
  const data = props.PelaporanIKKOutput.detail || [];
  console.log("detail ikk", data);

  return (
    <div>
      <div className="grid grid-cols-12 gap-2">
        <div className="col-span-4">
          <p>Tahun</p>
        </div>
        <div className="col-span-1 text-right">:</div>
        <div className="col-span-7">{data.data.tahun || ""}</div>
      </div>
      <div className="grid grid-cols-12 gap-2">
        <div className="col-span-4">
          <p>Deskripsi Pemda</p>
        </div>
        <div className="col-span-1 text-right">:</div>
        <div className="col-span-7">{data.data.desc_pemda || ""}</div>
      </div>
      <div className="grid grid-cols-12 gap-2">
        <div className="col-span-4">
          <p>IKK Output in Outcome</p>
        </div>
        <div className="col-span-1 text-right">:</div>
        <div className="col-span-7">{data.data.ikk_output_in_outcome}</div>
      </div>
      <div className="grid grid-cols-12 gap-2">
        <div className="col-span-4">
          <p>Nilai Pemda</p>
        </div>
        <div className="col-span-1 text-right">:</div>
        <div className="col-span-7">{data.data.nilai_pemda}</div>
      </div>
      <div className="grid grid-cols-12 gap-2">
        <div className="col-span-4">
          <p>Update by Pemda</p>
        </div>
        <div className="col-span-1 text-right">:</div>
        <div className="col-span-7">{data.data.update_by_pemda}</div>
      </div>
      <div className="grid grid-cols-12 gap-2">
        <div className="col-span-4">
          <p>Status APIP </p>
        </div>
        <div className="col-span-1 text-right">:</div>
        <div className="col-span-7">{data.data.status_apip}</div>
      </div>
      <div className="grid grid-cols-12 gap-2">
        <div className="col-span-4">
          <p>Desc APIP </p>
        </div>
        <div className="col-span-1 text-right">:</div>
        <div className="col-span-7">{data.data.desc_apip}</div>
      </div>
      <div className="grid grid-cols-12 gap-2">
        <div className="col-span-4">
          <p>Update APIP </p>
        </div>
        <div className="col-span-1 text-right">:</div>
        <div className="col-span-7">{data.data.update_apip}</div>
      </div>
      <div className="grid grid-cols-12 gap-2">
        <div className="col-span-4">
          <p>Status Rekomendasi </p>
        </div>
        <div className="col-span-1 text-right">:</div>
        <div className="col-span-7">{data.data.status_rekomendasi}</div>
      </div>
      <div className="grid grid-cols-12 gap-2">
        <div className="col-span-4">
          <p>Keterangan Rekomendasi </p>
        </div>
        <div className="col-span-1 text-right">:</div>
        <div className="col-span-7">{data.data.keterangan_rekomendasi}</div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  const mappedProps = {
    PelaporanIKKOutput: state.PelaporanIKKOutput,
  };
  console.log("Mapped Props:", mappedProps); // Add this line to log the result
  return mappedProps;
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPelaporanIKKOutputDetail: (id) =>
      dispatch(fetchPelaporanIKKOutputDetail(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PelaporanIKKOutputDetail);
