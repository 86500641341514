import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { fetchGalleryList } from "../../redux/webGallery/GalleryAction";

const Gallery2 = (props) => {
  useEffect(() => {
    props.fetchGalleryList();
  }, []);
  const data = props.Gallery.data.data || [];
  if (!data) {
    return <div>Loading...</div>; // You can show a loading indicator or handle it in another way
  }

  console.log("perangkat daerah", data);
  const initialPhotos = [
    {
      url: "https://cdnwpedutorenews.gramedia.net/wp-content/uploads/2022/07/13193302/Contoh-budaya-budaya-Indonesia1-810x455.jpg",
      checked: false,
    },
    {
      url: "https://cdnwpedutorenews.gramedia.net/wp-content/uploads/2022/07/13193302/Contoh-budaya-budaya-Indonesia1-810x455.jpg",
      checked: false,
    },
    {
      url: "https://cdnwpedutorenews.gramedia.net/wp-content/uploads/2022/07/13193302/Contoh-budaya-budaya-Indonesia1-810x455.jpg",
      checked: false,
    },
    // Add more photo objects as needed
  ];
  const Swal = require("sweetalert2");

  return (
    <div className="grid grid-cols-12 gap-4">
      {data.map((photo, index) => (
        <div key={index} className="mb-4 col-span-12 md:col-span-3">
          <div>
            <img
              src={photo.url}
              alt={`Photo ${index + 1}`}
              className="w-[500px] h-[300px] rounded-lg shadow-md"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Gallery: state.Gallery,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGalleryList: () => dispatch(fetchGalleryList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Gallery2);
