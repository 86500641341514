import React from "react";
import { Helmet } from "react-helmet";

const GprWidget = () => {
  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src="https://widget.kominfo.go.id/gpr-widget-kominfo.min.js"
        ></script>
      </Helmet>
      <div id="gpr-kominfo-widget-container"></div>
    </>
  );
};

export default GprWidget;
