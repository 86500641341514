import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchHomeList } from "../../redux/lppdHome/Action";
import { Text2, Text3, Text4, Text6 } from "../../mainComponent/Paragraph";
import opet from "../../assets/s1mu.webp";
import { Link } from "react-router-dom";

const Agenda = (props) => {
  useEffect(() => {
    props.fetchHome(); // Use the correct prop name "fetchHome" here
  }, []);

  return props.homeList.loading ? (
    <h2>loading...</h2>
  ) : props.homeList.error ? (
    <h2>{props.homeList.error}</h2>
  ) : (
    <div>
      <div>
        <div className="border-b border-blue">
          <div className="bg-blue w-[80px] px-2 py-1 rounded-t-lg">
            <Text4 text="AGENDA" />
          </div>
        </div>

        {props.homeList &&
          props.homeList.data.homepages &&
          props.homeList.data.homepages.map((item) => (
            <div key={item.id} className=" mt-4 ">
              {item.agenda ? (
                item.agenda.length > 0 ? (
                  <div className="grid grid-cols-12 gap-2">
                    {item.agenda.slice(0, 4).map((agendaItem, index) => (
                      <div className="col-span-6 " key={index}>
                        <div className="">
                          <img
                            src={agendaItem.imageThumbnailUrl}
                            alt="image"
                            className="rounded-lg h-[100px] w-[250px]"
                            onError={(e) => {
                              e.target.src = opet; // Set default image on error
                            }}
                          />
                        </div>
                        <div className="col-span-8">
                          <Text2 text={agendaItem.title_page} />
                          <div
                            dangerouslySetInnerHTML={{
                              __html: agendaItem.content,
                            }}
                          />
                          {/* <Text6 text={agendaItem.content} /> */}
                        </div>
                      </div>
                    ))}

                    {/* <div className="  flex justify-start items-end ">
                      <Link to="/agenda">
                        <Text3 text="Selengkapnya..." />
                      </Link>
                    </div> */}
                  </div>
                ) : (
                  <div className="col-span-12">
                    <Text2 text="No available data" />
                  </div>
                )
              ) : (
                <p>No agenda data</p>
              )}
            </div>
          ))}

        {/* <div className=" grid grid-cols-12 gap-2  mt-4">
          <div className="col-span-4">
            <img src={opet} alt="" className="rounded-lg" />
          </div>
          <div className="col-span-8">
            <p>AGENDA 1</p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    homeList: state.homeList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchHome: () => dispatch(fetchHomeList()), // Use the correct prop name "fetchHome" here
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Agenda);
