import axios from "axios";
import API_BASE_URL from "../config";
import Swal from "sweetalert2";
import {
  FETCH_LAYANAN_FAILURE,
  FETCH_LAYANAN_REQUEST,
  FETCH_LAYANAN_SUCCESS,
} from "./ActionType";

const fetchLayananRequest = () => {
  return {
    type: FETCH_LAYANAN_REQUEST,
  };
};

const fetchLayananSuccess = (data) => {
  return {
    type: FETCH_LAYANAN_SUCCESS,
    payload: data,
  };
};

const fetchLayananFailure = (err) => {
  return {
    type: FETCH_LAYANAN_FAILURE,
    payload: err,
  };
};

export const fetchLayananList = () => {
  return (dispatch) => {
    dispatch(fetchLayananRequest());

    // Retrieve the token from session storage
    const token = sessionStorage.getItem("token"); // Replace "your_token_key" with the actual key used to store the token

    // Set the token in the request headers
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${API_BASE_URL}/api/list/layanan`, axiosConfig)
      .then((res) => {
        let _list = res.data;
        dispatch(fetchLayananSuccess(_list));
      })
      .catch((err) => {
        dispatch(fetchLayananFailure(err.message));
      });
  };
};
