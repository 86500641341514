import {
  FETCH_CONTACT_FAILURE,
  FETCH_CONTACT_REQUEST,
  FETCH_CONTACT_SUCCESS,
  FETCH_OLD_CONTACT_FAILURE,
  FETCH_OLD_CONTACT_SUCCESS,
  UPDATE_CONTACT_FAILURE,
  UPDATE_CONTACT_SUCCESS,
} from "./ActionType";

const initialstate = {
  loading: true,
  data: [],
  oldData: null,
  error: "",
};

export const ContactReducer = (state = initialstate, action) => {
  switch (action.type) {
    case "CLEAR_ERRORS":
      return { ...state, error: null };
    case FETCH_CONTACT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    case FETCH_CONTACT_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };

    case FETCH_OLD_CONTACT_SUCCESS:
      return {
        ...state,
        oldData: action.payload,
      };
    case FETCH_OLD_CONTACT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
      };
    case UPDATE_CONTACT_FAILURE:
      return {
        ...state,
        data: [],
        error: action.payload || "An unknown error occurred",
      };
    default:
      return state;
  }
};
