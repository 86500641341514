import "./App.css";
import * as React from "react";
import WebRoute from "./router/WebRoute";
import { Provider } from "react-redux";
import store from "./redux/home/store";
import CopyIcon from "./assets/copy.png";

function App() {
  return (
    <Provider store={store}>
      <WebRoute />
    </Provider>
  );
}

export default App;
