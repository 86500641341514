import {
  FETCH_PELAPORAN_IKK_OUTPUT_DETAIL_FAILURE,
  FETCH_PELAPORAN_IKK_OUTPUT_DETAIL_REQUEST,
  FETCH_PELAPORAN_IKK_OUTPUT_DETAIL_SUCCESS,
  FETCH_PELAPORAN_IKK_OUTPUT_FAILURE,
  FETCH_PELAPORAN_IKK_OUTPUT_REQUEST,
  FETCH_PELAPORAN_IKK_OUTPUT_SUCCESS,
  REDIRECT_TO_LOGIN,
} from "./ActionType";

const initialstate = {
  loading: true,
  data: [],
  error: "",
  detail: null,
  redirectToLogin: false,
  deleteIkkStatus: null,
};

export const PelaporanIKKOutputReducer = (state = initialstate, action) => {
  switch (action.type) {
    case FETCH_PELAPORAN_IKK_OUTPUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PELAPORAN_IKK_OUTPUT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    case FETCH_PELAPORAN_IKK_OUTPUT_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };
    case FETCH_PELAPORAN_IKK_OUTPUT_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PELAPORAN_IKK_OUTPUT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        detail: action.payload,
        error: "",
      };
    case FETCH_PELAPORAN_IKK_OUTPUT_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        detail: [],
        error: action.payload,
      };

    case REDIRECT_TO_LOGIN:
      return {
        ...state,
        redirectToLogin: true,
      };
    default:
      return state;
  }
};
