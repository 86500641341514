import React from "react";
import { Input1 } from "./Input";

const InputView = () => {
  return (
    <div>
      <Input1 />
    </div>
  );
};

export default InputView;
