import { GET_CATEGORY, GET_HOME } from "./ActionType";

const initialstate = {
  id: 1,
  category: 22,
};
export const HomeReducer = (state = initialstate, action) => {
  switch (action.type) {
    case GET_HOME:
      return {
        ...state,
        id: state.id,
      };
    case GET_CATEGORY:
      return {
        ...state,
        category: state.category,
      };
    default:
      return state;
  }
};

// export default HomeReducer;
