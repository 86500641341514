import React from "react";
import MainLayoutAdmin from "../../../layout/MainLayoutAdmin";
import { Text8 } from "../../../mainComponent/Paragraph";
import ViewManagement from "../../../componentsAdmin/masterManagement/ViewManagement";

const ManagementUserAdm = () => {
  return (
    <MainLayoutAdmin>
      <div>
        <div className="pb-4  border-b border-darkgray mb-8">
          <Text8 text="Management User" />
        </div>
        <ViewManagement />
      </div>
    </MainLayoutAdmin>
  );
};

export default ManagementUserAdm;
