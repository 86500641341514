import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import defaultImage from "../../../assets/defaultimage.png";
import {
  cloneIKKOutput,
  updateIKKOutput,
  fetchOldIKKOutput,
} from "../../../redux/ikkOutput/IkkOutputAction";

const PelaporanIKKOutputPengajuanClone = ({
  cloneIKKOutput,
  updateIKKOutput,
  fetchOldIKKOutput,
  IkkOutputList,
  clearErrors,
  error,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [nilai_pemda, setNilaiPemda] = useState("");
  const [desc_pemda, setDescPemda] = useState("");
  const [selectedFile, setSelectedFile] = useState(defaultImage);

  console.log("image", selectedFile);

  useEffect(() => {
    fetchOldIKKOutput(id);
  }, [fetchOldIKKOutput]);

  useEffect(() => {
    if (IkkOutputList && IkkOutputList.data) {
      setNilaiPemda(IkkOutputList.data.nilai_pemda || "");
      setDescPemda(IkkOutputList.data.desc_pemda || "");
    }
  }, [IkkOutputList]);

  const isDataEmpty = () => {
    return !nilai_pemda || !desc_pemda || !selectedFile;
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];

      if (allowedTypes.includes(file.type)) {
        // Create a URL for the selected file and set it directly to state
        setSelectedFile(URL.createObjectURL(file));
      } else {
        Swal.fire({
          title: "Invalid File Type!",
          text: "Please select a valid file type (doc, pdf, xlsx, docx).",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const handleUpdate = async () => {
    const updatedIKKMakroData = {
      nilai_pemda,
      desc_pemda,
      selectedFile,
    };

    updateIKKOutput(id, updatedIKKMakroData);
  };

  const onCancel = async () => {
    const confirmationResult = await Swal.fire({
      title: "Apakah anda ingin membatalkan aktivitas?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    });
    if (confirmationResult.isConfirmed) {
      navigate("admin/ikk/pelaporan-ikk-makro");
    }
  };

  return (
    <div className="pl-8 overflow-hidden max-w-screen">
      <Box
        component="form"
        sx={{
          "& > :not(style)": { pb: 2, pr: 2, width: "30ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="filled-basic"
          label="Nilai Pemda"
          variant="filled"
          value={nilai_pemda}
          onChange={(e) => setNilaiPemda(e.target.value)}
        />

        <TextField
          id="filled-basic"
          label="Deskripsi "
          variant="filled"
          value={desc_pemda}
          onChange={(e) => setDescPemda(e.target.value)}
        />

        <div>
          <input type="file" onChange={handleFileChange} className="ml-2" />
        </div>
      </Box>

      <div className="w-[200px] flex justify-between">
        <Button
          variant="contained"
          onClick={handleUpdate}
          disabled={isDataEmpty()}
        >
          Simpan
        </Button>

        <Button variant="contained" color="error" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const mappedProps = {
    IkkOutputList: state.IkkOutputList.oldIKKOutputData,
  };

  console.log("Mapped Props:", mappedProps);
  return mappedProps;
};

export default connect(mapStateToProps, {
  cloneIKKOutput,
  fetchOldIKKOutput,
  updateIKKOutput,
})(PelaporanIKKOutputPengajuanClone);
