import React, { useEffect } from "react";
import defaultImg from "../../assets/default2.png";
import { Text2, Text3, Text6 } from "../../mainComponent/Paragraph";
import { fetchArticleList } from "../../redux/article/ArticleAction";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const ListArtikel = (props) => {
  useEffect(() => {
    props.fetchArticleList();
  }, []);
  const data = props.articleList.data.data || "";
  if (!Array.isArray(data)) {
    // Handle the case where data is not an array
    return <div>No articles to display.</div>;
  }

  console.log("perangkat daerah", data);

  return (
    <div className="grid grid-cols-12 gap-2">
      {data.map((item, index) => (
        <div className="col-span-12 md:col-span-4 " key={index}>
          {/* <Text2 text={`${item.title_page}`} /> */}
          {/* <p>category : {item.category.name}</p> */}
          <img
            src={item.imageThumbnailUrl}
            alt="artikel"
            className="rounded-md w-[500px] h-[300px]"
            onError={(e) => {
              e.target.src = defaultImg;
            }}
          />
          <Text3 text={item.title_page.slice(0, 30)} />
          <Link
            to={`/artikel/detail-artikel/${item.slug}/${item.category.name}`}
          >
            <Text2 text="Lihat Detail" />
          </Link>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    articleList: state.articleList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchArticleList: () => dispatch(fetchArticleList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListArtikel);
