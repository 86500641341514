import {
  DELETE_CAPAIAN_FAILURE,
  DELETE_CAPAIAN_SUCCESS,
  FETCH_CAPAIAN_IKK_FAILURE,
  FETCH_CAPAIAN_IKK_REQUEST,
  FETCH_CAPAIAN_IKK_SUCCESS,
  FETCH_OLD_CAPAIAN_FAILURE,
  FETCH_OLD_CAPAIAN_SUCCESS,
  REDIRECT_TO_LOGIN,
  UPDATE_CAPAIAN_FAILURE,
  UPDATE_CAPAIAN_SUCCESS,
} from "./ActionType";

const initialstate = {
  loading: true,
  data: [],
  oldCapaianData: null,
  error: "",
  redirectToLogin: false,
  deleteIkkStatus: null,
};

export const CapaianReducer = (state = initialstate, action) => {
  switch (action.type) {
    case FETCH_CAPAIAN_IKK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CAPAIAN_IKK_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    case FETCH_CAPAIAN_IKK_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };
    case REDIRECT_TO_LOGIN:
      return {
        ...state,
        redirectToLogin: true,
      };
    case DELETE_CAPAIAN_SUCCESS:
      return {
        ...state,
        deleteIkkStatus: "success",
      };
    case DELETE_CAPAIAN_FAILURE:
      return {
        ...state,
        deleteIkkStatus: "failure",
      };
    case FETCH_OLD_CAPAIAN_SUCCESS:
      return {
        ...state,
        oldCapaianData: action.payload,
      };

    case FETCH_OLD_CAPAIAN_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_CAPAIAN_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
      };
    case UPDATE_CAPAIAN_FAILURE:
      return {
        ...state,
        data: [],
        error: action.payload || "An unknown error occurred",
      };
    default:
      return state;
  }
};
