import React from "react";
import MainLayout from "../../layout/MainLayout";
import Layanan from "../../components/berita/Layanan";

const LayananView = () => {
  return (
    <MainLayout>
      <div className="col-span-12">
        <Layanan />
      </div>
    </MainLayout>
  );
};

export default LayananView;
