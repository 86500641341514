import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box, MenuItem, Stack, TextField } from "@mui/material";
import jsPDF from "jspdf";
import * as XLSX from "xlsx";

import { fetchIKKMakroPengajuanDaerahList } from "../../../redux/ikkMakroUserPengajuanDaerah/IkkMakroPengajuanDaerahAction";

const IKKMakroDropdown = (props) => {
  const Swal = require("sweetalert2");
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [ikkMakro, setIKKMakroId] = useState(1);
  const [year, setYear] = useState(currentYear);

  useEffect(() => {
    props.fetchIKKMakroPengajuanDaerahList(year);
  }, [year]);

  // console.log("pelaporan ikk output", props.IkkOutputList);
  const data = props.IkkMakroPengajuanDaerahList.data.data || [];
  console.log("dropdown", data);

  return (
    <div className="">
      <Box
        component="form"
        sx={{
          "& > :not(style)": { width: "30ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="filled-select-currency"
          select
          label="Select"
          value={ikkMakro}
          onChange={(e) => {
            setIKKMakroId(e.target.value);
            props.setIKKSelect(e.target.value); // Call the prop function
          }}
          helperText="Pilih IKK"
          variant="filled"
        >
          {data.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.ikk}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    IkkMakroPengajuanDaerahList: state.IkkMakroPengajuanDaerahList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchIKKMakroPengajuanDaerahList: (year) =>
      dispatch(fetchIKKMakroPengajuanDaerahList(year)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IKKMakroDropdown);
