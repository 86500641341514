import {
  FETCH_PELAPORAN_IKK_FAILURE,
  FETCH_PELAPORAN_IKK_REQUEST,
  FETCH_PELAPORAN_IKK_SUCCESS,
  REDIRECT_TO_LOGIN,
} from "./ActionType";

const initialstate = {
  loading: true,
  data: [],
  error: "",
  redirectToLogin: false,
  deleteIkkStatus: null,
};

export const PelaporanIKKReducer = (state = initialstate, action) => {
  switch (action.type) {
    case FETCH_PELAPORAN_IKK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PELAPORAN_IKK_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    case FETCH_PELAPORAN_IKK_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };
    case REDIRECT_TO_LOGIN:
      return {
        ...state,
        redirectToLogin: true,
      };
    default:
      return state;
  }
};
