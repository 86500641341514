// AddUrusan.js
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import DropdownUrusan from "../urusan/dropdownUrusan";
import {
  saveIKKOutput,
  clearErrors,
} from "../../../redux/ikkOutput/IkkOutputAction";
const AddPelaporanIKKOutput = ({ saveIKKOutput, clearErrors, error }) => {
  const navigate = useNavigate();
  const [urusanSelect, setUrusanSelect] = useState("");
  const [noIkkOutcome, setNoIkkOutcome] = useState("");
  const [ikkOutputInOutcome, setIkkOutputInOutcome] = useState("");

  const isDataEmpty = () => {
    // Check if all input fields are empty
    return !noIkkOutcome || !ikkOutputInOutcome || !urusanSelect;
  };

  const handleSave = () => {
    const data = {
      noIkkOutcome,
      ikkOutputInOutcome,
      urusanSelect,
    };

    saveIKKOutput(data);
  };

  useEffect(() => {
    if (error) {
      Swal.fire({
        title: "Error!",
        text: `Failed to save Urusan. ${error}`,
        icon: "error",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
    clearErrors();
  }, [error, clearErrors]);

  const onCancel = async () => {
    const confirmationResult = await Swal.fire({
      title: "Apakah anda ingin membatalkan aktivitas?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    });
    if (confirmationResult.isConfirmed) {
      navigate("admin/ikk/pelaporan-ikk-makro");
    }
  };

  return (
    <div className="pl-8 overflow-hidden max-w-screen">
      <Box
        component="form"
        sx={{
          "& > :not(style)": { pb: 2, pr: 2, width: "30ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <DropdownUrusan setUrusanSelect={setUrusanSelect} />

        <TextField
          id="filled-basic"
          label="No IKK Outcome"
          variant="filled"
          value={ikkOutputInOutcome}
          onChange={(e) => setIkkOutputInOutcome(e.target.value)}
        />

        <TextField
          id="filled-basic"
          label="IKK Outcome"
          variant="filled"
          value={noIkkOutcome}
          onChange={(e) => setNoIkkOutcome(e.target.value)}
        />
      </Box>

      <div className="w-[200px] flex justify-between">
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={isDataEmpty()}
        >
          Simpan
        </Button>

        <Button variant="contained" color="error" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.data,
  error: state.error,
});

export default connect(mapStateToProps, { saveIKKOutput, clearErrors })(
  AddPelaporanIKKOutput
);
