import {
  FETCH_BERITA_FAILURE,
  FETCH_BERITA_REQUEST,
  FETCH_BERITA_SUCCESS,
} from "./ActionType";

const initialstate = {
  loading: true,
  data: [],
  oldData: null,
  error: "",
};

export const BeritaReducer = (state = initialstate, action) => {
  switch (action.type) {
    case FETCH_BERITA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_BERITA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    case FETCH_BERITA_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
