import React from "react";
import {
  Text1,
  Text10,
  Text2,
  Text3,
  Text4,
  Text5,
  Text6,
  Text7,
  Text8,
  Text9,
} from "./Paragraph";

const ParagraphView = () => {
  return (
    <div>
      <Text1 text="TEXT 1" />
      <Text2 text="TEXT 2" />
      <Text3 text="TEXT 3" />
      <div className="bg-darkgray">
        <Text4 text="TEXT 4" />
      </div>
      <div className="bg-darkgray">
        <Text5 text="TEXT 5" />
      </div>
      <Text6 text="TEXT 6" />
      <Text7 text="TEXT 7" />
      <Text8 text="TEXT 8" />
      <Text9 text="TEXT 9" />
      <Text10 text="TEXT 10" />
    </div>
  );
};

export default ParagraphView;
