import React, { useEffect, useState } from "react";

import { fetchContactList } from "../../redux/ contact/ContactAction";
import { connect } from "react-redux";
import { Text2 } from "../../mainComponent/Paragraph";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const Contact = (props) => {
  const [data, setData] = useState("");
  useEffect(() => {
    props.fetchContact();
  }, []);

  useEffect(() => {
    if (props.Contact && props.Contact.data && props.Contact.data.data) {
      setData(props.Contact.data.data || "");
    }
  }, [props.Contact]);

  console.log("datanya", data);

  return (
    <div>
      <div className="mb-4">
        <Link to="/admin/contact/edit">
          <Button variant="contained">Edit</Button>
        </Link>
      </div>
      <div className="grid grid-cols-12 gap-1">
        <div
          className="col-span-12 md:col-span-6"
          dangerouslySetInnerHTML={{ __html: data.maps }}
        />
        <div className="col-span-6 mt-4 md:mt-0">
          <Text2 text={`${data.namaWeb}`} />
          <Text2 text={`${data.alamat}`} />
          <Text2 text={`${data.nomorTelp}`} />
          <Text2 text={`${data.facebook}`} />
          <Text2 text={`${data.instagram}`} />
          <Text2 text={`${data.tiktok}`} />
          <Text2 text={`${data.whatsapp}`} />
          <Text2 text={`${data.telegram}`} />
          <Text2 text={`${data.youtube}`} />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    Contact: state.Contact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchContact: () => dispatch(fetchContactList()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Contact);
