import React from "react";
import MainLayout from "../../layout/MainLayout";
import Berita from "../../components/berita/Berita";

const BeritaView = () => {
  return (
    <MainLayout>
      <div className="col-span-12">
        <Berita />
      </div>
    </MainLayout>
  );
};

export default BeritaView;
