import {
  FETCH_DETAIL_IKK_MAKRO_PENGAJUAN_FAILURE,
  FETCH_DETAIL_IKK_MAKRO_PENGAJUAN_REQUEST,
  FETCH_DETAIL_IKK_MAKRO_PENGAJUAN_SUCCESS,
  FETCH_IKK_MAKRO_PENGAJUAN_FAILURE,
  FETCH_IKK_MAKRO_PENGAJUAN_REQUEST,
  FETCH_IKK_MAKRO_PENGAJUAN_SUCCESS,
  FETCH_OLD_IKK_MAKRO_PENGAJUAN_FAILURE,
  FETCH_OLD_IKK_MAKRO_PENGAJUAN_SUCCESS,
  UPDATE_IKK_MAKRO_PENGAJUAN_FAILURE,
  UPDATE_IKK_MAKRO_PENGAJUAN_SUCCESS,
} from "./ActionType";
import axios from "axios";
import API_BASE_URL from "../config";
import Swal from "sweetalert2";

export const updateIKKMakroPengajuanSuccess = (data) => ({
  type: UPDATE_IKK_MAKRO_PENGAJUAN_SUCCESS,
  payload: data,
});

export const updateIKKMakroPengajuanFailure = (error) => ({
  type: UPDATE_IKK_MAKRO_PENGAJUAN_FAILURE,
  payload: error,
});

const fetchIKKMakroPengajuanRequest = () => {
  return {
    type: FETCH_IKK_MAKRO_PENGAJUAN_REQUEST,
  };
};

const fetchIKKMakroPengajuanSuccess = (data) => {
  return {
    type: FETCH_IKK_MAKRO_PENGAJUAN_SUCCESS,
    payload: data,
  };
};

const fetchIKKMakroPengajuanFailure = (err) => {
  return {
    type: FETCH_IKK_MAKRO_PENGAJUAN_FAILURE,
    payload: err,
  };
};

const fetchDetailIKKMakroUserPengajuanRequest = () => {
  return {
    type: FETCH_DETAIL_IKK_MAKRO_PENGAJUAN_REQUEST,
  };
};

const fetchDetailIKKMakroUserPengajuanSuccess = (data) => {
  return {
    type: FETCH_DETAIL_IKK_MAKRO_PENGAJUAN_SUCCESS,
    payload: data,
  };
};

const fetchDetailiIKKMakroUserPengajuanFailure = (err) => {
  return {
    type: FETCH_DETAIL_IKK_MAKRO_PENGAJUAN_FAILURE,
    payload: err,
  };
};

export const fetchIKKMakroPengajuanList = () => {
  return (dispatch) => {
    dispatch(fetchIKKMakroPengajuanRequest());

    // Retrieve the token from session storage
    const token = sessionStorage.getItem("token");
    const role = sessionStorage.getItem("role");

    const apiEndpoint =
      role === "3"
        ? `/api/dashboard/ikk/user-daerah/list`
        : role === "5"
        ? `/api/dashboard/ikk/user-daerah/apip/list`
        : "";

    if (!apiEndpoint) {
      // Handle invalid role
      dispatch(fetchIKKMakroPengajuanFailure("Invalid role"));

      Swal.fire({
        icon: "warning",
        title: "Error!",
        text: "Invalid role access",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
      return;
    }

    // Set the token in the request headers
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${API_BASE_URL}${apiEndpoint}`, axiosConfig)
      .then((res) => {
        let _list = res.data;
        dispatch(fetchIKKMakroPengajuanSuccess(_list));
      })
      .catch((err) => {
        dispatch(fetchIKKMakroPengajuanFailure(err.message));
      });
  };
};

export const clearErrors = () => (dispatch) => {
  dispatch({ type: "CLEAR_ERRORS" });
};

export const fetchDetailIKKMakroUserPengajuanList = (id, year) => {
  return (dispatch) => {
    dispatch(fetchDetailIKKMakroUserPengajuanRequest(id, year));

    // Retrieve the token from session storage
    const token = sessionStorage.getItem("token");
    const role = sessionStorage.getItem("role");
    // console.log("rolenya ", role);
    // console.log("Received parameters:", id, year);

    const apiEndpoint =
      role === "3"
        ? `/api/dashboard/report-ikk-makro-capaian/user_konfirmasi/${id}?year=${year}`
        : role === "5"
        ? `/api/dashboard/report-ikk-makro-capaian/apip/${id}?year=${year}`
        : "";

    if (!apiEndpoint) {
      dispatch(fetchDetailiIKKMakroUserPengajuanFailure("Invalid role"));

      Swal.fire({
        icon: "warning",
        title: "Error!",
        text: "Invalid role access",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
      return;
    }

    // Set the token in the request headers
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${API_BASE_URL}${apiEndpoint}`, axiosConfig)
      .then((res) => {
        let _list = res.data;
        dispatch(fetchDetailIKKMakroUserPengajuanSuccess(_list));
      })
      .catch((err) => {
        dispatch(fetchDetailiIKKMakroUserPengajuanFailure(err.message));
      });
  };
};

export const fetchOldIKKMakroPengajuan = (id) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");
    const response = await axios.get(
      `${API_BASE_URL}/api/dashboard/ikk-makro/update/user_konfirmasi/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: FETCH_OLD_IKK_MAKRO_PENGAJUAN_SUCCESS,
      payload: response.data, // Assuming the API returns the old data
    });
  } catch (error) {
    dispatch({
      type: FETCH_OLD_IKK_MAKRO_PENGAJUAN_FAILURE,
      payload: error.message,
    });
  }
};

export const updateIKKMakroPengajuan = (id, IKKData) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");

    const response = await axios.put(
      `${API_BASE_URL}/api/dashboard/ikk-makro/update/user_konfirmasi/${id}`,
      {
        status_rekomendasi: IKKData.status_rekomendasi,
        catatan_rekomendasi: IKKData.catatan_rekomendasi,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("Update IKK response:", response.data);
    // dispatch({ type: "ADD_URUSAN_SUCCESS", payload: response.data });
    dispatch(updateIKKMakroPengajuanSuccess(response.data));

    Swal.fire({
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      icon: "success",
      title: "Success!",
      text: "Data has been saved successfully.",
    }).then(() => {
      window.location.href = "/admin/ikk";
    });
  } catch (err) {
    // dispatch({ type: "ADD_URUSAN_FAILURE", payload: error.message });
    dispatch(updateIKKMakroPengajuanFailure(err.message));
    if (err.message === "Request failed with status code 401") {
      Swal.fire({
        title: "Error!",
        text: `${err.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then(() => {
        window.location.href = "/admin/login";
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: `${err.message}`,
        icon: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  }
};

export const updateIKKMakroPengajuanAPIP =
  (id, IKKData) => async (dispatch) => {
    console.log("file", IKKData.selectedFile);
    try {
      const token = sessionStorage.getItem("token");

      const response = await axios.put(
        `${API_BASE_URL}/api/dashboard/ikk-makro/update/apip/${id}`,
        {
          ikk: IKKData.ikk,
          satuan: IKKData.satuan,
          bukti_fisik: IKKData.selectedFile,
          diperbarui_pemda: IKKData.diperbarui_pemda,
          diperbarui_apip: IKKData.diperbarui_apip,
          status_apip: IKKData.status_apip,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Update IKK response:", response.data);
      // dispatch({ type: "ADD_URUSAN_SUCCESS", payload: response.data });
      dispatch(updateIKKMakroPengajuanSuccess(response.data));

      Swal.fire({
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        icon: "success",
        title: "Success!",
        text: "Data has been saved successfully.",
      }).then(() => {
        window.location.href = "/admin/ikk";
      });
    } catch (err) {
      // dispatch({ type: "ADD_URUSAN_FAILURE", payload: error.message });
      dispatch(updateIKKMakroPengajuanFailure(err.message));
      if (err.message === "Request failed with status code 401") {
        Swal.fire({
          title: "Error!",
          text: `${err.message}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        }).then(() => {
          window.location.href = "/admin/login";
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: `${err.message}`,
          icon: "warning",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    }
  };
