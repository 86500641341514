import React, { useEffect } from "react";
import { Text2, Text3 } from "../../mainComponent/Paragraph";
import { fetchInformasiList } from "../../redux/webInformasi/InformasiAction";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import defaultImg from "../../assets/default2.png";

const Informasi = (props) => {
  useEffect(() => {
    props.fetchInformasiList();
  }, []);
  const data = props.Informasi.data.data || "";
  if (!Array.isArray(data)) {
    // Handle the case where data is not an array
    return <div>No articles to display.</div>;
  }

  // console.log("perangkat daerah", data);

  return (
    <div className="grid grid-cols-12 gap-2">
      {data.map((item, index) => (
        <div className="col-span-12 md:col-span-4 " key={index}>
          <Text2 text={`${item.title_page}`} />
          {/* <p>category : {item.category.name}</p> */}
          <img
            src={item.imageThumbnailUrl}
            alt="artikel"
            className="rounded-md w-[500px] h-[300px] boder"
            onError={(e) => {
              e.target.src = defaultImg;
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: item.content.slice(0, 100),
            }}
          />

          {/* <Link
            to={`/artikel/detail-artikel/${item.slug}/${item.category.name}`}
          >
            <Text2 text="Lihat Detail" />
          </Link> */}
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Informasi: state.Informasi,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchInformasiList: () => dispatch(fetchInformasiList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Informasi);
