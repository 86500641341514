import React from "react";
import MainLayoutAdmin from "../../../layout/MainLayoutAdmin";
import { Text8 } from "../../../mainComponent/Paragraph";
import Parent from "../../../componentsAdmin/parent/Parent";

const ParentAdm = () => {
  return (
    <MainLayoutAdmin>
      <div>
        <div className="pb-4  border-b border-darkgray mb-8">
          <Text8 text="Parent " />
        </div>
        <Parent />
      </div>
    </MainLayoutAdmin>
  );
};

export default ParentAdm;
