export const FETCH_PELAPORAN_IKK_OUTPUT_REQUEST =
  "FETCH_PELAPORAN_IKK_OUTPUT_REQUEST";
export const FETCH_PELAPORAN_IKK_OUTPUT_SUCCESS =
  "FETCH_PELAPORAN_IKK_OUTPUT_SUCCESS";
export const FETCH_PELAPORAN_IKK_OUTPUT_FAILURE =
  "FETCH_PELAPORAN_IKK_OUTPUT_FAILURE";
export const REDIRECT_TO_LOGIN = "REDIRECT_TO_LOGIN";

export const FETCH_PELAPORAN_IKK_OUTPUT_DETAIL_REQUEST =
  "FETCH_PELAPORAN_IKK_OUTPUT_DETAIL_REQUEST";
export const FETCH_PELAPORAN_IKK_OUTPUT_DETAIL_SUCCESS =
  "FETCH_PELAPORAN_IKK_OUTPUT_DETAIL_SUCCESS";
export const FETCH_PELAPORAN_IKK_OUTPUT_DETAIL_FAILURE =
  "FETCH_PELAPORAN_IKK_OUTPUT_DETAIL_FAILURE";
