import React from "react";
import PelaporanIKK from "../../../componentsAdmin/masterIKK/userPengajuan/PelaporanIKK";
import MainLayoutAdmin from "../../../layout/MainLayoutAdmin";
import { Text8 } from "../../../mainComponent/Paragraph";

const PelaporanIKKUserPengajuanAdm = () => {
  return (
    <MainLayoutAdmin>
      <div>
        <div className="pb-4  border-b border-darkgray mb-8">
          <Text8 text="Pelaporan IKK" />
        </div>
        <PelaporanIKK />
      </div>
    </MainLayoutAdmin>
  );
};

export default PelaporanIKKUserPengajuanAdm;
